import React from 'react'
import { AppFormField } from '../../../../../../../AppComponents/Forms'
import styled from 'styled-components';

interface CouponCodeProps {
    disabled: boolean;
}
const CouponCode = ({ disabled }: CouponCodeProps) => {
    return (
        <Container>
            <AppFormField name="copy_coupon_code" disabled={true} placeholder={"Copy Code"} />
            <AppFormField name="coupon_code" disabled={disabled} placeholder="Enter text for coupon code" charactersLimit={15} />
        </Container>
    )
}

export default CouponCode;

const Container = styled.div`   
&&{
    display: flex;  
    gap: 1rem;
}`