import { Tab, Tabs } from '@mui/material'
import React, { useLayoutEffect, useMemo, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import AppButton from '../../AppComponents/AppButton'
import { device } from '../../Customization/BreakPoints'
import { theme } from '../../Customization/Theme'
import { useNotification } from '../../Hooks/useNotification/useNotification'
import { customFormTabsOptions } from '../../Models/defaultSelectOptions'
import { useAppDispatch, useAppSelector } from '../../Store'
import { useCurrentBot } from '../../Store/Slices/DashboardSlices'
import { FormAction } from '../../Store/Slices/FormBuilder/formbuilder.actions'
import { defaultFieldsIdStoreData, defaultValueStoreData, formBuilderStore, formNameStoreData, formSettingsStoreData, optionsToShowStoreData } from '../../Store/Slices/FormBuilder/formbuilder.selectors'
import { addDefaultFieldsId, addDefaultValue, addSettings, saveConditionLogicInEditFlow, saveFormConfig, saveFormName, setAllFields } from '../../Store/Slices/FormBuilder/formbuilder.slice'
import { formatString } from '../../Utils'
import Navigation from './Components/Navigation'

const CustomFormBuilder = (): JSX.Element => {
    const navigate = useNavigate()
    const location = useLocation();
    const { id } = useParams();
    const { showAlert } = useNotification();
    const dispatch = useAppDispatch();
    const [value, setValue] = useState(0);
    const currentBot = useAppSelector(useCurrentBot);
    const formFieldsData: any = useAppSelector(formBuilderStore);
    const defaultValuesData: any = useAppSelector(defaultValueStoreData);
    const defaultFieldsIdData: any = useAppSelector(defaultFieldsIdStoreData);
    const formSettings: any = useAppSelector(formSettingsStoreData);
    const optionsToShow: any = useAppSelector(optionsToShowStoreData);
    const formDetails: any = useAppSelector(formNameStoreData);

    const formBuilderOptionsMemo = useMemo(() => {
        return customFormTabsOptions.map((option: any) => ({
            ...option,
            url: formatString(option.url, [currentBot.bot_id]),
        }));
    }, [currentBot.bot_id, location]); // eslint-disable-line
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        navigate(formBuilderOptionsMemo[newValue].url);
    };

    const getCurrentTabAfterRefresh = () => {
        customFormTabsOptions?.forEach((item: any, index: number) => {
            if (location.pathname.includes(item?.value)) {
                setValue(index);
            }
        });
    };
    useLayoutEffect(() => {
        (async () => {
            if (id) {
                const res = await dispatch(FormAction.getFormDetails(id));
                const data = JSON.parse(res?.payload?.data?.form?.form_config)
                const fields = data?.fields?.map((item: any) => item.fields)
                const formDetails = res?.payload?.data?.form
                dispatch(addSettings(data?.form_settings))
                dispatch(saveFormName({ id: formDetails?.id, form_name: formDetails?.form_name }))
                dispatch(setAllFields(fields))
                dispatch(addDefaultValue(data?.form_settings?.default_value))
                dispatch(addDefaultFieldsId(data?.form_settings?.default_fields_id))
                dispatch(saveConditionLogicInEditFlow(data?.conditional_logic))
            }
        })();
    }, [id]) // eslint-disable-line

    useLayoutEffect(() => {
        getCurrentTabAfterRefresh();
    }, [location.pathname])// eslint-disable-line

    const generatePayload = async () => {
        const formConfigArray: any = {
            "form_settings": {
                ...formSettings,
                "default_value": defaultValuesData,
                'default_fields_id': defaultFieldsIdData,
                "button_style": {
                    "button_position": formSettings?.button_style?.button_position || "start",
                    "cover_whole_width": formSettings?.button_style?.cover_whole_width,
                    "button_radius": formSettings?.button_style?.button_radius || "5px",
                },
                "show_label": formSettings?.show_label,
                "is_application_widget_form": formSettings?.is_application_widget_form
            },
            "conditional_logic": optionsToShow,
            "fields": formFieldsData?.map((item: any, index: number) => {
                return {
                    label: `row${index + 1}`,
                    fields: item
                }
            }),
        }
        await dispatch(saveFormConfig(formConfigArray))
        const payload = {
            "form_name": formDetails?.form_name,
            "form_description": formDetails?.form_name,
            "form_config": formConfigArray,
            "form_type": "widget_form",
            "template_id": formSettings?.template_id,
            "pdf_download_attachment": formSettings?.pdf_download_attachment,
        };
        if (id) {
            let data = {
                "data": payload,
                "id": id
            }
            dispatch(FormAction.UpdateFormAction(data))
                .then((res: any) => {
                    const responseStatus = res?.payload?.status;
                    if (responseStatus === 200) {
                        showAlert("Form Config is Updated", "success");
                        navigate("/forms/lists");
                    } else {
                        showAlert(res?.payload?.data?.message || res?.payload?.data?.error || "Unable to update form please try again later!", "error");
                    }
                })
                .catch((error: any) => {
                    showAlert(error || "Unable to update form please try again later!", "error");
                });
        } else {
            dispatch(FormAction.CreateFormAction(payload))
                .then((res: any) => {
                    const responseStatus = res?.payload?.status;
                    if (responseStatus === 200) {
                        showAlert("Form Config is Saved", "success");
                        navigate("/forms/lists");
                    } else {
                        showAlert(res?.payload?.data?.message || res?.payload?.data?.error || "Unable to save form please try again later!", "error");
                    }
                }).catch((error: any) => {
                    showAlert(error || "Unable to save form please try again later!", "error");
                });
        }
    }

    return (
        <Container>
            <Navigation isPreviousPageAvaliable={true} previousRoute={"/forms/lists"}
                actionsSection={
                    <React.Fragment>
                        <AppButton onClick={() => generatePayload()}
                            isDisabled={!formDetails?.form_name || !formFieldsData?.flat()?.[0]?.hasOwnProperty("name")}
                        >  Save
                        </AppButton>

                        <AppButton variant="grey" onClick={() => navigate("/forms/lists")}>
                            Cancel
                        </AppButton>
                    </React.Fragment>} />
            <TabsContainer>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    {customFormTabsOptions.map((option: any, index: number) => (
                        <StyledTab
                            label={option.name}
                            key={index}
                            className="website-tabs"
                            id={`simple-tab-${index}`}
                        />
                    ))}
                </Tabs>
            </TabsContainer>
            <DndProvider backend={HTML5Backend}>
                <ContentWrapper>
                    <Outlet />
                </ContentWrapper>
            </DndProvider>
        </Container >
    )
}

const Container = styled.div`
&&{
    height:calc(100vh - 68px);
    background-color: ${theme.palette.default.lightGrey};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}
.website-tabs{
    text-transform: capitalize;
}
`
const ContentWrapper = styled.div`
&& {
    display:flex;
    justify-content: center;
    align-items:center;
    @media ${device.laptop2} {
        display:flex;
        flex-direction:column;
    }
}`

const TabsContainer = styled.div`
&&{
    position:relative;
    background: ${theme.palette.default.white};
    margin: 3px 0;
    padding: 8px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}`
// const EmbedButtonContainer = styled.div`
// &&{
//     position:absolute;
//     right:20px;
//     top:12px;
// }`

const StyledTab = styled(Tab)`

`
export default CustomFormBuilder
