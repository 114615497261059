import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { convertQueryToMongo, initialQuery } from "../../AppComponents/QueryBuilder/utils/utils";
import { theme } from "../../Customization/Theme";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import AppModel from "../../Hooks/useModel/AppModel";
import useModal from "../../Hooks/useModel/useModel";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { ConversationService } from "../../Services/Apis/ConversationService";
import { useAppDispatch, useAppSelector } from "../../Store";
import { ContactsActions } from "../../Store/Slices/Contacts/contacts.action";
import {
  ContactHasUpdateAccess,
  ContactsListingData,
  ContactSortingObject,
  ContactsSelectData,
  contactEditId,
  getFilterQuery,
  getSequenceList
} from "../../Store/Slices/Contacts/contacts.selector";
import { setContactsFilterDrawerStatus, setFilterQuery } from "../../Store/Slices/Contacts/contacts.slice";
import { useCurrentBot } from "../../Store/Slices/DashboardSlices";
import { marketingListActions } from "../../Store/Slices/MarketingList/marketingList.actions";
import Danger from "../../assets/images/danger-live.svg";
import { DeleteCannedResponse } from "../Broadcast/WhatsApp/Templates/TemplatesListing";
import DocumentUploadFirstStep from "../ContactMarketingList/commonComponents/drawer/drawerSteps/DocumentUploadFirstStep";
import LeadTable from "./LeadTable";
import {
  FirstNameColumn,
  IdCoulumns,
} from "./contactDetailedComponents/ContactConsts";
import ContactHeaderFilter from "./contactDetailedComponents/ContactHeaderFilter";
import SwimLaneView from "./swimLaneView/SwimLaneView";

// interface Props extends LoaderComponentProps {}
const LeadsPage = (props: any): JSX.Element => {
  useDocumentTitle("Contacts");
  const [searchParams] = useSearchParams();
  const currentViewId = searchParams.get("view");
  const retrievedValue = localStorage.getItem("grid_view") || null;
  const { showAlert } = useNotification();
  const currentBot = useSelector(useCurrentBot);
  const filterQuery = useAppSelector(getFilterQuery);
  const dispatch = useAppDispatch();
  const dataRows = useAppSelector(ContactsListingData);
  const sortingObject = useAppSelector(ContactSortingObject);
  const hasUpdateAceess: any = useAppSelector(ContactHasUpdateAccess);
  const useModalAddContact = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingContactModal: isShowing, toggleContactModal: toggle };
  };
  const { isShowingContactModal, toggleContactModal } = useModalAddContact();

  const SelectDropDownData = useAppSelector(ContactsSelectData);
  const getSquence = useSelector(getSequenceList);
  // import contact state
  const [firstStep, setFirstStep] = React.useState(false);
  const [openImportDrawer, setOpenImportDrawer] = useState(false);

  const ContactId: any = useAppSelector(contactEditId);
  // const isDrawerOpened: any = useAppSelector(contactsFilterDrawerStatus);
  // states
  const [skeltonLodaer, setSkeltonLoader] = useState(true);
  const [contactHeaderMapping, setContactHeaderMapping] = useState<any>([]);
  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [dynamicsColumn, setDynamicsColumn] = useState<any>([]);
  const [availableFields, setAvailableFields] = useState<any>([]);
  const [shouldApply, setShouldApply] = useState(false);
  const [showSwimlaneview, setShowSwimLaneView] = useState<boolean>(false);
  const [sorting, resetSorting] = useState<boolean>();
  const [initView, setInitView] = useState(false);
  // query builder drawer
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  // select row
  const [selectAll, setSelectAll] = useState<boolean>(false);
  // source id for update modal
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<any>([]);

  const useModal2 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal2: isShowing, toggleModal2: toggle };
  };
  const { isShowingModal2, toggleModal2 } = useModal2();
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    tags: "",
    search: "",
    perPage: { label: "50 per page", value: 50 },
    currentPage: 0,
    mongoQuery: "",
    filter_query: "all_contacts",
    contact_filter: "all_contacts",
    selected_view: "",
  });

  // useEffect(() => {
  //   if (shouldApply === true) {
  //     getLead(true, dynamicsColumn);
  //     setShouldApply(false);
  //   }
  // }, [shouldApply]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDynamicsColumn(getSquence);
  }, [dataRows, getSquence]);
  useEffect(() => {
    if (
      SelectDropDownData &&
      SelectDropDownData.length &&
      contactHeaderMapping.length <= 0
    ) {
      const updatedContactHeader = SelectDropDownData?.map(
        (item: { label: any; name: string }) => {
          setAvailableFields((oldArray: any) => [...oldArray, item.label]);
          let allColumn = {
            label: item.label,
            id: item.name,
            icon: null,
            size: 250,
          };
          if (item.name === "first_name") {
            return [FirstNameColumn];
          } else {
            return [allColumn];
          }
        }
      ).flat();
      setContactHeaderMapping([IdCoulumns, ...updatedContactHeader]);
    }
  }, [SelectDropDownData]); // eslint-disable-line react-hooks/exhaustive-deps

  // drawer
  const toggleDrawer = () => {
    setIsDrawerOpened(!isDrawerOpened);
    // dispatch(setContactsFilterDrawerStatus(!isDrawerOpened));
  };

  const CreateTableHeader = () => {
    if (
      selectedFields &&
      selectedFields.length &&
      contactHeaderMapping.length <= 0
    ) {
      setTimeout(() => {
        setDynamicsColumn(getSquence);
      }, 500);
    } else {
      setSelectedFields(getSquence);
    }
  };

  useEffect(() => {
    CreateTableHeader();
  }, [selectedFields, contactHeaderMapping]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!currentViewId || !localValues?.selected_view?.viewId) return;
    getLead(true, dynamicsColumn);
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (retrievedValue === "true") {
      setShowSwimLaneView(true);
    } else {
      setShowSwimLaneView(false);
    }
    setInitView(true);
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const refreshList = () => {
    getLead(true, dynamicsColumn);
  }
  const getLead = (update?: boolean, updatedList?: any) => {
    if (!retrievedValue) {
      setSkeltonLoader(true);
      let payload;
      if (update) {
        payload = {
          data: {
            search: localValues?.search,
            page_number: localValues?.currentPage,
            page_size: localValues?.perPage?.value,
            columns: updatedList.map((data: any) => {
              return data.name;
            }),
            mongodb_query: convertQueryToMongo(filterQuery),
            contact_filter: localValues?.selected_view?.type === "system" ? localValues?.selected_view?.contact_filter : null,
            filter_query: filterQuery,
            sort_field: localValues?.defaultApply
              ? localValues?.sort_field && localValues?.sort_field === "name"
                ? "first_name"
                : localValues?.sort_field
              : undefined,
            sort_by: localValues?.defaultApply
              ? localValues?.sort_by
              : undefined,
            view_id: currentViewId,
          },
        };
      } else {
        payload = {
          data: {
            search: localValues?.search,
            page_number: localValues?.currentPage,
            page_size: localValues?.perPage?.value,
            contact_filter: localValues?.selected_view?.type === "system" ? localValues?.selected_view?.contact_filter : null,
            sort_field: localValues?.defaultApply
              ? localValues?.sort_field && localValues?.sort_field === "name"
                ? "first_name"
                : localValues?.sort_field
              : undefined,
            sort_by: localValues?.defaultApply
              ? localValues?.sort_by
              : undefined,
            view_id: currentViewId,
          },
        };
      }
      dispatch(ContactsActions.getContactsListData(payload))
        .then((res: any) => {
          if (localValues?.sort_by === -1) {
            resetSorting(true);
          } else {
            resetSorting(false);
          }
          // setIsDrawerOpened(false);
          dispatch(setContactsFilterDrawerStatus(false));
          setShowSwimLaneView(false);
          setTimeout(() => {
            setSkeltonLoader(false);
          }, 200);
        })
        .catch((error: any) => {
          showAlert(
            error?.response?.statusText || "Enabled to fetch Data",
            "error"
          );
          setSkeltonLoader(false);
        });
    }
  };

  useEffect(() => {
    if (retrievedValue === "true") {
      setShowSwimLaneView(true);
      // setIsDrawerOpened(false);
      dispatch(setContactsFilterDrawerStatus(false));
      setSkeltonLoader(false);
    } else {
      setShowSwimLaneView(false);
    }
  }, [retrievedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let payload: any = {
      data: "",
      // headers: headers,
    };
    dispatch(ContactsActions.getContactsSelectData(payload))
      .then()
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {
        // setSkeltonLoader(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // delete rowByid =======**********88
  const deletecontactRowByid = () => {
    toggleModal2();
    setSkeltonLoader(true);
    const headers = {
      appid: JSON.stringify({
        botid: currentBot.bot_id,
      }),
    };
    ConversationService.contactDeletById({ contact_id: ContactId }, headers)
      .then((response: any) => {
        showAlert("contact deleted successfully", "success");
        getLead();
      })
      .catch((error: any) => { })
      .finally(() => {
        setSkeltonLoader(false);
      });
  };

  // view change
  const ChangeView = () => {
    setShowSwimLaneView(!showSwimlaneview);
    if (retrievedValue === "true") {
      localStorage.removeItem("grid_view");
    } else {
      localStorage.setItem("grid_view", "true");
    }
    setLocalValues((prev: any) => ({
      ...prev,
      search: "",
      mongoQuery: "",
      filter_query: "all_contacts",
    }));
  };
  const updateContactList = (list: any) => {
    if (retrievedValue === "true") {
      setShowSwimLaneView(true);
    } else {
      setShowSwimLaneView(false);
    }
    getLead(true, list);
  };

  // Import doc
  const createImportDoc = () => {
    dispatch(marketingListActions.CreateImportID({})).then((res: any) => {
      if (res?.payload?.data?.error) {
        setOpenImportDrawer(false);
        showAlert(res?.payload?.data.error || "Something went wrong", "error");
      } else {
        setOpenImportDrawer(true);
        localStorage.setItem("M_ImportID", res?.payload?.data?.importId);
      }
    });
  };
  useEffect(() => {
    // reset all select initial
    if (
      (selectedDocumentIds && selectedDocumentIds?.length === 0) ||
      (selectedDocumentIds?.length > 0 &&
        selectedDocumentIds?.length !== dataRows.length &&
        selectAll)
    ) {
      setSelectAll(false);
    }
  }, [selectedDocumentIds]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(setFilterQuery(initialQuery));
    }
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ContactMarketingWrap>
      <ContactHeaderFilter
        setShouldApply={setShouldApply}
        setLocalValues={setLocalValues}
        contactHeaderMapping={contactHeaderMapping}
        setContactHeaderMapping={setContactHeaderMapping}
        localValues={localValues}
        selectedDocumentIds={selectedDocumentIds}
        setSelectedDocumentIds={setSelectedDocumentIds}
        availableFields={availableFields}
        selectedFields={selectedFields}
        setSelectedFields={setSelectedFields}
        dynamicsColumn={dynamicsColumn}
        setDynamicsColumn={setDynamicsColumn}
        toggleContactModal={toggleContactModal}
        isShowingContactModal={isShowingContactModal}
        setSelectAll={setSelectAll}
        selectAll={selectAll}
        ChangeView={ChangeView}
        showSwimlaneview={showSwimlaneview}
        toggleDrawer={toggleDrawer}
        isDrawerOpened={isDrawerOpened}
        AllContactPropertiesListdata={SelectDropDownData}
        updateContactList={updateContactList}
        createImportDoc={createImportDoc}
        leadRefresh={refreshList}
        sortingObject={sortingObject}
      />
      {!showSwimlaneview ? (
        <>
          <LeadTable
            loader={skeltonLodaer}
            dynamicsColumn={dynamicsColumn}
            data={dataRows}
            toggleModal2={toggleModal2}
            contactHeaderMapping={contactHeaderMapping}
            setLocalValues={setLocalValues}
            localValues={localValues}
            setShouldApply={setShouldApply}
            toggleContactModal={toggleContactModal}
            setSelectedDocumentIds={setSelectedDocumentIds}
            selectedDocumentIds={selectedDocumentIds}
            createImportDoc={createImportDoc}
            setSelectAll={setSelectAll}
            selectAll={selectAll}
            sorting={sorting}
            hasUpdateAceess={hasUpdateAceess}
            sortingApply={true}
          />
        </>
      ) : (
        <SwimLaneView
          localValues={localValues}
          toggleContactModal={toggleContactModal}
          setShouldApply={setShouldApply}
          shouldApply={shouldApply}
          toggleDrawer={toggleDrawer}
        />
      )}
      {/* contact delete modal */}
      <AppModel
        isShowing={isShowingModal2}
        onClose={() => toggleModal2()}
        divStyle={{
          width: "550px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="danger">
                <img src={Danger} alt="" />
              </div>
              <div className="text">
                <h4>Delete The Listing</h4>
                <p>Are you sure, you want to delete?</p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => toggleModal2()}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => toggleModal2()}>
              Cancel
            </AppButton>
            <AppButton
              variant="danger-filled"
              onClick={() => deletecontactRowByid()}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </AppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>
      {/* drawer import documents*/}
      <Drawer anchor="right" open={openImportDrawer}>
        <DrawerWrapper>
          <DocumentUploadFirstStep
            setFirstStep={setFirstStep}
            setState={setFirstStep}
            state={firstStep}
            setOpenImportDrawer={setOpenImportDrawer}
          />
        </DrawerWrapper>
      </Drawer>
    </ContactMarketingWrap>
  );
};

export default LeadsPage;
const DrawerWrapper = styled.div`
  & {
    // width: 1000px;
    .header {
      border-bottom: 1px solid ${theme.palette.default.grey};
      padding: 0 24px;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        font-size: 20px;
        font-weight: 600;
      }
      .form-steps {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          margin-top: 4px;
          color: ${theme.palette.default.darkGrey};
          font-size: 16px;
        }
      }
    }

    // .body {
    .MuiRadio-root {
      padding: 0px;
    }
    .step-one__wrap {
      padding: 24px;
      height: calc(100vh - 133px);
      overflow: auto;
      .MuiButtonBase-root {
        padding: 0px;
      }
      label {
        color: ${theme.palette.default.black};
        font-size: 14px;
      }
      p.note {
        span {
          font-size: 14px;
          color: ${theme.palette.default.darkGrey};
        }
        margin-bottom: 24px;
      }

      .info {
        margin-top: 48px;
        padding: 0px 24px;
        color: ${theme.palette.default.darkGrey};
      }

      .info ul li {
        margin-bottom: 16px;
      }

      .sample-csv {
        margin-top: 12px;
        color: ${theme.palette.default.darkGrey};
      }
    }
    .step-two__wrap {
      padding: 24px;
      height: calc(100vh - 133px);
      overflow: auto;
      .marketing-list__select {
        border: 1px solid ${theme.palette.default.grey} !important;
        background-color: rgb(245, 246, 248);
        height: 40px;
      }
      .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-disabled {
        border: 1px solid yellow !important;
      }

      h4 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 32px;
      }
      .duplicate {
        .MuiFormGroup-root {
          flex-direction: column;
          gap: 24px;
          margin-bottom: 16px;
        }
        label {
          align-items: flex-start;
          .main_label {
            color: ${theme.palette.default.black};
            font-size: 14px;
            font-weight: 500;
          }
          .info {
          }
        }
      }

      .unique_value {
        label {
          margin-bottom: 10px;
        }
      }

      .subscription {
        .label {
          font-weight: 500;
          font-size: 14px;
        }
        label {
          margin-bottom: 10px;
        }
      }

      .list {
        width: 100%;
      }
    }

    .step-three__wrap {
      padding: 24px;
      height: calc(100vh - 133px);
      overflow: auto;
    }
    // }

    .footer {
      border-top: 1px solid ${theme.palette.default.grey};
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding-right: 24px;
      padding-left: 24px;

      height: 69px;
      align-items: center;
      .step3_text {
        font-size: 14px;
        color: ${theme.palette.default.darkGrey};
      }
    }
  }
`;

const ContactMarketingWrap = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  .customTable {
    // height: calc(100vh - 188px);
  }

  .topmenu {
    padding: 18px 22px;
    background: ${theme.palette.default.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
  }
  .skelton-loader .table-body {
    height: calc(100vh - 227px);
  }
  .custom-btn {
    padding: 0;
  }
  .left-menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .left-part {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .menu-right {
    display: flex;
    align-items: center;
  }
  .data-container {
    margin: 1rem 1rem 0;
    .customTable .icon-btn {
      min-width: 40px;
      min-height: 40px;
    }
  }
  .image {
    padding-top: 24px;
    padding-left: 3rem;
  }
  .norecord {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    /* padding-top: 128px; */
  }
  .data-details {
    display: flex;
    /* align-items:center; */
    justify-content: center;
    padding-top: 120px;
    gap: 72px;
  }
  .data-details p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.darkGrey};
  }
  .Import-details {
    width: 640px;
  }
  .Import-details button {
    width: 148px;
  }
  .Import-details li {
    margin-bottom: 1rem;
  }
  .Import-details p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: ${theme.palette.default.black};
  }
  .button {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .button button {
    /* padding: 0; */
  }
  .norecord-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 128px;
  }
  .norecord-data p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.default.darkGrey};
    text-transform: capitalize;
  }
  .add-list {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 1rem;
    gap: 2rem;
  }
  .add-list button {
    width: 148px;
  }
  .add-list p {
    width: 1115px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: ${theme.palette.default.black};
  }
`;
