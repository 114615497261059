import { useEffect, useState } from 'react'
import styled from 'styled-components';
import AppButton from '../../../AppComponents/AppButton';
import { theme } from '../../../Customization/Theme';
interface AppAccordionProps {
    label: string;
    children: JSX.Element[] | any;
    isDefaultOpen?: boolean;
    isResetAvaliable?: boolean;
}

const AppAccordion = ({ label, children, isDefaultOpen, isResetAvaliable }: AppAccordionProps) => {
    const [open, setOpen] = useState<boolean>(false);
    useEffect(() => {
        isDefaultOpen && setOpen(true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Container>
            <Header onClick={() => setOpen(!open)}>
                <p>{label}</p>
                <div className='actions'>
                    {isResetAvaliable && (
                        <AppButton variant='outline' >
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.99992 1.66659C5.27292 1.66659 3.73729 2.48694 2.76174 3.76174L4.33325 5.33325H0.333252V1.33325L1.81234 2.81234C3.03366 1.30125 4.90364 0.333252 6.99992 0.333252C10.6818 0.333252 13.6666 3.31802 13.6666 6.99992H12.3333C12.3333 4.0544 9.94545 1.66659 6.99992 1.66659ZM1.66659 6.99992C1.66659 9.94545 4.0544 12.3333 6.99992 12.3333C8.72692 12.3333 10.2626 11.5129 11.2381 10.2381L9.66658 8.66658H13.6666V12.6666L12.1875 11.1875C10.9662 12.6986 9.09618 13.6666 6.99992 13.6666C3.31802 13.6666 0.333252 10.6818 0.333252 6.99992H1.66659Z" fill="#5B73E8" />
                            </svg>
                            Reset
                        </AppButton>
                    )}
                    {open ? <AccordionCloseIcon /> : <AccordionOpenIcon />}
                </div>
            </Header>
            <AccoordionBody>
                {
                    open ? <>{children}</> : null
                }
            </AccoordionBody>
        </Container>
    )
}

const Container = styled.div`
&{
    margin: 1rem 0;
}`

const Header = styled.div`
&&{
    height: 50px;
    display:flex;
    justify-content: space-between;
    align-items:center;
    padding: 0 10px;
    cursor:pointer;
    background: rgba(91, 115, 232, 0.1); 
    border-bottom: 1px solid ${theme.palette.primary.light};
    p{
        color: ${theme.palette.primary.light};
    }
    .actions{
        gap:8px;
        display:flex;
        align-items:center;
    }
}`

//need to manage max height
const AccoordionBody = styled.div`
&&{
    overflow-y:auto;
    padding: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
}`

const AccordionCloseIcon = () => {
    return (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.9997 3.05016L11.9495 7.99988L13.3637 6.58567L6.9997 0.221658L0.635742 6.58567L2.04996 7.99988L6.9997 3.05016Z" fill="#5B73E8" />
        </svg>
    )
}
const AccordionOpenIcon = () => {
    return (
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.9997 5.1714L11.9495 0.22168L13.3637 1.63589L6.9997 7.9999L0.635742 1.63589L2.04996 0.22168L6.9997 5.1714Z" fill="#5B73E8" />
        </svg>

    )
}
export default AppAccordion