import { useFormikContext } from 'formik';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useAppSelector } from '../../../../../../Store';
import { getTemplateButtonsLengthChecker } from '../../../../../../Store/Slices/Whatsapp/whatsapp.selectors';
import { AppMaterialIcons } from '../../../../../../AppComponents/AppMaterialIcons';
import AppFormCheckboxField from '../../../../../../AppComponents/Forms/AppFormCheckboxField';
import CouponCode from "./RenderDynamicButtonFields/CouponCode";
import WebsitUrlField from "./RenderDynamicButtonFields/WebsitUrlField";
import PhoneNumber from "./RenderDynamicButtonFields/PhoneNumber";
import QuickRepliesButton from './RenderDynamicButtonFields/QuickRepliesButton';
import styled from 'styled-components';

const RenderDynamicButton: React.FC<IRenderDynamicButtonTypes> = ({
  disabled = false,
}) => {
  const { setFieldValue, values } = useFormikContext<any>();
  const buttonsLengthChecker = useAppSelector(getTemplateButtonsLengthChecker)
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return;
    const newItems = Array.from(values?.copyUrlOptions);
    const [reorderedItem]: any = newItems.splice(result?.source?.index, 1);
    newItems.splice(result?.destination.index, 0, reorderedItem);
    // Check if 'quick_replies' is at the 1st or 2nd position and move it to the last position
    const quickRepliesIndex = newItems.findIndex((item: any) => item.name === 'add_quick_replies');
    if (quickRepliesIndex === 1 || quickRepliesIndex === 2) {
      const [quickRepliesItem] = newItems.splice(quickRepliesIndex, 1);
      newItems.push(quickRepliesItem);
    }
    setFieldValue('copyUrlOptions', newItems);
  };


  return (
    <Container>
      <p className='heading'>Copy Code, URL, Quick Replies etc</p>
      <p className='sub_heading'>Create buttons that let customers respond to your message or take action.</p>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {values?.copyUrlOptions?.map((item: any, index: number) => (
                <Draggable key={item.name} draggableId={item.name} index={index}>
                  {(provided) => {
                    return (
                      <span
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          padding: '8px',
                          margin: '4px'
                        }}
                      >
                        <SortableItem disable>
                          <AppMaterialIcons iconName={"DragIndicator"} />
                          <AppFormCheckboxField
                            name={`copyUrlOptions.[${index}].${item?.name}`}
                            label={item?.label}
                            disabled={buttonsLengthChecker || disabled}
                            checked={item.checked}
                            onChange={() => setFieldValue(`copyUrlOptions.[${index}].checked`, !item.checked)}
                          />
                        </SortableItem>
                        {item.checked && renderComponentByType(item.name, { disabled })}
                      </span>
                    )
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
};

const SortableItem = ({ id, children }: any) => {
  const style = {
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  };
  return (
    <div style={style}>
      {children}
    </div>
  );
};

const renderComponentByType = (name: any, props: any) => {
  switch (name) {
    case 'add_coupon_code':
      return <CouponCode {...props} />;
    case 'add_website_url':
      return <WebsitUrlField {...props} />;
    case 'add_phone_number':
      return <PhoneNumber {...props} />;
    case 'add_quick_replies':
      return <QuickRepliesButton {...props} />;
    default:
      return null;
  }
};

export default RenderDynamicButton;

const Container = styled.div`
&&{
    display:flex;
    flex-direction:column;
    justify-content:start;
    .heading{
        font-size: 15px;
        display: block;
    }
    .sub_heading{
        font-size: 15px;
        font-weight: 400;
        margin: 0;
        color: rgba(126, 131, 146, 1);
    }
}`

