import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { LeftCol, RightCol, TopBar, WrapperContainer } from "./HistoryStyles";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import { marketingListActions } from "../../../../Store/Slices/MarketingList/marketingList.actions";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import BroadcastHistory from "../../../../AppComponents/Forms/AppMarketingListDetails";
import { broadcastActions } from "../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import DetailsTable from "../../../MainDashboard/DeatilsTable/DetailsTable";
import { IconButton, Typography } from "@mui/material";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import AppRangeDatePicker from "../../../../AppComponents/AppRangeDatePicker";
import { dateRange } from "../../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { setDashboardDateRange } from "../../../../Store/Slices/DashboardRedux/dashboard.slice";
import { format } from "date-fns";
import AppPaginationComponent from "../../../../AppComponents/Pagination/Pagination";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../../../HOC/HocBackdropLoader";
import AppSkeletonTableLoader from "../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
interface Props extends HocBackdropLoaderProps {
}
const WhatsappBroadcastHistory: React.FC<Props> = ({ setLoading }) => {
  const dispatch = useAppDispatch();
  const dashdateRange: any = useAppSelector(dateRange);
  const broadcast_id = useParams();
  const navigate = useNavigate();
  const [errorApi, setErrorApi] = useState(false);
  const [statsData, setStatsData] = useState<any>([]);
  const [pagination, setPagination] = useState<any>();
  const [whatsappTotalMessagesLogsData, setWhatsappTotalMessagesLogsData] = useState<any>()
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    totalPages: 0,
  });
  let { showAlert } = useNotification();
  const getStatsData = () => {
    dispatch(
      broadcastActions.getBroadcastStatsData(broadcast_id?.broadcast_id)
    )
      .then((res: any) => {
        setStatsData(res?.payload?.data?.broadcast_stats);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => { });
  };
  const convertResponseToTableColumns = (data: any[]) => {
    let columns: any = {};
    data.forEach((item: any) => {
      columns[item?.key] = item?.label;
    });
    return columns
  }

  const onChange = (date: any) => {
    let temp = { ...date };
    delete temp.label;
    dispatch(setDashboardDateRange(temp));
  };
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };
  const handleBackClick = () => {
    navigate('/broadcast/whatsapp/history');
  };
  const getLogs = () => {
    // setLoading(true)
    setWhatsappTotalMessagesLogsData({})
    let data = {
      "log_type": "broadcast_logs",
      "broadcast_id": broadcast_id?.broadcast_id,
      "start_date": format(dashdateRange.startDate, "yyyy-MM-dd"),
      "end_date": format(dashdateRange.endDate, "yyyy-MM-dd"),
      "limit": localValues?.itemsPerPage?.value,
      "offset": localValues?.currentPage,
    }
    dispatch(
      broadcastActions.getWhatsappLogs(data)
    )
      .then((res: any) => {
        // setLoading(false)
        setPagination(res?.payload?.data?.data?.pagination)
        setWhatsappTotalMessagesLogsData(res?.payload?.data?.data)
      })
      .catch((error: any) => { });
  }
  useEffect(() => {
    getLogs()
    getStatsData()
  }, [dashdateRange, localValues])

  return (
    <>
      <WrapperContainer style={{ height: "calc(100vh - 114px)"}}>
        <TopBar>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
            <IconButton onClick={() => handleBackClick()}>
              <AppMaterialIcons
                iconName={"ArrowBackIosRounded"}
                style={{ fontSize: "0.8rem", color: "black" }}
              />
            </IconButton>
            <Typography fontSize="13px" fontWeight="500" component="h6">
              history
            </Typography>
          </Box>
          <AppRangeDatePicker
            dateRange={dashdateRange}
            setDateRange={onChange}
          />
        </TopBar>
        {whatsappTotalMessagesLogsData && whatsappTotalMessagesLogsData["logs"] ?

          <DetailsTable
            keyToHeaderMap={convertResponseToTableColumns(whatsappTotalMessagesLogsData?.label_mapping ?? [])}
            tableData={whatsappTotalMessagesLogsData && whatsappTotalMessagesLogsData["logs"] || []}
            border={false}
            tableHeaderColor={true}
            pagination={true}
            height={"calc(100vh - 202px)"}
          />
          : <AppSkeletonTableLoader
            numberOfCol={4}
            numberofRow={10}
            skeletonHeight={30}
            skeletonWidth={"100%"}
            skeletonHeaderHeight={40}
            skeletonHeaderWidth={"100%"}
            tableHeight={`calc(100vh - ${335}px)`}
          />}
      </WrapperContainer>
      {whatsappTotalMessagesLogsData && whatsappTotalMessagesLogsData["logs"]?.length ? (
        <AppPaginationComponent
          totalCount={pagination?.totalRecords}
          handlePageChange={handlePageChange}
          currentPage={localValues?.currentPage}
          totalPages={pagination?.totalPages}
          rowsPerPage={localValues?.itemsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          title="Contacts"
        />
      ) : null}
    </>
  );
};

// Default Export of component
export default HocBackdropLoader(WhatsappBroadcastHistory);
