import { Box, MenuItem, Select, Typography, debounce } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { format } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../HOC/HocBackdropLoader";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { filtersValuesData, logListDataObj, whatapplogListDataObj } from "../../Store/Slices/BroadcastDashboard/Broadcast.selectors";
import { dateRange } from "../../Store/Slices/DashboardRedux/dashboard.selectors";
import { broadcastActions } from "../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { marketingListActions } from "../../Store/Slices/MarketingList/marketingList.actions";
import { AppForm } from "../../AppComponents/Forms";
import { TableFilter, TableWrapper } from "../../TableStyle";
import AppFormSearchField from "../../AppComponents/Forms/AppFormSearchField";
import AppButton from "../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { NewNativeSelect } from "../../AppComponents/Forms/Styles";
import AppSkeletonTableLoader from "../../Components/UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { BroadcastBodyWrapper, SmsMenuFilter, BroadcastMainWrapper } from "../../Components/Broadcast/SMS/SMS_Dashboard/SMS_Dashboard_Insights/Logs/Logs2/LogsTableStyles";
import NoRecordsFound from "../../Components/UiComponents/NoRecordsFound/NoRecordsFound";
import AppRangeDatePicker from "../../AppComponents/AppRangeDatePicker";
import { setDashboardDateRange } from "../../Store/Slices/DashboardRedux/dashboard.slice";

interface Props extends HocBackdropLoaderProps {
  type: string,
  header: any,
  keyToHeaderMap: any,
  colWidth: any,
  categoryForWhatsapp?: string,
  tableDateRange?: string,
  showDateRange?: boolean,
  tableHeight?: string,
}
const BroadcastLogsTable: React.FC<Props> = ({
  type,
  header,
  keyToHeaderMap,
  colWidth,
  categoryForWhatsapp,
  tableDateRange,
  showDateRange = true,
  tableHeight,
}) => {


  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const filtersValues = useSelector(filtersValuesData);
  const logListData = useSelector(logListDataObj);
  const totalcount = logListData?.count;
  const list = logListData?.results;
  const [logsData, setLogsData] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<any>("");
  const [loading, setLoading] = useState(false)

  // Whatsapp
  const logListDataWhatsapp = useSelector(whatapplogListDataObj);
  const whatsAppList = logListDataWhatsapp?.logs;
  const [logsDataWhatsapp, setLogsDataWhatsapp] = useState(false);
  const [errorApiWhatsapp, setErrorApiWhatsapp] = useState(false);
  const [isDownloadingWhatsapp, setIsDownloadingWhatsapp] = useState<boolean>(false);
  const [filterTypeWhatsapp, setFilterTypeWhatsapp] = useState<any>("");
  const [loadingWhatsapp, setLoadingWhatsapp] = useState(false);


  const [mainListing, setMainListing] = useState<any>([])
  const [mainPagination, setMainPagination] = useState<any>([])



  const [localValues, setLocalValues] = useState<any>({
    status: "",
    startDate: null,
    endDate: null,
    search: "",
    itemsPerPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    broadcast_type: type,
    destination: "",
    sender_id: "",
    unit: "",
    direction: "",
    source: ""
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const dashdateRange: any = useAppSelector(dateRange);


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
      currentPage: 0,
    });
    setLogsData(true);
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
    setLogsData(true);
  };

  // Whatsapp
  const getWhatsappLogsData = () => {
    setLoading(true);
    dispatch(


      //       2024-05-31
      // end_date: 2024-06-06
      broadcastActions.getWhatappDetailsLogs({
        start: tableDateRange || format(dashdateRange.startDate, "yyyy-MM-dd"),
        end: tableDateRange || format(dashdateRange.endDate, "yyyy-MM-dd"),
        limit: localValues.itemsPerPage?.value,
        offset: localValues?.currentPage,
        status: localValues?.status,
        search: localValues?.search,
        type: localValues?.broadcast_type,
        source: localValues?.source,
        category: categoryForWhatsapp || ""
      })
    )
      .then((res: any) => {
        if (res?.payload?.status === 500) {
          setErrorApi(true);
          setLoading(false);
        } else {
          setErrorApi(false);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
        setLoading(false);
      });
  };

  // Eamail And SMS
  const getLogsData = () => {

    setLoading(true);

    let payload;

    if (type === "sms") {

      payload = {
        start: tableDateRange || format(dashdateRange.startDate, "yyyy-MM-dd"),
        end: tableDateRange || format(dashdateRange.endDate, "yyyy-MM-dd"),
        limit: localValues.itemsPerPage?.value,
        offset: localValues?.currentPage,
        status: localValues?.status,
        search: localValues?.search,
        type: localValues?.broadcast_type,
        destination: localValues?.destination,
        sender_id: localValues?.sender_id,
        unit: localValues?.unit,
        direction: localValues?.direction,
        source: localValues?.source,
      }

    } else if (type === "email") {
      payload = {
        start: tableDateRange || format(dashdateRange.startDate, "yyyy-MM-dd"),
        end: tableDateRange || format(dashdateRange.endDate, "yyyy-MM-dd"),
        limit: localValues.itemsPerPage?.value,
        offset: localValues.currentPage,
        status: localValues?.status,
        search: localValues?.search,
        type: localValues?.broadcast_type,
      }
    }

    dispatch(
      broadcastActions.getAllCommunicationLogs(payload)
    )
      .then((res: any) => {
        if (res?.payload?.status === 500) {
          setErrorApi(true);
          setLoading(false);
        } else {
          setErrorApi(false);
          setLoading(false);
        }
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
        setLoading(false);
      });
  };

  const getValueForFilterLogs = () => {
    dispatch(
      broadcastActions.getAllCommunicationFilterSettings({ type: type })
    )
      .then((res: any) => { })
      .catch((error: any) => { });
  };

  const restFilter = () => {
    setLocalValues({
      status: "",
      startDate: null,
      endDate: null,
      search: "",
      itemsPerPage: { label: "10  per page", value: 10 },
      currentPage: 0,
      broadcast_type: type,
      destination: "",
      sender_id: "",
      unit: "",
      direction: "",
      source: "",
    });
    setLogsData(true);
    handleClose();
  };

  const applyFilter = () => {
    setLogsData(true);
    handleClose();
  };

  useEffect(() => {
    if (dashdateRange?.startDate) {
      if (type === "whatsapp") {
        getWhatsappLogsData()
      } else {
        getLogsData();
        setLogsData(false);
        getValueForFilterLogs();
      }

    }
  }, [logsData, dashdateRange, localValues, type]);



  // function handlePageChange(event: any, value: any) {
  //   setLocalValues({
  //     ...localValues,
  //     currentPage: value,
  //   });
  //   setLogsData(true);
  // }
  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setLocalValues({
  //     ...localValues,
  //     itemsPerPage: parseInt(event.target.value),
  //     currentPage: 0,
  //   });
  //   setLogsData(true);
  // };

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
    setLogsData(true);
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
    setLogsData(true);
  };

  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
      currentPage: 0,
    });
    setLogsData(false);
  };

  const handleSelectChangeEmail = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      status: e.target.value,
    });
  };

  const handleSelectChangeWhatsapp = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
      currentPage: 0,
    });
    setLogsData(false);
  };


  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format("DD MMM, YYYY HH:mm A");
    return date;
  };

  useEffect(() => {
    handleExportClick();
  }, [filterType && isDownloading]);

  const handleExportClick = () => {
    if (filterType) {
      setIsDownloading(true);
      let payload: any;
      if (filterType === "filter") {
        payload = {
          start: format(dashdateRange.startDate, "yyyy-MM-dd"),
          end: format(dashdateRange.endDate, "yyyy-MM-dd"),
          status: localValues?.status,
          search: localValues?.search,
          type: type,
          destination: localValues?.destination,
          sender_id: localValues?.sender_id,
          unit: localValues?.unit,
          direction: localValues?.direction,
          filter_type: filterType,
        };
      }
      if (filterType === "all") {
        payload = {
          type: type,
          filter_type: filterType,
        };
      }
      dispatch(marketingListActions.getCommunicationLogsDownload(payload))
        .then((res: any) => {
          window.open(res?.payload?.data?.data.source, "_blank");
        })
        .catch((error: any) => {
          console.error("Error occurred while fetching the file URL.", error);
        });
      setIsDownloading(false);
      setFilterType("");
    }
  };

  const renderSelectIconStatus = localValues.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconSenderId = localValues.sender_id ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconUnit = localValues.unit ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconDestination = localValues.destination ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconDirection = localValues.direction ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconSource = localValues.source ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  const onChange = (date: any) => {
    dispatch(setDashboardDateRange(date));
  };

  useEffect(() => {
    if (type === "sms" || type === "email") {
      setMainListing(logListData?.results)
      setMainPagination(logListData?.pagination)
    } else {
      setMainListing(logListDataWhatsapp?.logs)
      setMainPagination(logListDataWhatsapp?.pagination)
    }
  }, [type, logListData, logListDataWhatsapp])

  return (
    <BroadcastMainWrapper style={{ padding: 0 }}>
      <BroadcastBodyWrapper style={{ padding: 0 }}>
        <AppForm
          initialValues={{ search: "" }}
          onSubmit={(values: any, submitProps: any) => { }}
          divStyle={{ width: "100%" }}
        >
          <TableFilter border={false} style={{ padding: "0px 16px" }}>
            <div className="filter-left">
              <AppFormSearchField
                name="search"
                placeholder="Search"
                defaultBorder={true}
                onChange={(e: any) => handleSelectSearch(e, "search")}
                clearCallback={(e: any) => clearCallback(e, "search")}
                divStyle={{
                  marginBottom: "0",
                  width: "100%",
                  minWidth: "100%",
                }}
                disabled={logsData}
              />
            </div>
            <div className="filter-left">



              {
                type === "whatsapp" && <>

                  <AppButton
                    variant="grey"
                    style={{ width: "100%" }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <AppMaterialIcons iconName={"Sort"} />
                    <span style={{ marginLeft: "0.7rem" }}>Filter</span>
                  </AppButton>
                  <SmsMenuFilter
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <div className="setting-page-mainboxtopbar__right">
                      <MenuItem>
                        <p>Status</p>
                        {/* Select 1 */}
                        <NewNativeSelect>
                          <Select
                            defaultValue={undefined}
                            name="status"
                            value={localValues?.status}
                            onChange={(e: any) => handleSelectChangeWhatsapp(e, "status")}
                            displayEmpty
                            className="new-select-main"
                          >
                            <MenuItem value="" disabled>
                              {" "}
                              Select an option
                            </MenuItem>
                            {/* {filtersValues?.status?.map(
                            (item: any, index: any) => ( */}
                            <MenuItem value={"sent"} key={1}>
                              {"Sent"}
                            </MenuItem>
                            <MenuItem value={"delivered"} key={2}>
                              {"Delivered"}
                            </MenuItem>
                            <MenuItem value={"seen"} key={3}>
                              {"Seen"}
                            </MenuItem>
                            <MenuItem value={"replied"} key={4}>
                              {"Replied"}
                            </MenuItem>
                            <MenuItem value={"failed"} key={5}>
                              {"Failed"}
                            </MenuItem>

                            {/* )
                          )} */}
                          </Select>
                          <div
                            className="clear-icon"
                            onClick={(e: any) => clearCallback(e, "status")}
                          >
                            {renderSelectIconStatus}
                          </div>
                        </NewNativeSelect>
                      </MenuItem>
                      <MenuItem>
                        <p>Source</p>
                        {/* Select 2 */}
                        <NewNativeSelect>
                          <Select
                            defaultValue={undefined}
                            name="source"
                            value={localValues?.source}
                            onChange={(e: any) =>
                              handleSelectChangeWhatsapp(e, "source")
                            }
                            displayEmpty
                            className="new-select-main"
                          >
                            <MenuItem value="" disabled>
                              {" "}
                              Select an option
                            </MenuItem>
                            {/* {filtersValues?.sender_ids?.map(
                            (item: any, index: any) => ( */}
                            <MenuItem value={"automation"} key={1}>
                              {"Automation"}
                            </MenuItem>
                            <MenuItem value={"broadcast"} key={1}>
                              {"Broadcast"}
                            </MenuItem>
                            <MenuItem value={"single"} key={1}>
                              {"Single"}
                            </MenuItem>
                            {/* )
                          )} */}
                          </Select>
                          <div
                            className="clear-icon"
                            onClick={(e: any) => clearCallback(e, "source")}
                          >
                            {renderSelectIconSource}
                          </div>
                        </NewNativeSelect>
                      </MenuItem>
                    </div>
                    <hr />
                    <div className="setting-page-mainboxtopbar__right">
                      <Box display="flex" sx={{ gap: "1rem" }}>
                        {/* <AppButton
                        style={{ minWidth: "95px" }}
                        onClick={() => {
                          restFilter();
                        }}
                      >
                        Reset
                      </AppButton> */}
                        <AppButton
                          variant="outline"
                          style={{ minWidth: "95px" }}
                          onClick={() => {
                            restFilter();
                          }}
                        >
                          Reset
                        </AppButton>
                      </Box>
                    </div>
                  </SmsMenuFilter>

                </>
              }


              {type === "email" && <NewNativeSelect>
                <Select
                  defaultValue={""}
                  name="status"
                  value={localValues?.status}
                  onChange={(e: any) => handleSelectChangeEmail(e, "status")}
                  displayEmpty
                  className="new-select-main"
                  style={{
                    height: "27px",
                    fontSize: "14px",
                  }}
                >
                  <MenuItem value=""> Filter By Status </MenuItem>
                  {filtersValues?.status?.map((item: any, index: any) => (
                    <MenuItem value={item?.value} key={index}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
                <div
                  className="clear-icon"
                  onClick={(e: any) => clearCallback(e, "status")}
                >
                  {renderSelectIconStatus}
                </div>
              </NewNativeSelect>}

              {type === "sms" && <>

                <AppButton
                  variant="grey"
                  style={{ width: "100%" }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <AppMaterialIcons iconName={"Sort"} />
                  <span style={{ marginLeft: "0.7rem" }}>Filter</span>
                </AppButton>
                <SmsMenuFilter
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <div className="setting-page-mainboxtopbar__right">
                    <MenuItem>
                      <p>Status</p>
                      {/* Select 1 */}
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="status"
                          value={localValues?.status}
                          onChange={(e: any) => handleSelectChange(e, "status")}
                          displayEmpty
                          className="new-select-main"
                        >
                          <MenuItem value="" disabled>
                            {" "}
                            Select an option
                          </MenuItem>
                          {filtersValues?.status?.map(
                            (item: any, index: any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "status")}
                        >
                          {renderSelectIconStatus}
                        </div>
                      </NewNativeSelect>
                    </MenuItem>
                    <MenuItem>
                      <p>Sender Ids</p>
                      {/* Select 2 */}
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="sender_id"
                          value={localValues?.sender_id}
                          onChange={(e: any) =>
                            handleSelectChange(e, "sender_id")
                          }
                          displayEmpty
                          className="new-select-main"
                        >
                          <MenuItem value="" disabled>
                            {" "}
                            Select an option
                          </MenuItem>
                          {filtersValues?.sender_ids?.map(
                            (item: any, index: any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "sender_id")}
                        >
                          {renderSelectIconSenderId}
                        </div>
                      </NewNativeSelect>
                    </MenuItem>
                    <MenuItem>
                      <p>Unit</p>
                      {/* Select 3 */}
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="unit"
                          value={localValues?.unit}
                          onChange={(e: any) => handleSelectChange(e, "unit")}
                          displayEmpty
                          className="new-select-main"
                        >
                          <MenuItem value="" disabled>
                            {" "}
                            Select an option
                          </MenuItem>
                          {filtersValues?.units?.map(
                            (item: any, index: any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "unit")}
                        >
                          {renderSelectIconUnit}
                        </div>
                      </NewNativeSelect>
                    </MenuItem>
                    <MenuItem>
                      <p>Destinations</p>
                      {/* Select 4 */}
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="destination"
                          value={localValues?.destination}
                          onChange={(e: any) =>
                            handleSelectChange(e, "destination")
                          }
                          displayEmpty
                          className="new-select-main"
                        >
                          <MenuItem value="" disabled>
                            {" "}
                            Select an option
                          </MenuItem>
                          {filtersValues?.destinations?.map(
                            (item: any, index: any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "destination")}
                        >
                          {renderSelectIconDestination}
                        </div>
                      </NewNativeSelect>
                    </MenuItem>
                    <MenuItem>
                      <p>Directions</p>
                      {/* Select 5 */}
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="direction"
                          value={localValues?.direction}
                          onChange={(e: any) =>
                            handleSelectChange(e, "direction")
                          }
                          displayEmpty
                          className="new-select-main"
                        >
                          <MenuItem value="" disabled>
                            {" "}
                            Select an option
                          </MenuItem>
                          {filtersValues?.directions?.map(
                            (item: any, index: any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "direction")}
                        >
                          {renderSelectIconDirection}
                        </div>
                      </NewNativeSelect>
                    </MenuItem>
                    <MenuItem>
                      <p>Source</p>
                      {/* Select 5 */}
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="source"
                          value={localValues?.source}
                          onChange={(e: any) =>
                            handleSelectChange(e, "source")
                          }
                          displayEmpty
                          className="new-select-main"
                        >
                          <MenuItem value="" disabled>
                            {" "}
                            Select an option
                          </MenuItem>
                          {filtersValues?.sources?.map(
                            (item: any, index: any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "source")}
                        >
                          {renderSelectIconDirection}
                        </div>
                      </NewNativeSelect>
                    </MenuItem>
                  </div>
                  <hr />
                  <div className="setting-page-mainboxtopbar__right">
                    <Box display="flex" sx={{ gap: "1rem" }}>
                      <AppButton
                        style={{ minWidth: "95px" }}
                        onClick={() => {
                          restFilter();
                        }}
                      >
                        Reset
                      </AppButton>
                      <AppButton
                        variant="outline"
                        style={{ minWidth: "95px" }}
                        onClick={() => {
                          applyFilter();
                        }}
                      >
                        Apply
                      </AppButton>
                    </Box>
                  </div>
                </SmsMenuFilter>

              </>}

              {
                showDateRange && <AppRangeDatePicker
                  dateRange={dashdateRange}
                  setDateRange={onChange}
                />
              }


            </div>
          </TableFilter>
        </AppForm>
      </BroadcastBodyWrapper>

      {
        loading ? (<AppSkeletonTableLoader
          numberOfCol={4}
          numberofRow={10}
          skeletonHeight={30}
          skeletonWidth={"100%"}
          skeletonHeaderHeight={40}
          skeletonHeaderWidth={"100%"}
          tableHeight={"calc(100vh - 356px)"}
        />) : <>
          <TableWrapper border={false} height={mainListing?.length ? tableHeight : ""} className="sms-insights-table">
            <div>
              <Box className="table-header" style={{ zIndex: "0" }}>
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{ minWidth: colWidth[header] + "px" }}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                {/* Table Body */}
              </Box>
              <Box className="table-body">
                {errorApi || mainListing?.length === 0 ? (
                  <NoRecordsFound
                    imageWidth={"170px"}
                    headerTextSize={"20px"}
                    height="calc(100vh - 334px)"
                  />
                ) : (
                  <>
                    {mainListing?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className="table-row">
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                  header === "Date"
                                    ? formatDateField(
                                      row[keyToHeaderMap[header]]
                                    )
                                    : row[keyToHeaderMap[header]]
                                      ? row[keyToHeaderMap[header]]
                                      : "-"
                                }
                                style={{
                                  // maxWidth: isActionColumn
                                  //   ? "400px"
                                  //   : colWidth[header] + "px",
                                  minWidth: isActionColumn
                                    ? "400px"
                                    : colWidth[header] + "px",
                                }}
                              >
                                <Typography
                                  className="table-text"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textTransform: header === "From" && type === "email" ? "lowercase" : ""
                                  }}
                                >
                                  {
                                    header === "Date"
                                      ? formatDateField(row[keyToHeaderMap[header]])
                                      : header === "Status"
                                        ? row[keyToHeaderMap[header]]
                                          ?.charAt(0)
                                          ?.toUpperCase() + row[keyToHeaderMap[header]]?.slice(1)
                                        : header === "Source"
                                          ? row[keyToHeaderMap[header]]
                                            ? row[keyToHeaderMap[header]].replace(/_/g, " ")
                                            : "-"
                                          : row[keyToHeaderMap[header]]
                                            ? row[keyToHeaderMap[header]]
                                            : "-"
                                  }
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
            </div>
          </TableWrapper>
          {mainListing?.length ? (
            <AppPaginationComponent
              totalCount={mainPagination?.totalRecords}
              handlePageChange={handlePageChange}
              currentPage={localValues?.currentPage}
              totalPages={mainPagination?.totalPages}
              rowsPerPage={localValues?.itemsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </>
      }
    </BroadcastMainWrapper>
  );
};

// Default Export of component
export default HocBackdropLoader(BroadcastLogsTable);
