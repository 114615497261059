import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import * as Yup from "yup"
import { AppForm, AppFormColorPicker, AppFormField, AppFormObserver, AppFormSelectField, AppSubmitButton } from '../../../../../AppComponents/Forms'
import AppFileUploadField from '../../../../../AppComponents/Forms/AppFileUploadField'
import AppFormCheckboxField from "../../../../../AppComponents/Forms/AppFormCheckboxField"
import { theme } from '../../../../../Customization/Theme'
import { useNotification } from '../../../../../Hooks/useNotification/useNotification'
import { ChatbotConsoleService } from '../../../../../Services/Apis/ChatbotConsoleService'
import { useAppDispatch, useAppSelector } from "../../../../../Store"
import { formBuilderStore, formSettingsStoreData } from '../../../../../Store/Slices/FormBuilder/formbuilder.selectors'
import { addSettings } from "../../../../../Store/Slices/FormBuilder/formbuilder.slice"
import { SMSActions } from '../../../../../Store/Slices/SMS/sms.actions'
import { applicationWidgetFormOptions, buttonPositionOptions, coverWholeWidthOptions, showLableOptions } from '../../../Components/constants'
import { Box } from '@mui/material'
import AppReactSelectField from '../../../../../AppComponents/Forms/AppFormReactSelect'

const validationSchema = Yup.object().shape({
  capcha_id: Yup.string()
    .label("Capcha Id")
    .min(3, "Declaration must be at least 3 characters"),
  declaration: Yup.string()
    .label("Declaration")
    .min(5, "Declaration must be at least 5 characters"),
  button_position: Yup.string().required("Button Position is required"),
  cover_whole_width: Yup.string().required("Cover Whole Width is required"),
  pdf_download_attachment: Yup.string(),
  file_download_name: Yup.string().max(30, "File Name should not be more than 30 characters"),
  button_name: Yup.string().max(30, "Button Text should not be more than 30 characters"),
});
const GeneralSetting = (): JSX.Element => {
  const formRef: any = useRef()
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const formSettings: any = useAppSelector(formSettingsStoreData);
  const formFieldsData: any = useAppSelector(formBuilderStore);
  const [formikProps, setFormikProps] = useState<any>(null);
  const [templateOptions, setTemplateOptions] = useState<any>([]);
  const [paramsOptions] = useState<any>(formFieldsData?.flat()?.filter((field: any) => field?.type !== "dropdown"))
  useEffect(() => {
    const payload: any = {
      limit: 999,
      offset: 0,
    };
    dispatch(SMSActions.getSMSTemplateListData(payload))
      .then((res: any) => {
        let tempArray: any = [];
        res?.payload.data?.results?.map((ele: any) => {
          if (ele.status === "APPROVED") {
            tempArray.push({
              id: ele.id,
              name: ele.name,
              sender_ids: ele.sender_ids,
            });
          }
          return null;
        });
        setTemplateOptions(tempArray);
      }).then(() => {
      }).catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch SMS Templates List for now please try again later.",
          "error"
        );
      })
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AppForm
      initialValues={{
        capcha_id: formSettings?.capcha_id || "",
        declaration: formSettings?.declaration || "",
        declaration_value: formSettings?.declaration_value || false,
        button_position: formSettings?.button_style?.button_position || "",
        cover_whole_width: formSettings?.button_style?.cover_whole_width === true ? "yes" : "no" || "no",
        button_name: formSettings?.button_name || "",
        show_label: formSettings?.show_label === true ? "yes" : "no" || "",
        button_background_color: formSettings?.button_background_color || "",
        button_text_color: formSettings?.button_text_color || "",
        is_application_widget_form: formSettings?.is_application_widget_form === true ? "yes" : "no" || "",
        pdf_download_avaliable: formSettings?.pdf_download_avaliable || false,
        pdf_download_attachment: formSettings?.pdf_download_attachment || "",
        button_radius: formSettings?.button_style?.button_radius || "",
        is_opt_required: formSettings?.is_opt_required || false,
        template_id: formSettings?.template_id || "",
        pdf_download_details: formSettings?.pdf_download_details || "",
        file_download_name: formSettings?.file_download_name || "",
        pdf_attachment_url: formSettings?.pdf_attachment_url || "",
        params: formSettings?.params || [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values: any, formikProps: any) => {
        const formBuilderObject: any = {
          capcha_id: values.capcha_id,
          declaration: values.declaration,
          declaration_value: values.declaration_value,
          button_style: {
            button_position: values.button_position,
            cover_whole_width: values.cover_whole_width === "yes" ? true : false,
            button_radius: values.button_radius,
          },
          show_label: values.show_label === "yes" ? true : false,
          button_background_color: values.button_background_color,
          button_text_color: values.button_text_color,
          button_name: values.button_name,
          is_application_widget_form: values.is_application_widget_form === "yes" ? true : false,
          pdf_download_avaliable: values.pdf_download_avaliable,
          pdf_download_details: values?.pdf_download_details,
          pdf_download_attachment: values?.pdf_download_attachment,
          is_opt_required: values.is_opt_required,
          template_id: values?.template_id || "",
          file_download_name: values?.file_download_name,
          pdf_attachment_url: values?.pdf_attachment_url,
          params: values?.params,
        }
        showAlert("General Settings Saved Successfully", "success");
        dispatch(addSettings(formBuilderObject))
        formikProps.setSubmitting(false)
      }}
      innerRef={formRef}
    >
      <Container>
        <FieldLabel>General Settings</FieldLabel>
        <div className='fields-container'>
          <AppFormField
            name="capcha_id"
            errorMessage=''
            label='Google Captcha Site Key'
            placeholder='Google Captcha Site Key'
            defaultBorder={true} labelStyle={{ color: theme.palette.default.newFontColor }}
          />
          <AppFormField
            name="declaration"
            errorMessage=''
            label='Declaration Text'
            placeholder='Declaration Text'
            defaultBorder={true} labelStyle={{ color: theme.palette.default.newFontColor }}
          />
          <AppFormSelectField
            name="is_application_widget_form"
            Options={applicationWidgetFormOptions}
            popOverMaxWidth={"100%"}
            label='Application Widget Form'
            labelStyle={{ color: theme.palette.default.newFontColor }}
            enableSearch
            divStyle={{
              maxWidth: "100%",
            }}
          />
        </div>

        <div className='fields-container'>
          <AppFormSelectField
            name="show_label"
            Options={showLableOptions}
            popOverMaxWidth={"100%"}
            label='Show Label'
            labelStyle={{ color: theme.palette.default.newFontColor }}
            enableSearch
            divStyle={{ maxWidth: "32.50%" }}
          />
          <AppFormCheckboxField name="declaration_value" label={"Keep default Declaration Selected ?"} />
        </div>

        <FieldLabel>Button Settings</FieldLabel>
        <div className='fields-container'>
          <AppFormField
            name="button_name"
            errorMessage='' label='Button Text'
            placeholder="Enter Button Text"
            defaultBorder={true} labelStyle={{ color: theme.palette.default.newFontColor }}
          />
          <AppFormSelectField
            name="cover_whole_width"
            Options={coverWholeWidthOptions}
            popOverMaxWidth={"100%"}
            label='Full Size Button'
            labelStyle={{ color: theme.palette.default.newFontColor }}
            enableSearch
            divStyle={{
              maxWidth: "100%",
            }}
          />

          <AppFormSelectField
            name="button_position"
            Options={buttonPositionOptions}
            popOverMaxWidth={"100%"}
            label='Button Position'
            labelStyle={{ color: theme.palette.default.newFontColor }}
            enableSearch
            divStyle={{
              maxWidth: "100%",
            }}
          />
        </div>

        <div className='fields-container'>
          <AppFormColorPicker
            label="Button Text Color"
            name="button_text_color"
            labelStyle={{ color: theme.palette.default.newFontColor }}
          />

          <AppFormColorPicker
            label="Button Background Color"
            name="button_background_color"
            labelStyle={{ color: theme.palette.default.newFontColor }}
          />

          <AppFormField
            name="button_radius"
            errorMessage=''
            label='Button Radius'
            placeholder='Enter Button Radius In PX'
            defaultBorder={true}
            labelStyle={{ color: theme.palette.default.newFontColor }}
          />
        </div>

        <FieldLabel>File Download Settings</FieldLabel>
        <div className='fields-container'>
          <AppFormCheckboxField divStyle={{
            width: "33.33%",
          }} name="pdf_download_avaliable" label={"Download PDF"}
            onChange={(e: any) => {
              if (e.target.checked) {
                formikProps.setFieldValue('pdf_attachment_url', '');
              } else {
                formikProps.setFieldValue('pdf_download_attachment', '');
                formikProps.setFieldValue('file_download_name', '');
              }
            }}
          />
          <Box sx={{ minWidth: "33.33%" }}>
            <AppFileUploadField
              name="pdf_download_attachment"
              uploadFile={ChatbotConsoleService.uploadFile}
              label={"Upload PDF File To Generate URL"}
              detailsKey={"pdf_download_details"}
              disabled={!formRef?.current?.values?.pdf_download_avaliable}
            />
          </Box>
          <AppFormField
            name="file_download_name"
            errorMessage=''
            label='File Name For Download'
            placeholder='Enter File Name For Download'
            defaultBorder={true}
            labelStyle={{ color: theme.palette.default.newFontColor }}
            disabled={!formRef?.current?.values?.pdf_download_avaliable}
            divStyle={{
              width: "33.33%"
            }}
          />
        </div>
        <div className='fields-container'>
          <AppFormField
            name="pdf_attachment_url"
            errorMessage=''
            label='PDF URL'
            placeholder='Enter PDF URL'
            defaultBorder={true}
            labelStyle={{ color: theme.palette.default.newFontColor }}
            disabled={formRef?.current?.values?.pdf_download_avaliable}
            divStyle={{
              width: "33.33%"
            }}
          />
        </div>

        <FieldLabel>OPT Settings</FieldLabel>
        <div className='fields-container'>
          <div style={{ minWidth: "32.50%" }}>
            <AppFormCheckboxField name="is_opt_required" label={"OTP Validation Required?"}
              onChange={(e: any) => {
                if (!e.target.checked) {
                  formikProps.setFieldValue('template_id', '');
                }
              }}
            />
          </div>
          {formRef?.current?.values?.is_opt_required ?
            <AppFormSelectField
              name="template_id"
              Options={templateOptions}
              popOverMaxWidth={"100%"}
              errorMessage=''
              label='Choose SMS Template'
              placeholder='Select SMS Template'
              defaultBorder={true}
              labelStyle={{ color: theme.palette.default.newFontColor }}
              divStyle={{ maxWidth: "32.50%" }}
            />
            : null}
        </div>
        <FieldLabel>URL Parameter Settings</FieldLabel>
        <div className='fields-container' style={{ width: "32%" }}>
          <AppReactSelectField
            name={"params"}
            options={paramsOptions}
            label={"Select URL Parameters"}
            displayKey="label"
            valueKey="id"
            isSearchable={true}
            isClearable={true}
            isMulti={true}
            hideSelectedOptions={true}
            divStyle={{ background: "none", padding: "0" }}
            optionsPlaceholder="No Options Found"
          />
        </div>
        <AppFormObserver setFormikProps={setFormikProps} />
        <div style={{ width: "250px", marginTop: "10px" }}>
          <AppSubmitButton title="Save" variant={"primarydark"} />
        </div>
      </Container>
    </AppForm >
  )
}
const Container = styled.div`
&&{
  // max-width: 90%;
  padding: 16px;
    .infoText{
      color:${theme.palette.default.darkGrey};
      padding: 0.5rem 0;
    }
    .fields-container{
      display:flex;
      align-items:center;
      gap:20px;
    }
}`

const FieldLabel = styled('p')({
  color: theme.palette.default.newFontColor,
  fontSize: "1.1rem",
  fontWeight: 600,
  padding: "0.8rem 0",
});


export default GeneralSetting;
