import React from "react";
import { Route, Routes } from "react-router-dom";
import App404 from "../AppComponents/App404";
import GoogleIndex from "../Components/Integrations/Other/GoogleSheets/GoogleIndex";
import ZohoIndex from "../Components/Integrations/Other/Zoho/ZohoIndex";
import EmailBroadcastHistory from "../Components/Broadcast/Email/BroadCastHistory/EmailBroadcastHistory";
import EmailBroadcastCreate from "../Components/Broadcast/Email/ScheduledBroadcast/EmailBroadcastCreate";
import EmailScheduledBroadcast from "../Components/Broadcast/Email/ScheduledBroadcast/EmailScheduledBroadcast";
import EmailCardTemplatesListing from "../Components/Broadcast/Email/Template/EmailCardTemplateListing";
import { EmailCreateTemplate } from "../Components/Broadcast/Email/Template/EmailCreateTemplate";
import SmsTemplateListing from "../Components/Broadcast/SMS/BroadCastTemplate/SmsTemplateListing";
import UploadTemplate from "../Components/Broadcast/SMS/BroadCastTemplate/UploadTemplate";
import SmsBroadcastHistory from "../Components/Broadcast/SMS/BroadcastHistory/SmsBroadCastHistory";
import SmsLogs from "../Components/Broadcast/SMS/Logs/Logs";
import SmsLogsReport from "../Components/Broadcast/SMS/Logs/Reports/Reports";
import CreateBroadcast from "../Components/Broadcast/SMS/ScheduledBroadcast/CreateBroadcast";
import SMSScheduledBroadcast from "../Components/Broadcast/SMS/ScheduledBroadcast/SMSScheduledBroadcast";
import SenderIDListing from "../Components/Broadcast/SMS/SenderID/SenderIDlisting";
// import WhatsappAutomation from "../Components/Broadcast/WhatsApp/Automation/WhatsappAutomation";
import WhatsappBroadcastHistory from "../Components/Broadcast/WhatsApp/BroadcastHistory/WhatsappBroadcastHistory";
import WhatsappDetails from "../Components/Broadcast/WhatsApp/BroadcastHistory/WhatsappDetails";
import WhatsappPreferences from "../Components/Broadcast/WhatsApp/Preferences/WhatsappPreferences";
import BroadcastCreate from "../Components/Broadcast/WhatsApp/ScheduledBroadcast/BroadcastCreate";
import WhatsappScheduledBroadcast from "../Components/Broadcast/WhatsApp/ScheduledBroadcast/WhatsappScheduledBroadcast";
import TemplateCreate from "../Components/Broadcast/WhatsApp/Templates/TemplateCreate";
import TemplatesListing from "../Components/Broadcast/WhatsApp/Templates/TemplatesListing";
import ChatHistory from "../Components/ChatHistory/ChatHistory";
import ContactMarketingImportDeatails from "../Components/ContactMarketingList/ContactMarketingImportDeatails";
import ContactMarketingImport from "../Components/ContactMarketingList/ContactMarketingImportHistory";
import ContactMarketingList from "../Components/ContactMarketingList/ContactMarketingList";
import FacebookIndex from "../Components/Integrations/Channels/Facebook/FacebookIndex";
import InstagramIndex from "../Components/Integrations/Channels/Instagram/InstagramIndex";
import WhatsappIntegration from "../Components/Integrations/Channels/Whatsapp/WhatsappIntegration";
import Integrations from "../Components/Integrations/Integrations";
import ShopifyIndex from "../Components/Integrations/Other/Shopify/ShopifyIndex";
import BuildLayout from "../Components/Layouts/BuildLayout";
import BusinessHours from "../Components/Settings/ChatSettings/BusinessHours/BusinessHours";
import CannedResponse from "../Components/Settings/ChatSettings/CannedResponse/CannedResponse";
import ChatAssignment from "../Components/Settings/ChatSettings/ChatAssignment/ChatAssignment";
import InactivityTimeout from "../Components/Settings/ChatSettings/InactivityTimeout/InactivityTimeout";
import ChatLabels from "../Components/Settings/ChatSettings/Labels/ChatLabels";
import CreateLifeCycle from "../Components/Settings/DataManagement/LifecycleStage/CreateLifeCycle";
import EmailSettings from "../Components/Settings/Email";
import DomainVerification from "../Components/Settings/Email/Domain/DomainVerification";
import RolesPermissions from "../Components/Settings/UsersAndPermissions/RolesPermissions/RolesPermissions";
import AddUser from "../Components/Settings/UsersAndPermissions/Users/AddUser";
import Users from "../Components/Settings/UsersAndPermissions/Users/Users";
import WhatsAppForm from "../Components/Settings/Whatsapp/WhatsappSetup/form";
import EmailValidation from "../Components/Welcome/EmailValidation";
import Home from "../socket/screens/Home";
import Automation from "../Components/AutomationBuilder/Pages/Automation";
import AutomationBuilder from "../Components/AutomationBuilder/Index";
import AutoAssignmentRulesIndex from "../Components/Settings/AutoAssignment/AutoAssignmentRules/AutoAssignmentRulesIndex";
import AutoAssignmentTabs from "../Components/Settings/AutoAssignment/AutoAssignmentRules/AutoAssignmentTabs";
import AutoAssignmentListing from "../Components/Settings/AutoAssignment/AutoAssignmentRules/Contacts/AutoAssignmentListing";
import ContactsAutoAssignmentRule from "../Components/Settings/AutoAssignment/AutoAssignmentRules/Contacts/ContactsAutoAssignmentRule";
import { SidebarLayoutWrapper } from "../AppComponents/SidebarAccordion";
import ContactsExportHistory from "../Components/Leads/ContactsExportHistory";
import EmailLogs from "../Components/Broadcast/Email/Logs/EmailLogs";
import EmailReports from "../Components/Broadcast/Email/Logs/EmailReports/EmailReports";
import DashboardLogs from "../Components/Broadcast/SMS/SMS_Dashboard/SMS_Dashboard_Insights/Logs/Logs2/DashboardLogs";
import SmsBroadcastDashboardSummary from "../Components/Broadcast/SMS/SMS_Dashboard/SMS_Dashboard_Insights/Logs/Overview/SmsBroadcastDashboardSummary";
import { SMSTopbar } from "../Components/Broadcast/SMS/SMS_Dashboard/SMS_Dashboard_Insights/Logs/SMSTopbar";
import SmsDashboardLogs from "../Components/Broadcast/SMS/SMS_Dashboard/SMS_dashboard_byId/SMS_Dashboard_Logs/SmsDashboardLogs";
import SmsBroadcastDashboard from "../Components/Broadcast/SMS/SMS_Dashboard/SMS_dashboard_byId/SMS_Dashboard_Overview/SmsBroadcastDashboard";
import { SmsMenubar } from "../Components/Broadcast/SMS/SMS_Dashboard/SMS_dashboard_byId/SmsMenubar";
import WelcomeSmsBroadcast from "../Components/Broadcast/WelComeSmsBroadcast";
import WelcomeEmailBroadcast from "../Components/Broadcast/WelcomeEmailBroadcast";
import WelcomeWhatsappBroadcast from "../Components/Broadcast/WelcomeWhatsappBroadcast";
import EmailDashboardLogs from "../Components/Broadcast_Dashboard/Email_Broadcast_Logs/EmailDashboardLogs";
import EmailDashboardOverview from "../Components/Broadcast_Dashboard/Email_Broadcast_Overview/Email_broadcast";
import EmailDashboardSummary from "../Components/Broadcast_Dashboard/Email_Broadcast_Overview_Summary/EmailBroadcastSummary";
import { TopMenubarSummary } from "../Components/Broadcast_Dashboard/Email_Broadcast_Overview_Summary/EmailDashboardTopbar";
import EmailLogsInsights from "../Components/Broadcast_Dashboard/Email_Broadcast_Overview_Summary/EmailLogsInsights";
import { TopMenubar } from "../Components/Broadcast_Dashboard/TopMenubar";
import Agent from "../Components/Dashboard/Agent_Dashboard/Agent";
import ParentBot from "../Components/Dashboard/Bot_Dashboard/ParentBot";
import Summary from "../Components/Dashboard/Summary";
import Homepage from "../Components/Homepage";
import BlankLayout from "../Components/Layouts/BlankLayout";
import LeadsPage from "../Components/Leads/LeadsPage";
import SegmentListFn from "../Components/Segment/SegmentList";
import SegmentContactList from "../Components/Segment/Segment_ContactList";
import Permissions from "../Components/Settings/UsersAndPermissions/RolesPermissions/Tabs/Permission";
import WallectInvoices from "../Components/Subscription/Credit_List/WallectInvoiceList";
import Invoices from "../Components/Subscription/InvoicesList";
import PlanDetails from "../Components/Subscription/PlanDetails";
import Plans from "../Components/Subscription/SubscriptionPlanList";
import Subscription from "../Components/Subscription/Subscriptionplan";
import SubscriptionSuccess from "../Components/Subscription/SuccessSubscription";
import RouteGuard from "./RouteGuard";
import LeadsDetailsPage from "../Components/Leads/contactDetailedComponents/SubPages/BasicInformation/LeadsDetailsPage";
import { ProtectedAccessDeniedPage } from "../AppComponents/ProtectedComponents";
import LifecycleListing from "../Components/Settings/DataManagement/LifecycleStage/LifecycleListing";
import DependencyFiledList from "../Components/Settings/ContactModule/Dependencies/DependencyFiledList";
import ContactProperties from "../Components/Settings/ContactModule/ContactProperties";
import LeadCommunicationStats from "../Components/Leads/contactDetailedComponents/SubPages/LeadCommunicationStats";
import LeadActivities from "../Components/Leads/contactDetailedComponents/SubPages/Activities/LeadActivities";
import HomeRouteIndex from "../Components/HomeRouteIndex/Index";
import LeadFiles from "../Components/Leads/contactDetailedComponents/SubPages/Files/LeadFile";
import LeadNotes from "../Components/Leads/contactDetailedComponents/SubPages/Activities/LeadNotes";
import LeadTask from "../Components/Leads/contactDetailedComponents/SubPages/Activities/LeadContactTask";

//extra imports
import {
  getBroadcastMenuKeyName,
  getBroadcastMenuLogo,
} from "../Components/Broadcast/Sidebar/navigationConst";
import { ContactSideBarMenu } from "../Components/Leads/contactDetailedComponents/Sidebar/ContactSidebar";
import CommonTabHeader from "../Components/Leads/contactDetailedComponents/Sidebar/CommonTabHeader";
import FacebookSettings from "../Components/Integrations/Channels/Facebook/FacebookSettings";
import FormBuilder from "../Components/FormBuilder";
import StylePreview from "../Components/FormBuilder/Tabs/StylePreview/StylePreview";
import PaymentSettings from "../Components/FormBuilder/Tabs/Setting/components/PaymentSetting";
import ScheduleSetting from "../Components/FormBuilder/Tabs/Setting/components/ScheduleSetting";
import ThankYouPageSetting from "../Components/FormBuilder/Tabs/Setting/components/ThankYouPageSetting";
import NotificationsSetting from "../Components/FormBuilder/Tabs/Setting/components/NotificationSetting";
import GeneralSetting from "../Components/FormBuilder/Tabs/Setting/components/GeneralSetting";
import Forms from "../Components/FormBuilder/Tabs/Form/Forms";
import CustomFormBuilder from "../Components/FormBuilder/CustomFormBuilder";
// import ChooseFormType from "../Components/FormBuilder/ChooseFormType";
import Setting from "../Components/FormBuilder/Tabs/Setting/Setting";
import ApplicationFormListing from "../Components/ApplicationForm";
import MainDashboard from "../Components/MainDashboard/MainDashboard";
import ApplicationSummaryDetails from "../Components/MainDashboard/ApplicationSummaryDetails/ApplicationSummaryDetails";
import TrackingExpenses from "../Components/Subscription/TrackingExpenses/TrackingExpenses";
import { WhatsappTopbar } from "../Components/Broadcast/WhatsApp/Whatsapp_Dashboard/Whatsapp_Dashboard_Insights/Logs/WhatsappTopbar";
import WhatsappBroadcastDashboardSummary from "../Components/Broadcast/WhatsApp/Whatsapp_Dashboard/Whatsapp_Dashboard_Insights/Logs/Overview/WhatsappBroadcastDashboardSummary";
// import WhatsappDashboardLogs from "../Components/Broadcast/WhatsApp/Whatsapp_Dashboard/Whatsapp_dashboard_byId/Whatsapp_Dashboard_Logs/WhatsappDashboardLogs";
import WhatsappTemplateAnalytics from "../Components/Broadcast/WhatsApp/Templates/WhatsappTemplateAnalytics/WhatsappTemplateAnalytics";
import BillingSummary from "../Components/Subscription/Biling-summary";
import WhatsappBillingLogs from "../Components/Subscription/Biling-summary/WhatsappBillingLogs";

// Whatsapp Flow Bulder Routes
import WhatsappBuilder from "../Whatsapp/src/whatsAppWapper"
import FlowCreateScreen from "../Whatsapp/src/flowCreateScreen"
import FlowBuilderList from "../Whatsapp/src/flowBuilderList"
import WhatsappBroadcastHistoryLogs from "../Components/Broadcast/WhatsApp/ScheduledBroadcast/BroadcastLogsTable";

const BuildRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BuildLayout />}>
        <Route path="/zoho-callback" element={<ZohoIndex />} />
        <Route path="/google-sheet-callback" element={<GoogleIndex />} />
        <Route path="/404" element={<App404 />} />
        <Route path="/verify" element={<EmailValidation />} />
        <Route path="/forbidden" element={<ProtectedAccessDeniedPage />} />

        {/*************** Homepage ************** */}
        <Route path="/" element={<Homepage />} />
      </Route>
      {/*************** ANALYTICS ************** */}
      <Route
        path="/analytics"
        element={<RouteGuard moduleId="analytics" element={<BlankLayout />} />}
      >
        <Route
          path="dashboard"
          element={
            <RouteGuard
              moduleId="application_forms"
              element={<MainDashboard />}
            />
          }
        />

        {/* <Route
          path="dashboard/:id/:key"
          element={
            <RouteGuard moduleId="conversation_summary" element={<ApplicationSummaryDetails />} />
          }
        /> */}

        {/* Rout For Lcoal storage */}

        <Route
          path="dashboard/:id"
          element={
            <RouteGuard
              moduleId="conversation_summary"
              element={<ApplicationSummaryDetails />}
            />
          }
        />

        <Route
          path="dashboard-summary"
          element={
            <RouteGuard moduleId="conversation_summary" element={<Summary />} />
          }
        />
        <Route
          path="dashboard-agent"
          element={<RouteGuard moduleId="agent_summary" element={<Agent />} />}
        />
        <Route
          path="dashboard-bot"
          element={
            <RouteGuard moduleId="bot_summary" element={<ParentBot />} />
          }
        />
      </Route>

      {/*************** CONVERSATION INBOX ************** */}
      <Route
        path="/conversations"
        element={
          <RouteGuard moduleId="conversation_inbox" element={<ChatHistory />} />
        }
      >
        <Route path="chats" element={<Home />} />
      </Route>

      {/*************** CONTACTS ************** */}
      <Route
        path="/contacts"
        element={
          <RouteGuard
            moduleId="contacts"
            element={<BlankLayout layoutWithDiv={true} />}
          />
        }
      >
        <Route
          path="list"
          element={<RouteGuard moduleId="contacts" element={<LeadsPage />} />}
        />
        <Route
          path="list/:view_id"
          element={<RouteGuard moduleId="contacts" element={<LeadsPage />} />}
        />
        <Route
          path="view/:contact_id"
          element={
            <RouteGuard
              moduleId="contacts"
              element={
                <SidebarLayoutWrapper
                  sidebarMenuOptions={ContactSideBarMenu}
                  menuDisplayType={"menu"}
                  sidebarTitle="Contact Details"
                  outletCommonHeader={<CommonTabHeader />}
                  dynamicRouteSlug="contact_id"
                  layoutWithDiv={true}
                />
              }
            />
          }
        >
          <Route path="basic-information" element={<LeadsDetailsPage />} />
          <Route
            path="communication-stats"
            element={<LeadCommunicationStats />}
          />
          <Route path="files" element={<LeadFiles />} />
          <Route path="activities" element={<LeadActivities />}>
            <Route path="tasks" element={<LeadTask />} />
            <Route path="notes" element={<LeadNotes />} />
          </Route>
        </Route>

        <Route
          path="segment"
          element={<RouteGuard moduleId="segments" element={<BlankLayout />} />}
        >
          <Route path="list" element={<SegmentListFn />} />
          <Route path="list/:seg_id" element={<SegmentContactList />} />
        </Route>

        <Route
          path="marketing"
          element={
            <RouteGuard moduleId="marketing_list" element={<BlankLayout />} />
          }
        >
          <Route path="list" element={<ContactMarketingList />} />
          <Route
            path="detail/:id"
            element={<ContactMarketingImportDeatails />}
          />
        </Route>
      </Route>

      {/*************** BROADCAST ************** */}
      <Route
        path="/broadcast"
        element={
          <RouteGuard
            moduleId="broadcast"
            element={
              <SidebarLayoutWrapper
                menuOptionsKeyName={getBroadcastMenuKeyName()}
                Logo={getBroadcastMenuLogo()}
                menuDisplayType="menu"
              />
            }
          />
        }
      >
        {/*************** BROADCAST /WHATSAPP ************** */}
        <Route
          path="whatsapp"
          element={
            <RouteGuard
              moduleId="whatsapp_broadcast"
              element={<BlankLayout />}
            />
          }
        >
          <Route
            index
            element={
              <HomeRouteIndex
                dynamicMenuKeyName="whatsapp_broadcast_sidebar_menu"
                routeIndexPath="/broadcast/whatsapp"
              />
            }
          />
          <Route
            index
            element={
              <HomeRouteIndex
                dynamicMenuKeyName="whatsapp_broadcast_sidebar_menu"
                routeIndexPath="/broadcast/whatsapp"
              />
            }
          />
          <Route
            path="dashboard"
            element={
              <RouteGuard
                moduleId="whatsapp_insights"
                element={<WhatsappTopbar />}
              />
            }
          >
            <Route
              path="summary"
              element={<WhatsappBroadcastDashboardSummary />}
            />
            <Route path="logs" element={<WhatsappBroadcastHistoryLogs />} />
          </Route>

          <Route path="start" element={<WelcomeWhatsappBroadcast />} />
          <Route
            path="history"
            element={
              <RouteGuard
                moduleId="whatsapp_broadcast_crud"
                element={<WhatsappBroadcastHistory />}
              />
            }
          />
          <Route
            path="scheduled"
            element={
              <RouteGuard
                moduleId="whatsapp_broadcast_crud"
                element={<WhatsappScheduledBroadcast />}
              />
            }
          />
          <Route
            path="templates"
            element={
              <RouteGuard
                moduleId="whatsapp_template"
                element={<TemplatesListing />}
              />
            }
          />
          <Route
            path="templates/:id"
            element={
              <RouteGuard
                moduleId="whatsapp_template"
                element={<WhatsappTemplateAnalytics />}
              />
            }
          />
          <Route
            path="preferences"
            element={
              <RouteGuard
                moduleId="whatsapp_preferences"
                element={<WhatsappPreferences />}
              />
            }
          />
          {/* <Route
            path="automation"
            element={
              <RouteGuard
                moduleId="whatsapp_automation"
                element={<WhatsappAutomation />}
              />
            }
          /> */}

          <Route
            path="broadcast-details/:broadcast_id"
            element={
              <RouteGuard
                moduleId="whatsapp_broadcast_crud"
                element={<WhatsappDetails />}
              />
            }
          />

          <Route
            path="create-broadcast/:step_id"
            element={
              <RouteGuard
                moduleId="whatsapp_broadcast_crud"
                element={<BroadcastCreate />}
              />
            }
          />
          <Route
            path="edit-broadcast/:step_id/:broadcast_id"
            element={
              <RouteGuard
                moduleId="whatsapp_broadcast_crud"
                element={<BroadcastCreate />}
              />
            }
          />
          <Route
            path="create-template"
            element={
              <RouteGuard
                moduleId="whatsapp_template"
                element={<TemplateCreate />}
              />
            }
          />
          <Route
            path="edit-template/:template_id"
            element={
              <RouteGuard
                moduleId="whatsapp_template"
                element={<TemplateCreate />}
              />
            }
          />
          <Route
            path="view-template/:template_id"
            element={
              <RouteGuard
                moduleId="whatsapp_template"
                element={<TemplateCreate />}
              />
            }
          />
        </Route>

        {/*************** BROADCAST /SMS ************** */}
        <Route
          path="sms"
          element={
            <RouteGuard moduleId="sms_broadcast" element={<BlankLayout />} />
          }
        >
          <Route
            index
            element={
              <HomeRouteIndex
                dynamicMenuKeyName="sms_broadcast_sidebar_menu"
                routeIndexPath="/broadcast/sms"
              />
            }
          />
          <Route
            path="dashboard"
            element={
              <RouteGuard moduleId="sms_insights" element={<SMSTopbar />} />
            }
          >
            <Route path="summary" element={<SmsBroadcastDashboardSummary />} />
            <Route path="logs" element={<DashboardLogs />} />
          </Route>

          <Route path="start" element={<WelcomeSmsBroadcast />} />

          <Route
            path="history"
            element={
              <RouteGuard
                moduleId="sms_broadcast_crud"
                element={<SmsBroadcastHistory />}
              />
            }
          />
          <Route
            path="scheduled"
            element={
              <RouteGuard
                moduleId="sms_broadcast_crud"
                element={<SMSScheduledBroadcast />}
              />
            }
          />
          <Route
            path="template"
            element={
              <RouteGuard
                moduleId="sms_template"
                element={<SmsTemplateListing />}
              />
            }
          />
          <Route
            path="sender_id"
            element={
              <RouteGuard moduleId="sender_ids" element={<SenderIDListing />} />
            }
          />
          <Route
            path="logs"
            element={<RouteGuard moduleId="sms_logs" element={<SmsLogs />} />}
          />
          <Route
            path="reports"
            element={
              <RouteGuard moduleId="sms_reports" element={<SmsLogsReport />} />
            }
          />

          <Route
            path="broadcast-details/:broadcast_id"
            element={
              <RouteGuard
                moduleId="sms_broadcast_crud"
                element={<SmsMenubar />}
              />
            }
          >
            <Route path="overview" element={<SmsBroadcastDashboard />} />
            <Route path="logs" element={<SmsDashboardLogs />} />
          </Route>
          <Route path="upload-template" element={<UploadTemplate />} />
          <Route
            path="create-broadcast/:step_id"
            element={
              <RouteGuard
                moduleId="sms_template"
                element={<CreateBroadcast />}
              />
            }
          />
          <Route
            path="edit-broadcast/:step_id/:broadcast_id"
            element={
              <RouteGuard
                moduleId="sms_broadcast_crud"
                element={<CreateBroadcast />}
              />
            }
          />
        </Route>

        {/*************** BROADCAST /EMAIL ************** */}
        <Route
          path="email"
          element={
            <RouteGuard moduleId="email_broadcast" element={<BlankLayout />} />
          }
        >
          <Route
            index
            element={
              <HomeRouteIndex
                dynamicMenuKeyName="email_broadcast_sidebar_menu"
                routeIndexPath="/broadcast/email"
              />
            }
          />
          <Route
            path="dashboard"
            element={
              <RouteGuard
                moduleId="email_insights"
                element={<TopMenubarSummary />}
              />
            }
          >
            <Route path="summary" element={<EmailDashboardSummary />} />
            <Route path="logs" element={<EmailLogsInsights />} />
          </Route>

          <Route path="start" element={<WelcomeEmailBroadcast />} />
          <Route
            path="history"
            element={
              <RouteGuard
                moduleId="email_broadcast_crud"
                element={<EmailBroadcastHistory />}
              />
            }
          />
          <Route
            path="scheduled"
            element={
              <RouteGuard
                moduleId="email_broadcast_crud"
                element={<EmailScheduledBroadcast />}
              />
            }
          />
          <Route
            path="templates"
            element={
              <RouteGuard
                moduleId="email_template"
                element={<EmailCardTemplatesListing />}
              />
            }
          />
          <Route
            path="logs"
            element={<RouteGuard moduleId="sms_logs" element={<EmailLogs />} />}
          />
          <Route
            path="reports"
            element={
              <RouteGuard moduleId="sms_reports" element={<EmailReports />} />
            }
          />

          <Route
            path="broadcast-details/:broadcast_id"
            element={
              <RouteGuard
                moduleId="email_broadcast_crud"
                element={<TopMenubar />}
              />
            }
          >
            <Route path="overview" element={<EmailDashboardOverview />} />
            <Route path="logs" element={<EmailDashboardLogs />} />
          </Route>
          <Route
            path="create-broadcast/:step_id"
            element={
              <RouteGuard
                moduleId="email_broadcast_crud"
                element={<EmailBroadcastCreate />}
              />
            }
          />
          <Route
            path="edit-broadcast/:step_id/:broadcast_id"
            element={
              <RouteGuard
                moduleId="email_broadcast_crud"
                element={<EmailBroadcastCreate />}
              />
            }
          />
          <Route
            path="create-template"
            element={
              <RouteGuard
                moduleId="email_template"
                element={<EmailCreateTemplate />}
              />
            }
          />
          <Route
            path="edit-template/:template_id"
            element={
              <RouteGuard
                moduleId="email_template"
                element={<TemplateCreate />}
              />
            }
          />
          <Route
            path="view-template/:template_id"
            element={
              <RouteGuard
                moduleId="email_template"
                element={<TemplateCreate />}
              />
            }
          />
        </Route>
      </Route>

      {/*************** INTEGRATIONS ************** */}
      <Route
        path="/integrations"
        element={
          <RouteGuard moduleId="integrations" element={<Integrations />} />
        }
      >
        <Route path="zoho" element={<ZohoIndex />} />
        <Route path="shopify" element={<ShopifyIndex />} />
        <Route path="google-sheets" element={<GoogleIndex />} />
        {/* <Route path="facebook" element={<FacebookIndex />} /> */}
        <Route path="whatsapp" element={<WhatsappIntegration />} />
        <Route path="instagram" element={<InstagramIndex />} />
      </Route>

      {/*************** Automation Builder ************** */}
      <Route
        path="/automation"
        element={<RouteGuard moduleId="automation" element={<BlankLayout />} />}
      >
        <Route path="lists" element={<Automation />} />
        <Route path="edit/:id" element={<AutomationBuilder />} />
        <Route path="view/:id" element={<AutomationBuilder />} />
      </Route>

      {/*************** Whatsapp Flow Builder ************** */}
      <Route
        path="/whatsapp/flow"
        element={<RouteGuard moduleId="automation" element={<BlankLayout />} />}
      >
        <Route path="" element={<FlowBuilderList />} />
        <Route path="create" element={<FlowCreateScreen />} />
        <Route path="builder/:id" element={<WhatsappBuilder />} />
        <Route path="edit/:id" element={<AutomationBuilder />} />
        <Route path="view/:id" element={<AutomationBuilder />} />
      </Route>

      {/*************** SETTINGS ************** */}
      <Route
        path="/settings"
        element={
          <RouteGuard
            moduleId="settings"
            element={
              <SidebarLayoutWrapper
                menuOptionsKeyName="setting_menu_options"
                menuDisplayType={"accordion"}
              />
            }
          />
        }
      >
        <Route
          index
          element={
            <HomeRouteIndex
              dynamicMenuKeyName="setting_menu_options"
              routeIndexPath="/settings"
            />
          }
        />
        {/*************** SETTINGS / USERS AND PERMISSIONS ************** */}
        <Route
          path="users-and-permissions"
          element={
            <RouteGuard
              moduleId="users_and_permissions"
              element={<BlankLayout />}
            />
          }
        >
          <Route
            path="users"
            element={
              <RouteGuard moduleId="manage_users" element={<BlankLayout />} />
            }
          >
            <Route path="list" element={<Users />} />
            <Route path="add-user" element={<AddUser />} />
            <Route path="edit-user/:id" element={<AddUser />} />
          </Route>
          <Route
            path="roles"
            element={
              <RouteGuard moduleId="manage_roles" element={<BlankLayout />} />
            }
          >
            <Route path="list" element={<RolesPermissions />} />
            <Route path="list/create" element={<Permissions />} />
            <Route path="list/:id" element={<Permissions />} />
          </Route>
        </Route>

        {/*************** SETTINGS / DATA MANAGEMENT ************** */}
        <Route
          path="data-management"
          element={
            <RouteGuard moduleId="data_management" element={<BlankLayout />} />
          }
        >
          <Route
            path="import-history"
            element={
              <RouteGuard
                moduleId="import_history"
                element={<ContactMarketingImport />}
              />
            }
          />
          <Route
            path="export-contact-history"
            element={
              <RouteGuard
                moduleId="export_history"
                element={<ContactsExportHistory />}
              />
            }
          />
          <Route
            path="lifecycle"
            element={
              <RouteGuard
                moduleId="manage_lifecycle"
                element={<LifecycleListing />}
              />
            }
          />

          <Route
            path="contact-properties"
            element={
              <RouteGuard
                moduleId="manage_contact_properties"
                element={<BlankLayout />}
              />
            }
          >
            <Route path="list" element={<ContactProperties />} />
            <Route
              path="field-dependency-list"
              element={<DependencyFiledList />}
            />
          </Route>
          <Route
            path="lifecycle-stage"
            element={
              <RouteGuard
                moduleId="manage_lifecycle"
                element={<BlankLayout />}
              />
            }
          >
            <Route path="create-template" element={<CreateLifeCycle />} />
            <Route path="edit-template/:id" element={<CreateLifeCycle />} />
          </Route>
        </Route>
        {/*************** SETTINGS / AUTOMATION / Auto assignments ************** */}

        <Route
          path="automation/auto-assignment"
          element={
            <RouteGuard
              moduleId="auto_assignment_rules"
              element={<AutoAssignmentRulesIndex />}
            />
          }
        >
          {" "}
          <Route path="rules" element={<AutoAssignmentTabs />}>
            <Route path="contacts" element={<AutoAssignmentListing />} />
            <Route path="create" element={<ContactsAutoAssignmentRule />} />
            <Route path="edit/:id" element={<ContactsAutoAssignmentRule />} />
          </Route>
          {/* create contcts */}
        </Route>

        {/*************** SETTINGS / CHAT SETTINGS ************** */}
        <Route
          path="chat-settings"
          element={
            <RouteGuard moduleId="chat_settings" element={<BlankLayout />} />
          }
        >
          <Route
            path="assignment"
            element={
              <RouteGuard
                moduleId="chat_assignment"
                element={<ChatAssignment />}
              />
            }
          />
          <Route
            path="labels"
            element={
              <RouteGuard
                moduleId="manage_conversation_labels"
                element={<ChatLabels />}
              />
            }
          />
          <Route
            path="canned-response"
            element={
              <RouteGuard
                moduleId="manage_canned_responses"
                element={<CannedResponse />}
              />
            }
          />
          <Route
            path="business-hours"
            element={
              <RouteGuard
                moduleId="manage_business_hours"
                element={<BusinessHours />}
              />
            }
          />
          <Route
            path="inactivity-timeouts"
            element={
              <RouteGuard
                moduleId="inactivity_timeouts"
                element={<InactivityTimeout />}
              />
            }
          />
        </Route>

        {/*************** SETTINGS / CHANNELS ************** */}
        <Route
          path="channels"
          element={
            <RouteGuard moduleId="manage_channels" element={<BlankLayout />} />
          }
        >
          <Route
            path="email"
            element={
              <RouteGuard
                moduleId="manage_channel_email"
                element={<EmailSettings />}
              />
            }
          >
            <Route path=":step_id" element={<DomainVerification />} />
          </Route>
          <Route
            path="email/email-verification"
            element={
              <RouteGuard
                moduleId="manage_channel_email"
                element={<EmailSettings />}
              />
            }
          />
          <Route path="instagram" element={<InstagramIndex />}></Route>
          <Route
            path="facebook"
            element={
              <RouteGuard
                moduleId="manage_channel_facebook"
                element={<FacebookIndex />}
              />
            }
          ></Route>
          <Route
            path="facebook/config/:page_id"
            element={<FacebookSettings />}
          />
          {/* <Route
            path="facebook/config/:page_id"
            element={
              <RouteGuard
                moduleId="manage_channel_facebook"
                element={<FacebookSettings />}
              />
            }
          ></Route> */}
          <Route
            path="whatsapp"
            element={
              <RouteGuard
                moduleId="manage_channel_whatsapp"
                element={<WhatsAppForm />}
              />
            }
          />
        </Route>
      </Route>

      {/*************** SUBSCRIPTION ************** */}
      <Route
        path="/subscription"
        element={
          <RouteGuard
            moduleId="subscriptions"
            element={
              <SidebarLayoutWrapper
                menuOptionsKeyName="subscription_sidebar_menu"
                menuDisplayType={"menu"}
                sidebarTitle={"Subscription"}
              />
            }
          />
        }
      >
        <Route
          index
          element={
            <HomeRouteIndex
              dynamicMenuKeyName="subscription_sidebar_menu"
              routeIndexPath="/subscription"
            />
          }
        />
        <Route path="subscription-plans" element={<Subscription />} />
        <Route path="track-expenses" element={<TrackingExpenses />} />
        <Route path="subscription-plans/details" element={<PlanDetails />} />
        <Route path="subscription-plans/plans" element={<Plans />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="billing-summary" element={<BillingSummary />} />
        <Route
          path="billing-summary/whatsapp"
          element={<WhatsappBillingLogs />}
        />
        <Route path="wallet-invoices" element={<WallectInvoices />} />
        <Route path="success" element={<SubscriptionSuccess />} />
      </Route>

      {/* Form Builder Routes */}
      <Route
        path="/forms"
        element={<RouteGuard moduleId="forms" element={<BlankLayout />} />}
      >
        <Route
          path="lists"
          element={<RouteGuard moduleId="forms" element={<FormBuilder />} />}
        />
        {/* <Route path="choose-form-type" element={<ChooseFormType />} /> */}
        {/* Create Flow Routes */}
        <Route path="custom-form" element={<CustomFormBuilder />}>
          <Route path="build" element={<Forms />} />
          <Route path="setting" element={<Setting />}>
            <Route path="generalSettings" element={<GeneralSetting />} />
            <Route
              path="notificationsSettings"
              element={<NotificationsSetting />}
            />
            <Route
              path="thankYouPageSettings"
              element={<ThankYouPageSetting />}
            />
            <Route path="scheduleSettings" element={<ScheduleSetting />} />
            <Route path="paymentSettings" element={<PaymentSettings />} />
          </Route>
          <Route path="style-preview" element={<StylePreview />} />
        </Route>

        {/* Edit Routes */}
        <Route path="edit-custom-form/:id" element={<CustomFormBuilder />}>
          <Route path="build" element={<Forms />} />
          <Route path="setting" element={<Setting />}>
            <Route path="generalSettings" element={<GeneralSetting />} />
            <Route
              path="notificationsSettings"
              element={<NotificationsSetting />}
            />
            <Route
              path="thankYouPageSettings"
              element={<ThankYouPageSetting />}
            />
            <Route path="scheduleSettings" element={<ScheduleSetting />} />
            <Route path="paymentSettings" element={<PaymentSettings />} />
          </Route>
          <Route path="style-preview" element={<StylePreview />} />
        </Route>
      </Route>
      <Route
        path="application_forms_lists"
        element={
          <RouteGuard
            moduleId="application_forms"
            element={<ApplicationFormListing />}
          />
        }
      />
      {/* <Route path="*" element={<Navigate to="/404" replace />} /> */}
    </Routes>
  );
};

export default BuildRoutes;
