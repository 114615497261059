import { Switch } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { Box, IconButton } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../../AppComponents/AppButton";
import AppDeleteItem from "../../../../../AppComponents/AppDeleteItem";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import { withLoader } from "../../../../../AppComponents/Loader";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { ContactsActions } from "../../../../../Store/Slices/Contacts/contacts.action";
import { AutoAssignmentRulesSettingsActions } from "../../../../../Store/Slices/Settings/Automation/AutoAssignmentRules/autoAssignmentRulesSettings.actions";

import {
  allTabsPagination,
  contactsList,
} from "../../../../../Store/Slices/Settings/Automation/AutoAssignmentRules/autoAssignmentRulesSettings.selectors";
import {
  paginationHandling,
  unmountListing,
} from "../../../../../Store/Slices/Settings/Automation/AutoAssignmentRules/autoAssignmentRulesSettings.slice";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/deletegrey.svg";
import { ReactComponent as ListIcon } from "../../../../../assets/images/drag-icon.svg";
import nodata from "../../../../../assets/images/noDatafound.svg";
import AppDragAndDrop from "./SortableList";
import styled from "styled-components";
import * as MUI from "@mui/material";
import { theme } from "../../../../../Customization/Theme";

const AutoAssignmentListing = (props: any) => {
  const navigate = useNavigate();
  const ContactRulesList = useAppSelector(contactsList);
  const pagination = useAppSelector(allTabsPagination);
  const dispatch = useAppDispatch();
  let { showAlert } = useNotification();
  const [deleteData, setDeleteData] = useState<any>(null);
  const [deleteId, setDeleteId] = useState("");
  const [dragedPosition, setDragedPosition] = useState<any>({});
  const [RulesList, setRulesList] = useState<any>([]);

  // get assign list data
  const getAssignee = async () => {
    try {
      await dispatch(ContactsActions.getAssigneeList({}));
    } catch (error: any) {
      showAlert(
        error?.response?.statusText || "Enabled to fetch Data",
        "error",
      );
    }
  };
  // api call to get website list
  const getContactRulesList = () => {
    props.setLoading(true);
    setTimeout(() => {
      dispatch(AutoAssignmentRulesSettingsActions.getRulesList(pagination))
        .then((res: any) => {
          props.setLoading(false);
        })
        .catch((error: any) => {
          showAlert(
            error?.response?.statusText || "Enabled to fetch Data",
            "error",
          );
        })
        .finally(() => {
          props.setLoading(false);
        });
    }, 1000);
  };

  // Delete rule
  const handleDeleteBtn = () => {
    setDeleteData(null);
    dispatch(
      AutoAssignmentRulesSettingsActions.deleteContactsRulesById(deleteId),
    )
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(res?.error?.message || "Rule Not deleted", "error");
        } else {
          showAlert(res?.payload?.data?.msg || "Rule deleted", "success");
          getContactRulesList();
        }
      })
      .catch((error) => {
        showAlert("something went wrong", "error");
      })
      .finally(() => {});
  };
  let subTitle = `Are you sure, you want to delete This item`;

  // onsortabale Update priority
  const upDateList = (payload: any) => {
    dispatch(
      AutoAssignmentRulesSettingsActions.UpdateContactsRulesById(payload),
    )
      .then((res: any) => {
        if (res?.payload?.data?.Status === "Success") {
          getContactRulesList();
        } else {
          setRulesList(ContactRulesList);
          showAlert(
            res?.payload?.data?.error || "Contact not updated",
            "error",
          );
        }
      })
      .catch((err: any) => {
        console.warn("error while creating segment", err);
        setRulesList(ContactRulesList);
      });
  };

  // get contact rules List api ***
  useEffect(() => {
    getContactRulesList();
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  // get assignee
  useEffect(() => {
    getAssignee();
    return () => {
      let intialFilter = {
        currentPage: 0,
        itemsPerPage: 10,
        query: "",
      };
      dispatch(unmountListing([]));
      dispatch(paginationHandling(intialFilter));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // listing state
  useEffect(() => {
    if (ContactRulesList && ContactRulesList?.length > 0) {
      setRulesList(ContactRulesList);
    }
    if (ContactRulesList?.length === 0) {
      setRulesList([]);
    }
  }, [ContactRulesList]);

  useEffect(() => {
    if (dragedPosition && dragedPosition?.active?.id?.rule_id) {
      let payload = {
        rule_id: dragedPosition?.active?.id?.rule_id,
        data: {
          priority: dragedPosition?.over?.data?.current?.sortable?.index + 1,
        },
      };
      upDateList(payload);
    }
  }, [dragedPosition]); // eslint-disable-line react-hooks/exhaustive-deps

  const ActivateToggel = (e: any, id: string) => {
    if (id && e?.target) {
      let payload = {
        rule_id: id,
        data: {
          is_active: e?.target?.checked,
        },
      };
      upDateList(payload);
    }
  };

  const ChildrenComponent = (
    item: any,
    index: any,
    setNodeRef: any,
    listeners: any,
    styles: any,
    restrictToVerticalAxis: any,
    restrictToWindowEdges: any,
    isDragging: any,
  ) => {
    return (
      <Box
        className="table-row"
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        ref={setNodeRef}
        {...listeners}
        style={{
          ...(isDragging
            ? {
                backgroundColor: "white",
                boxShadow:
                  "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                ...styles,
              }
            : {
                backgroundColor: "white",
                ...styles,
              }),
        }}
      >
        <Box className="row-inner">
        <Box className="row-left">
          <Box
            className="col-body"
            sx={{ gap: 3, maxWidth: "100px !important", minWidth: "100px !important" }}
          >
            <ListIcon />
            <CustomSwitch
              defaultChecked={item?.is_active}
              onClick={(e) => {
                ActivateToggel(e, item?.rule_id);
              }}
            />
          </Box>
          <Box className="col-body" sx={{ gap: 3, maxWidth: "120px !important", minWidth: "120px !important" }}
          >{index + 1}</Box>
          <Box className="col-body">
            <MUI.Typography title={item?.name || ""} className="text">
              {item?.name || ""}
            </MUI.Typography>
          </Box>
          <Box className="col-body">
          <MUI.Typography title={item?.description || ""} className="text">
            {item?.description || ""}
          </MUI.Typography>
          </Box>
          <Box className="col-body">
            <MUI.Typography title= {item?.created_by || ""}className="text">
            {item?.created_by || ""}
            </MUI.Typography>
          </Box>
          <Box className="col-body">
            {moment(item?.created_on).format("DD-MM-YYYY")}
          </Box>
          <Box className="col-body">
            {moment(item?.updated_on).format("DD-MM-YYYY")}
          </Box>

          </Box>
          
          <Box className="empty-col">
                 
                </Box>

          <Box className="row-left">

          

            <Box className="col-body" justifyContent={"flex-end"}>
              <IconButton
                onClick={() => {
                  navigate(
                    `/settings/automation/auto-assignment/rules/edit/${item?.rule_id}`,
                  );
                }}
              >
                <Edit />
              </IconButton>

              <IconButton
                onClick={() => {
                  setDeleteData(item?.rule_id);
                  setDeleteId(item?.rule_id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>

          </Box>

        </Box>
        
      </Box>
    );
  };

  return (
    <React.Fragment>
      {/* Table Start */}
      {!RulesList || RulesList.length === 0 ? (
        <div
          className="front-image"
          style={{ minHeight: "100vh - 261px !important" }}
        >
          <div className="no-data-img">
            <img src={nodata} alt="Description about" />
          </div>
          <div className="front-text">No Data found</div>
          <AppButton
            onClick={() =>
              navigate("/settings/automation/auto-assignment/rules/create")
            }
          >
            <AppMaterialIcons iconName="add" />
            Create Rules
          </AppButton>
        </div>
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <NewTable>
            <Box className="table-header">
              <Box className="table-row">

                <Box className="row-inner">

                  <Box className="row-left">

                  
                <Box
                  className="col-head hide-column-line"
                  sx={{ maxWidth: "100px !important", minWidth: "100px !important" }}
                >
                  
                </Box>
                {/* <Box className="col-head hide-column-line"></Box> */}
                <Box className="col-head hide-column-line"
                sx={{ gap: 3, maxWidth: "120px !important", minWidth: "120px !important" }}
                >Priority No.</Box>
                <Box className="col-head hide-column-line">Rule Name</Box>
                <Box className="col-head hide-column-line">Description</Box>
                <Box className="col-head hide-column-line">Created by</Box>
                <Box className="col-head hide-column-line">Created at</Box>
                <Box className="col-head hide-column-line">Last updated</Box>

                </Box>

                <Box className="empty-col">
                  
                </Box>

                <Box className="row-right">
                <Box className="col-head hide-column-line"  justifyContent={"flex-end"}>Actions</Box>
                </Box>


                </Box>
              </Box>
            </Box>
            <Box
              className="table-body"
              sx={{ height: "calc(100vh - 298px)"}}
            >
              <AppDragAndDrop
                element={ChildrenComponent}
                listData={RulesList}
                setDragedPosition={setDragedPosition}
              />
            </Box>
          </NewTable>
        </Box>
      )}

      {/* delete rule confirmation modal */}
      <AppDeleteItem
        title="Delete Rule"
        subTitle={subTitle}
        onDelete={() => handleDeleteBtn()}
        setDeleteData={setDeleteData}
        deleteData={deleteData}
      />
    </React.Fragment>
  );
};

export default withLoader(AutoAssignmentListing);


const CustomSwitch = MUI.styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 4,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#FFFFFF',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#5B73E8;',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 8,
    height: 8,
    borderRadius: 6,
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));



const NewTable = styled(Box)`
  & {
    width: 100%;
    min-width: 1410px;
    background-color: ${theme.palette.default.white};
    min-height: calc(100vh - 400px);
    .empty-col {
      flex: 1;
       border-top: 1px solid ${theme.palette.default.border};
    }
    .chip .MuiButtonBase-root {
      padding: 0 !important;
    }
    .table-header {
      position: sticky;
      top: 0;
      z-index: 1;
      .table-row {
        display: flex;
        align-items: stretch;

         .row-inner {

         
         
    display: flex;
    width: 100%;
    min-width: 100%;
    justify-content: space-between;
    
        }

        .row-left {
          display: flex;
          justify-content: space-between;
        }


        > div {
          
        }
      }
      .table-row > div:nth-child(2) {
        min-width: 120px;
        max-width: 120px;
      }

      .table-row > div:nth-child(5) {
        border-right: none;
      }

      // .table-row > div:last-child {
      //   border-right: none;
      //   justify-content: flex-end;
      //   max-width: 60px;
      // }

      .col-head {
      border-top: 1px solid ${theme.palette.default.border};
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        font-weight: 500;
        border-right: 1px solid rgba(126, 131, 146, 0.5);
        font-size: 13px;
         min-width: 230px;
      max-width: 230px; 
      }
      .hide-column-line {
        border-right: none !important;
      }
      .hidden-text {
        visibility: hidden;
      }
    }

    // Body
    .table-body {
      .table-row {

        .row-inner {
        
    display: flex;
    width: 100%;
    min-width: 100%;
    justify-content: space-between;
    
        }

        .row-left {
          display: flex;
          justify-content: space-between;
        }
      
        display: flex;
        > div {
          
        }
      }
      
      .table-row > div:nth-child(2) {
        min-width: 120px;
        max-width: 120px;
        color: ${theme.palette.default.black};
      }
    }

   
    .col-body {

  border-top: 1px solid ${theme.palette.default.border};
  .text {
      text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

      min-width: 230px;
      max-width: 230px; 
      display: flex;
      word-break: break-all;
          text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
      flex: 1;
      align-items: center;
      padding: 8px 16px;
      color: ${theme.palette.default.darkGrey};
      font-size: 13px;
      .url-count {
        .MuiButtonBase-root {
          font-size: 13px;
        }
      }
      .status {
        display: flex;
        gap: 8px;
        color: #25926a;
        padding: 4px 16px;
        align-items: center;
        background-color: #e5fff6;
        border: 1px solid #12c584;
        border-radius: 4px;
        justify-content: center;
      }
      .status-uploaded {
        display: flex;
        gap: 8px;
        color: ${theme.palette.primary.main};
        padding: 4px 16px;
        align-items: center;
        background-color: ${theme.palette.default.pattensBlue};
        border: 1px solid ${theme.palette.primary.main};
        border-radius: 4px;
        justify-content: center;
      }
      .in-progress {
        display: flex;
        gap: 8px;
        color: #918548;
        padding: 4px 16px;
        align-items: center;
        background-color: #fffbe5;
        border: 1px solid #ffe55e;
        border-radius: 4px;
        justify-content: center;
      }
    }
    .col-body.url {
      color: ${theme.palette.primary.main} !important;
    }
  }
`;
