import { Button } from '@mui/material';
import React from 'react'
import styled from 'styled-components';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useFormikContext } from 'formik';
import { useNotification } from '../../Hooks/useNotification/useNotification';

const AppFileUploadField = ({ name, label, uploadFile, disabled, detailsKey }: any) => {
    const { setFieldValue } = useFormikContext<any>();
    const { showAlert } = useNotification();
    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (!event.target.files) return;
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append("source", file);
            uploadFile(formData).then((response: any) => {
                setFieldValue(name, response?.data?.id)
                if (detailsKey) {
                    setFieldValue(detailsKey, response?.data)
                }
                showAlert("File Uploaded Successfully", "success");
            }).catch((error: any) => {
                showAlert("File Upload Failed", "error");
            });
        }
    };
    return (
        <Container>
            <Button
                component="label"
                role={undefined}
                variant="outlined"
                size='small'
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                disabled={disabled}
            >
                {label}
                <VisuallyHiddenInput type="file" onChange={(e: any) => { handleFileChange(e) }} />
            </Button>
        </Container>
    )
}

const Container = styled.div`
&&{
    width: 100%;
}`
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default AppFileUploadField