import { getIn, useFormikContext } from "formik";
import AppFormRadioFastField from "./FastField/AppFormRadioFastField";
import { broadcastSendOptions } from "./Utils/Constants";
import AppFormDatePicker from "./AppFormDatePicker";
import AppFormTimePickerMui from "./AppFormTimePickerMui";
import { useEffect } from "react";
import styled from "styled-components";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TestSMSTemplate from "../../Components/Broadcast/SMS/ScheduledBroadcast/component/TestSMSTemp";
import TestWhatsappTemplate from "../../Components/Broadcast/WhatsApp/ScheduledBroadcast/components/TestWhatsappTemp";
interface Props {
  broadcastByIdData: any
}
const ScheduleBroadcast: React.FC<Props> = ({ broadcastByIdData }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const formkey1 = "when_to_send";
  const formkey2 = "date";
  const formkey3 = "time";

  useEffect(() => {
    setFieldValue(formkey3, new Date())
  }, [])

  return (
    <>
      <AppFormRadioFastField
        label={"When to Send"}
        name={formkey1}
        Options={broadcastSendOptions}
        row={false}
      />
      {getIn(values, formkey1) === "schedule_broadcast" && (
        <div style={{ display: "flex", alignItems: "flex-start", gap: "2rem" }}>
          <AppFormDatePicker name={formkey2} label="Select date " minDate={new Date()} broadcastData={broadcastByIdData} />
          <AppFormTimePickerMui
            name={formkey3}
            label="Select time "
            utcTime={true}
            disablePast={true}
            broadcastByIdData={broadcastByIdData}
          />
        </div>
      )}
      <BroadcastText>
        What is the difference between now and schedule broadcast?
      </BroadcastText>
      <BroadcastInfo>
        <FiberManualRecordIcon style={actionIson} /> <p style={action}>Broadcast Now</p>is an instant delivery of a message or mail to your selected audience.
      </BroadcastInfo>
      <BroadcastInfo style={{ marginBottom: "24px" }}>
        <FiberManualRecordIcon style={actionIson} /> <p style={action}>Schedule a Broadcast</p>is planning content delivery for a specific date and time to reach a targeted audience effectively.
      </BroadcastInfo>
      <TestSMSTemplate />
      <TestWhatsappTemplate />
    </>
  )
}
const BroadcastText = styled.p`
  color: #101010;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin : 100px 2px 3px 3px;
`
const BroadcastInfo = styled.div`
  color: #7E8392;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  display:flex
`
const action = {
  color: "#101010",
  fontWeight: 500,
  margin: "0px 5px 0px 4px"
}
const actionIson = {
  fontSize: "16px",
  marginTop: "3px"
}
export default ScheduleBroadcast;