import { IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormError,
  AppFormField,
  AppFormObserver,
  AppFormSelectField,
  AppSubmitButton
} from "../../../../AppComponents/Forms";
import AppFormToggleButtonGroup from "../../../../AppComponents/Forms/AppFormToggleButtonGroup";
import { LoaderComponentProps } from "../../../../AppComponents/Loader";
import {
  SettingPageMainBoxBtnBox,
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
} from "../../../../Customization/CommonElements";
import { theme } from "../../../../Customization/Theme";
import { HocBackdropLoader } from "../../../../HOC/HocBackdropLoader";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { DefaultIndiaMobileFormat } from "../../../../Models/defaultSelectOptions";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { CoreService } from "../../../../Services/Apis/CoreApis";
import { useAppDispatch } from "../../../../Store";
import { setTemplateButtonsLengthChecker } from "../../../../Store/Slices/Whatsapp/whatsapp.slice";
import { WHATSAPP_PRIMARY_COLOR } from "../../BroadcastPreview/Constants";
import {
  WhatsappCreateTemplateSchema,
  whatsappTemplateButtonTypeOptions,
  whatsappTemplateHeaderForButtonsToggleOptions, whatsappTemplateHeaderForCarouselToggleOptions, whatsappTemplateHeaderForLTOToggleOptions
} from "../Utils/Constants";
import {
  dataToTemplateFormData,
  dataToWhatsappTemplatePayload,
} from "../Utils/utils";
import BroadcastTemplatePreview from "./components/Preview/BroadcastTemplatePreview"
import { StyledContentBox, WhatsappTemplateWrap } from "./Styles";
import CarouselFieldArray from "./components/ButtonTypes/CarouselFieldArray"
import LimitedTimeOffer from "./components/ButtonTypes/LimitedTimeOffer";
import RenderDynamicButton from "./components/ButtonTypes/RenderDynamicButton";
import RenderDynamicHeaderTypes from "./components/RenderDynamicHeaderTypes";
import RenderDynamicVariableMapping from "./components/RenderDynamicVariableMapping";
import WhatsappTextRichEditor from "./components/WhatsappTextRichEditor";
import FlowButton from "./components/ButtonTypes/FlowButton";
import AppModel from "../../../../Hooks/useModel/AppModel";
import styled from "styled-components";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import useModal from "../../../../Hooks/useModel/useModel";

interface Props extends LoaderComponentProps { }

const TemplateCreate: React.FC<Props> = ({ setLoading }) => {
  const navigate = useNavigate();
  const { showAlert } = useNotification();
  let { template_id } = useParams();
  const formRef: any = useRef();
  const dispatch = useAppDispatch();
  const [renderForm, setRenderForm] = useState<boolean>(false);
  const [categoryOptions, setCategoryOptions] = useState<any>([]);
  const [languageOptions, setLanguageOptions] = useState<any>([]);
  const [buttonTypeOptions, setButtonTypeOptions] = useState<any>([]);
  const [filteredButtonTypeOptions, setFilteredButtonTypeOptions] = useState<any>();
  const [disable, setDisable] = useState<boolean>(false);
  const { isShowing, toggle } = useModal();
  const [statusApproved, setStatusApproved] = useState<boolean>(false);

  const [formikProps, setFormikProps] = useState<any>(null);
  const { values }: any = formikProps || {};
  const [formInput, setFormInput] = useState<any>({
    template_name: "",
    template_category: 0,
    template_language: 0,
    button_type: "none",
    header_type: "NONE",
    header_value: "",
    header_text: [],
    body_value: "",
    body_text: [],
    footer_value: "",
    footer_text: [],
    copyUrlOptions: [
      {
        name: "add_quick_replies",
        label: "Add Quick Replies",
        checked: false
      },
      {
        name: "add_coupon_code",
        label: "Add Coupon Code",
        checked: false
      },
      {
        name: "add_website_url",
        label: "Add Website Url",
        checked: false
      },

      {
        name: "add_phone_number",
        label: "Add Phone Number",
        checked: false
      },
    ],
    copy_coupon_code: "",
    coupon_code: "",
    website_url: [
      {
        button_text: "",
        url_type: "static",
        url: ""
      }
    ],
    phone_number: "",
    phone_number_button_text: "",
    quick_replies: [
      {
        type: "QUICK_REPLY",
        text: ""
      }
    ],
    offer_title: "",
    offer_coupon_code: "",
    offer_url_type: "static",
    offer_url: "",
    offer_button_text: "",
    carousel: [
      {
        card_header: "",
        url: "",
      }
    ],
    buttons_example: [],
    variables_header: [],
    variables_body: [],
    variables_footer: [],
    variables_button: [],
    mobileCountryFormat: DefaultIndiaMobileFormat,
  });
  // useEffecr to control the buttons Limit based on the type of buttons (max liimt 10)
  useEffect(() => {
    dispatch(setTemplateButtonsLengthChecker(values))
  }, [values?.add_coupon_code, values?.add_website_url, values?.add_phone_number, values?.add_quick_replies, values?.quick_replies, values?.carousel, values?.coupon_code, values?.website_url]) // eslint-disable-line react-hooks/exhaustive-deps

  //* HANDLE SUBMIT BTN API CALL
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    let data: any = dataToWhatsappTemplatePayload(values);
    data = { ...data, inputValues: values }
    SubmitProps.setSubmitting(false);
    setLoading(true);
    ChatbotConsoleService.createWhatsappTemplate(data)
      .then((res: any) => {
        SubmitProps.resetForm();
        toggle();
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.data?.error || "Internal server error",
          "error",
        );
      })
      .finally(() => {
        SubmitProps.setSubmitting(false);
        setLoading(false);
      });
  };

  // get all options on index page 
  useEffect(() => {
    CoreService.getWhasAppTemplateOptions().then((res: any) => {
      setCategoryOptions(res?.data?.whatsapp_category || []);
      setLanguageOptions(res?.data?.whatsapp_languages || []);
      setButtonTypeOptions(res?.data?.whatsapp_template_type || [])
      setFilteredButtonTypeOptions(res?.data?.whatsapp_template_type || [])
    })
  }, [])

  //filtering options of template type based on category
  const handleButtonTypeOptions = (option: any) => {
    if (option === "UTILITY") {
      let filteredOptions = buttonTypeOptions.filter((option: any) => !["send_products", "limited_time_offer"].includes(option.value));
      setFilteredButtonTypeOptions(filteredOptions)
    } else {
      setFilteredButtonTypeOptions(buttonTypeOptions)
    }
  }
  //Handling Headers Options Based on Template Type
  const resetTemplateFields = () => {
    formikProps.setFieldValue("offer_button_text", "")
    formikProps.setFieldValue("offer_coupon_code", "")
    formikProps.setFieldValue("offer_title", "")
    formikProps.setFieldValue("offer_url", "")
    formikProps.setFieldValue("offer_url_type", "static")
    formikProps.setFieldValue("carousel", [
      {
        "card_header": "",
        "url": ""
      }
    ])
    formikProps.setFieldValue("quick_replies", [
      {
        "type": "QUICK_REPLY",
        "text": ""
      }
    ])
    formikProps.setFieldValue("website_url", [
      {
        "button_text": "",
        "url_type": "static",
        "url": ""
      }
    ])
    formikProps.setFieldValue("copyUrlOptions", [
      {
        name: "add_quick_replies",
        label: "Add Quick Replies",
        checked: false
      },
      {
        name: "add_coupon_code",
        label: "Add Coupon Code",
        checked: false
      },
      {
        name: "add_website_url",
        label: "Add Website Url",
        checked: false
      },
      {
        name: "add_phone_number",
        label: "Add Phone Number",
        checked: false
      }])
    formikProps.setFieldValue("flow", [])
  }

  //* GETTING REQUIRED TEMPLATE DATA
  const getTemplateById = (id: string) => {
    setLoading(true);
    ChatbotConsoleService.getWhatsappTemplateById(id)
      .then((res: any) => {
        const data = dataToTemplateFormData(res?.data);
        data?.then((resolvedData) => {
          setFormInput({
            template_name: resolvedData?.template_name,
            template_category: resolvedData?.template_category,
            template_language: resolvedData?.template_language,
            ...resolvedData?.original_response,
          });
        });
        if (["APPROVED", "REJECTED", "PENDING"].includes(res?.data?.status)) {
          setDisable(true);
          setStatusApproved(true);
        }
      }).catch((error: any) => {
        showAlert(
          error?.response?.data?.error || "Internal server error",
          "error",
        );
      }).finally(() => {
        setLoading(false);
        setRenderForm(true);
      });
  };

  useEffect(() => {
    if (values?.header_type) {
      document.getElementById("add_button")?.focus();
    }
  }, [values?.header_type]);

  useEffect(() => {
    if (template_id) {
      getTemplateById(template_id);
    } else {
      setRenderForm(true);
    }
  }, [template_id]); // eslint-disable-line react-hooks/exhaustive-deps

  const copyCodeUrlQuickRepliesEtcState = values?.copyUrlOptions?.reduce((acc: any, state: any) => {
    if (state.checked) {
      acc[state.name] = true;
    }
    return acc;
  }, {});
  return (
    <WhatsappTemplateWrap style={{ height: "calc(100vh - 64px)" }}>
      {/* TOPBAR */}
      <SettingPageMainBoxTopbar
        style={{
          backgroundColor: theme.palette.default.white,
          minHeight: "60px",
          position: "fixed",
          zIndex: 1000,
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.08)",
        }}
      >
        <SettingPageMainBoxTitle style={{ width: "100%", flexWrap: "wrap" }}>
          <IconButton onClick={() => navigate("/broadcast/whatsapp/templates")}>
            <AppMaterialIcons
              iconName={"ArrowBackIosRounded"}
              style={{ fontSize: "0.8rem" }}
            />
          </IconButton>
          {template_id ? "View" : "Create "} Broadcast Template
        </SettingPageMainBoxTitle>
        <SettingPageMainBoxBtnBox></SettingPageMainBoxBtnBox>
      </SettingPageMainBoxTopbar>

      <StyledContentBox
        style={{
          display: "flex",
          height: "100%",
          overflow: "hidden",
          padding: "1rem 0rem 0rem 1rem",
        }}
      >
        {/* FORM BOX */}
        <div
          className="formBox"
          style={{
            backgroundColor: theme.palette.default.background,
            padding: "16px 16px 0px 16px",
            width: "64%",
          }}
        >
          {renderForm && (
            <AppForm
              initialValues={formInput}
              onSubmit={(values: any, submitProps: any) => {
                handleSubmitBtn(values, submitProps);
              }}
              validationSchema={
                !template_id ? WhatsappCreateTemplateSchema : null
              }
              innerRef={formRef}
            >
              <div className="formBoxInner">
                <div className="templateBoader">
                  <div className="catLangDiv">
                    <AppFormField
                      name="template_name"
                      label="Template Name"
                      placeholder="Enter template name"
                      disabled={disable}
                      labelStyle={{ color: theme.palette.default.black }}
                      divStyle={{ marginBottom: "2rem" }}
                      requiredSign
                    />

                    <AppFormSelectField
                      name="template_category"
                      label="Template Category"
                      Options={categoryOptions}
                      defaultText="Select a category"
                      disabled={disable}
                      labelStyle={{ color: theme.palette.default.black }}
                      onChange={(e) => {
                        handleButtonTypeOptions(e);
                      }}
                      requiredSign
                    />

                  </div>
                  <div className="catLangDiv">
                    <AppFormSelectField
                      name="button_type"
                      label="Button Type"
                      Options={filteredButtonTypeOptions}
                      defaultText="Select a type"
                      disabled={disable}
                      labelStyle={{ color: theme.palette.default.black }}
                      onChange={() => resetTemplateFields()}
                      requiredSign

                    />
                    <AppFormSelectField
                      name="template_language"
                      label="Template Language"
                      Options={languageOptions}
                      defaultText="Select a language"
                      disabled={disable}
                      enableSearch={true}
                      labelStyle={{ color: theme.palette.default.black }}
                      requiredSign

                    />
                  </div>
                </div>
                <div className="templateBoader">
                  <AppFormToggleButtonGroup
                    name="header_type"
                    label="Select Header Type *"
                    Options={values?.button_type === "limited_time_offer" ? whatsappTemplateHeaderForLTOToggleOptions : values?.button_type === "carousel" ? whatsappTemplateHeaderForCarouselToggleOptions : whatsappTemplateHeaderForButtonsToggleOptions}
                    disabled={disable}
                    labelStyle={{ color: theme.palette.default.black }}
                    subTitle="Add a title or choose which type of media you'll use for this header. Your title can't include more than one variable."
                    optionalText="(optional)"
                    divStyle={{ marginBottom: "1rem" }}
                  />


                  {/* DYNAMIC HEADER */}
                  <AppFormError name="headerVariables" />
                  <RenderDynamicHeaderTypes
                    disabled={disable}
                    headerType={
                      values?.header_type
                    }
                  />
                </div>

                <div className="templateBoader">
                  <AppFormError name="bodyVariables" />
                  <WhatsappTextRichEditor
                    name="body_value"
                    label="Body Message *"
                    placeholder="Hey {{1}} ! Is the end of the month making you feel broke but you want to pamper yourself? Well, we have an offer. Get 20% off on all our services on the {{2}} of every month. To book, visit {{3}}."
                    variablesKey="body_text"
                    charactersLimit={1024}
                    disabled={disable}
                    labelStyle={{ color: theme.palette.default.black }}
                    description="To add a custom variable, please add a variable in double curly brackets without a space. Example: {{1}}"
                    variablesButtonLimit={10}
                  />
                </div>

                {!["limited_time_offer", "carousel"].includes(values?.button_type) && (
                  <div className="templateBoader">
                    <AppFormField
                      name="footer_value"
                      label="Footer"
                      placeholder="Footer"
                      subTitle="Add a short line of text to the bottom of your message template."
                      charactersLimit={60}
                      disabled={disable}
                      labelStyle={{ color: theme.palette.default.black }}
                      optionalText="(optional)"
                    />
                  </div>
                )}

                {values?.button_type === "limited_time_offer" && (
                  <div className="templateBoader">
                    <LimitedTimeOffer disabled={disable} />
                  </div>
                )}

                {values?.button_type === "carousel" && (
                  <div className="templateBoader">
                    <CarouselFieldArray disabled={disable} />
                  </div>
                )}

                {values?.button_type === "flow" && (
                  <div className="templateBoader">
                    <FlowButton disabled={disable} />
                  </div>
                )}

                {values?.button_type === "copy_code_url_quick_replies_etc" && (
                  <div className="templateBoader">
                    <RenderDynamicButton
                      disabled={disable}
                    />
                  </div>
                )}

                {/* VARIABLES MAPPING */}
                <RenderDynamicVariableMapping
                  boxTitle="Add Sample Data"
                  headerVariables={values?.header_text}
                  bodyVariables={values?.body_text}
                  footerVariables={values?.footer_text}
                  buttonVariables={values?.buttons_example}
                  disabled={disable}
                />

                <AppFormObserver setFormikProps={setFormikProps} />
              </div>
              <div className="BtnsBox">
                {statusApproved ? (
                  <AppButton variant="outline" onClick={() => navigate(-1)}>
                    Back
                  </AppButton>
                ) : (
                  <React.Fragment>
                    <AppButton variant="grey" onClick={() => navigate(-2)}>
                      Cancel
                    </AppButton>
                    <AppSubmitButton
                      className="btnWidth"
                      variant="submit"
                      title={
                        template_id ? "Edit Template" : "Send for approval"
                      }
                    />
                  </React.Fragment>
                )}
              </div>
            </AppForm>
          )}
        </div>
        {/* PREVIEW WIDGET */}

        <BroadcastTemplatePreview
          primaryColor={WHATSAPP_PRIMARY_COLOR}
          bodyValue={values?.body_value?.replace(
            /\*(.*?)\*/g,
            "<strong>$1</strong>",
          )}
          headerValue={values?.header_value}
          footerValue={values?.footer_value}
          headerType={values?.header_type}
          copyCodeUrlQuickRepliesEtcStateRenderState={values?.copyUrlOptions}
          phoneNumberButtonData={(copyCodeUrlQuickRepliesEtcState?.add_phone_number ? { button_text: values?.phone_number_button_text, value: values?.phone_number } : null)}
          button_type={values?.button_type}
          buttonsData={
            copyCodeUrlQuickRepliesEtcState?.add_quick_replies ?
              values?.quick_replies : []
          }
          flowButton={
            values?.button_type === "flow" ? values?.flow : []
          }
          websiteButtonData={copyCodeUrlQuickRepliesEtcState?.add_website_url ? values?.website_url : []}
          carouselButtonData={values?.carousel?.[0]?.text || values?.carousel?.[0]?.card_header ? values?.carousel : []}
          limitedTimeOfferData={values?.button_type === "limited_time_offer" ? { offer_button_text: values?.offer_button_text } : null}
        />
      </StyledContentBox>
      {/* Info Modal After template creation */}
      <AppModel isShowing={isShowing} divStyle={{ width: "550px" }}>
        <WaningContainer>
          <IconContainer>
            <CheckCircleIcon fontSize="large" style={{ fill: theme.palette.default.white }} />
          </IconContainer>
          <p className="modal_container_header"> WhatsApp can take up to 24 hours to review (approve / reject) a template</p>
          <p className="text">
            In some cases, after template submission, the approval, the approved / rejected comes within the  first minute itself.
            However, it the template status shown pending (yellow) even after 1 minute, then, it implies that WhatsApp might have sent it for manual review, which typically takes upto 24 hours.
          </p>
          <AppButton
            variant="primary"
            onClick={() => {
              toggle();
              navigate("/broadcast/whatsapp/templates");
              showAlert("Template Created", "success");
            }}
            style={{ width: "150px", margin: "15px auto 0" }}
          >
            Understood
          </AppButton>
        </WaningContainer>
      </AppModel>
    </WhatsappTemplateWrap>
  );
};

export default HocBackdropLoader(TemplateCreate);

export const WaningContainer = styled.div`
 .modal_container_header{
   padding:20px 0;
   text-align:center;
   font-size:20px;
   font-weight:500;
 }
 .text{
   font-size: 16px;
   line-height: 24px;
   color:${theme.palette.default.black};
   margin: 0;
 }
 .icon{
   margin: 0 auto;
 }
`;

export const IconContainer = styled.div`
   text-align:center;
   width:50px;
   height:50px;
   padding:10px;
   background: rgb(33,28,82);
   border-radius: 50%;
   margin:0 auto;
   display:flex;
   align-items:center;
   justify-content:center;
`;
