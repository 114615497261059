
import { Box, Grid, MenuItem, Select, Typography, debounce } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import TemplateAPIERROR from "../../../assets/images/No_records_found.svg";
import { useSelector } from "react-redux";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import ResponsiveTable from "./ResponsiveTable";

import { AppPagination } from "../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import { ChatbotConsoleService } from "../../../Services/Apis/ChatbotConsoleService";
import { AppForm } from "../../../AppComponents/Forms";
import AppFormSearchField from "../../../AppComponents/Forms/AppFormSearchField";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import AppButton from "../../../AppComponents/AppButton";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  MainContainer,
  TemplateTable,
  ErrorComponent,
  NewNativeSelect,
  TableTitleHeader,
} from "../../Broadcast/SMS/SMS_Dashboard/SMS_dashboard_byId/SMS_Dashboard_Logs/SmsLogsTableDataStyles";
import { theme } from "../../../Customization/Theme";
import SkeletonLoader from "../../../AppComponents/Loader/SkeletonLoader";
import { broadcastActions } from "../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { broadcastDataById, filtersValuesData } from "../../../Store/Slices/BroadcastDashboard/Broadcast.selectors";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import AppPaginationComponent from "../../../AppComponents/Pagination/Pagination";

interface Props {

}
const EmailLogsTableData: React.FC<Props> = () => {
  const formikRef = useRef(null);
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const filtersValues = useSelector(filtersValuesData);
  const BroadcastByIdData = useAppSelector(broadcastDataById);
  const BroadcastByIdDataList = BroadcastByIdData?.results;
  const totalcount = BroadcastByIdData?.count || 0;
  const [loading, setLoading] = useState(false);
  const [resultList, setResultList] = useState([]);
  const [headerKeys, setHeaderKeys] = useState<any[]>([]);
  const [tableValue, setTableValue] = useState<any>({});
  const broadcast_id = useParams();

  const [onSearch, setOnSearch] = useState(false);

  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    totalPages: 0,
    status: "",
    search: "",
    id: broadcast_id.broadcast_id,
  });

  const getBroadCastDetailedData = () => {

    setLoading(true);
    const payload = {
      limit: localValues.itemsPerPage?.value,
      offset: onSearch ? 0 : localValues.currentPage,
      status: localValues?.status,
      search: localValues?.search,
      id: broadcast_id.broadcast_id,
    };
    dispatch(broadcastActions.getBroadcastByID(payload))
      .then((res: any) => {
        setResultList(res?.payload?.data?.results);
        setHeaderKeys(res?.payload?.data?.columns_mapping);
        setTableValue(res?.payload?.data?.field_mapping);

        setLoading(false);
      })
      .catch((error: any) => {

        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );

        setOnSearch(false);

        setLoading(false);
      })
      .finally(() => { });
  };

  useEffect(() => {
    getBroadCastDetailedData();
    getLogsListFilters();
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const getLogsListFilters = () => {
    let payload = {
      type: "email",
    };
    dispatch(broadcastActions.getAllCommunicationFilterSettings(payload))
      .then((res: any) => { })
      .catch((err: any) => { })
      .finally(() => { });
  };

  

     // All About Pagination
     function handlePageChange(event: any, value: any) {
      setLocalValues({
        ...localValues,
        currentPage: value - 1,
      });
    }
    const handleChangeRowsPerPage = (value: any) => {
      setLocalValues({
        ...localValues,
        itemsPerPage: value,
        currentPage: 0,
      });
    };



  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      status: e.target.value,
    });
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      search: e,
      currentPage: 0,
    });

    setOnSearch(true);
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });

  };
  const clearAllCallback = () => {
    setLocalValues({
      ...localValues,

      status: "",
      search: "",
    });

  };

  const handleButtonClick = (formikRef: any) => {
    formikRef.current.handleReset();
  };
  const renderSelectIcon = localValues.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  const handleExportButton = () => {
    const id :any = broadcast_id.broadcast_id;
    const type :any = "email"
    ChatbotConsoleService.getWhatsappBroadcastDetailsDownload(id,type,localValues?.search,localValues?.status)
      .then((response: any) => {
        window.open(response.data.data.source, "_blank");
      })
      .catch((error: any) => {
        console.error("Error occurred while fetching the file URL.", error);
      });
  };

  const handleRefresh = () => {
    setTimeout(() => {
      getBroadCastDetailedData();
      setLocalValues({
        itemsPerPage: 10,
        currentPage: localValues.currentPage,
        status: "",
        search: "",
      });
    }, 1000);
  };

  return (
    <MainContainer>
      <TemplateTable>
        <TableTitleHeader>
          <div className="left">
            <div className="title">
              <p>Filter By</p>
            </div>
            <div className="">
              <AppForm
                initialValues={{ search: "" }}
                onSubmit={(values: any, submitProps: any) => { }}
                innerRef={formikRef}
                divStyle={{ width: "100%" }}
              >
                <Grid
                  container
                  spacing={1}
                  display="flex"
                  sx={{
                    m: 0.2,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    alignItems="center"
                    sx={{ paddingTop: "0 !important" }}
                  >
                    <NewNativeSelect>
                      <Select
                        defaultValue={undefined}
                        name="status"
                        value={localValues?.status}
                        onChange={(e) => handleSelectChange(e, "status")}
                        displayEmpty
                        className="new-select-main"
                        placeholder="Hello"
                      >
                        <MenuItem value="" disabled>
                          Select Status
                        </MenuItem>
                        {filtersValues?.status?.map((item: any, index: any) => (
                          <MenuItem value={item.value} key={index}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <div
                        className="clear-icon"
                        onClick={(e: any) => clearCallback(e, "status")}
                      >
                        {" "}
                        {renderSelectIcon}
                      </div>
                    </NewNativeSelect>
                  </Grid>

                  <Grid
                    item
                    alignItems="center"
                    display="flex"
                    sx={{ padding: "0 !important" }}
                    gap="0.5rem"
                  >
                    <AppFormSearchField
                      name="search"
                      placeholder="Search"
                      defaultBorder={true}
                      divStyle={{
                        marginLeft: "10px",
                        minWidth: "220px",
                        marginBottom: "0",
                      }}
                      onChange={(e: any) => {
                        handleSelectSearch(e, "search");
                      }}
                      clearCallback={(e: any) => clearCallback(e, "search")}
                    />

                    {localValues.status && localValues.search && (
                      <AppButton
                        variant="outline"
                        onClick={() => {
                          clearAllCallback();
                          handleButtonClick(formikRef);
                        }}
                        sx={{ mb: "9px" }}
                      >
                        Reset
                      </AppButton>
                    )}
                  </Grid>
                </Grid>
              </AppForm>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              float: "right",
              gap: "10px",
            }}
          >
            <Grid item>
              {resultList?.length === 0 ? (
                <AppButton
                  style={{ width: "20px" }}
                  variant="outline"
                  disabled
                  onClick={() => handleExportButton()}
                  downloadFile
                >
                  <FileUploadOutlinedIcon />
                </AppButton>
              ) : (
                <AppButton
                  style={{ width: "20px" }}
                  variant="outline"
                  onClick={() => handleExportButton()}
                  downloadFile
                >
                  <FileUploadOutlinedIcon />
                </AppButton>
              )}
            </Grid>
            <Grid item>
              <AppButton
                style={{ width: "20px" }}
                variant="contained"
                onClick={handleRefresh}
              >
                <RefreshTwoToneIcon />
              </AppButton>
            </Grid>
          </div>
        </TableTitleHeader>
        {
          loading ? (<SkeletonLoader numberOfCol={4} numberofRow={4} />) : BroadcastByIdDataList?.length ? (
            <ResponsiveTable
              columns={headerKeys}
              data={resultList}
              rowsPerPage={localValues?.itemsPerPage?.value}
              pageNumber={localValues?.currentPage}
              totalDataCount={totalcount}
              hasMoreData={true}
              name={"formkey4"}
              setCheckboxFieldValue={"setFieldValue"}
              subscriptionName={"subscriptionName"}
              tableValue={tableValue}
            />
          ) : (
            <NoRecordsFound height="calc(100vh - 289px)" />
          )
        }

      </TemplateTable>

      {BroadcastByIdDataList?.length ? (
              <AppPaginationComponent
                totalCount={BroadcastByIdData && BroadcastByIdData?.pagination?.totalRecords}
                handlePageChange={handlePageChange}
                currentPage={localValues?.currentPage}
                totalPages={BroadcastByIdData && BroadcastByIdData?.pagination?.totalPages}
                rowsPerPage={localValues?.itemsPerPage?.value}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
          
      ) : null}
    </MainContainer>
  );
};

export default EmailLogsTableData;
