
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { broadcastActions } from "./BroadcastDash.actions";

interface initialStateTypes {
  data: {
    broadcastDataEmailAll:any;
    logListDataObj: any;
    whatapplogListDataObj: any;
    broadcastDataById:any;
    filtersValues: any;
    broadcastType:any;
    logReportListDataObj: any;
    whatsappGraphTemplateData: any;
    whatsappTotalMessagesData: any;
    whatsappTotalMessagesLogsData: any;
  };
  error: any;
  loading: boolean;
  whatsApploadingArray: Array<any>;
}

const initialState: initialStateTypes = {
  data: {
    broadcastDataEmailAll:null,
    logListDataObj: {
      count: 0,
      results: [],
    },
    whatapplogListDataObj : {
      count: 0,
      results: [],
    },
    broadcastDataById:null,
    filtersValues: null,
    broadcastType:null,
    logReportListDataObj: {
      count: 0,
      results: [],
    },
    whatsappGraphTemplateData: {},
    whatsappTotalMessagesData: {},
    whatsappTotalMessagesLogsData: {}
  },
  error: "",
  loading: false,
  whatsApploadingArray: [],
};

const BroadcastSlice = createSlice({
  name: "BroadcastSlice",
  initialState,
  reducers: {
    removebroadcastDashboard(){
      return initialState
    },
    removeLoadingFromArray(state: any, action: PayloadAction<any>) {
      state.whatsApploadingArray = state.whatsApploadingArray.filter(
        (ele: any) => ele !== action?.payload,
      );
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(
        broadcastActions.getEmail_InsightsAll.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        broadcastActions.getEmail_InsightsAll.fulfilled,
        (state, action) => {
          
          state.data.broadcastDataEmailAll = action.payload;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        broadcastActions.getEmail_InsightsAll.rejected,
        (state, action) => {
          state.loading = false;
          state.data.broadcastDataEmailAll = null;
          state.error = action?.error?.message || "";
        }
      )


      .addCase(
        broadcastActions.getAllCommunicationLogs.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )

      .addCase(
        broadcastActions.getAllCommunicationLogs.fulfilled,
        (state, action) => {
          state.data.logListDataObj = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        broadcastActions.getAllCommunicationLogs.rejected,
        (state, action) => {
          state.loading = false;
          state.data.logListDataObj = {};
          state.error = action?.error?.message || "";
        }
      )

      //whatsapp whatsapp logs
      .addCase(
        broadcastActions.getWhatappDetailsLogs.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )

      .addCase(
        broadcastActions.getWhatappDetailsLogs.fulfilled,
        (state, action) => {
          state.data.whatapplogListDataObj = action.payload?.data?.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        broadcastActions.getWhatappDetailsLogs.rejected,
        (state, action) => {
          state.loading = false;
          state.data.whatapplogListDataObj = {
            count: 0,
            results: []
          };
          state.error = action?.error?.message || "";
        }
      )
      .addCase(
        broadcastActions.getBroadcastByID.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        broadcastActions.getBroadcastByID.fulfilled,
        (state, action) => {
          state.data.broadcastDataById = action.payload?.data;
          state.error = "";
          state.loading = false;
        }
      )
      // .addCase(
      //   broadcastActions.getBroadcastByID.rejected,
      //   (state, action) => {
      //     state.loading = false;
      //     state.data.logListDataObj = {};
      //     state.error = action?.error?.message || "";
      //   }
      // )


      .addCase(
        broadcastActions.getBroadcastByID.rejected,
        (state, action) => {
          
          state.data.broadcastDataById = null;
          state.error = "";
          state.loading = false;
        }
      )


      .addCase(
        broadcastActions.getAllCommunicationFilterSettings.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        broadcastActions.getAllCommunicationFilterSettings.fulfilled,
        (state, action) => {
          state.data.filtersValues = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )

      // Import Step 1 cases
      .addCase(
        broadcastActions.getAllCommunicationFilterSettings.rejected,
        (state, action) => {
          state.loading = false;
          state.data.filtersValues = null;
          state.error = action?.error?.message || "";
        }
      )

      .addCase(
        broadcastActions.getCommunicationReportsList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )

      .addCase(
        broadcastActions.getCommunicationReportsList.fulfilled,
        (state, action) => {
          state.data.logReportListDataObj = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )

      .addCase(
        broadcastActions.getCommunicationReportsList.rejected,
        (state, action) => {
          state.loading = false;
          state.data.logReportListDataObj = {};
          state.error = action?.error?.message || "";
        }
      )

      .addCase(
        broadcastActions.getBroadcastStatsData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        broadcastActions.getBroadcastStatsData.fulfilled,
        (state, action) => {
          state.data.broadcastType = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )

      // Import Step 1 cases
      .addCase(
        broadcastActions.getBroadcastStatsData.rejected,
        (state, action) => {
          state.loading = false;
          state.data.broadcastType = null;
          state.error = action?.error?.message || "";
        }
      )

      // Whatsapp Template Graph
      .addCase(
        broadcastActions.getWhatsappTemplateGraphAction.rejected,
        (state, action) => {
          state.loading = false;
          state.error = true;
          state.data.whatsappGraphTemplateData = {};
        },
      )
      .addCase(
        broadcastActions.getWhatsappTemplateGraphAction.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.whatsApploadingArray = [...state.whatsApploadingArray, "whatsappGraph"];
        },
      )
      .addCase(
        broadcastActions.getWhatsappTemplateGraphAction.fulfilled,
        (state, action) => {
          state.data.whatsappGraphTemplateData = action?.payload?.data?.data;
          state.loading = false;
          state.error = false;
        },
      )

      // Whatsapp Total Messages
      .addCase(
        broadcastActions.getWhatsappTotalMessagesAction.rejected,
        (state, action) => {
          state.loading = false;
          state.error = true;
          state.data.whatsappTotalMessagesData = {};
        },
      )
      .addCase(
        broadcastActions.getWhatsappTotalMessagesAction.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.whatsApploadingArray = [...state.whatsApploadingArray, "whatsappTotalMessages"];
        },
      )
      .addCase(
        broadcastActions.getWhatsappTotalMessagesAction.fulfilled,
        (state, action) => {
          state.data.whatsappTotalMessagesData = action?.payload?.data?.data;
          state.loading = false;
          state.error = false;
        },
      )

      // Whatsapp Total Messages Logs
      .addCase(
        broadcastActions.getWhatsappTotalMessagesLogsAction.rejected,
        (state, action) => {
          state.loading = false;
          state.error = true;
          state.data.whatsappTotalMessagesLogsData = {};
        },
      )
      .addCase(
        broadcastActions.getWhatsappTotalMessagesLogsAction.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.whatsApploadingArray = [...state.whatsApploadingArray, "whatsappTotalMessagesLogs"];
        },
      )
      .addCase(
        broadcastActions.getWhatsappTotalMessagesLogsAction.fulfilled,
        (state, action) => {
          state.data.whatsappTotalMessagesLogsData = action?.payload?.data?.data;
          state.loading = false;
          state.error = false;
        },
      )  
}});

export const { removebroadcastDashboard, removeLoadingFromArray } = BroadcastSlice.actions;

export default BroadcastSlice.reducer;

