import React from 'react'
import styled from 'styled-components'
import { AppFormField, AppFormSelectField } from '../../../../../../AppComponents/Forms'
import { whatsappCtaValueAction } from '../../../Utils/Constants'
import { FieldArray, getIn, useFormikContext } from 'formik'
import WhatsappTextField from '../WhatsappTextField'

interface LimitedTimeOfferProps {
    disabled: boolean
}
const LimitedTimeOffer = (props: LimitedTimeOfferProps): JSX.Element => {
    const { disabled } = props
    const { values } = useFormikContext<any>();
    return (
        <Container>
            <TextContent>
                <p className='heading'>
                    Limited Time Offer
                </p>
                <p className='content'>
                    Create buttons that let customers respond to your message or take action.
                </p>
            </TextContent>
            <AppFormField
                name="offer_title"
                placeholder="Enter text for limited time offer"
                charactersLimit={16}
                disabled={disabled}
            />
            <AppFormField
                name="offer_coupon_code"
                placeholder="Enter text for coupon code"
                charactersLimit={15}
                disabled={disabled}
            />
            <div className='url_container'>
                <AppFormSelectField
                    label=""
                    Options={whatsappCtaValueAction}
                    name={"offer_url_type"}
                    disabled={disabled}
                    placeholder="Select URL type"
                    divStyle={{ width: "200px" }}
                />
                {values?.offer_url_type === "dynamic" ?
                    <WhatsappTextField
                        name={"offer_url"}
                        label={``}
                        placeholder="https://www.geta.ai/{{1}}"
                        charactersLimit={2000}
                        variablesKey={"offer_url_mapping"}
                        variablesButtonLimit={1}
                        endVariableRequired={true}
                        disabled={disabled}
                        removeSpaceBeforAfterVariable={true}
                    />
                    : <AppFormField
                        name="offer_url"
                        placeholder="https://www.geta.ai/"
                        charactersLimit={2000}
                        disabled={disabled}
                    />}

            </div>
            <AppFormField
                name="offer_button_text"
                placeholder="Enter text for the button"
                charactersLimit={25}
                disabled={disabled}
            />
            <FieldArray name={`offer_url_mapping`}>
                {({ remove }) => (
                    <React.Fragment>
                        {values &&
                            getIn(values, `offer_url_mapping`)?.length > 0 &&
                            getIn(values, `offer_url_mapping`)?.map((item: any, index: number) => {
                                return (
                                    <LimitedOfferVariablesContainer>
                                        <p>{`{{${index + 1}}}`} </p>

                                        <AppFormField
                                            name={`offer_url_mapping[${index}].variable`}
                                            placeholder="Resolve Limited Offer Variable"
                                            charactersLimit={25} />
                                    </LimitedOfferVariablesContainer>
                                )
                            })}
                    </React.Fragment>
                )}
            </FieldArray>
        </Container>
    )
}

export default LimitedTimeOffer

const Container = styled.div`
&&{
    display:flex;
    flex-direction:column;
    .url_container {
        display: flex;  
        gap: 1rem;
    }
}`;
const TextContent = styled.div`
&&{
    display:flex;
    flex-direction:column;
    margin-bottom: 1rem;
    .heading{
        font-size: 15px;
        margin: 0;
    }
    .content{
        font-size: 15px;
        font-weight: 400;
        margin: 0;
        color: rgba(126, 131, 146, 1);
    }
}`

const LimitedOfferVariablesContainer = styled.div`
&&{
    display:flex;
    gap:1rem;
    align-items:start;
    width:70%;
        p{
        margin-top:0.8rem;
        font-size:14px;
        font-weight:500;
    }
    
}`