import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";


// Email Domain
export class EmailNetworkService {

  static postEmailDomain(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BROADCAST_EMAIL_DOMAIN}`,
      data
    );
  }
  static postFilteredData(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.FILTERED_DATA}`, data);
  }
  static postEmailDomainVerification(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BROADCAST_EMAIL_DOMAIN_VERIFICATION}`, data
    );
  }
  static postEmailDefault(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BROADCAST_EMAIL_DEFAULT_VERIFICATION}`,
      data
    );
  }
  static deleteDomain(data: any) {
    return http.delete(
      `${consoleBaseUrl}${Endpoints.BROADCAST_EMAIL_DOMAIN_DELETE}/${data.domain}`, {}

    );
  }
  static postBounceDomain(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BROADCAST_EMAIL_BOUNCE_DOMAIN}`, data
    );
  }


  static getEmailAnalyticsList(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_EMAIL_ANALYTICS_LIST}`,
      data
    );
  }

  static getEmailAnalyticsListByID(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_EMAIL_ANALYTICS_LIST_BY_ID}/${data.id}`, data
    );
  }

  static getEmailTemplatesLists(data: any) {
    const { limit, offset, search } = data;
    const url = `${consoleBaseUrl}${Endpoints.EMAIL_TEMPLATE_LISTINGS}`;
    const params = { limit, offset, search };
    if (!limit && !offset) {
      return http.get(url);

    } else {
      return http.get(url, { params });

    }
  }

  static getEmailgalleryTemplatesListings(search?: any, category?: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.EMAIL_GALLERY_TEMPLATE_LISTINGS}?search=${search}&category=${category}`
    );
  }

  // Email Template 
  static postEmailTemplate(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BROADCAST_CREATE_EMAIL_TEMPLATE}`,
      data
    );
  }
  static getEmailTag() {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BROADCAST_EMAIL_TAG_LIST}`
    );
  }
  static getEmailTemplateList(id: any) {

    return http.get(
      `${consoleBaseUrl}${Endpoints.BROADCAST_EMAIL_TEMPLATE_LIST}/${id}`
    );
  }
  static deleteEmailTemplate(id: any) {

    return http.post(
      `${consoleBaseUrl}${Endpoints.DELETE_EMAIL_TEMPLATE
      }/${id}`
    );
  }

  static getBroadcastById(id: string) {
    return http.post(`${consoleBaseUrl}${Endpoints.BROADCAST_BY_ID}/${id}`, {});
  }
  static postCreateBroadcast(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BROADCAST_EMAIL_CREATE}`, data
    );

  }
  static deleteEmailBroadcast(data:any) {
    const{id}=data
    const type={
      type:data.type
    }
    return http.post(
      `${consoleBaseUrl}${Endpoints.DELETE_EMAIL_BROADCAST
      }/${id}`, type
    );
  }

  static updateSelectedContactItem(data: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_SELECTED_CONTACT_SINGLE_ITEM}`,
      data
    );
  }
  static postUpload(data: any, config: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BROADCAST_ATTACHMENT_UPLOAD}`,
      data,
      config
    );
  }

  static getValidateEmail() {
    return http.get(
      `${consoleBaseUrl}${Endpoints.BROADCAST_VALID_EMAIL_LIST}`
    );

  }
  static getFieldsTypeMappingList(id?: string) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_FIELDS_TYPE_MAPPING_LIST}?list_id=${id}`
    );
  }

  static getFooterList() {
    return http.get(
      `${consoleBaseUrl}${Endpoints.BROADCAST_VALID_EMAIL_FOOTER_LIST}`
    );

  }

  static postSelectedListTable(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.POST_SELECTED_LIST_TABLE}`, payload);
  }

  static updateEmailBroadcast(data: any, id: string) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_EMAIL_BROADCAST}/${id}`,
      data
    );
  }

  static updateEmailTemplate(data: any) {
    const { id, payload } = data;
    return http.post(`${consoleBaseUrl}${Endpoints.UPDATE_EMAIL_TEMPLATE}/${id}`, payload);
  }

  static updateEmailThumbnail(data: any) {
    const { id, payload } = data;
    return http.post(`${consoleBaseUrl}${Endpoints.UPDATE_THUMBNAIL}/${id}`, payload);
  }


  static postTestEmailTemplate(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.TEST_EMAIL_TEMPLATE}`, payload);
  }


  static getContactPropertiesList(header: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.CONTACT_PROPERTIES}`
    );

  }

  static sendEmailMessage(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.SEND_EMAIL_MESSAGE}`,
      data
    );
  }
}

//*emails *//
class Endpoints {
  static BROADCAST_EMAIL_DOMAIN = "/workspace/add-domain";
  static BROADCAST_EMAIL_DOMAIN_VERIFICATION = "/workspace/domain-verification";
  static BROADCAST_EMAIL_DEFAULT_VERIFICATION = "/workspace/set-default-domain-email";
  static BROADCAST_EMAIL_DOMAIN_DELETE = "/workspace/delete-domain";
  static BROADCAST_EMAIL_BOUNCE_DOMAIN = "/workspace/bounce-domain";

  static GET_EMAIL_ANALYTICS_LIST_BY_ID = "/workspace/email-broadcastById";
  static EMAIL_GALLERY_TEMPLATE_LISTINGS = "/core/email-template-gallery_list"
  
  static POST_SELECTED_LIST_TABLE = "/workspace/add-broadcast-data";
  static TEST_EMAIL_TEMPLATE = "/workspace/send-email-single-contact";
  static CONTACT_PROPERTIES = "/workspace/contact-properties/fields?status=visible"
  static FILTERED_DATA = "/workspace/filter_list_data";
  
  
  
  //new endpoints for node microservice
  static EMAIL_TEMPLATE_LISTINGS = "/workspace/broadcast/email-template-list";
  static BROADCAST_CREATE_EMAIL_TEMPLATE = "/workspace/broadcast/create-email-template"; 
  static UPDATE_THUMBNAIL = "/workspace/broadcast/create-update-email-thumbnail";
  static DELETE_EMAIL_TEMPLATE = "/workspace/broadcast/delete-email-template";
  static UPDATE_EMAIL_TEMPLATE = "/workspace/broadcast/update-email-template";
  static BROADCAST_EMAIL_TAG_LIST = "/workspace/broadcast/email-tags-list";
  static BROADCAST_EMAIL_TEMPLATE_LIST = "/workspace/broadcast/email-templateById";
  static BROADCAST_VALID_EMAIL_LIST = "/workspace/broadcast/validated-email-list";
  static BROADCAST_VALID_EMAIL_FOOTER_LIST = "/workspace/broadcast/update-profile-list";
  static BROADCAST_ATTACHMENT_UPLOAD = "/workspace/broadcast/upload_email_attachment";
  static BROADCAST_EMAIL_CREATE = "/workspace/broadcast/create-email-broadcast";
  static UPDATE_EMAIL_BROADCAST = "/workspace/broadcast/update-email-broadcast";

  static UPDATE_SELECTED_CONTACT_SINGLE_ITEM = "/workspace/broadcast/update-broadcast-list-temp-data";
  static GET_FIELDS_TYPE_MAPPING_LIST = "/workspace/broadcast/fields_type_mapping_list";


  static BROADCAST_BY_ID = "/workspace/broadcast/broadcasting-list";
  static GET_EMAIL_ANALYTICS_LIST = "/workspace/broadcast/broadcasting-list";
  static DELETE_EMAIL_BROADCAST = "/workspace/broadcast/delete-broadcast-by-id";

  static SEND_EMAIL_MESSAGE = "/workspace/send-email-single-contact";

}
