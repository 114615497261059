import { Box, IconButton, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import AppButton from "../../../../../AppComponents/AppButton";
import AppInputField from "../../../../../AppComponents/AppInputField";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import { AppForm } from "../../../../../AppComponents/Forms";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader";
import { theme } from "../../../../../Customization/Theme";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { RoleNetworkService } from "../../../../../Store/Slices/Settings/LiveChat/RolesPermission/RoleNetworkService";
import { BroadcastTopTitle } from "../../../../../TableStyle";
import { getDefaultValue } from "../../../../../Utils";
import { RolesPermissionWrapper } from "../RolesPermissionsStyle";
import SwitchButton from "../components/SwitchButton";
import FieldPermission from "./FieldPermission";
import { RoleUserTable } from "./PermissionStyle";

interface Props extends LoaderComponentProps {}
const Permission: React.FC<Props> = ({ setLoading }): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showAlert } = useNotification();
  const [roleName, setRoleName] = useState<any>("");
  const [data, setData] = useState<any>([]);
  // Tabs Functionality
  const [index, setIndex] = useState<number>(0);
  const onTabClicked = (event: any, index: any) => {
    setIndex(index);
  };
  const [userInput] = useState({
    role_name: "",
    access_level: "",
  });
  const [options, setOptions] = useState<any>([]);
  const accessPermissions = ["create", "read", "delete", "update"];
  // Get Permission ID
  const getPermissionById = () => {
    setLoading(true);
    const data = {
      id: id,
    };
    RoleNetworkService.getPermissionListing(data)
      .then((res: any) => {
        setData(res?.data?.data?.permission_config);
        setRoleName(res?.data?.data?.label);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // Get Create Permissions
  const getCreatePermissions = () => {
    setLoading(true);
    RoleNetworkService.getInitialPermissions()
      .then((res: any) => {
        setData(res?.data?.permission_config);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getPermissionOptions = () => {
    RoleNetworkService.getPermissionOptions().then((res: any) => {
      setOptions(res?.data?.data);
      // setOptions(res?.data?.data_without_none)
    });
  };
  useEffect(() => {
    getPermissionOptions();
    id ? getPermissionById() : getCreatePermissions();
  }, [id]); //eslint-disable-line react-hooks/exhaustive-deps

  // change Permission function
  const handleChange = (
    value: any,
    action: string,
    parentIndex: any,
    firstChildIndex?: any,
    secondChildIndex?: any,
    thirdChildIndex?: any,
    isContactCrudItem?: boolean
  ) => {
    const updatePermissionConfig = (item: any) => {
      if (action === "read" && value === "restricted_access") {
        item.permission_config = {
          ...item.permission_config,
          read: "restricted_access",
          update: "restricted_access",
          delete: "none",
        };
      } else {
        item.permission_config = {
          ...item.permission_config,
          [action || ""]: value,
          is_accessible: !accessPermissions.includes(action)
            ? value
            : item.permission_config?.is_accessible,
        };
      }
    };
    let dataCopy: any = JSON.parse(JSON.stringify(data));
    if (parentIndex >= 0) {
      const parentItem = dataCopy[parentIndex];
      if (firstChildIndex === undefined && secondChildIndex === undefined) {
        updatePermissionConfig(parentItem);
        //condition to unselect all the child modules if parent is false
        if (!dataCopy[parentIndex]?.permission_config?.is_accessible) {
          dataCopy[parentIndex]?.childs?.forEach((item: any) => {
            return (
              (item.permission_config.is_accessible = false),
              (item.permission_config.create = false),
              item?.childs &&
                item?.childs?.map((child: any) => {
                  return (
                    (child.permission_config.is_accessible = false),
                    (child.permission_config.create = false),
                    (child.permission_config.read = "restricted_access"),
                    (child.permission_config.update = "restricted_access"),
                    (child.permission_config.delete = "restricted_access"),
                    child.childs?.map((subChild: any) => {
                      return (
                        (subChild.permission_config.is_accessible = false),
                        (subChild.permission_config.create = false),
                        (subChild.permission_config.read = "restricted_access"),
                        (subChild.permission_config.update =
                          "restricted_access"),
                        (subChild.permission_config.delete =
                          "restricted_access")
                      );
                    })
                  );
                })
            );
          });
        }
        //condition to select all the child modules if parent is true
        if (dataCopy[parentIndex]?.permission_config?.is_accessible) {
          dataCopy[parentIndex]?.childs?.forEach((item: any) => {
            return (
              (item.permission_config.is_accessible = true),
              item?.childs &&
                item?.childs?.map((child: any) => {
                  return (
                    (child.permission_config.is_accessible = true),
                    child.childs?.map((subChild: any) => {
                      return (subChild.permission_config.is_accessible = true);
                    })
                  );
                })
            );
          });
        }
      }
    }
    if (parentIndex >= 0 && firstChildIndex >= 0) {
      if (secondChildIndex === undefined) {
        const parentItem = dataCopy[parentIndex];
        const childItem = parentItem.childs?.[firstChildIndex];

        if (!parentItem.childs?.[firstChildIndex]?.is_accessible) {
          dataCopy[parentIndex]?.childs?.[firstChildIndex]?.childs?.forEach(
            (item: any) => {
              return (
                (item.permission_config.is_accessible = false),
                (item.permission_config.create = false),
                (item.permission_config.read = "restricted_access"),
                (item.permission_config.update = "restricted_access"),
                (item.permission_config.delete = "restricted_access"),
                item.childs?.map((subChild: any) => {
                  return (subChild.permission_config.is_accessible = false);
                })
              );
            }
          );
        }

        updatePermissionConfig(childItem);

        //hardcoded for contacts crud module
        if (isContactCrudItem) {
          const contactCrudModuleReadStatus = parentItem.childs?.[0]?.is_accessible ?? false;
      
          if (!contactCrudModuleReadStatus) {
            dataCopy[parentIndex]?.childs?.forEach(
              (item: any) => {
                return (
                  (item.permission_config.is_accessible = false),
                  (item.permission_config.create = false),
                  (item.permission_config.read = "restricted_access"),
                  (item.permission_config.update = "restricted_access"),
                  (item.permission_config.delete = "restricted_access"),
                  item.childs?.map((subChild: any) => {
                    return (subChild.permission_config.is_accessible = false);
                  })
                );
              }
            );
          };}       
                // if child is not selected then parent is made off
                const subModuleChecker = parentItem.childs?.every(
                  (item: any) => !item.permission_config?.is_accessible
                );
                dataCopy[parentIndex].permission_config.is_accessible =
                  !subModuleChecker;
        if (
          !dataCopy[parentIndex]["childs"][firstChildIndex].permission_config
            ?.is_accessible
        ) {
          dataCopy[parentIndex]["childs"][firstChildIndex].permission_config = {
            ...dataCopy[parentIndex]["childs"][firstChildIndex]
              .permission_config,
            create: false,
            read: "restricted_access",
            update: "restricted_access",
            delete: "none",
          };
        }
      }
    }
    
    if (
      parentIndex >= 0 &&
      firstChildIndex >= 0 &&
      secondChildIndex >= 0 &&
      thirdChildIndex === undefined
    ) {
      const parentItem = dataCopy[parentIndex];
      const childItem = parentItem.childs?.[firstChildIndex];
      const secondChildItem = childItem.childs?.[secondChildIndex];
      updatePermissionConfig(secondChildItem);
      // if child is not selected then parent is made off
      const subModuleChecker = childItem.childs?.every(
        (item: any) => !item.permission_config?.is_accessible
      );
      dataCopy[parentIndex]["childs"][
        firstChildIndex
      ].permission_config.is_accessible = !subModuleChecker;

      if (!secondChildItem.childs?.[firstChildIndex]?.is_accessible) {
        dataCopy[parentIndex]?.childs?.[firstChildIndex]?.childs?.[
          secondChildIndex
        ]?.childs?.forEach((item: any) => {
          return (
            (item.permission_config.is_accessible = false),
            (item.permission_config.create = false),
            (item.permission_config.read = "restricted_access"),
            (item.permission_config.update = "restricted_access"),
            (item.permission_config.delete = "restricted_access")
          );
        });
      }
      if (
        !dataCopy[parentIndex]["childs"][firstChildIndex]["childs"][
          secondChildIndex
        ].permission_config?.is_accessible
      ) {
        dataCopy[parentIndex]["childs"][firstChildIndex]["childs"][
          secondChildIndex
        ].permission_config = {
          ...dataCopy[parentIndex]["childs"][firstChildIndex]["childs"][
            secondChildIndex
          ].permission_config,
          create: false,
          read: "restricted_access",
          update: "restricted_access",
          delete: "none",
        };
      }
    }
    if (
      parentIndex >= 0 &&
      firstChildIndex >= 0 &&
      secondChildIndex >= 0 &&
      thirdChildIndex >= 0
    ) {
      const parentItem = dataCopy[parentIndex];
      const childItem = parentItem.childs?.[firstChildIndex];
      const secondChildItem = childItem.childs?.[secondChildIndex];
      const thirdChildItem = secondChildItem.childs?.[thirdChildIndex];
      updatePermissionConfig(thirdChildItem);
      // if child is not selected then parent is made off
      const subModuleChecker = secondChildItem.childs?.every(
        (item: any) => !item.permission_config?.is_accessible
      );
      dataCopy[parentIndex]["childs"][firstChildIndex]["childs"][
        secondChildIndex
      ].permission_config.is_accessible = !subModuleChecker;
    }
    if (action === "read" && value === "restricted_access") {
      const parentItem = dataCopy[parentIndex];
      const childItem = parentItem.childs?.[firstChildIndex];
      if (
        parentIndex >= 0 &&
        firstChildIndex !== undefined &&
        !secondChildIndex
      ) {
        updatePermissionConfig(childItem);
      }
      if (
        parentIndex >= 0 &&
        firstChildIndex !== undefined &&
        secondChildIndex !== undefined
      ) {
        const secondChildItem = childItem.childs?.[secondChildIndex];
        updatePermissionConfig(secondChildItem);
      }
    }
    setData(dataCopy);
  };

  const checkValidatePermissions = (tempData: any) => {
    const parentModules = tempData?.filter((parent: any) => {
      return parent?.permission_config?.is_accessible && parent?.childs?.length;
    });
    const result = parentModules?.map(
      (item: any) =>
        item?.childs?.length !== 0 &&
        item?.childs?.some(
          (child: any) => child?.permission_config?.is_accessible
        )
    );
    return result?.includes(false) ? false : true;
  };
  const handleSubmit = () => {
    if (!roleName) return showAlert("Role name is required", "error");

    const parentModules = data?.filter((parent: any) => {
      return parent?.permission_config?.is_accessible && parent?.childs?.length;
    });
    if (parentModules?.length === 0)
      return showAlert(
        "Incomplete Selection: If parent module is chosen, select at least one child module",
        "error"
      );

    const validationFlag = checkValidatePermissions(data) || false;
    if (!validationFlag) {
      return showAlert(
        "Incomplete Selection: If parent module is chosen, select at least one child module",
        "error"
      );
    }
    setLoading(true);
    if (id) {
      const editPermissions = {
        id: id,
        label: roleName,
        permission_config: data,
      };
      RoleNetworkService.putPermission(editPermissions)
        .then((res: any) => {
          if (res.status === 200) {
            showAlert(res.data?.message, "success");
            // navigate(`/settings/users-and-permissions/roles/list`)
          } else {
            showAlert(res.data?.message, "success");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const payload = {
        label: roleName,
        permission_config: data,
      };
      RoleNetworkService.postRole(payload)
        .then((res: any) => {
          showAlert(res.data?.message, "success");
          // navigate("/settings/users-and-permissions/roles/list")
        })
        .catch((err: any) => {
          showAlert(err?.response?.data?.message, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <AppForm
      initialValues={userInput}
      onSubmit={(values: any, submitProps: any) => {
        handleSubmit();
      }}
      validationSchema={undefined}
      divStyle={{
        gap: 0,
      }}
    >
      <BroadcastTopTitle
        style={{
          padding: "16px",
          backgroundColor: `${theme.palette.default.white}`,
        }}
      >
        <Box className="topTitle-left">
          <IconButton
            onClick={() =>
              navigate("/settings/users-and-permissions/roles/list")
            }
          >
            <AppMaterialIcons iconName="ChevronLeftOutlined" />
          </IconButton>
          <Typography fontSize="16px" fontWeight="500" component="h6">
            {id ? `Edit Role` : `Add Role`}
          </Typography>
        </Box>
        <Box className="topTitle-right"></Box>
      </BroadcastTopTitle>

      <RolesPermissionWrapper>
        {/* <RoleTab value={index} onChange={onTabClicked}>
          <Tab label="Permissions" />
          <Tab label="Field Permissions" />
        </RoleTab>      //commented for now  */}
        {index === 0 && (
          <RoleUserTable>
            <div className="role_name_container">
              <AppInputField
                name="role_name"
                value={roleName}
                divStyle={{ width: "300px" }}
                label="Role Name"
                onChange={(e: any) => setRoleName(e.target.value)}
                errorMessage=""
                style={{
                  border: `1px solid ${theme.palette.default.BorderColorGrey}`,
                }}
              />
            </div>
            {data?.map((item: any, parentIndex: any) => {
              return (
                <div className="row" key={parentIndex}>
                  <div className="header">
                    <div className="parent_module">
                      <SwitchButton
                        checked={item?.permission_config?.is_accessible}
                        onChange={(e: any) =>
                          handleChange(
                            e.target.checked,
                            item?.name,
                            parentIndex
                          )
                        }
                      />
                    </div>
                    <div className="module_title">
                      <div className="title">{item?.Label}</div>
                      <p className="menu_item">(Menu Item)</p>
                    </div>
                  </div>
                  {item?.childs?.map((subItem: any, firstChildIndex: any) => {
                    const isContactCrudItem:boolean = item?.name === "contacts" && subItem?.name === "contacts_crud" ;
                    return (
                      <div className="" key={firstChildIndex}>
                        <div className="main">
                          <span className="module_title_container">
                            <SwitchButton
                              checked={
                                subItem?.permission_config?.is_accessible
                              }
                              disabled={!item.permission_config?.is_accessible}
                              onChange={(e: any) =>
                                handleChange(
                                  e.target.checked,
                                  subItem?.name,
                                  parentIndex,
                                  firstChildIndex,
                                  undefined,
                                  undefined,
                                  isContactCrudItem
                                )
                              }
                            />
                            <p className="title">{subItem?.Label}</p>
                          </span>
                          <div className="subDataRight">
                            {subItem?.permission_config?.has_crud && (
                              <div className="featuresWrapper">
                                <span>
                                  <label>Create</label>
                                  <SwitchButton
                                    checked={subItem?.permission_config?.create}
                                    disabled={
                                      !subItem?.permission_config
                                        ?.is_accessible ||
                                      !item?.permission_config?.is_accessible
                                    }
                                    onChange={(e: any) =>
                                      handleChange(
                                        e.target.checked,
                                        "create",
                                        parentIndex,
                                        firstChildIndex
                                      )
                                    }
                                    divStyle={{ marginTop: "12px" }}
                                  />
                                </span>
                                <div className="">
                                  <p>View</p>
                                  <Select
                                    defaultValue={{
                                      value: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.read
                                      )?.value,
                                      label: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.read
                                      )?.label,
                                    }}
                                    value={{
                                      value: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.read
                                      )?.value,
                                      label: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.read
                                      )?.label,
                                    }}
                                    isDisabled={
                                      !subItem?.permission_config?.is_accessible
                                    }
                                    onChange={(e: any) =>
                                      handleChange(
                                        e.value,
                                        "read",
                                        parentIndex,
                                        firstChildIndex
                                      )
                                    }
                                    options={options}
                                    className="select"
                                    filterOption={(
                                      option: any,
                                      rawInput: any
                                    ) => {
                                      if (option?.value === "none") return null;

                                      return option;
                                    }}
                                  />
                                </div>
                                <div className="">
                                  <p>Edit</p>
                                  <Select
                                    defaultValue={{
                                      value: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.update
                                      )?.value,
                                      label: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.update
                                      )?.label,
                                    }}
                                    value={{
                                      value: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.update
                                      )?.value,
                                      label: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.update
                                      )?.label,
                                    }}
                                    isDisabled={
                                      !subItem?.permission_config
                                        ?.is_accessible ||
                                      subItem?.permission_config?.read ===
                                        "restricted_access"
                                    }
                                    onChange={(e: any) =>
                                      handleChange(
                                        e.value,
                                        "update",
                                        parentIndex,
                                        firstChildIndex
                                      )
                                    }
                                    options={options}
                                    className="select"
                                    filterOption={(
                                      option: any,
                                      rawInput: any
                                    ) => {
                                      if (option?.value === "none") return null;

                                      return option;
                                    }}
                                  />
                                </div>
                                <div className="">
                                  <p>Delete</p>
                                  <Select
                                    defaultValue={{
                                      value: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.delete
                                      )?.value,
                                      label: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.delete
                                      )?.label,
                                    }}
                                    value={{
                                      value: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.delete
                                      )?.value,
                                      label: getDefaultValue(
                                        options,
                                        "value",
                                        subItem?.permission_config?.delete
                                      )?.label,
                                    }}
                                    isDisabled={
                                      !subItem?.permission_config?.is_accessible
                                    }
                                    onChange={(e: any) =>
                                      handleChange(
                                        e.value,
                                        "delete",
                                        parentIndex,
                                        firstChildIndex
                                      )
                                    }
                                    options={options}
                                    className="select"
                                    filterOption={(
                                      option: any,
                                      rawInput: any
                                    ) => {
                                      if (
                                        option?.value === "global_access" &&
                                        subItem?.permission_config?.read ===
                                          "restricted_access"
                                      )
                                        return null;
                                      return option;
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {subItem?.childs ? (
                          subItem?.childs?.map(
                            (subChildItem: any, secondChildIndex: any) => {
                              return (
                                <div
                                  className="sub_header"
                                  key={secondChildIndex}
                                >
                                  {subChildItem?.permission_config?.has_crud ? (
                                    <div className="sub_header_container">
                                      <span className="sub_header_container_switch">
                                        <SwitchButton
                                          checked={
                                            subChildItem?.permission_config
                                              ?.is_accessible
                                          }
                                          disabled={
                                            !subItem.permission_config
                                              .is_accessible
                                          }
                                          onChange={(e: any) =>
                                            handleChange(
                                              e.target.checked,
                                              subChildItem?.permission_config
                                                ?.name,
                                              parentIndex,
                                              firstChildIndex,
                                              secondChildIndex
                                            )
                                          }
                                        />
                                        <p>{subChildItem?.Label}</p>
                                      </span>
                                      <div className="createField">
                                        <label>Create</label>
                                        <SwitchButton
                                          checked={
                                            subChildItem?.permission_config
                                              ?.create
                                          }
                                          disabled={
                                            !subItem.permission_config
                                              .is_accessible
                                          }
                                          onChange={(e: any) =>
                                            handleChange(
                                              e.target.checked,
                                              "create",
                                              parentIndex,
                                              firstChildIndex,
                                              secondChildIndex
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="">
                                        <p>View</p>
                                        <Select
                                          defaultValue={{
                                            value: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.read
                                            )?.value,
                                            label: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.read
                                            )?.label,
                                          }}
                                          value={{
                                            value: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.read
                                            )?.value,
                                            label: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.read
                                            )?.label,
                                          }}
                                          isDisabled={
                                            !subChildItem?.permission_config
                                              ?.is_accessible
                                          }
                                          onChange={(e: any) =>
                                            handleChange(
                                              e.value,
                                              "read",
                                              parentIndex,
                                              firstChildIndex,
                                              secondChildIndex
                                            )
                                          }
                                          options={options}
                                          className="select"
                                          filterOption={(
                                            option: any,
                                            rawInput: any
                                          ) => {
                                            if (option?.value === "none")
                                              return null;
                                            return option;
                                          }}
                                        />
                                      </div>
                                      <div className="">
                                        <p>Edit</p>
                                        <Select
                                          defaultValue={{
                                            value: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.update
                                            )?.value,
                                            label: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.update
                                            )?.label,
                                          }}
                                          value={{
                                            value: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.update
                                            )?.value,
                                            label: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.update
                                            )?.label,
                                          }}
                                          isDisabled={
                                            !subItem?.permission_config
                                              ?.is_accessible ||
                                            subChildItem?.permission_config
                                              ?.read === "restricted_access"
                                          }
                                          onChange={(e: any) =>
                                            handleChange(
                                              e.value,
                                              "update",
                                              parentIndex,
                                              firstChildIndex,
                                              secondChildIndex
                                            )
                                          }
                                          options={options}
                                          className="select"
                                          filterOption={(
                                            option: any,
                                            rawInput: any
                                          ) => {
                                            if (option?.value === "none")
                                              return null;
                                            return option;
                                          }}
                                        />
                                      </div>
                                      <div className="">
                                        <p>Delete</p>
                                        <Select
                                          defaultValue={{
                                            value: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.delete
                                            )?.value,
                                            label: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.delete
                                            )?.label,
                                          }}
                                          value={{
                                            value: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.delete
                                            )?.value,
                                            label: getDefaultValue(
                                              options,
                                              "value",
                                              subChildItem?.permission_config
                                                ?.delete
                                            )?.label,
                                          }}
                                          isDisabled={
                                            !subChildItem?.permission_config
                                              ?.is_accessible
                                            // || subChildItem?.permission_config?.read === 'restricted_access'
                                          }
                                          onChange={(e: any) =>
                                            handleChange(
                                              e.value,
                                              "delete",
                                              parentIndex,
                                              firstChildIndex,
                                              secondChildIndex
                                            )
                                          }
                                          options={options}
                                          className="select"
                                          filterOption={(
                                            option: any,
                                            rawInput: any
                                          ) => {
                                            if (
                                              option?.value ===
                                                "global_access" &&
                                              subChildItem?.permission_config
                                                ?.read === "restricted_access"
                                            )
                                              return null;
                                            return option;
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="sub_childs_container">
                                      <div className="fields">
                                        <SwitchButton
                                          checked={
                                            subChildItem?.permission_config
                                              ?.is_accessible
                                          }
                                          onChange={(e: any) =>
                                            handleChange(
                                              e.target.checked,
                                              subChildItem?.name,
                                              parentIndex,
                                              firstChildIndex,
                                              secondChildIndex
                                            )
                                          }
                                          disabled={
                                            !subItem.permission_config
                                              ?.is_accessible
                                          }
                                        />
                                        <div className="title">
                                          {subChildItem?.Label}
                                        </div>
                                      </div>
                                      {subChildItem?.childs &&
                                        subChildItem?.childs?.map(
                                          (
                                            child: any,
                                            thirdChildIndex: any
                                          ) => {
                                            return (
                                              <div
                                                className="sub_childs_child_container"
                                                key={thirdChildIndex}
                                              >
                                                <div className="fields">
                                                  <SwitchButton
                                                    checked={
                                                      child?.permission_config
                                                        ?.is_accessible
                                                    }
                                                    onChange={(e: any) =>
                                                      handleChange(
                                                        e.target.checked,
                                                        child?.name,
                                                        parentIndex,
                                                        firstChildIndex,
                                                        secondChildIndex,
                                                        thirdChildIndex
                                                      )
                                                    }
                                                    disabled={
                                                      !subChildItem
                                                        .permission_config
                                                        ?.is_accessible
                                                    }
                                                  />
                                                  <p className="title">
                                                    {child?.Label}
                                                  </p>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </RoleUserTable>
        )}
        {index === 1 && <FieldPermission />}
        <div className="footer-button">
          <AppButton
            onClick={() =>
              navigate("/settings/users-and-permissions/roles/list")
            }
            variant="outline"
            style={{ minWidth: "100px" }}
          >
            cancel
          </AppButton>
          <AppButton variant="default" onClick={handleSubmit}>
            {/* <AppMaterialIcons iconName='ri-save-line' /> */}
            Save
          </AppButton>
        </div>
      </RolesPermissionWrapper>
    </AppForm>
  );
};

export default withLoader(Permission);

const RoleTab = styled(Tabs)`
  && {
    // border: 2px solid blue;
    // border-bottom: 1px solid rgba(126, 131, 146, 0.2);
    margin: 0 1rem;
    button {
      text-transform: capitalize;
      font-size: 0.875rem;
    }
    .Mui-selected {
      border-bottom: 3px solid;
    }
  }
`;
