import React, { useEffect, useRef, useState } from "react";
import AppButton from "../../../../../../AppComponents/AppButton";
import {
  AppForm,
  AppSubmitButton,
} from "../../../../../../AppComponents/Forms";
import { convertFormToPayloadSingleWhatsappBroadcast } from "../../../../../../Components/Leads/contact-action/utils/constants";
import WhatsappBroadcastResolveVaribles from "../../../../../../Components/Leads/contact-action/utils/WhatsappBroadcastResolveVaribles";
import { useNotification } from "../../../../../../Hooks/useNotification/useNotification";
import { scheduleSchema } from "../../../../../../Models/ValidationSchema";
import { useAppDispatch, useAppSelector } from "../../../../../../Store";
import {
  allMessagesStore
} from "../../../../../../Store/Slices/socket/AllMessagesSlice";
import { useLiveChatWhatsappTemplateData } from "../../../../../../Store/Slices/socket/liveChatWhatsappTemplateSelect/LiveChatWhatsappTemplateSelect.selectors";
import { WhatsappNetworkService } from "../../../../../../Store/Slices/Whatsapp/WhatsappNetworkService";
import { setSelectedTemplateData } from "../../../../../../Store/Slices/socket/liveChatWhatsappTemplateSelect/LiveChatWhatsappTemplateSelect.slice";
import { dataToTemplateFormData } from "../../../../../../Components/Broadcast/WhatsApp/Utils/utils";
interface Props {
  isShowing: boolean;
  toggle: (val?: boolean | undefined) => void;
}

const ModelBody: React.FC<Props> = ({ isShowing, toggle }) => {
  const { showAlert } = useNotification();
  const { userInfoData } = useAppSelector(allMessagesStore);
  const formRef: any = useRef(null)
  const dipatch = useAppDispatch()
  const handleContinueClick = (values: any, submitProps: any) => {
    let userInput: string = values?.body_preview || "";
    if (!userInput || userInput?.length < 1) {
      return;
    }

    let payload = convertFormToPayloadSingleWhatsappBroadcast(values)
    payload['broadcast_template_id'] = values?.template?.id
    payload['sender_field'] = "mobile_phone_number"
    payload['sender_field_value'] = userInfoData?.phone
    payload['contact_id'] = userInfoData?.contact_id
    WhatsappNetworkService.sendWhatsappMessage(payload)
      .then((res: any) => {
        showAlert(res?.data?.message || "Message sent!", "success");
        toggle(false);
      })
      .catch((error: any) => {
        showAlert(error?.response?.statusText || "Message sending failed", "error");
      })
      .finally(() => {
        toggle(false);
        dipatch(setSelectedTemplateData([]));
      });
    return;
  };

  const { selectedTemplateData } = useAppSelector(
    useLiveChatWhatsappTemplateData,
  );
  useEffect(() => {
    const data = { ...dataToTemplateFormData(selectedTemplateData), components: selectedTemplateData?.components };
    formRef?.current?.setFieldValue("template", data)
  }, [selectedTemplateData]) // eslint-disable-line react-hooks/exhaustive-deps

  const [userInput] = useState<any>({
    resolved_header_variables: [],
    resolved_body_variables: [],
    header_type: "text",
    resolved_buttons_variables: [],
    footer_value: "",
    header_media: null,
    originalTemplateData: selectedTemplateData,
    modifiedTemplateData: null,
    body_preview: "",
  });

  const handleCancelClick = () => {
    toggle(false);
  };

  return (
    <AppForm
      initialValues={userInput}
      onSubmit={(values: any, submitProps: any) => {
        handleContinueClick(values, submitProps);
      }}
      validationSchema={scheduleSchema}
      divStyle={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
      innerRef={formRef}
    >
      <div className="modelBodyRight">
        {selectedTemplateData?.template_name}
        <WhatsappBroadcastResolveVaribles />
      </div>

      <div className="modelFooter">
        <div className="modelFooterAction">
          <AppButton variant="danger" onClick={() => handleCancelClick()}>
            Cancel
          </AppButton>
          <AppSubmitButton title="Continue" />
        </div>
      </div>
    </AppForm>
  );
};
export default ModelBody;
