import { Box, Checkbox, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { ApexOptions } from "apexcharts";
import { format } from "date-fns";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import styled from "styled-components";
import DeliveredSVG from "../../../../../../../assets/images/delivered.svg";
import ReceivedSVG from "../../../../../../../assets/images/received.svg";
import SentSVG from "../../../../../../../assets/images/sent.svg";
import { useAppDispatch, useAppSelector } from "../../../../../../../Store";
import { broadcastActions } from "../../../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { removebroadcastDashboard } from "../../../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.slice";
import { dateRange } from "../../../../../../../Store/Slices/DashboardRedux/dashboard.selectors";
import ResponsePrgressBar from "../../../../../../Analystics_common_components/ProccessBar";
import { SkeletonCard, SkeletonCircleIconCard } from "../../../../../../Analystics_common_components/SkeletonCard";
import { SummaryWrapper } from "../../../../../../Dashboard/AnalyticsSummaryStyles";
import AppSkeletonLoader from "../../../../../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import DashBoardHeader from "../../../../../../UiComponents/DashBoardHeader/DashBoardHeader";
import { convertToK, whatsappBroadcastLayout, whatsappBroadcastLayoutForConversations } from "../../../../../Utils/utils";
import { capitalizeFirstLetter } from "../../../../../../../Utils";

interface Props {
  domElements?: any[];
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
  preventCollision?: boolean;
  margin?: any;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const ResponsiveGridLayout: React.ComponentClass<
  RGL.ResponsiveProps & RGL.WidthProviderProps,
  any
> = WidthProvider(Responsive);

const WhatsappBroadcastDashboardSummary: FunctionComponent<Props> = (props) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("lg");
  const [loading, setLoading] = useState<any>(false);
  const dashdateRange: any = useAppSelector(dateRange);
  const [freeConversion, SetFreeConversion] = useState<any>({})
  const [paidConversation, SetPaidConversation] = useState<any>({})
  const [allconversations, setAllconversations] = useState<any>({})
  const [chartLineData, setChartLineData] = useState<any>({ "options": {}, series: [] })
  const [messageSummaryGraph, setMessageSummaryGraph] = useState<any>({ "options": {}, series: [] })
  const [messageSummary, setMessageSummary] = useState<any>({ "sent": 0, "delivered": 0, "received": 0 })

  const [category, setCategory] = useState<string[]>([]);
  const [categoryList, setCategoryList] = useState<any[]>([]);

  const [whatsappConversationGraphSeries, SetWhatsappConversationGraphSeries] = useState<any>([])
  const [allWhatsappConversationGraphSeries, SetAllWhatsappConversationGraphSeries] = useState<any>([])

  const dispatch = useAppDispatch();

  const getWhastappInsightsApi = () => {
    setLoading(true);
    SetFreeConversion({
      ...freeConversion,
      data: []
    })
    SetPaidConversation({
      ...paidConversation,
      data: []
    })
    setAllconversations({})
    let payload = {
      type: "sms",
      start_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(broadcastActions.getWhastappConversationInsights(payload))
      .then((res: any) => {
        SetFreeConversion({
          "label": "Free Conversation",
          "info": "Free Conversation",
          "total_count": res?.payload?.data?.data?.data?.free_conversations?.free_conversations,
          "data": [
            {
              "label": "Free tier",
              "value": res?.payload?.data?.data?.data?.free_conversations?.free_tier,
              "backgroundColor": "#2CAFFE"

            },
            {
              "label": "Free entry Point",
              "value": res?.payload?.data?.data?.data?.free_conversations?.free_entry_point,
              "backgroundColor": "#AC2E82"
            }
          ]
        })
        SetPaidConversation({
          "label": "Paid Conversations",
          "info": "Paid Conversations",
          "total_count": res?.payload?.data?.data?.data?.paid_conversations?.paid_conversations,
          "data": [

            {
              "label": "Marketing",
              "value": res?.payload?.data?.data?.data?.paid_conversations?.marketing,
              "backgroundColor": "#2CAFFE"
            },
            {
              "label": "Utility",
              "value": res?.payload?.data?.data?.data?.paid_conversations?.utility,
              "backgroundColor": "#AC2E82"
            },
            {
              "label": "Service",
              "value": res?.payload?.data?.data?.data?.paid_conversations?.service,
              "backgroundColor": "#D35C43"
            },
            {
              "label": "Authentication",
              "value": res?.payload?.data?.data?.data?.paid_conversations?.authentication,
              "backgroundColor": "#30835F"
            }

          ]
        })
        setAllconversations(
          {
            "label": "All Conversations",
            "info": "All Conversations",
            "total_count": res?.payload?.data?.data?.data?.all_conversations?.all_conversations,
            "data": [

              {
                "label": "Marketing",
                "value": res?.payload?.data?.data?.data?.all_conversations?.marketing,
                "backgroundColor": "#2CAFFE"
              },
              {
                "label": "Utility",
                "value": res?.payload?.data?.data?.data?.all_conversations?.utility,
                "backgroundColor": "#AC2E82"
              },
              {
                "label": "Service",
                "value": res?.payload?.data?.data?.data?.all_conversations?.service,
                "backgroundColor": "#D35C43"
              },
              {
                "label": "Authentication",
                "value": res?.payload?.data?.data?.data?.all_conversations?.authentication,
                "backgroundColor": "#30835F"
              }

            ]
          })
        setLoading(false)
      })
      .catch((err) => { })
      .finally(() => {
        setLoading(false);
      });
    setChartLineData({ "options": {}, series: [] })
    dispatch(broadcastActions.getWhatsappConversationGraphAction(payload))
      .then((res) => {
        SetWhatsappConversationGraphSeries(res?.payload?.data?.data?.series)
        SetAllWhatsappConversationGraphSeries(res?.payload?.data?.data?.series)
        setCategory(res?.payload?.data?.data?.series?.map((res: any) => capitalizeFirstLetter(res.name)))
        setCategoryList(res?.payload?.data?.data?.series?.map((res: any) => capitalizeFirstLetter(res.name)))
        setChartLineData({
          "options": {
            "chart": {
              "height": 350,
              "type": "line",
              "zoom": {
                "enabled": false
              },
              "toolbar": {
                "show": true,
                "offsetX": -10,
                "offsetY": -114
              }
            },
            "dataLabels": {
              "enabled": false
            },
            "stroke": {
              "curve": "straight"
            },
            xaxis: {
              tickPlacement: 'on',
              labels: {
                formatter: function (value, timestamp, opts) {
                  const index = res?.payload?.data?.data?.options?.xaxis?.categories.indexOf(value);
                  if (res?.payload?.data?.data?.options?.xaxis?.categories?.length === 91) {
                    if (index % 6) {
                      return '';
                    } else {
                      return moment(value).format("MMM D");
                    }
                  } else if (res?.payload?.data?.data?.options?.xaxis?.categories?.length === 31) {
                    if (index % 2) {
                      return '';
                    } else {
                      return moment(value).format("MMM D");
                    }
                  } else if (res?.payload?.data?.data?.options?.xaxis?.categories?.length === 8) {
                    return moment(value).format("MMM D");
                  } else if (res?.payload?.data?.data?.options?.xaxis?.categories?.length > 91) {
                    if (index % 25) {
                      return '';
                    } else {
                      return moment(value).format("MMM D");
                    }
                  }
                }
              },
              categories: res?.payload?.data?.data?.options?.xaxis?.categories
            },
            tooltip: {
              x: {
                formatter: function (value, { dataPointIndex }) {
                  return moment(res?.payload?.data?.data?.options?.xaxis?.categories[dataPointIndex]).format("MMM D");
                }
              }
            }
          } as ApexOptions,
          "series": res?.payload?.data?.data?.series
        })
        setLoading(false)
      })
      .catch((err) => { })
      .finally(() => {
        setLoading(false);
      });

    dispatch(broadcastActions.getWhatsappMessageSummary(payload))
      .then((res) => {
        setMessageSummary(convertToK(res?.payload?.data?.data))
        setLoading(false)
      })
      .catch((err) => { })
      .finally(() => {
        setLoading(false);
      });
    setMessageSummaryGraph({ "options": {}, series: [] })

    dispatch(broadcastActions.getWhatsappMessageSummaryGraph(payload))
      .then((res) => {
        setMessageSummaryGraph({
          "options": {
            "chart": {
              "height": 350,
              "type": "line",
              "zoom": {
                "enabled": false
              },
              "toolbar": {
                "show": true,
                "offsetX": -10,
                "offsetY": -114
              }
            },
            "dataLabels": {
              "enabled": false
            },

            "title": {
              "align": "left",
              "text": ""
            },
            xaxis: {
              tickPlacement: 'on',
              labels: {
                formatter: function (value, timestamp, opts) {
                  const index = res?.payload?.data?.data?.options?.xaxis?.categories.indexOf(value);
                  if (res?.payload?.data?.data?.options?.xaxis?.categories?.length === 91) {
                    if (index % 6) {
                      return '';
                    } else {
                      return moment(value).format("MMM D");
                    }
                  } else if (res?.payload?.data?.data?.options?.xaxis?.categories?.length === 31) {
                    if (index % 2) {
                      return '';
                    } else {
                      return moment(value).format("MMM D");
                    }
                  } else if (res?.payload?.data?.data?.options?.xaxis?.categories?.length === 8) {
                    return moment(value).format("MMM D");
                  } else if (res?.payload?.data?.data?.options?.xaxis?.categories?.length > 91) {
                    if (index % 25) {
                      return '';
                    } else {
                      return moment(value).format("MMM D");
                    }
                  }
                }
              },
              categories: res?.payload?.data?.data?.options?.xaxis?.categories
            },
            tooltip: {
              x: {
                formatter: function (value, { dataPointIndex }) {
                  return moment(res?.payload?.data?.data?.options?.xaxis?.categories[dataPointIndex]).format("MMM D");
                }
              }
            }
          } as ApexOptions,
          "series": res?.payload?.data?.data?.series
        })
        setLoading(false)
      })
      .catch((err) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dashdateRange?.startDate) {
      getWhastappInsightsApi();
    }
  }, [dashdateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(removebroadcastDashboard());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event: SelectChangeEvent<typeof category>) => {
    const {
      target: { value },
    } = event;
    setCategory(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    let data: any = value
    const lowerCaseCategories = data?.map((category: any) => category.toLowerCase());
    SetWhatsappConversationGraphSeries(allWhatsappConversationGraphSeries.filter((item: any) => lowerCaseCategories.includes(item.name.toLowerCase())))
  };
  useEffect(() => {
    if (whatsappConversationGraphSeries) {
      setChartLineData({
        ...chartLineData,
        series: whatsappConversationGraphSeries
      })
    }
  }, [whatsappConversationGraphSeries]) // eslint-disable-line react-hooks/exhaustive-deps

  let coverStyle = {
    height: "inherit",
    border: "1px solid #e9e8ee",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
    borderRadius: "4px !important"
  }

  return (
    <SummaryWrapper>
      <div className="wrapper_div">
        <div style={{
          borderRadius: "4px",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
          backgroundColor: "white",
          paddingBottom: "16px"
        }}>
          <DashBoardHeader
            enableToolTip={true}
            title="All conversations"
            toolTipText="All conversations"
          />
          <ResponsiveGridLayout
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
            layouts={whatsappBroadcastLayoutForConversations}
            isDraggable={false}
            isResizable={false}
            margin={[20, 20]}
            style={{ marginBottom: 0 }}
          >

            <div key={"1"}>
              <Box className="cover" sx={coverStyle}>
                <Box sx={{
                  height: "calc(100%)",
                }}>
                  <ResponsePrgressBar loading={loading} data={allconversations} />
                </Box>
              </Box>
            </div>

            <div key={"2"}>
              <Box className="cover" sx={coverStyle}>
                <Box sx={{
                  height: "calc(100%)",
                }}>
                  <ResponsePrgressBar loading={loading} data={freeConversion} />
                </Box>
              </Box>
            </div>

            <div key={"3"}>
              <Box className="cover" sx={coverStyle}>
                <Box sx={{
                  height: "calc(100%)",
                }}>
                  <ResponsePrgressBar loading={loading} data={paidConversation} />
                </Box>
              </Box>
            </div>

            <div key={"4"}>
              <Box className="cover" sx={coverStyle}>
                <Box>
                  <DashBoardHeader title="Total Message Sent" />
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={category || "Category"}
                    onChange={(e: any) => handleChange(e)}
                    input={<OutlinedInput label="Tag" />}
                    MenuProps={MenuProps}
                    placeholder="Please Select"
                    renderValue={(selected: any) => {
                      if (!Array.isArray(selected) || selected.length === 0) {
                        return <em>Placeholder</em>;
                      }

                      return selected.join(', ');
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}

                    style={{
                      marginBottom: '0px',
                      fontSize: '14px',
                      fontWeight: 400,
                      width: '100%',
                      maxWidth: '235px', // Adjust maxWidth as needed
                      height: '30px',
                      border: '1px solid lightgray',
                      position: "absolute",
                      right: 61, // Space between the select and title
                      top: 14,
                      zIndex: 1000 // Ensure dropdown is above other elements
                    }}
                  >
                    {categoryList?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={category.includes(name)} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}

                  </Select>
                </Box>

                <Box sx={{
                  height: "calc(100% - 60px)",
                  padding: "8px",
                  display: "grid",
                  alignItems: "center"
                }}>
                  {!loading && chartLineData?.options && chartLineData?.series?.length !== 0 ? <ReactApexChart
                    type="line"
                    options={chartLineData?.options || {}}
                    series={chartLineData?.series || []}
                    width="100%"
                    height={350}

                  /> : <AppSkeletonLoader />}
                </Box>
              </Box>
            </div>

          </ResponsiveGridLayout>
        </div>
        {/* )} */}
      </div>
      <div className="wrapper_div">
        <div style={{
          borderRadius: "4px",
          backgroundColor: "white",
          boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
          paddingBottom: "16px"
        }}>
          <DashBoardHeader
            enableToolTip={true}
            title="All Messages"
            toolTipText="All Messages"
          />
          <ResponsiveGridLayout
            breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
            layouts={whatsappBroadcastLayout}
            isDraggable={false}
            isResizable={false}
            style={{ marginBottom: 0 }}
            margin={[20, 20]}
          >
            <div key={"1"} className="users" style={{ border: "1px solid #e9e8ee", borderRadius: "4px", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)" }}>
              <p className="section_f_text">
                Sent
              </p>
              <div className="users_count">
                <p className="users_count_text">
                  {loading ? (
                    <SkeletonCard />
                  ) : <>
                    {messageSummary?.sent || 0}
                  </>}
                </p>
                <div className="c_div1_icon">
                  {loading ? (
                    <SkeletonCircleIconCard />
                  ) : (
                    <img src={SentSVG} alt="Not Found" />
                  )}
                </div>
              </div>


            </div>
            <div key={"2"} className="users" style={{ border: "1px solid #e9e8ee", borderRadius: "4px", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)", }}>
              <>
                <p className="section_f_text">
                  Delivered

                </p>
                <div className="users_count">
                  <p className="users_count_text">
                    {loading ? (
                      <SkeletonCard />
                    ) : <>
                      {messageSummary?.delivered || 0}
                    </>}
                  </p>
                  <div className="c_div1_icon">
                    {loading ? (
                      <SkeletonCircleIconCard />
                    ) : (
                      <img src={DeliveredSVG} alt="Not Found" />
                    )}
                  </div>
                </div>

              </>
            </div>
            <div key={"3"} className="users" style={{ border: "1px solid #e9e8ee", borderRadius: "4px", boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)", }}>
              <>
                <p className="section_f_text">
                  Received

                </p>
                <div className="users_count">
                  <p className="users_count_text">
                    {loading ? (
                      <SkeletonCard />
                    ) : <>
                      {messageSummary?.received || 0}
                    </>}
                  </p>
                  <div className="c_div1_icon">
                    {loading ? (
                      <SkeletonCircleIconCard />
                    ) : (
                      <img src={ReceivedSVG} alt="Not Found" />
                    )}
                  </div>
                </div>
              </>
            </div>


            <div key={"4"}>
              <Box className="cover" sx={coverStyle}>
                <DashBoardHeader title="Total Message Sent" />
                <Box sx={{
                  height: "calc(100% - 60px)",
                  padding: "8px",
                  display: "grid",
                  alignItems: "center"
                }}>
                  {loading ? <AppSkeletonLoader /> : <ReactApexChart
                    type="line"
                    options={messageSummaryGraph?.options || {}}
                    series={messageSummaryGraph?.series || []}
                    width="100%"
                    height={350}
                  />}
                </Box>
              </Box>
            </div>
          </ResponsiveGridLayout>
        </div>
        {/* )} */}
      </div>

    </SummaryWrapper>
  );
};

WhatsappBroadcastDashboardSummary.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: (layout: any, layouts: any) => { },
  cols: { lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [16, 16],
  margin: [16, 16],
  preventCollision: true,
};

export default WhatsappBroadcastDashboardSummary;

export const DateBox = styled.div`
  color: blue;
`;
