import { IconButton, Tab, Tabs } from "@mui/material";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../../HOC/HocBackdropLoader";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";
import { useCurrentUser } from "../../../Store/Slices/CurrentUser/CurrentUserSlice";
import { SubscriptionActions } from "../../../Store/Slices/Subscriptions/subscriptions.actions";
import { getCreditsUtilization } from "../../../Store/Slices/Subscriptions/subscriptions.selectors";
import { SubscriptionsNetworkService } from "../../../Store/Slices/Subscriptions/subscriptionsNetworkService";
import { ReactComponent as Credit } from "../../../assets/images/icons/credit.svg";
import AddWallet from "../../Menu/AddWallet";
import NavigationBar from "../NavigationBar";
import CrediteUtilization from "../crediteUtilization";
import { Button, CreditBalanceDetails, CreditDetails, LivePlanContainer, Title, WebsiteWrap } from "../style";
import BroadcastCreditRateList from "./BroadcastCreditRateList";
import CreditRateList from "./CreditRateList";
interface MenuProps extends HocBackdropLoaderProps {

}
const WallectInvoiceList: React.FC<MenuProps> = ({
    setLoading
}) => {
    const dispatch = useAppDispatch()
    const { userCreditsInfo } = useAppSelector(useCurrentUser);
    const getCreditsUtilizationData = useSelector(getCreditsUtilization);
    const { userConfig } = useSelector(useAuthStore);
    const [check, setCheck] = useState(false)
    const [index, setIndex] = useState(0)
    const [broadcastType, setBroadcastType] = useState("")
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setBroadcastType("")
        setIndex(newValue);
    };
    useEffect(() => {
        setBroadcastType("")
    }, [])
    const setSelectBroadcast = (value: string) => {
        setBroadcastType(value)
    }
    const getInvoiceList = (localValues: any, dateRange: any) => {
        const payload: any = {
            "start": format(dateRange.startDate, "yyyy-MM-dd"),
            "limit": localValues?.perPage?.value,
            "offset": localValues?.currentPage,
            "end": format(dateRange.endDate, "yyyy-MM-dd")
        }
        dispatch(SubscriptionActions.getCreditUtilization(payload))
        // const payload: any = {
        //     start_date: format(dateRange.startDate, "yyyy-MM-dd"),
        //     end_date: format(dateRange.endDate, "yyyy-MM-dd"),
        //     limit: localValues?.itemsPerPage,
        //     offset: localValues?.currentPage + 1,
        //     filter_by: "",
        //     search: localValues?.search,
        //     customer_Id: authData?.data?.business?.subscription_data?.customer_id
        //     // customer_Id : "4348562000000194061"
        // };
        // dispatch(SubscriptionActions.getAllWalletInvoiceList(payload))
        // const totalCountPayload: any = {
        //     start_date: format(dateRange.startDate, "yyyy-MM-dd"),
        //     end_date: format(dateRange.endDate, "yyyy-MM-dd"),
        //     filter_by: "",
        //     search: localValues?.search,
        //     customer_Id: authData?.data?.business?.subscription_data?.customer_id
        //     // customer_Id : "4348562000000194061"
        // };
        // dispatch(SubscriptionActions.getWalletInvoiceCount(totalCountPayload))
    }
    const addBalance = (balance: number) => {
        setLoading(true);
        setCheck(false)
        let data = {
            "subscription_id": userConfig?.payload?.subscription_id,
            "addons": [
                {
                    "addon_code": "wallet",
                    "quantity": balance
                }
            ],
            "redirect_url": "/subscription/success",
        }
        SubscriptionsNetworkService.addCredits(
            data,
            userConfig?.payload?.subscription_id
        ).then((res: any) => {
            window.open(res?.data?.hostedpage?.url, "_self")
        }).catch((err: any) => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        <CreditDetails>
            <NavigationBar list={["Invoices"]} />
            <CreditBalanceDetails>
                <div>
                    <IconButton color="inherit">
                        <Credit />
                        <Title style={{ marginLeft: "15px" }} fontSize={16} color={"#7E8392"}>Available Credit balance<br />
                            <Title fontSize={30} color={"#000"}>INR {userCreditsInfo?.remaining_amounts}</Title>
                        </Title>
                    </IconButton></div>
                <LivePlanContainer align={"flex-end"} gap={"8px"} style={{ margin: "-4.5rem 0rem 2rem" }} >
                    <Button
                        minWidth={"272px"}
                        onClick={() => setCheck(true)}
                    >
                        Buy credits
                    </Button>
                </LivePlanContainer>
            </CreditBalanceDetails>
            <WebsiteWrap>
                <Tabs
                    className="website-tabs"
                    value={index}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    {["Credit details", "pay as you go"].map((option: any, index: number) => (
                        <Tab label={option} id={`simple-tab-${index}`} key={index} />
                    ))}
                </Tabs>
            </WebsiteWrap>
            {index === 0 ?
                <CrediteUtilization callBackApi={getInvoiceList} creditList={getCreditsUtilizationData?.results} pagination={getCreditsUtilizationData?.pagination} check={false} setCheck={setCheck} /> : null}
            {index === 1 && broadcastType === "" ?
                <CreditRateList setSelectBroadcast={setSelectBroadcast} /> : null}
            {index === 1 && broadcastType !== "" ?
                <BroadcastCreditRateList setBroadcastType={setBroadcastType} broadcastType={broadcastType} /> : null}
            <AddWallet check={check} setCheck={setCheck} addBalance={addBalance} />
        </CreditDetails>
    )
}
export default HocBackdropLoader(WallectInvoiceList) 