import { createAsyncThunk } from "@reduxjs/toolkit";
import { DashboardNetworkService } from "./DashboardNetworkService";

export class DashboardActions {
  // Users
  static getDashboardUsers = createAsyncThunk(
    "DashboardSlice/getDashboardUsers",
    (data: any) => {
      return DashboardNetworkService.getDashboardUsers(data)

        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  Returning leads

  static getDashReturningLead = createAsyncThunk(
    "DashboardSlice/getDashReturningLead",
    (data: any) => {
      return DashboardNetworkService.getDashReturningLeads(data)
        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  Duration
  static getDashDuration = createAsyncThunk(
    "DashboardSlice/getDashDuration",
    (data: any) => {
      return DashboardNetworkService.getDuration(data)
        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  Conversation Status
  static getCoversationStatus = createAsyncThunk(
    "DashboardSlice/getCoversationStatus",
    (data: any) => {
      return DashboardNetworkService.getCoversationStatus(data)
        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  Agent Status
  static getAgentStatus = createAsyncThunk(
    "DashboardSlice/getAgentStatus",
    (data: any) => {
      return DashboardNetworkService.getAgentStatus(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  User Growth
  static getUserGrowthChannelWise = createAsyncThunk(
    "DashboardSlice/getUserGrowthChannelWise",
    (data: any) => {
      return DashboardNetworkService.getUserGrowth(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  Message Summary
  static getSummaryMsg = createAsyncThunk(
    "DashboardSlice/getSummaryMsg",
    (data: any) => {
      return DashboardNetworkService.getSummaryMessages(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  bot conversation
  static getBotConversation = createAsyncThunk(
    "DashboardSlice/getBotConversation",
    (data: any) => {
      return DashboardNetworkService.getBotConversation(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  Respnse Time
  static getResponseTime = createAsyncThunk(
    "DashboardSlice/getResponseTime",
    (data: any) => {
      return DashboardNetworkService.getResponseTime(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  // Traffic
  static getCoversationalTraffic = createAsyncThunk(
    "DashboardSlice/getCoversationalTraffic",
    (data: any) => {
      return DashboardNetworkService.getCoversationalTraffic(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  label
  static getlabelsSummary = createAsyncThunk(
    "DashboardSlice/getlabelsSummary",
    (data: any) => {
      return DashboardNetworkService.getLabels(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  // Download label
  static getDownloadLabel = createAsyncThunk(
    "DashboardSlice/getDownloadLabel",
    (data: any) => {
      return DashboardNetworkService.getDownloadLabel(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  // Agent label
  static getConversationsByAgent = createAsyncThunk(
    "DashboardSlice/getConversationsByAgent",
    (data: any) => {
      return DashboardNetworkService.getConversationsByAgent(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  // Download Agent label
  static getDownloadConversations = createAsyncThunk(
    "DashboardSlice/getDownloadConversations",
    (data: any) => {
      return DashboardNetworkService.getDownloadConversations(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  // Download Agent label
  static getAgentResponseTime = createAsyncThunk(
    "DashboardSlice/getAgentResponseTime",
    (data: any) => {
      return DashboardNetworkService.getAgentResponseTime(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  // Download Agent label
  static getEnagementandConversion = createAsyncThunk(
    "DashboardSlice/getEnagementandConversion",
    (data: any) => {
      return DashboardNetworkService.getEnagementandConversion(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  CSAT
  static getCsatDetails = createAsyncThunk(
    "DashboardSlice/getCsatDetails",
    (data: any) => {
      return DashboardNetworkService.getCsatData(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  Agent Conversation Status
  static getAgentCoversationStatus = createAsyncThunk(
    "DashboardSlice/getAgentCoversationStatus",
    (data: any) => {
      return DashboardNetworkService.getAgentCoversationStatus(data)
        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //   Bot Conversation Status
  static getBotCoversationStatus = createAsyncThunk(
    "DashboardSlice/getBotCoversationStatus",
    (data: any) => {
      return DashboardNetworkService.getBotCoversationStatus(data)
        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  // failed answer
  static getFailedAnswer = createAsyncThunk(
    "DashboardSlice/getFailedAnswer",
    (data: any) => {
      return DashboardNetworkService.getFailedAnswer(data)
        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  // Aquistion by device
  static getAquisitionByDevice = createAsyncThunk(
    "DashboardSlice/getAquisitionByDevice",
    (data: any) => {
      return DashboardNetworkService.getAquisitionByDevice(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  Aquisition by language
  static getAquisitionByLanguage = createAsyncThunk(
    "DashboardSlice/getAquisitionByLanguage",
    (data: any) => {
      return DashboardNetworkService.getAquisitionByLanguage(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  Aquisition by location
  static getAquisitionByLocation = createAsyncThunk(
    "DashboardSlice/getCsatDetails",
    (data: any) => {
      return DashboardNetworkService.getAquisitionByLocation(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  //  Aquisition Overview
  static getAquisitionByOverview = createAsyncThunk(
    "DashboardSlice/getAquisitionByOverview",
    (data: any) => {
      return DashboardNetworkService.getAcquisitionOverview(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );
  //  Matomo Details
  static getMatomoDetails = createAsyncThunk(
    "DashboardSlice/getMatomoDetails",
    (data: any) => {
      return DashboardNetworkService.getMatomoDetails(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );
  //  Matomo Details
  static getProjectVersion = createAsyncThunk(
    "DashboardSlice/getProjectVersion",
    (data: any) => {
      return DashboardNetworkService.getProjectVersion(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  );

  // Application From Analytics
  static getApplicationFormAnalytics = createAsyncThunk(
    "DashboardSlice/getApplicationFormAnalytics",
    (data: any) => {
      return DashboardNetworkService.getApplicationFormAnalyticsNetwork(data)
        .then((response) => {
          return response;
        })
        .catch((err: any) => { })
        .finally(() => { });
    }
  );


  //COntact vs Application Action
  static getContactVSApplicationAction = createAsyncThunk(
    "broadcastSlice/getContactVSApplicationAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return DashboardNetworkService.getContactVSApplicationNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getApplicationPaymentFilterAction = createAsyncThunk(
    "broadcastSlice/getApplicationPaymentFilterAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return DashboardNetworkService.getApplicationPaymentFilterNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getApplicationPaymentFilterActionCity = createAsyncThunk(
    "broadcastSlice/getApplicationPaymentFilterActionCity",
    (data: any, { dispatch, rejectWithValue }) => {
      return DashboardNetworkService.getApplicationPaymentFilterNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getApplicationDateFilterAction = createAsyncThunk(
    "broadcastSlice/getApplicationDateFilterAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return DashboardNetworkService.getApplicationDateFilterNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getApplicationSubmissionFilterAction = createAsyncThunk(
    "broadcastSlice/getApplicationSubmissionFilterAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return DashboardNetworkService.getApplicationSubmissionFilterNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getApplicationAnalyticsSummaryFilterAction = createAsyncThunk(
    "broadcastSlice/getApplicationAnalyticsFilterAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return DashboardNetworkService.getApplicationAnaliticsSummaryFilterNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getApplicationFilterAction = createAsyncThunk(
    "broadcastSlice/getApplicationFilterAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return DashboardNetworkService.getApplicationFiltersNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getApplicationFilterActionOfflineOnline = createAsyncThunk(
    "broadcastSlice/getApplicationFilterActionOfflineOnline",
    (data: any, { dispatch, rejectWithValue }) => {
      return DashboardNetworkService.getApplicationFiltersNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getApplicationDataAction = createAsyncThunk(
    "broadcastSlice/getApplicationDataAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return DashboardNetworkService.getApplicationDataNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
}