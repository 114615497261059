import http from "../../../NetworkServices/http";
let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class DashboardNetworkService {
  static getDashboardUsers(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.GET_DASHBOARD_USERS}`, data);
  }

  static getDashReturningLeads(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_DASHBOARD_RETURNING_LEADS}`,
      data
    );
  }

  static getDuration(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.GET_DURATION}`, data);
  }

  static getCoversationStatus(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_CONVERSATION_STATUS}`,
      data
    );
  }

  static getAgentStatus(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.GET_AGENT_STATUS}`, data);
  }

  static getUserGrowth(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_USERGROWTH_CHANNELWISE}`,
      data
    );
  }

  static getSummaryMessages(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_SUMMARY_MESSAGES}`,
      data
    );
  }

  static getBotConversation(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_BOT_CONVERSATION}`,
      data
    );
  }

  static getResponseTime(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.GET_RESPONSE_TIME}`, data);
  }

  // static getChannelOverview(data:any){
  //     return http.post(`${consoleBaseUrl}${Endpoints.GET_CHANNEL_OVERVIEW}`,data)
  // }

  static getCoversationalTraffic(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_CONVERSATIONAL_TRAFFIC}`,
      data
    );
  }

  static getLabels(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.GET_LABELS}`, data);
  }

  static getCsatData(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.GETCSAT}`, data);
  }

  static getAgentCoversationStatus(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_AGENT_CONVERSATION_STATUS}`,
      data
    );
  }
  static getBotCoversationStatus(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_BOT_CONVERSATION_STATUS}`,
      data
    );
  }
  static getFailedAnswer(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_TOTAL_FAILED_ANSWERS}`,
      data
    );
  }
  static getAgentList() {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_AGENT_FILTER}`);
  }
  static getBotList() {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_BOT_FILTER}`);
  }
  static getChannelList() {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_CHANNEL_FILTER}`);
  }
  static getAquisitionByDevice(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_ACQUISITION_BY_DEVICE}`,
      data
    );
  }
  static getAquisitionByLanguage(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_ACQUISITION_BY_LANGUAGE}`,
      data
    );
  }
  static getAquisitionByLocation(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_ACQUISITION_BY_LOCATION}`,
      data
    );
  }
  static getDownloadLabel(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.GET_LABELS_DOWNLOAD}`, data);
  }
  static getDownloadConversations(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_AGENT_CONVERSATION_DOWNLOAD}`,
      data
    );
  }
  static getConversationsByAgent(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_CONVERSATION_BY_AGENT}`,
      data
    );
  }
  static getAgentResponseTime(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_AGENT_RESPONSE_TIME}`,
      data
    );
  }
  static getBotIntent(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.GET_INTENT}`, data);
  }
  static getEnagementandConversion(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_ENGAGEMENTRATE_AND_CONVERSIONRATE}`,
      data
    );
  }
  static getAcquisitionOverview(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_ACQUISITION_OVERVIEW}`,
      data
    );
  }
  static getProjectVersion(data: any) {
    let payload: any = {
      bot_id: data,
    };
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_PROJECT_VERSION}/?bot_id=${data}`
    );
  }

  // Application From Network
  static getApplicationFormAnalyticsNetwork(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.APPLICATION_RES_ANALYTICS}`,
      data
    );
  }

  static getMatomoDetails(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_MATOMO_DETAILS}/${data}/configuration/`
    );
  }

  // Contact vs Applications
  static getContactVSApplicationNetwork(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.CONTACT_VS_APPLICATION}`,
      data
    );
  }

  // Application Payment Filter
  static getApplicationPaymentFilterNetwork(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.APPLICATION_PAYMENT_FILTER}`,
      data
    );
  }

  // Application Payment Filter
  static getApplicationDateFilterNetwork(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.APPLICATION_DATE_FILTER}`,
      data
    );
  }

  // Application Payment Filter
  static getApplicationSubmissionFilterNetwork(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.APPLICATION_SUBMISSION_FILTER}`,
      data
    );
  }

  // Application Summary Filter
  static getApplicationAnaliticsSummaryFilterNetwork(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.APPLICATION_ANALYTICS_SUMMARY_FILTER}`,
      data
    );
  }

  // Application Filters
  static getApplicationFiltersNetwork(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.APPLICATION_FILTERS}`,
      data
    );
  }

  // Application Data
  static getApplicationDataNetwork(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.ANALYTICS_APPLICATION_DATA}`,
      data
    );
  }
}

class Endpoints {
  static GET_DASHBOARD_USERS = "/workspace/analytics/users";
  static GET_DASHBOARD_RETURNING_LEADS = "/workspace/analytics/total-leads";
  static GET_DURATION = "/workspace/analytics/average-duration";
  static GETCSAT = "/workspace/analytics/analytics-csat";
  static GET_CONVERSATION_STATUS = "/workspace/analytics/conversation-status";
  static GET_AGENT_STATUS = "/workspace/analytics/agent-status";
  static GET_USERGROWTH_CHANNELWISE =
    "/workspace/analytics/user-growth-channelwise";
  static GET_AGENT_RESPONSE_TIME = "/workspace/analytics/time-slots";
  static GET_SUMMARY_MESSAGES = "/workspace/analytics/message";
  static GET_BOT_CONVERSATION = "/workspace/analytics/bot-conversations";
  static GET_RESPONSE_TIME = "/workspace/analytics/time-slots";
  static GET_CHANNEL_OVERVIEW = "/workspace/analytics/channel-overview";
  static GET_CONVERSATIONAL_TRAFFIC =
    "/workspace/analytics/conversational-traffic";
  static GET_LABELS = "/workspace/analytics/conversation-label";
  static GET_AGENT_CONVERSATION_STATUS =
    "/workspace/analytics/conversation-status";
  static GET_BOT_CONVERSATION_STATUS =
    "/workspace/analytics/bot-conversation-stats";
  static GET_TOTAL_FAILED_ANSWERS = "/workspace/analytics/bot-failed";
  static GET_AGENT_FILTER = "/workspace/analytics/agent-filter";
  static GET_BOT_FILTER = "/workspace/analytics/bot-filter";
  static GET_CHANNEL_FILTER = "/workspace/analytics/channel-filter";
  static GET_ACQUISITION_BY_DEVICE =
    "/workspace/analytics/acquisition-by-device";
  static GET_ACQUISITION_BY_LANGUAGE = "/workspace/analytics/browser-language";
  static GET_ACQUISITION_BY_LOCATION =
    "/workspace/analytics/acquisition-by-location";
  static GET_LABELS_DOWNLOAD =
    "/workspace/analytics/conversation-labels/download";
  static GET_AGENT_CONVERSATION_DOWNLOAD =
    "/workspace/analytics/agent-conversations-list/download";
  static GET_CONVERSATION_BY_AGENT =
    "/workspace/analytics/agent-conversations-list";
  static GET_INTENT = "/workspace/analytics/intents";
  static GET_ENGAGEMENTRATE_AND_CONVERSIONRATE =
    "/workspace/analytics/engagement-rate-conversion-rate";
  static GET_ACQUISITION_OVERVIEW = "/workspace/analytics/acquisition-overview";
  static GET_PROJECT_VERSION = "/chatbot/get-by-bot-id";
  static GET_MATOMO_DETAILS = "/core/project-version";
  
  // Application Form Analytics API
  static APPLICATION_RES_ANALYTICS = "/workspace/application-responses-analytics";


  // Contact VS Application API
  static CONTACT_VS_APPLICATION = "/workspace/analytics/contact-vs-application"

  // Application Payment Filter API
  static APPLICATION_PAYMENT_FILTER = "/workspace/analytics/application-payment-filters" // W

  // Application DATE Filter API
  static APPLICATION_DATE_FILTER = "/workspace/analytics/application-date-filters"

  // Application DATE Filter API
  static APPLICATION_SUBMISSION_FILTER = "/workspace/analytics/application-submission-filters"  // w

  // Application ANALYTICS Filter API
  static APPLICATION_ANALYTICS_SUMMARY_FILTER = "/workspace/analytics/analytics-summary"

  // Application Filters
  static APPLICATION_FILTERS = "/workspace/analytics/application-filters"

  // Analytics Application Data
  static ANALYTICS_APPLICATION_DATA = "/workspace/analytics/application-data"

}
