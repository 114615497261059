import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { Toolbar } from "@mui/material";

export const StyledCreateBroadcastWrap = styled.div`
  && {
    // background-color: ${theme.palette.default.lightPrimaryBackground};
    width: 100%;
    height: calc(100vh - 4rem);
    // height: auto;
    overflow: overlay;
    // padding: 0rem 0rem 0.5rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    z-index: 1;
  }
`;

export const StyledTableToolbar = styled(Toolbar)`
  && {
    width: 100%;
    height: 54px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    padding: 0px 8px;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-start;
    overflow: hidden;

    p {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 0.9rem;
      color: ${theme.palette.default.text};
      line-height: 20px;
      white-space: nowrap;
    }
  }
`;
export const StyledRenderQueryFilterWrap = styled.div`
  && {
    .form-heading {
      display: flex;
      justify-content: space-between;
      height: 56px;
      align-items: center;
      padding: 0rem 1rem 0rem 1rem;
      position: sticky;
      background-color: #fff;
      top: 0;
      z-index: 9;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

      h6 {
        font-size: 1.05rem;
        color: ${theme.palette.default.text};
      }

      svg {
        color: ${theme.palette.default.darkGrey};
      }
    }

    .footer {
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);

      .footer-btns {
        justify-content: end;
        gap: 1.3rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        padding-right: 1rem;
        .saveBtn {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

export const SchenduleBoradcastWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  overflow-y: hidden;
`;

export const PageDiv = styled.div`
  padding: 0 1rem !important;
      height:calc(840px + 0px);
    overflow: scroll;
  // background-color: ${theme.palette.default.lightGrey};
`;

export const StyledResponsiveDivTable = styled.div`
  && {
    width: 100%;
    .table {
    }
    .tableStickyHead {
      position: sticky;
      top: 0;
      z-index: 999;
      background-color: #fff !important;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      width: fit-content;
      min-width: 100%;
    }

    .tablHeadRow {
      display: flex;
      flex: 1 !important;
      width: fit-content;
      background-color: #fff;
    }
    .tableHeadCell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-width: 220px;
      max-width: 300px;
      &:first-child {
        min-width: 50px;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }
    .tableHeadCell:first-child {
      width: 50px;
    }
    .tableBodyCell {
      min-width: 220px;
      max-width: 300px;
      max-height: 36px;
      word-break: break-all;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &:first-child {
        min-width: 50px;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }
    .tableBodyRow {
      display: flex;
      width: fit-content;
      min-width: 100%;
      border-bottom: 1px solid ${theme.palette.default.border};
    }
    .activeRow {
      width: fit-content;
      background-color: ${theme.palette.action.selected};
    }
    .no-more-entries {
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-weight: 400;
      font-size: 1rem;
      display: flex;
      justify-content: center;
    }
  }
`;

export const WhatsappBoxTopbar = styled.div`
  & {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: ${theme.palette.default.white};
    justify-content: space-between;
    padding: 1rem 1rem;
    height: 54px;
    border-bottom: 1px solid ${theme.palette.default.grey};

    .left {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      p.heading {
        font-size: 14px;
        color: ${theme.palette.default.black};
        font-weight: 500;
      }
    }

    .test-tamplate__btn {
      display: flex;
      gap: 0.5rem;
      min-width: 287px;
      .back-btn {
      }
    }
  }
`;
export const WhatsappStepperBar = styled.div`
  & {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: ${theme.palette.default.white};
    justify-content: space-between;
    padding: 1rem 1rem;
    height: 54px;
    border-bottom: 1px solid ${theme.palette.default.grey};
  }
`;

export const BreadcrumbsItem = styled.div`
& {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0px 10px;
    .icon {
        display: flex;
        align-items: center;
        svg path {
            fill: ${(props: any) =>
    props.iconColorCondition
      ? `${theme.palette.primary.light}`
      : `${theme.palette.default.darkGrey}`};
          }

    }
    .text {
    display: flex;
    align - items: center;
    p {
        font-size: 14px;
        margin: 0;
        color:  ${(props: any) =>
    props.iconColorCondition
      ? `${theme.palette.primary.light}`
      : `${theme.palette.default.darkGrey}`}
}
}
`;
