import { createAsyncThunk } from "@reduxjs/toolkit";
import { WhatsappSettingsService } from "./whatsappSettingsService";

export class WhatsappSettingsActions {

    // Validate whatsappConfigData Settings
    static whatsappConfigData = createAsyncThunk(
        "WhatsappSettingsSlice/whatsappConfigData",
        (data: any, { dispatch, rejectWithValue }) => {
            return WhatsappSettingsService.whatsappConfigData(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )


    // Validate whatsappConfigData Settings
    static whatsappConfigList = createAsyncThunk(
        "WhatsappSettingsSlice/whatsappConfigList",
        ({ }: any, { dispatch, rejectWithValue }) => {
            return WhatsappSettingsService.getWhatsappConfigData()
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )


}