import { LoadingButton } from "@mui/lab";
import styled from "styled-components";
import { theme } from "../Customization/Theme";
import AppIcon from "./AppIcon";
import React from "react";
import { alpha } from "@material-ui/core";

interface AppButtonProps {
  variant?: string;
  startIcon?: string;
  endIcon?: string;
  iconColor?: any;
  iconHoverColor?: any;
  iconSize?: any;
  width?: any;
  [otherProps: string]: any;
  children?: React.ReactNode;
  autoFocus?: boolean;
  buttonStyle?: any;
  onClick?: any;
  isDisabled?: boolean;
}

const AppButton: React.FC<AppButtonProps> = ({
  children,
  variant = "primary",
  startIcon = "",
  endIcon = "",
  iconColor,
  iconHoverColor,
  iconSize = "18px",
  autoFocus,
  width,
  buttonStyle,
  isDisabled = false,
  ...otherProps
}) => {
  const buttonRef = React.useRef<HTMLButtonElement>();
  React.useEffect(() => {
    // Focus the input element when the component is mounted
    if (autoFocus) {
      buttonRef?.current?.focus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (variant === "outline")
    return (
      <OutlineButton
        disabled={isDisabled}
        startIcon={
          startIcon ? (
            <AppIcon
              title={startIcon}
              size={iconSize}
              color={iconColor}
              hoverColor={iconHoverColor}
            />
          ) : (
            ""
          )
        }
        endIcon={
          endIcon ? (
            <AppIcon
              title={endIcon}
              size={iconSize}
              color={iconColor}
              hoverColor={iconHoverColor}
            />
          ) : (
            ""
          )
        }
        {...otherProps}
        ref={buttonRef}
      >
        {children}
      </OutlineButton>
    );
  if (variant === "danger")
    return (
      <OutlineErrorButton
        startIcon={<AppIcon title={startIcon} size="18px" color="#F34E4E" />}
        endIcon={<AppIcon title={endIcon} size="18px" color="#F34E4E" />}
        {...otherProps}
        ref={buttonRef}
        disabled={isDisabled}
      >
        {children}
      </OutlineErrorButton>
    );
  if (variant === "danger-filled")
    return (
      <OutlineErrorButtonFilled
        startIcon={<AppIcon title={startIcon} size="18px" color="#F34E4E" />}
        endIcon={<AppIcon title={endIcon} size="18px" color="#F34E4E" />}
        {...otherProps}
        ref={buttonRef}
        disabled={isDisabled}
      >
        {children}
      </OutlineErrorButtonFilled>
    );
  if (variant === "success")
    return (
      <OutlineSuccessButton
        startIcon={<AppIcon title={startIcon} size="18px" color="#27B783" />}
        endIcon={<AppIcon title={endIcon} size="18px" color="#27B783" />}
        {...otherProps}
        ref={buttonRef}
        disabled={isDisabled}
      >
        {children}
      </OutlineSuccessButton>
    );
  if (variant === "contained")
    return (
      <ContainedButton
        startIcon={
          <AppIcon
            title={startIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        }
        endIcon={
          <AppIcon
            title={endIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        }
        {...otherProps}
        ref={buttonRef}
        disabled={isDisabled}
      >
        {children}
      </ContainedButton>
    );

  if (variant === "grey")
    return (
      <GreyButton
        startIcon={<AppIcon title={startIcon} size="18px" color="#CBCDD3" />}
        endIcon={<AppIcon title={endIcon} size="18px" color="#CBCDD3" />}
        {...otherProps}
        ref={buttonRef}
        disabled={isDisabled}
      >
        {children}
      </GreyButton>
    );

  if (variant === "text")
    return (
      <TextButton
        startIcon={
          startIcon ? (
            <AppIcon
              title={startIcon}
              size={iconSize}
              color={iconColor}
              hoverColor={iconHoverColor}
            />
          ) : null
        }
        endIcon={
          endIcon ? (
            <AppIcon
              title={endIcon}
              size={iconSize}
              color={iconColor}
              hoverColor={iconHoverColor}
            />
          ) : null
        }
        {...otherProps}
        // style={style}
        ref={buttonRef}
        style={buttonStyle}
        disabled={isDisabled}
      >
        {children}
      </TextButton>
    );
  if (variant === "textGrey")
    return (
      <TextButton
        startIcon={
          <AppIcon
            title={startIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        }
        endIcon={
          <AppIcon
            title={endIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        }
        {...otherProps}
        style={{ color: theme.palette.default.darkGrey }}
        ref={buttonRef}
        disabled={isDisabled}
      >
        {children}
      </TextButton>
    );
  if (variant === "cta")
    return (
      <CtaButton
        startIcon={
          <AppIcon
            title={startIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        }
        endIcon={
          <AppIcon
            title={endIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        }
        {...otherProps}
        ref={buttonRef}
        disabled={isDisabled}
      >
        {children}
      </CtaButton>
    );

  if (variant === "ctaGrey")
    return (
      <CtaGreyButton
        startIcon={
          <AppIcon
            title={startIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        }
        endIcon={
          <AppIcon
            title={endIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        }
        {...otherProps}
        ref={buttonRef}
        disabled={isDisabled}
      >
        {children}
      </CtaGreyButton>
    );

  if (variant === "options")
    return (
      <OptionsButton
        disabled={isDisabled}
        startIcon={
          <AppIcon
            title={startIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        }
        endIcon={
          <AppIcon
            title={endIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        }
        {...otherProps}
        ref={buttonRef}
      >
        {children}
      </OptionsButton>
    );

  if (variant === "primarydark")
    return (
      <PrimaryDarkButton
        startIcon={<AppIcon title={startIcon} size="18px" color="#fff" />}
        endIcon={
          <AppIcon
            title={endIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        }
        style={{
          width: width ? "auto !important" : "100% !important",
        }}
        {...otherProps}
        ref={buttonRef}
        disabled={isDisabled}
      >
        {children}
      </PrimaryDarkButton>
    );

  return (
    <PrimaryButton
      startIcon={
        startIcon ? <AppIcon title={startIcon} size="18px" color="#fff" /> : ""
      }
      endIcon={
        endIcon ? (
          <AppIcon
            title={endIcon}
            size={iconSize}
            color={iconColor}
            hoverColor={iconHoverColor}
          />
        ) : (
          ""
        )
      }
      style={{
        width: width ? "auto !important" : "100% !important",
      }}
      {...otherProps}
      ref={buttonRef}
      disabled={isDisabled}
    >
      {children}
    </PrimaryButton>
  );
};

export default AppButton;

const PrimaryButton = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;

    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 28px;
    height: 28px;
    min-width: 35px;
    padding: 6px 8px;
    border-radius: 6px;
    // background-color: rgba(${theme.palette.primary.main}, 0.12);
    background-color: ${alpha(theme.palette.primary.dark, 1)};
    // color: ${theme.palette.primary.main};
    color: ${theme.palette.default.white};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    border: 1px solid ${theme.palette.primary.main};
    i {
      color: ${theme.palette.primary.dark};
    }
    svg,
    svg * {
      fill: ${theme.palette.default.white};
    }
    &:hover {
      color: ${theme.palette.primary.dark};
      background-color: transparent;
      i,
      svg * {
        color: ${theme.palette.primary.dark};
        fill: ${theme.palette.primary.dark};
      }
    }
    &:disabled {
      border: 1px solid ${theme.palette.default.darkGrey};
      background-color: transparent;
      color: ${theme.palette.default.darkGrey};
      pointer-events: all;
      cursor: not-allowed;
      padding: 6px 8px;
      svg,
      i {
        color: ${theme.palette.default.darkGrey} !important;
      }
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }

    .MuiButton-startIcon {
      margin-right: 0;
    }
  }
`;

const PrimaryDarkButton = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;

    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 28px;
    height: 28px;
    padding: 6px 8px;
    border-radius: 6px;
    min-width: 35px;
    background-color: ${theme.palette.primary.dark};
    color: ${theme.palette.default.white};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    border: 1px solid ${theme.palette.primary.dark};
    &:hover {
      background-color: ${theme.palette.primary.light};
      color: ${theme.palette.primary.dark};
      background-color: transparent;
      svg * {
        fill: ${theme.palette.primary.dark};
      }
      .
      i {
        color: ${theme.palette.primary.main} !important;
      }
    }
    &:disabled {
      border: 1px solid ${theme.palette.default.darkGrey};
      background-color: transparent;
      color: ${theme.palette.default.darkGrey};
      pointer-events: all;
      cursor: not-allowed;
      svg,
      i {
        color: ${theme.palette.default.darkGrey} !important;
      }
    }

    svg {
      font-size: 18px;
      margin: 0 4px;
    }
    svg,
    svg * {
      fill: ${theme.palette.default.white};
    }
  }
`;
const CtaButton = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 28px;
    height: 28px;
    min-width: 35px;
    border-radius: 6px;
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.default.white};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 1px;
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
    &:disabled {
      background-color: ${theme.palette.default.grey};
      cursor: not-allowed;
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;
const CtaGreyButton = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 28px;
    height: 28px;
    min-width: 35px;
    border-radius: 6px;
    background-color: ${theme.palette.default.grey};
    color: ${theme.palette.default.black};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 1px;
    &:hover {
      background-color: ${theme.palette.default.grey};
    }
    &:disabled {
      background-color: ${theme.palette.default.lightGrey};
      cursor: not-allowed;
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;

const OptionsButton = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6px 6px;
    min-height: 34px;
    height: 34px;
    border-radius: 6px;
    min-width: 35px;
    background-color: ${theme.palette.default.white};
    color: ${theme.palette.primary.main};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    border: 1px solid ${theme.palette.primary.main};
    &:hover {
      color: ${theme.palette.default.white};
      background-color: ${theme.palette.primary.main};
    }
    &:disabled {
      color: ${theme.palette.default.darkGrey};
      border: 1px solid ${theme.palette.default.border};
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
  .css-ks7bn3-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    background-color: red !important;
  }
`;

const OutlineButton = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6px 6px;
    min-height: 28px;
    height: 28px;
    min-width: 35px;
    gap: 4px;
    border-radius: 6px;
    background-color: transparent;
    color: ${theme.palette.primary.dark};
    background-color: ${theme.palette.default.white};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    border: 1px solid ${theme.palette.primary.dark};
    svg {
      margin: 0 4px;
    }
    svg,
    svg *,
    i {
      fill: ${theme.palette.primary.dark};
      color: ${theme.palette.primary.dark} !important;
      font-size: 16px;
    }
    &:hover {
      color: ${theme.palette.default.white};
      background-color: ${theme.palette.primary.dark};

      i,
      svg * {
        color: ${theme.palette.default.white} !important;
        fill: ${theme.palette.default.white};
      }
    }
    &:disabled {
      border: 1px solid ${theme.palette.primary.dark};
      background-color: ${theme.palette.primary.dark};
      color: ${theme.palette.default.white};
      svg,
      svg *,
      i {
        color: ${theme.palette.default.white};
        fill: ${theme.palette.default.white};
      }
    }

    .MuiButton-startIcon {
      margin: 0;
    }
  }
`;
const OutlineErrorButton = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6px 6px;
    min-height: 28px;
    height: 28px;
    min-width: 35px;
    border-radius: 6px;
    background-color: ${theme.palette.default.white};
    color: ${theme.palette.default.error};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    border: 1px solid ${theme.palette.default.error};
    &:hover {
      color: ${theme.palette.default.white};
      background-color: ${theme.palette.default.error};
      i {
        color: ${theme.palette.default.white} !important;
      }
    }
    &:disabled {
      color: ${theme.palette.default.error};
      border: 1px solid ${theme.palette.default.error};
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;
const OutlineErrorButtonFilled = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 28px;
    height: 28px;
    min-width: 35px;
    border-radius: 6px;
    background-color: ${theme.palette.default.error};
    color: ${theme.palette.default.white};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    padding: 6px 15px;
    border: 1px solid ${theme.palette.default.error};
    &:hover {
      color: ${theme.palette.default.white};
      background-color: ${theme.palette.default.error};
      i {
        color: ${theme.palette.default.white} !important;
      }
    }
    &:disabled {
      color: ${theme.palette.default.error};
      border: 1px solid ${theme.palette.default.error};
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;
const OutlineSuccessButton = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 6px 6px;
    min-height: 28px;
    height: 28px;
    min-width: 35px;
    border-radius: 6px;
    background-color: ${theme.palette.default.success};
    color: ${theme.palette.default.white};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    border: 1px solid ${theme.palette.default.success};
    &:hover {
      color: ${theme.palette.default.white};
      background-color: ${theme.palette.default.success};
      i {
        color: ${theme.palette.default.white} !important;
      }
    }
    &:disabled {
      color: ${theme.palette.default.success};
      border: 1px solid ${theme.palette.default.success};
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;
const GreyButton = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;
    display: flex;
    text-transform: capitalize;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 28px;
    height: 28px;
    min-width: 35px;
    padding: 6px;
    border-radius: 6px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    background-color: ${theme.palette.default.white};
    color: ${theme.palette.default.darkGrey};
    border: 1px solid ${theme.palette.default.border};
    &:hover {
      color: ${theme.palette.default.black};
      border: 1px solid ${theme.palette.default.black};
    }
    &:disabled {
      color: ${theme.palette.default.darkGrey} !important;
      cursor: not-allowed !important;
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;

export const TextButton = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 28px;
    height: 28px;
    min-width: 35px;
    padding: 6px 4px;
    border-radius: 6px;
    background-color: transparent;
    color: ${theme.palette.primary.main};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    &:disabled {
      color: ${theme.palette.default.grey};
      span,
      i {
        color: ${theme.palette.default.grey} !important;
      }
    }
    &:hover {
      color: ${theme.palette.primary.dark};
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;

const ContainedButton = styled(LoadingButton)`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 50%;
    padding: 6px 6px;
    min-height: 28px;
    height: 28px;
    min-width: 35px;
    border-radius: 6px;
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.default.white};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    border: 1px solid ${theme.palette.primary.main};
    &:hover {
      color: ${theme.palette.default.white};
      background-color: ${theme.palette.primary.main};
    }
    &:disabled {
      color: ${theme.palette.default.lightGrey};
      border: 1px solid ${theme.palette.default.lightGrey};
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;
