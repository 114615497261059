import { Box, Divider, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import AppButton from "../../../../AppComponents/AppButton";
import { AppForm, AppFormField, AppFormImagePicker, AppSubmitButton } from "../../../../AppComponents/Forms";
import React from "react";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { useDispatch, useSelector } from "react-redux";
import { ChatbotTrackingCode } from "../../../../Models";
import { getBotTrackingCodeFn } from "../../../../Services/Apis";
import { useAuthStore } from "../../../../Store/Slices/AuthSlice";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { LandingPageBotSchema } from "./LandingPageBotSchema";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";

const LandingPageBot = () => {

    const [localValues, setLocalValues] = React.useState<any>(null);
    const [btnsDisabled, setBtnsDisabled] = React.useState<boolean>(true);
    let { showAlert } = useNotification();
    const [generatedUrl, setGeneratedUrl] = React.useState('');
    const dispatch = useDispatch();
    const authData = useSelector(useAuthStore);
    const [trackingCode, setTrackingCode] = React.useState<ChatbotTrackingCode>();
    const currentBot = useSelector(useCurrentBot);
    const [userInput, setUserInput] = React.useState<any>({
        landing_page_logo: "",
        landing_page_heading: "",
        landing_page_sub_heading: "",
        landing_page_button_text: "",
        landing_page_button_link: ""
    });

    const myRef = React.useRef<HTMLPreElement>(null);

    React.useEffect(() => {
        if (currentBot?.id) {
            ChatbotConsoleService.getBotConfiguration(currentBot.chatbot_configuration as string).then((res: any)=> {
                if (res.data) {
                    console.log(res?.data, "This is data res")
                    if (res?.data?.landing_page_heading && res?.data?.landing_page_sub_heading) {
                        setBtnsDisabled(false)
                        setUserInput({
                            landing_page_logo: res?.data?.landing_page_logo,
                            landing_page_heading: res?.data?.landing_page_heading,
                            landing_page_sub_heading: res?.data?.landing_page_sub_heading,
                            landing_page_button_text: res?.data?.landing_page_button_text,
                            landing_page_button_link: res?.data?.landing_page_button_link
                        })
                    }else {
                        setBtnsDisabled(true)
                    }
                    
                    // setBtnsDisabled(true)
                }
                // setBtnsDisabled(false)
            })
        }
    }, [currentBot?.id]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleSubmitBtn = (values: any, SubmitProps: any) => {

        let data = {
            chatbot: { name: currentBot?.name },
            chatbot_configuration: {
                landing_page_logo: values?.landing_page_logo?.id,
                landing_page_heading: values?.landing_page_heading,
                landing_page_sub_heading: values?.landing_page_sub_heading,
                landing_page_button_text: values?.landing_page_button_text,
                landing_page_button_link: values?.landing_page_button_link
            },
        }

        ChatbotConsoleService.createUpdateBotConfiguration(
            currentBot.id as string,
            data
        )
            .then((response) => {
                setBtnsDisabled(false)
                showAlert("Landing Page Data Save successfully", "success");
                
            })
            .catch((error) => {
                setBtnsDisabled(true)
                showAlert(
                    error?.response?.statusText || "Something went wrong",
                    "error"
                );
            })
            .finally(() => {
                SubmitProps?.setSubmitting(false);
            });
    }

    const sendValuesToParent = (values: any): void => {
        setLocalValues(values);
    };

    const CopyTextToClipboard = async () => {
        await navigator.clipboard.writeText(generatedUrl);
        showAlert("URL copied to clipboard", "success");
    };

    

    const handleButtonClick = () => {
        const getaHost = trackingCode?.geta_host || '';
        const matomoSiteId = trackingCode?.matomo_site_id || '';
        const botId = trackingCode?.bot_id || '';
        const url = `${process.env.REACT_APP_LANDING_PAGE_BOT_URL}/?geta_host=${getaHost}&matomo_site_id=${matomoSiteId}&bot_id=${botId}&env=${process.env.REACT_APP_CORE_API_BASE_URL}`;
        setGeneratedUrl(url);
        window.open(url, '_blank');
    };


    React.useEffect(() => {
        if (currentBot?.id) {
            getBotTrackingCodeFn(dispatch)({
                pathParams: [currentBot?.id + ""],
            }).then((res: ChatbotTrackingCode) => {
                setTrackingCode(res);
            });
        }
    }, [authData, currentBot]);

    React.useEffect(() => {
        const getaHost = trackingCode?.geta_host || '';
        const matomoSiteId = trackingCode?.matomo_site_id || '';
        const botId = trackingCode?.bot_id || '';
        const url = `${process.env.REACT_APP_LANDING_PAGE_BOT_URL}/?geta_host=${getaHost}&matomo_site_id=${matomoSiteId}&bot_id=${botId}&env=${process.env.REACT_APP_CORE_API_BASE_URL}`;
        setGeneratedUrl(url);
    }, [trackingCode]);


    return (<LandingPageBotWrapper>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <AppForm
                    initialValues={userInput}
                    onSubmit={(values: any, submitProps: any) => {
                        handleSubmitBtn(values, submitProps);
                    }}
                    validationSchema={LandingPageBotSchema}
                >
                    <AppFormImagePicker
                        label="Landing Page Logo"
                        name="landing_page_logo"
                        tooltip={
                            <ul>
                                <li>Recommended size 64*64 px</li>
                                <li>Your logo must be smaller than 1 MB</li>
                                <li>A PNG, JPEG or JPG works best</li>
                            </ul>
                        }
                        sizeLimit={1000000}
                        sizeErrorMsg={"Image size must be smaller than 1MB."}
                        formatErrorMsg={"image should be PNG, JPEG or JPG."}
                    />

                    
                    <AppFormField
                        label="Landing Page Heading"
                        name="landing_page_heading"
                        placeholder="Heading"
                        sendValuesToParent={(values: any) => sendValuesToParent(values)}
                        requiredSign
                    />

                    <AppFormField
                        label="Landing Page Subheading"
                        name="landing_page_sub_heading"
                        placeholder="Sub heading"
                        sendValuesToParent={(values: any) => sendValuesToParent(values)}
                        requiredSign
                    />

                    <AppFormField
                        label="Button Text"
                        name="landing_page_button_text"
                        placeholder="Button Text"
                        sendValuesToParent={(values: any) => sendValuesToParent(values)}
                    />

                    <AppFormField
                        label="Button Link"
                        name="landing_page_button_link"
                        placeholder="Button Link"
                        sendValuesToParent={(values: any) => sendValuesToParent(values)}
                    />
                    

                    <AppSubmitButton
                        variant="cta"
                        style={{ width: "100%", margin: "0.5rem 0" }}
                        title={"Save"}
                    />
                </AppForm>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>

                    <Box textAlign={"center"} mb={2}>
                        <Typography fontSize={"18px"} variant="h6">Here is the unique link to your Landing page bot..</Typography>
                        <Typography variant="body2">Simply share it with your users on social media, contact pages or business cards.</Typography>
                    </Box>
                    <Box mb={2}>
                        <AppButton isDisabled={btnsDisabled} variant="primaryDark" onClick={handleButtonClick}>
                            {/* <AppMaterialIcons iconName="RemoveRedEyeOutlined" />    */}
                            Test 
                        </AppButton>
                    </Box>
                    
                    <Box width={"45%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Divider />
                        <span >OR</span>
                        <Divider />
                    </Box>

                    <Box mt={2}>
                        <AppButton isDisabled={btnsDisabled} onClick={() => CopyTextToClipboard()} variant="primaryDark">
                            {/* <AppMaterialIcons iconName="FileCopy" />  */}
                            Copy URL
                        </AppButton>    
                    </Box> 
                </Box>

            </Grid>
        </Grid>
    </LandingPageBotWrapper>)
}

export default LandingPageBot;

const LandingPageBotWrapper = styled.div`
&& {
    width: 100%;
    margin: 16px;
    border-radius: 8px;
    background-color: ${theme.palette.default.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    padding: 16px;
    height: fit-content;
}
`