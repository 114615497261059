// vendors
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

// components
import NodeType from "../Components/NodeType";
import UIButton from "../Components/Button";
import UISelect from "../Components/Select";
import Input from "../Components/Input";
import Icon from "../Components/Icon";

// services
import { ContactsNetworkService } from "../../../Store/Slices/Contacts/contactsNetworkService";

// styles
import {
  PopoverConfig,
  HeaderStyled,
  WrapperStyled,
  ButtonWrapperStyled,
  ResponseTargetFieldWrapper,
} from "./SaveProperty.styled";
import { Link } from "react-router-dom";
import AppReactSelect from "../../../AppComponents/AppReactSelect";

type Property = {
  name: string;
  type: string;
  property: string;
  format?: string;
};

interface SavePropertyProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onSave: (e: any, property: Property) => void;
  property: Property;
}

const ResponseTarget = [
  // {
  //   label: "None",
  //   value: "",
  // },
  {
    label: "Contact properties",
    value: "contact-properties",
  },
  // {
  //   label: "Bot Variable",
  //   value: "bot-variable",
  // },
];

const ContactProperties = [
  {
    label: "None",
    value: "",
  },
  {
    label: "First Name",
    value: "first-name",
  },
  {
    label: "Last Name",
    value: "last-name",
  },
  {
    label: "Phone Number",
    value: "phone-number",
  },
  {
    label: "Email",
    value: "email",
  },
];

const FORMATS = [
  { label: "Text", value: "text" },
  { label: "Number", value: "number" },
  { label: "Date", value: "date" },
  { label: "Regex", value: "regex" },
];

const SaveProperty = (props: SavePropertyProps) => {
  const { onClose, open, anchorEl, onSave, property } = props;
  const [responseTarget, updateResponseTarget] = useState<string>(
    property?.name || ResponseTarget[0].value,
  );
  const [propertyType, updatePropertyType] = useState<string>(
    property?.type || "existing",
  );
  const [contactProperty, updateContactProperty] = useState<any>(
    property?.property || null,
  );
  const [newPropertyName, updateNewPropertyName] = useState<string>(
    property?.format ? property?.property : "",
  );
  const [newPropertyFormat, updateNewPropertyFormat] = useState<string>(
    property?.format || FORMATS[0].value,
  );
  const [contactProperties, updateContactProperties] = useState<any>([]);

  useEffect(() => {
    ContactsNetworkService.getContactsSelectData({
      data: "",
    }).then(async (response: any) => {
      let properties = response.data.data;
      if (response.data.data.length > 0) {
        properties = JSON.parse(
          JSON.stringify(properties).split('"name":').join('"value":'),
        );
        updateContactProperties(properties);
      }

      return response;
    });
  }, []);

  const handleChange = (event: any) => {
    updateResponseTarget(event.target.value);
  };

  const handlePropertyNameChange = (event: any) => {
    updatePropertyType(event.target.value);
  };

  const handleContactPropertyChange = (value: any) => {
    updateContactProperty(value);
  };

  const handleNewPropertyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateNewPropertyName(event.target.value);
  };

  const handleFormatChange = (event: any) => {
    updateNewPropertyFormat(event.target.value);
  };

  const isValid = () => {
    if (propertyType === "create-new") {
      return responseTarget !== "" && newPropertyName !== "";
    }
    return responseTarget !== "" && contactProperty !== "";
  };

  const handleSave = (e: React.MouseEvent<Element>) => {
    e.stopPropagation();
    if (propertyType === "create-new") {
      onSave(e, {
        name: responseTarget,
        type: propertyType,
        property: newPropertyName,
        format: newPropertyFormat,
      });
      return;
    }
    if (propertyType === "existing") {
      onSave(e, {
        name: responseTarget,
        type: propertyType,
        property: contactProperty,
      });
    }
  };

  return (
    <Popover
      className="ignore-onClickOutside"
      onClose={onClose}
      anchorEl={anchorEl}
      {...PopoverConfig(Boolean(open))}
    >
      <HeaderStyled>
        <NodeType text="Save Response" icon="date" color="#EDEF8A" />
      </HeaderStyled>
      <WrapperStyled style={{ minHeight: "280px" }}>
        <ResponseTargetFieldWrapper>
          <Typography
            variant="body2"
            color={"default.black"}
            component={"label"}
          >
            Save the response in
          </Typography>
          <UISelect
            id="responseTarget"
            value={responseTarget}
            options={ResponseTarget}
            onChange={handleChange}
          />
        </ResponseTargetFieldWrapper>
        <Box sx={{ mt: "18px" }}>
          <Typography
            variant="body2"
            color={"default.black"}
            component={"label"}
          >
            Property name
          </Typography>
          <Box
            sx={{ display: "flex", my: "8px", justifyContent: "space-between" }}
          >
            <RadioGroup
              name="property-type"
              row
              value={propertyType}
              onChange={handlePropertyNameChange}
            >
              <FormControlLabel
                value={"existing"}
                label={
                  <Typography variant="body2" sx={{}}>
                    {/* Choose properties */}
                  </Typography>
                }
                control={
                  <Radio
                    checkedIcon={<Icon icon="radio-checked" size={16} />}
                    icon={<Icon icon="radio-unchecked" size={16} />}
                  />
                }
              />
              {/* <FormControlLabel
              value={"create-new"}
              label={<Typography variant="body2">Create new</Typography>}
              control={
                <Radio
                  checkedIcon={<Icon icon="radio-checked" size={16} />}
                  icon={<Icon icon="radio-unchecked" size={16} />}
                />
              }
            /> */}
            </RadioGroup>
            <a
              href={"/settings/data-management/contact-properties/list"}
              title="Create New"
              style={{
                fontSize: "12px",
                color: "#7E8392",
                textDecoration: "none",
              }}
              target="_blank"
              rel="noreferrer"
            >
              Create New
            </a>
          </Box>
        </Box>

        {propertyType === "existing" ? (
          <AppReactSelect
            value={contactProperty}
            options={contactProperties}
            onChangeCallbackFn={handleContactPropertyChange}
            name={""}
            valueKey="value"
            displayKey="name"
            menuPlacement = "bottom"
          />
        ) : (
          <>
            <Input
              placeholder="Enter property name"
              onChange={handleNewPropertyNameChange}
              value={newPropertyName}
            />
            <Box sx={{ mt: "18px" }}>
              <Typography
                variant="body2"
                color={"default.black"}
                component={"label"}
              >
                Format
              </Typography>
              <UISelect
                value={newPropertyFormat}
                options={FORMATS}
                onChange={handleFormatChange}
              />
            </Box>
          </>
        )}
      </WrapperStyled>
      <WrapperStyled>
        <ButtonWrapperStyled>
          <UIButton variant="outlined" onClick={onClose}>
            Cancel
          </UIButton>
          <UIButton
            variant="contained"
            onClick={handleSave}
            disabled={!isValid()}
          >
            Save
          </UIButton>
        </ButtonWrapperStyled>
      </WrapperStyled>
    </Popover>
  );
};
export default SaveProperty;
