import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import {
  GraphContainer,
  HeaderWrapper,
  WholeWrap,
  WrapperD,
} from "./ResponseTimeStyl";
import {
  SkeletonProgressBar,
  SkeletonSkeletonDoughnutCard,
} from "./SkeletonLoader.tsx/SkeletonCard";
import { TextTitle } from "./AnalyticsSummaryStyles";

const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#775dd0" : "#308fe8",
  },
}));

const BorderLinearProgress3 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#00a76e" : "#308fe8",
  },
}));

interface Props {
  resolution_time?: any;
  response_time?: any;
  xAxisMinValue?: any;
  resolutionTimeTooltip?: any;
  responseTimeTooltip?: any;
  xAxisCount?: any;
  loading?: boolean;
}

const ResponseTime: React.FC<Props> = ({
  resolution_time,
  response_time,
  xAxisMinValue,
  resolutionTimeTooltip,
  responseTimeTooltip,
  xAxisCount,
  loading,
}) => {
  function formatTime(seconds: any) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = Math.round(seconds % 60);
    var timeString = "";
    if (hours > 0) {
      timeString += hours + " hr";
      if (hours > 1) {
        timeString += "s";
      }
      timeString += " ";
    }
    if (minutes > 0) {
      timeString += minutes + " min";
      if (minutes > 1) {
        timeString += "s";
      }
      timeString += " ";
    }
    if (remainingSeconds > 0 || timeString === "") {
      timeString += remainingSeconds + " sec";
      if (remainingSeconds !== 1) {
        timeString += "s";
      }
    }
    return timeString;
  }

  return (
    <Box sx={{ flexGrow: 1 }} className="responseWrap">
      <WholeWrap>
        <HeaderWrapper>
          <p className="ResponseTimeHeader">
            <TextTitle>Time Stats</TextTitle>
          </p>
          <AppToolTip
            tooltipProps={{
              title: "Time performance metrics for agents and bots",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
          />
        </HeaderWrapper>
        <GraphContainer>
          <WrapperD>
            <div className="p">
              {loading ? (
                <SkeletonSkeletonDoughnutCard />
              ) : (
                "Average Resolution Time"
              )}
            </div>
            <div className="p">
              {loading ? (
                <SkeletonSkeletonDoughnutCard />
              ) : (
                ` ${formatTime(resolutionTimeTooltip) || 0}`
              )}{" "}
            </div>
          </WrapperD>
          {loading ? (
            <SkeletonProgressBar />
          ) : (
            <BorderLinearProgress2
              variant="determinate"
              value={
                (resolution_time / (xAxisMinValue * xAxisCount)) * 100 || 0
              }
            />
          )}

          <WrapperD>
            <div className="p">
              {loading ? (
                <SkeletonSkeletonDoughnutCard />
              ) : (
                "Avg. Conversation Duration"
              )}
            </div>
            <div className="p">
              {loading ? (
                <SkeletonSkeletonDoughnutCard />
              ) : (
                ` ${formatTime(responseTimeTooltip) || 0} `
              )}{" "}
            </div>
          </WrapperD>
          {loading ? (
            <SkeletonProgressBar />
          ) : (
            <BorderLinearProgress3
              variant="determinate"
              value={(response_time / (xAxisMinValue * xAxisCount)) * 100 || 0}
            />
          )}
        </GraphContainer>
      </WholeWrap>
    </Box>
  );
};
export default ResponseTime;
