import styled from 'styled-components'
import { theme } from '../../../../Customization/Theme'
import { useAppSelector } from '../../../../Store'
import { formBuilderStore, formSettingsStoreData } from '../../../../Store/Slices/FormBuilder/formbuilder.selectors'
import FieldLabel from '../../Components/FieldLabel'
import React from 'react'

const StylePreview = (): JSX.Element => {
  const formFieldsData: any = useAppSelector(formBuilderStore);
  const formSettings: any = useAppSelector(formSettingsStoreData);
  const showLabel = formSettings?.show_label || false;
  return (
    <Container>
      {/* <LeftSection>
        <AppForm initialValues={{}} validationSchema={undefined} onSubmit={() => console.log("Submited")}>
          <AppAccordion label='Basic' isDefaultOpen={true} isResetAvaliable={true}>
            <FormContainer>
              <p className='info'>Customize your form and page background and form border color here. Background colors will also apply to "Thank you" page</p>
              <div className='fieldsContainer'>
                <AppFormField name='width' label='Form Width' labelStyle={{ color: theme.palette.default.lighterFilterGrey }} errorMessage='' />
                <span>
                  <FieldLabel label='Format' />
                  <AppFormRadioField name='options' Options={formatOptions} />
                </span>
              </div>
              <div className='fieldsContainer'>
                <AppFormColorPicker
                  label="Background Color"
                  name="primary"
                  onColorChange={(color) => { }}
                  notSupportedHexCodes={notSupportedHexCodes}
                  tooltip="Used as a background color to highlight areas and buttons"
                // customOnBlur={changeTheme}
                />
                <AppFormColorPicker
                  label="Form Background"
                  name="primary"
                  onColorChange={(color) => { }}
                  notSupportedHexCodes={notSupportedHexCodes}
                  tooltip="Used as a background color to highlight areas and buttons"
                // customOnBlur={changeTheme}
                />
              </div>
              <div className='fieldsContainer'>
                <AppFormColorPicker
                  label="Form Border Color"
                  name="primary"
                  onColorChange={(color) => { }}
                  notSupportedHexCodes={notSupportedHexCodes}
                  tooltip="Used as a background color to highlight areas and buttons"
                // customOnBlur={changeTheme}
                />
                <AppFormSelectField
                  label="Padding"
                  Options={[]}
                  name="font_family"
                  tooltip="Used as the chatbots font option for chats."
                />
              </div>
            </FormContainer>
          </AppAccordion>

          <AppAccordion label='Intro and success message' isDefaultOpen={true} isResetAvaliable={true}>
            <FormContainer>
              <p className='info'>Customize the intro and success messages’ text size, color, and font.</p>
              <div className='fieldsContainer'>
                <AppFormColorPicker
                  label="Message Text Color"
                  name="primary"
                  onColorChange={(color) => { }}
                  notSupportedHexCodes={notSupportedHexCodes}
                  tooltip="Used as a background color to highlight areas and buttons"
                // customOnBlur={changeTheme}
                />

                <AppFormSelectField
                  label="Message Font"
                  Options={[]}
                  name="font_family"
                  tooltip="Used as the chatbots font option for chats."
                />
              </div>

              <div className='fieldsContainer'>
                <AppFormSelectField
                  label="Message Font Size"
                  Options={[]}
                  name="font_family"
                  tooltip="Used as the chatbots font option for chats."
                />
                <AppFormSelectField
                  label="Message Font Style "
                  Options={[]}
                  name="font_family"
                  tooltip="Used as the chatbots font option for chats."
                />
              </div>
            </FormContainer>
          </AppAccordion>

          <AppAccordion label='Form Fields' isDefaultOpen={true} isResetAvaliable={true}>
            <FormContainer>
              <p className='info'>Customize form input labels, label text sizes, and border colors</p>
              <div className='fieldsContainer'>
                <AppFormSelectField
                  label="Label and Input Fields"
                  Options={[]}
                  name="font_family"
                  tooltip="Used as the chatbots font option for chats."
                />
                <AppFormSelectField
                  label="Label Font Size"
                  Options={[]}
                  name="font_family"
                  tooltip="Used as the chatbots font option for chats."
                />
              </div>

              <div className='fieldsContainer'>
                <AppFormSelectField
                  label="Input Font Size"
                  Options={[]}
                  name="font_family"
                  tooltip="Used as the chatbots font option for chats."
                />
                <AppFormColorPicker
                  label="Label Color"
                  name="primary"
                  onColorChange={(color) => { }}
                  notSupportedHexCodes={notSupportedHexCodes}
                  tooltip="Used as a background color to highlight areas and buttons"
                // customOnBlur={changeTheme}
                />
              </div>
              <div className='fieldsContainer'>

                <AppFormColorPicker
                  label="Input Text Color"
                  name="primary"
                  onColorChange={(color) => { }}
                  notSupportedHexCodes={notSupportedHexCodes}
                  tooltip="Used as a background color to highlight areas and buttons"
                // customOnBlur={changeTheme}
                />
                <AppFormColorPicker
                  label="Input Border Color"
                  name="primary"
                  onColorChange={(color) => { }}
                  notSupportedHexCodes={notSupportedHexCodes}
                  tooltip="Used as a background color to highlight areas and buttons"
                // customOnBlur={changeTheme}
                />
              </div>

              <div className='fieldsContainer'>
                <AppFormSelectField
                  label="Input Border Radius"
                  Options={[]}
                  name="font_family"
                  tooltip="Used as the chatbots font option for chats."
                />

                <span>
                  <FieldLabel label='Label UpperCase' />
                  <AppFormRadioField name='options' Options={labelUpperCase} />
                </span>
              </div>
            </FormContainer>
          </AppAccordion>



          <AppAccordion label='Button' isDefaultOpen={true} isResetAvaliable={true}>
            <FormContainer>
              <p className='info'>Customize the form submit button color, border radius,and text size</p>
              <div className='fieldsContainer'>
                <AppFormSelectField
                  label="Button Text Size"
                  Options={[]}
                  name="font_family"
                  tooltip="Used as the chatbots font option for chats."
                />
                <AppFormSelectField
                  label="Input Border Radius"
                  Options={[]}
                  name="font_family"
                  tooltip="Used as the chatbots font option for chats."
                />
              </div>
              <div className='fieldsContainer'>
                <AppFormColorPicker
                  label="Button Background Color"
                  name="primary"
                  onColorChange={(color) => { }}
                  notSupportedHexCodes={notSupportedHexCodes}
                  tooltip="Used as a background color to highlight areas and buttons"
                // customOnBlur={changeTheme}
                />
                <AppFormColorPicker
                  label="Button Hover Color"
                  name="primary"
                  onColorChange={(color) => { }}
                  notSupportedHexCodes={notSupportedHexCodes}
                  tooltip="Used as a background color to highlight areas and buttons"
                // customOnBlur={changeTheme}
                />
              </div>
              <div className='fieldsContainer'>
                <AppFormColorPicker
                  label="Button Text Color"
                  name="primary"
                  onColorChange={(color) => { }}
                  notSupportedHexCodes={notSupportedHexCodes}
                  tooltip="Used as a background color to highlight areas and buttons"
                // customOnBlur={changeTheme}
                />
              </div>
            </FormContainer>
          </AppAccordion>
        </AppForm>
      </LeftSection>
      <RightSection>
        <p>Right Section</p>
      </RightSection> */}

      <FormRenderedScreen>
        {formFieldsData?.map((fields: any, index: number) => {
          const length = fields?.filter((field: any) => !field?.is_hidden)
            ?.length;
          const className = length === 1 ? "col-12" : length === 2 ? "col-6" : "col-4"
          return (
            <div className="row" key={index}>
              {fields?.map((field: any) => {
                return (
                  <React.Fragment>
                    {!field?.is_hidden ? (
                      <div
                        className={className}
                      >
                        {showLabel && <FieldLabel label={field?.label} />}
                        <Field>{field?.placeholder}</Field>
                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
        {formSettings?.is_application_widget_form ? (
          <div>
            <div>
              <SumbitButton
                className="btn btn-primary"
                style={{ width: "-webkit-fill-available" }}
              >
                {"Register now "}
              </SumbitButton>
            </div>
            <div>
              <SumbitButton
                className="btn btn-primary"
                style={{ width: "-webkit-fill-available" }}
              >
                {"Existing user login"}
              </SumbitButton>
            </div>
          </div>
        ) :
          <div
            style={{
              textAlign: formSettings?.button_style?.button_position
                ? formSettings?.button_style?.button_position
                : "start",
            }}>

            <SumbitButton
              backgroundColor={formSettings?.button_background_color || "red"}
              buttonTextColor={formSettings?.button_text_color || "#FFF"}
              style={{
                width: formSettings?.button_style?.cover_whole_width
                  ? "-webkit-fill-available"
                  : "100%"
              }}>{formSettings?.button_name || "Submit"}</SumbitButton>
          </div>
        }
      </FormRenderedScreen>
    </Container>
  )
}

const Container = styled.div`
&&{
  margin:1rem;
  display:flex;
  justify-content:center;
  height: calc(100vh - 250px);
  width: 100%;
  gap:20px;
  background: ${theme.palette.default.white};
  .info{
    color:${theme.palette.default.darkGrey};
    padding:1rem;
  }
}`

const HeaderSection = styled.div`
&&{
  display:flex;
  justify-content:center;
  gap:20px;
  padding:1rem;
}`;
const FormRenderedScreen = styled.div`
&&{
  display:flex;
  flex-direction:column;
  align-items:center;
  gap: 20px;
  padding:1rem;
  background:${theme.palette.default.lightGrey};
  width:50%;
  height:100%;
  .row{
    width:90%;
    display:flex;
    align-items:center;
    justify-content:start; 
    gap:1rem;
  }
  .col-12 {
    flex: 1; 
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-4 {
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}`;

const Field = styled.p`
&&{
    display: grid;
    width: 100%;
    height: 40px;
    font-size:14px;
    border: 1px solid rgba(126, 131, 146, 0.3);
    align-items:center;
    padding:0px 10px;
    border-radius: 5px;
    cursor: pointer;
    color:${theme.palette.default.darkGrey};
    text-align:left;
}`

const SumbitButton = styled.button`
  padding: 8px;
  width: 100px;
  color: ${(props: { buttonTextColor: string }) => props.buttonTextColor || "#FFF"};
  background-color: ${(props: { backgroundColor: string }) => props.backgroundColor || "red"}; 
  font-weight: 700;
  cursor: pointer;
  border-radius: 13px;
  border: 1px solid ${(props: { backgroundColor: string }) => props.backgroundColor || "red"};
  border-radius: 5px;
  margin-top: 7px;
  &:hover {
    background: ${(props: { buttonTextColor: string }) => props.buttonTextColor || "#FFF"};
    color: ${(props: { backgroundColor: string }) => props.backgroundColor || "red"}; ;
  }
`;

const FormContainer = styled.div`
&&{
  .fieldsContainer{
    gap:16px;
    display: grid; 
    grid-template-columns: 1fr 1fr;
    padding:8px 10px;
    }
}`
const LeftSection = styled.div`
&&{
  width:35%;
  background: ${theme.palette.default.white};
  overflow-y:auto;
  padding:0 10px;
}`;

const RightSection = styled.div`
&&{
  width: 65%;
  background: ${theme.palette.default.white};
  padding:1rem;
  overflow-y:auto;
}`
export default StylePreview