import { FieldArray, getIn, useFormikContext } from 'formik';
import React from 'react'
import styled from 'styled-components';
import { AppErrorMessage, AppFormField, AppFormSelectField } from '../../../../../../../AppComponents/Forms';
import { IconButton } from '@mui/material';
import AppButton from '../../../../../../../AppComponents/AppButton';
import { AppMaterialIcons } from '../../../../../../../AppComponents/AppMaterialIcons';
import { whatsappCtaValueAction } from '../../../../Utils/Constants';
import WhatsappTextField from '../../WhatsappTextField';
import { getTemplateButtonsLengthChecker } from '../../../../../../../Store/Slices/Whatsapp/whatsapp.selectors';
import { useAppSelector } from '../../../../../../../Store';

interface WebsitUrlFieldProps {
    disabled: boolean

}
const WebsitUrlField = (props: WebsitUrlFieldProps) => {
    const { disabled } = props;
    const { values, errors, touched } = useFormikContext<any>();
    const buttonsLengthChecker = useAppSelector(getTemplateButtonsLengthChecker)
    const name = "website_url"
    const handleAddButtonClick = (push: any) => {
        push({
            button_text: "",
            url: "",
            url_type: "static",
        });
    }
    return (
        <FieldArray name={name}>
            {({ remove, push }) => (
                <React.Fragment>
                    {values &&
                        name &&
                        getIn(values, name)?.length > 0 &&
                        getIn(values, name)?.map((item: any, index: number) => {
                            const fieldName: string = `${name}.${index}.button_text`;
                            const urlTypeFieldName: string = `${name}.${index}.url_type`;
                            const urlField: string = `${name}.${index}.url`;
                            const dynamic_variables_for_url = `${name}.${index}.dynamic_variables_for_url`;
                            return (
                                <React.Fragment>
                                    <React.Fragment key={index.toString()}>
                                        <UrlTypeSelectionContainer>
                                            <AppFormSelectField
                                                label=""
                                                Options={whatsappCtaValueAction}
                                                name={urlTypeFieldName}
                                                disabled={disabled}
                                                placeholder="Select URL type"
                                                divStyle={{ width: "200px" }}
                                            />

                                            {values?.website_url?.[index]?.url_type === "dynamic" ?
                                                <WhatsappTextField
                                                    name={urlField}
                                                    label={``}
                                                    placeholder="https://www.geta.ai/{{1}}"
                                                    charactersLimit={2000}
                                                    variablesKey={dynamic_variables_for_url}
                                                    variablesButtonLimit={1}
                                                    endVariableRequired={true}
                                                    disabled={disabled}
                                                    removeSpaceBeforAfterVariable={true}
                                                />
                                                :
                                                <AppFormField
                                                    name={urlField}
                                                    placeholder="Enter url, example: https://www.geta.ai/"
                                                    charactersLimit={2000}
                                                    disabled={disabled}
                                                    id={
                                                        getIn(values, name)?.length > 1 &&
                                                            index + 1 === getIn(values, name)?.length
                                                            ? "add_button"
                                                            : ""
                                                    }
                                                />
                                            }
                                        </UrlTypeSelectionContainer>
                                        <ButtonTextContainer>
                                            <AppFormField
                                                name={fieldName}
                                                placeholder="Enter text for the button"
                                                charactersLimit={25}
                                                disabled={disabled}
                                                id={
                                                    getIn(values, name)?.length > 1 &&
                                                        index + 1 === getIn(values, name)?.length
                                                        ? "add_button"
                                                        : ""
                                                }
                                            />

                                            {/* Remove */}
                                            <IconButton
                                                onClick={() => remove(index)}
                                                disabled={disabled}
                                            >
                                                <AppMaterialIcons iconName={"Delete"} />
                                            </IconButton>
                                        </ButtonTextContainer>

                                        <FieldArray name={dynamic_variables_for_url}>
                                            {({ remove: removeButtonIndex }) => (
                                                <React.Fragment>
                                                    {values &&
                                                        name &&
                                                        getIn(values, dynamic_variables_for_url)?.length > 0 &&
                                                        getIn(values, dynamic_variables_for_url)?.map((item: any, buttonIndex: number) => {
                                                            return (
                                                                <ButtonsVariableContainer>
                                                                    <p>{`{{${buttonIndex + 1}}}`} </p>

                                                                    <AppFormField
                                                                        name={`${dynamic_variables_for_url}.${buttonIndex}.resolve`}
                                                                        placeholder="Resolve Button Variable"
                                                                        charactersLimit={25} />
                                                                </ButtonsVariableContainer>
                                                            )
                                                        })}
                                                </React.Fragment>
                                            )}
                                        </FieldArray>

                                    </React.Fragment>
                                    <AppErrorMessage
                                        error={getIn(errors, name)?.[index]?.name}
                                        visible={getIn(touched, name)}
                                        index={index}
                                    />
                                </React.Fragment>
                            );
                        })}

                    {/* Add new field at last position */}
                    <AppButton
                        variant="text"
                        onClick={() => handleAddButtonClick(push)}
                        style={{
                            marginTop: "0.5rem",
                            minWidth: "170px",
                            width: "170px",
                            border: "1px solid rgba(91, 115, 232, 1)",
                            borderRadius: "6px",
                        }}
                        startIcon={"ri-add-line"}
                        isDisabled={
                            buttonsLengthChecker || (getIn(values, name)?.length === 2 ? true : false) || disabled
                        }
                        id="add_button"
                    >
                        Add Another Website URL
                    </AppButton>
                </React.Fragment>
            )}
        </FieldArray>
    )
}

export default WebsitUrlField;

const ButtonTextContainer = styled.div`
&&{
    display:flex;
    align-items:start;
}`

const UrlTypeSelectionContainer = styled.div`
&&{
    width:100%;
    display:flex;
    gap:1rem;
}`


const ButtonsVariableContainer = styled.div`
&&{
    display:flex;
    gap:1rem;
    align-items:start;
    width:70%;
        p{
        margin-top:0.8rem;
        font-size:14px;
        font-weight:500;
    }
    
}`