import { AxiosResponse } from "axios";

import { conversationAxios } from "../../../Utils";
import { getHttpService } from "../../HttpService";
import { FLOW_BUILDER_ENDPOINTS } from "../Endpoints";

export class FlowBuilderService {
  static httpService = getHttpService(conversationAxios);

  static getFlowById = (
    id = '',
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.get({
      url: FLOW_BUILDER_ENDPOINTS.GET_FLOW,
      headers: config,
      pathParams: [id],
    });
  };

  static getFlows = (
    queryData = {},
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.get({
      url: FLOW_BUILDER_ENDPOINTS.GET_FLOWS,
      params: queryData,
      headers: config,
    });
  };

  static createFlow = (data = {}, config = {}): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.post({
      url: FLOW_BUILDER_ENDPOINTS.CREATE_FLOW,
      data,
      headers: config,
    });
  };

  static updateFlow = (
    id = '',
    queryData = {},
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.put({
      url: FLOW_BUILDER_ENDPOINTS.UPDATE_FLOW,
      data: queryData,
      headers: config,
      pathParams: [id],
    });
  };

  static deleteFlow = (
    id = '',
    queryData = {},
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.httpDelete({
      url: FLOW_BUILDER_ENDPOINTS.DELETE_FLOW,
      data: queryData,
      headers: config,
      pathParams: [id],
    });
  };

  static createNode = (data = {}, config = {}): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.post({
      url: FLOW_BUILDER_ENDPOINTS.CREATE_NODE,
      data,
      headers: config,
    });
  };

  static updateNode = (data = {}, config = {}): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.put({
      url: FLOW_BUILDER_ENDPOINTS.UPDATE_NODE,
      data,
      headers: config,
    });
  };

  static deleteNode = (data = {}, config = {}): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.httpDelete({
      url: FLOW_BUILDER_ENDPOINTS.UPDATE_NODE,
      data,
      headers: config,
    });
  };

  static updateNodeStage = (data = {}, config = {}): Promise<AxiosResponse<any>> => {
    return FlowBuilderService.httpService.post({
      url: FLOW_BUILDER_ENDPOINTS.UPDATE_NODE_STAGE,
      data,
      headers: config,
    });
  };
}
