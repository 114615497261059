import {
  Box,
  Grid,
  MenuItem,
  Select,
  Typography,
  debounce,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import TemplateAPIERROR from "../../../../../../assets/images/No_records_found.svg";
import { useSelector } from "react-redux";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import AppButton from "../../../../../../AppComponents/AppButton";
import { AppForm } from "../../../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../../../AppComponents/Forms/AppFormSearchField";
import { theme } from "../../../../../../Customization/Theme";
import { useNotification } from "../../../../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../../../../Services/Apis/ChatbotConsoleService";
import { useAppDispatch, useAppSelector } from "../../../../../../Store";
import { AppPagination } from "../../../../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import AppPaginationComponent from "../../../../../../AppComponents/Pagination/Pagination";
import ResponsiveTable from "./ResponsiveTable";
import {
  ErrorComponent,
  MainContainer,
  NewNativeSelect,
  TableTitleHeader,
  TemplateTable,
} from "./SmsLogsTableDataStyles";
import { useParams } from "react-router-dom";
import SkeletonLoader from "../../../../../../AppComponents/Loader/SkeletonLoader";
import { broadcastActions } from "../../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { broadcastDataById, filtersValuesData } from "../../../../../../Store/Slices/BroadcastDashboard/Broadcast.selectors";

interface Props { }

const SmsLogsTableData: React.FC<Props> = () => {
  const formikRef = useRef(null);
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const filtersValues = useSelector(filtersValuesData);
  const BroadcastByIdData = useAppSelector(broadcastDataById);
  const BroadcastByIdDataList = BroadcastByIdData?.results;
  const totalcount = BroadcastByIdData?.count || 0;
  const [resultList, setResultList] = useState([]);
  const [headerKeys, setHeaderKeys] = useState<any[]>([]);
  const [tableValue, setTableValue] = useState<any>({});
  const broadcast_id = useParams();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<any>();

  const [onSearch, setOnSearch] = useState(false);

  const [localValues, setLocalValues] = useState<any>({
    // itemsPerPage: 10,
    itemsPerPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    status: "",
    search: "",
    id: broadcast_id.broadcast_id,
  });

  const getBroadCastDetailedData = () => {
    setLoading(true);
    const payload = {
      limit: localValues?.itemsPerPage?.value,
      offset: onSearch ? 0 : localValues.currentPage,
      status: localValues?.status,
      search: localValues?.search,
      id: broadcast_id.broadcast_id,
    };
    dispatch(broadcastActions.getBroadcastByID(payload))
      .then((res: any) => {
        setPagination(res?.payload?.data?.pagination);
        setResultList(res?.payload?.data?.results);
        setHeaderKeys(res?.payload?.data?.columns_mapping);
        setTableValue(res?.payload?.data?.field_mapping);
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getBroadCastDetailedData();
    getLogsListFilters();
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const getLogsListFilters = () => {
    let payload = {
      type: "sms",
    };
    dispatch(broadcastActions.getAllCommunicationFilterSettings(payload))
      .then((res: any) => { })
      .catch((err: any) => { })
      .finally(() => { });
  };

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };

  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      status: e.target.value,
    });
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      search: e,
      currentPage: 0,
    });
    setOnSearch(true);
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };
  const clearAllCallback = () => {
    setLocalValues({
      ...localValues,
      status: "",
      search: "",
    });
  };

  const handleButtonClick = (formikRef: any) => {
    formikRef.current.handleReset();
  };
  const renderSelectIcon = localValues.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  const handleExportButton = () => {
    const id :any = broadcast_id.broadcast_id;
    const type :any = "sms"
    ChatbotConsoleService.getWhatsappBroadcastDetailsDownload(id,type,localValues?.search,localValues?.status)
      .then((response: any) => {
        window.open(response.data.data.source, "_blank");
      })
      .catch((error: any) => {
        console.error("Error occurred while fetching the file URL.", error);
      });
  };

  const handleRefresh = () => {
    setTimeout(() => {
      getBroadCastDetailedData();
      setLocalValues({
        itemsPerPage: 10,
        currentPage: localValues.currentPage,
        status: "",
        search: "",
      });
    }, 1000);
  };

  return (
    <MainContainer>
      <TemplateTable>
        <TableTitleHeader>
          <div className="left">
            <div className="title">
              <p>Filter By</p>
            </div>
            <div className="">
              <AppForm
                initialValues={{ search: "" }}
                onSubmit={(values: any, submitProps: any) => { }}
                innerRef={formikRef}
                divStyle={{ width: "100%" }}
              >
                <Grid
                  container
                  spacing={1}
                  display="flex"
                  sx={{
                    m: 0.2,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    item
                    alignItems="center"
                    sx={{ paddingTop: "0 !important" }}
                  >
                    <NewNativeSelect>
                      <Select
                        defaultValue={undefined}
                        name="status"
                        value={localValues?.status}
                        onChange={(e) => handleSelectChange(e, "status")}
                        displayEmpty
                        className="new-select-main"
                        placeholder="Hello"
                      >
                        <MenuItem value="" disabled>
                          Select Status
                        </MenuItem>
                        {filtersValues?.status?.map((item: any, index: any) => (
                          <MenuItem value={item.value} key={index}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <div
                        className="clear-icon"
                        onClick={(e: any) => clearCallback(e, "status")}
                      >
                        {" "}
                        {renderSelectIcon}
                      </div>
                    </NewNativeSelect>
                  </Grid>

                  <Grid
                    item
                    alignItems="center"
                    display="flex"
                    sx={{ padding: "0 !important" }}
                    gap="0.5rem"
                  >
                    <AppFormSearchField
                      name="search"
                      placeholder="Search"
                      defaultBorder={true}
                      divStyle={{
                        marginLeft: "10px",
                        minWidth: "220px",
                        marginBottom: "0",
                      }}
                      onChange={(e: any) => {
                        handleSelectSearch(e, "search");
                      }}
                      clearCallback={(e: any) => clearCallback(e, "search")}
                    />

                    {localValues.status && localValues.search && (
                      <AppButton
                        variant="outline"
                        onClick={() => {
                          clearAllCallback();
                          handleButtonClick(formikRef);
                        }}
                        sx={{ mb: "9px" }}
                      >
                        Reset
                      </AppButton>
                    )}
                  </Grid>
                </Grid>
              </AppForm>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              float: "right",
              gap: "10px",
            }}
          >
            <Grid item>
              {resultList?.length === 0 ? (
                <AppButton
                  style={{ width: "20px" }}
                  variant="outline"
                  disabled
                  onClick={() => handleExportButton()}
                  downloadFile
                >
                  <FileUploadOutlinedIcon />
                </AppButton>
              ) : (
                <AppButton
                  style={{ width: "20px" }}
                  variant="outline"
                  onClick={() => handleExportButton()}
                  downloadFile
                >
                  <FileUploadOutlinedIcon />
                </AppButton>
              )}
            </Grid>
            <Grid item>
              <AppButton
                style={{ width: "20px" }}
                variant="contained"
                onClick={handleRefresh}
              >
                <RefreshTwoToneIcon />
              </AppButton>
            </Grid>
          </div>
        </TableTitleHeader>
        {loading ? (
          <Box sx={{ 
              height: "calc(100vh - 302px)", 
              backgroundColor: `${theme.palette.default.white}`, 
            }}>
            <SkeletonLoader numberOfCol={4} numberofRow={6} />
          </Box>
        ) : BroadcastByIdDataList?.length ? (
          <ResponsiveTable
            columns={headerKeys}
            data={resultList}
            rowsPerPage={localValues?.itemsPerPage}
            pageNumber={localValues?.currentPage}
            totalDataCount={totalcount}
            hasMoreData={true}
            name={"formkey4"}
            setCheckboxFieldValue={"setFieldValue"}
            subscriptionName={"subscriptionName"}
            tableValue={tableValue}
          />
        ) : (
          <ErrorComponent
            style={{
              height: "calc(100vh - 269px)",
              border: `1px solid ${theme.palette.default.border}`,
              borderTop: "none",
              backgroundColor: `${theme.palette.default.white}`,
            }}
          >
            <Box display="flex" justifyContent="center">
              <img width="400" src={TemplateAPIERROR} alt="" />
            </Box>
            <Box className="error-Info">
              <Typography variant="h6" fontSize="20px">
                No Records Found
              </Typography>
            </Box>
          </ErrorComponent>
        )}
      </TemplateTable>
      {BroadcastByIdDataList?.length ? (
          <AppPaginationComponent
            totalCount={pagination?.totalRecords}
            handlePageChange={handlePageChange}
            currentPage={localValues?.currentPage}
            totalPages={pagination?.totalPages}
            rowsPerPage={localValues?.itemsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
      ) : null}
    </MainContainer>
  );
};

export default SmsLogsTableData;
