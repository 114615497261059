import { Box, Drawer, IconButton, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { socialMediaValidationSchema } from "../../../../Models/ValidationSchema";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { ContactsActions } from "../../../../Store/Slices/Contacts/contacts.action";
import { ContactBasicInfo, ContactsSelectData, contactFieldHistoryData, contactFieldHistoryDataError, contactFieldHistoryDataLoading, contactFieldHistoryDataPagination } from "../../../../Store/Slices/Contacts/contacts.selector";
import { ReactComponent as FacebookIcon } from "../../../../assets/images/icons/facebook.svg";
import { ReactComponent as CopyIcon } from "../../../../assets/images/icons/grey-copy.svg";
import { ReactComponent as LinkdinIcon } from "../../../../assets/images/icons/linked-in.svg";
import { ReactComponent as EditIcon } from "../../../../assets/images/icons/pencil.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/images/icons/phone-vector.svg";
import { ReactComponent as TutorIcon } from "../../../../assets/images/icons/twitter.svg";
import { ReactComponent as TagCrossIcon } from "../../../../assets/images/tag-cross-icon.svg";
import AssignTo from "../../bulkActions/AssignTo";
import { ModalUI } from "../SubPages/BasicInformation/LeadsDetailsPage";
import TagAssignmentWrapper from "./TagAssignmentWrapper";
import { DependencyListTopBar } from "../../../Settings/ContactModule/Dependencies/DependencyFieldListStyles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import AppRangeDatePicker from "../../../../AppComponents/AppRangeDatePicker";
import { dateRange } from "../../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { setDashboardDateRange } from "../../../../Store/Slices/DashboardRedux/dashboard.slice";
import FieldHistorySlides from "./component/FieldHistorySlides";
import CheckBoxListButton from "./component/CheckBoxListButton";
import moment from "moment";
import AppPaginationComponent from "../../../../AppComponents/Pagination/Pagination";

const CommonTabHeader = (props: any) => {
  const navigate = useNavigate();

  const params = useParams();
  const { contact_id } = params;
  const dispatch = useAppDispatch();
  const SelectDropDownData = useAppSelector(ContactsSelectData);
  const basicInformation: any = useAppSelector(ContactBasicInfo);
  const [checkedIds, setCheckedIds] = useState([]);
  const ContactFieldHistoryData = useAppSelector(contactFieldHistoryData);
  const ContactFieldHistoryDataPagination = useAppSelector(contactFieldHistoryDataPagination);
  const ContactFieldHistoryDataError = useAppSelector(contactFieldHistoryDataError)

  const GetContactHistoryLoading = useAppSelector(contactFieldHistoryDataLoading);
  const [checked, setChecked] = React.useState([]);

  const dashdateRange: any = useAppSelector(dateRange);
  const initialValue = {
    twitter_profile:
      basicInformation?.general_information?.twitter_profile || "",
    linkedin_profile:
      basicInformation?.general_information?.linkedin_profile || "",
    facebook_profile:
      basicInformation?.general_information?.facebook_profile || "",
  };

  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    totalPages: 0,
  });

  const { showAlert } = useNotification();
  const useModal4 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal4: isShowing, toggleModal4: toggle };
  };
  const { isShowingModal4, toggleModal4 } = useModal4();

  const useModal5 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal5: isShowing, toggleModal5: toggle };
  };
  const { isShowingModal5, toggleModal5 } = useModal5();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getContactDetails = () => {
    setLoading(true);
    let payload = {
      contact_id,
    };

    dispatch(ContactsActions.getBasicInfo(payload))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(
            res?.payload?.data?.message || "Enabled to fetch Data",
            "error"
          );
        } else {
        }
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (contact_id) {
      getContactDetails();
    }
  }, [contact_id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitBtn = async (values: any, submitProps: any) => {
    if (Object.values(values).filter((ele: any) => ele !== "").length > 0) {
      try {
        var data = {
          values: values,
          contact_id: contact_id,
        };
        const response: any = await dispatch(
          ContactsActions.saveContactsSocialMedia(data)
        );
        if (response?.payload?.data?.status) {
          toggleModal4();
          showAlert(response?.payload?.data.message, "success");
          getContactDetails();
          submitProps.resetForm();
        } else {
          showAlert(response?.payload?.data?.error, "warning");
        }
      } catch (error: any) {
        showAlert(
          error?.response?.data?.error || "Something went wrong",
          "warning"
        );
      } finally {
        submitProps.setSubmitting(false);
      }
    } else {
      toggleModal4();
    }
  };
  const SubmitReset = () => {
    toggleModal5();
    getContactDetails();
  };
  const CopyTextToClipboard = async (value: string) => {
    let temp: any = value;
    await navigator.clipboard.writeText(temp);
    showAlert("Email copied clipboard", "success");
  };

  const handleFieldHistoryDrawer = () => {
    setIsOpen(true)
  }

  const onChange = (date: any) => {
    let temp = { ...date };
    delete temp.label;
    dispatch(setDashboardDateRange(temp));
  };

  const handleCheckedItemsChange = (checkedItems: any) => {
    setCheckedIds(checkedItems);
  };

  // All About Pagination
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };


  React.useEffect(() => {
    if (isOpen) {
      const payload = {
        id: contact_id,
        data: {
          "fields": checkedIds || [],
          "created_by": [],
          "start_date": moment(dashdateRange.startDate).format("yyyy-MM-DD"),
          "end_date": moment(dashdateRange.endDate).format("yyyy-MM-DD"),
          limit: localValues.itemsPerPage?.value,
          offset: localValues.currentPage,
        }
      }
      dispatch(ContactsActions.fieldHistoryAction(payload))
    }
    
  }, [isOpen, dashdateRange, checkedIds, localValues])



  React.useEffect(() => {
    let payload: any = {
      data: "",
      // headers: headers,
    };
    dispatch(ContactsActions.getContactsSelectData(payload))
      .then()
      .catch((error: any) => {
        showAlert(
            error?.response?.statusText || "Unable to fetch Data",
            "error"
        );
      })
      .finally(() => {
        // setSkeltonLoader(false);
      });
  }, []);

  return (
    <HeaderWraper>
      <DependencyListTopBar>
        <Box className="heading">
          <IconButton 
            color="inherit"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon style={{ fontSize: "15px" }} />
          </IconButton>
          Back To Listing Page
        </Box>

        <AppButton variant="outline" onClick={handleFieldHistoryDrawer}>
          Contact Field History
        </AppButton>
      </DependencyListTopBar>
      <TopWrapper>
        <TopBar>
          <div className="userprofile-details flexWrapper">
            <div className="userDetails">
              <div className="flexWrapper">
                {!isLoading ? (
                  <React.Fragment>
                    <div className="avtar">
                      {basicInformation?.general_information?.first_name
                        ?.charAt(0)
                        .toUpperCase() +
                        (basicInformation?.general_information?.last_name
                          ? basicInformation?.general_information?.last_name
                            ?.charAt(0)
                            .toUpperCase()
                          : "")}
                    </div>

                    <div className="user-name">
                      <h6>{`${basicInformation?.general_information?.first_name || "-"
                        } ${basicInformation?.general_information?.last_name || "-"
                        }`}</h6>
                      <p>
                        Lead Score:{" "}
                        {basicInformation?.general_information?.geta_score}

                      </p>
                    </div>
                  </React.Fragment>
                ) : (
                  <>
                    <div className="avtar">
                      <Skeleton
                        variant="circular"
                        width={"100%"}
                        height={"100%"}
                      />
                    </div>
                    <div
                      className="user-name"
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"14px"}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"14px"}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

           
          </div>
          <div className="user-contact-details">
            <p>
              <span>@</span>{" "}
              {!isLoading ? (
                <>
                  {basicInformation?.general_information?.email || "---"}
                  <IconButton className="copy-icon">
                    <CopyIcon
                      onClick={() =>
                        CopyTextToClipboard(
                          basicInformation?.general_information?.email
                        )
                      }
                    />
                  </IconButton>
                </>
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"14px"}
                />
              )}
            </p>
            <p>
              <span>
                <PhoneIcon />
              </span>{" "}
              {!isLoading ? (
                basicInformation?.general_information?.mobile_phone_number ||
                basicInformation?.general_information?.mobile_phone_number
                  ?.number ||
                "---"
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={"14px"}
                />
              )}
            </p>
          </div>
          <div className="assign-user">
            <div className="section">
              <div className="assign-name">
                <label>Social Media</label>
                {basicInformation?.can_update ? (
                  <button onClick={() => toggleModal4()} className="edit-btn">
                    <EditIcon />
                  </button>
                ) : null}
              </div>
              <div className="social-media-list">
                {!isLoading ? (
                  <>
                    <a
                      href={
                        basicInformation?.general_information
                          ?.facebook_profile || "#"
                      }
                      title="Facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FacebookIcon />
                    </a>
                    <a
                      href={
                        basicInformation?.general_information
                          ?.linkedin_profile || "#"
                      }
                      title="Twitter"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TutorIcon />
                    </a>
                    <a
                      href={
                        basicInformation?.general_information
                          ?.twitter_profile || "#"
                      }
                      title="Linkedin"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkdinIcon />
                    </a>
                  </>
                ) : (
                  <>
                    <Skeleton
                      variant="circular"
                      width={"20px"}
                      height={"20px"}
                    />
                    <Skeleton
                      variant="circular"
                      width={"20px"}
                      height={"20px"}
                    />
                    <Skeleton
                      variant="circular"
                      width={"20px"}
                      height={"20px"}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="section">
            <div className="assign-name">
              <label>Owner</label>
              {basicInformation?.can_update ? (
                <IconButton onClick={() => toggleModal5()} className="edit-btn">
                  <EditIcon />
                </IconButton>
              ) : null}
            </div>
            {!isLoading ? (
              basicInformation?.general_information?.contact_owner || "---"
            ) : (
              <Skeleton variant="rectangular" width={"100%"} height={"20px"} />
            )}
          </div>
        </TopBar>
       
      </TopWrapper>
      <TagAssignmentWrapper isLoadingData={isLoading} />
      <AppModel
        isShowing={isShowingModal4}
        onClose={{}}
        divStyle={{
          width: "671px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <ModalUI>
          <div className="social-media-popup">
            <div className="popup-header">
              <div className="popup-heading-text">
                <h4>Edit social profile</h4>
              </div>
              <div className="popup-heading-icon">
                <IconButton onClick={() => {toggleModal4(); setChecked([])}}>
                  <TagCrossIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <AppForm
            initialValues={initialValue}
            validationSchema={socialMediaValidationSchema}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
            }}
          >
            <div className="input">
              <AppFormField
                label="Facebook"
                placeholder="https://facebook.com/profile"
                name="facebook_profile"
              />
            </div>
            <div className="input">
              <AppFormField
                label="Twitter"
                name="twitter_profile"
                placeholder="https://twitter.com/profile"
              />
            </div>
            <div className="input">
              <AppFormField
                label="Linked In"
                name="linkedin_profile"
                placeholder="https://linkedin.com/in/profile/"
              />
            </div>
            <div className="mark-complete-buttons">
              <AppButton onClick={() => toggleModal4()} variant="grey">
                cancel
              </AppButton>
              <AppSubmitButton title="Save"></AppSubmitButton>
            </div>
          </AppForm>
        </ModalUI>
      </AppModel>
      <AppModel
        isShowing={isShowingModal5}
        onClose={{}}
        divStyle={{
          width: "671px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem",
        }}
      >
        <ModalUI>
          <div className="social-media-popup">
            <div className="popup-header">
              <div className="popup-heading-text">
                <h4>Edit Contact Owner</h4>
              </div>
              <div className="popup-heading-icon">
                <IconButton onClick={() => toggleModal5()}>
                  <TagCrossIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <AssignTo
            closeModal={toggleModal5}
            payload={{
              selectAll: false,
              contactId_list: [contact_id],
            }}
            hideTitle={true}
            ownerLabel={basicInformation?.general_information?.contact_owner}
            SubmitReset={SubmitReset}
          />
        </ModalUI>
      </AppModel>



      {isOpen && (
        <Drawer 
        anchor="right" 
        open={isOpen} 
        >
                <Box
                  p={2}
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{
                    height: "54px",
                    maxHeight: "54px",
                    borderBottom: "1px solid #7E839233",
                  }}
                >
                  <Typography
                    variant="h6"
                    component="div"
                    fontSize={"18px"}
                    fontWeight={500}
                    color={"#211C52"}
                  >
                    Contact Field History
                  </Typography>
                  <Box onClick={() => setIsOpen(false)} sx={{ cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <AppMaterialIcons style={{ color: "#7E8392", fontSize: "21px" }} iconName="Close" />
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "80vw",
                    px: 2,
                    height: "calc(100% - 54px)",
                    overflow: "auto",
                  }}
                  role="presentation"
                >
                  <DrawerWrapper>
                    <Box className="top-bar">
                      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"}>
                        <Typography fontSize={"15px"} fontWeight={500}>
                          {`${basicInformation?.general_information?.first_name || "-"
                            } ${basicInformation?.general_information?.last_name || "-"
                            }`}
                        </Typography>
                      </Box>

                      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <CheckBoxListButton onCheckedItemsChange={handleCheckedItemsChange} dropdownData={SelectDropDownData} />
                        <AppRangeDatePicker
                          dateRange={dashdateRange}
                          setDateRange={onChange}
                        // hideTodayOption={true}
                        />
                      </Box>
                    </Box>

                    <Box className="slide-wrapper">
                <FieldHistorySlides data={ContactFieldHistoryData} isLoading={GetContactHistoryLoading} isError={ContactFieldHistoryDataError}  />
                    </Box>

                    {
                      ContactFieldHistoryData?.length > 0 ? <AppPaginationComponent
                        totalCount={ContactFieldHistoryDataPagination?.totalRecords}
                        handlePageChange={handlePageChange}
                        currentPage={localValues?.currentPage}
                        totalPages={ContactFieldHistoryDataPagination?.totalPages}
                        rowsPerPage={localValues?.itemsPerPage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                      /> : null
                    }
                  </DrawerWrapper>
                </Box>    
        </Drawer>
      )}

    </HeaderWraper>
  );
};
export default CommonTabHeader;

const HeaderWraper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  .tagsWrapper {
    margin: 5px 15px;
  }
`;
const TopWrapper = styled.div`
  display: flex;
  margin: 0 15px;
  justify-content: space-between;
  .MuiButtonGroup-vertical {
    min-width: calc(5% - 32px);
    margin: 0 0 0 16px;
    display: flex;
    justify-content: flex-start;
    .MuiButton-root {
      width: 48px;
      height: 48px;
      border: 1px solid #d9d9d980;
    }
  }
`;

const TopBar = styled.div`
  padding: 16px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  // width: 95%;
  box-shadow: 0px 2px 8px 0px #00000014;
  padding: 10px;
  width: calc(100%);
  > div {
    width: 100%;
    border-right: 1px solid #00000014;
    &:last-child {
      border: none;
    }
  }
  button.edit-btn {
    padding: 5px;
    border-radius: 50%;
    border: none;
    background: none;
    width: 35px;
    height: 35px;
    svg {
      width: 18px;
    }
    &:hover {
      background: #f0f0f0;
    }
  }
  .copy-icon {
    svg {
      width: 12px;
    }
  }
  .user-contact-details {
    padding: 10px;
  }
  .userprofile-details {
    .userDetails .flexWrapper {
      display: flex;
      gap: 12px;
    }
    padding: 10px;
    min-width: 230px;
    flex-wrap: wrap;

    p {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #101010;
      span {
        margin-right: 4px;
      }
    }
  }

  .avtar {
    background: none;
    border: none;
    width: 35px;
    height: 35px;
    background: #ede2ff;
    color: #4a13a4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
  }

  .avtar svg {
    width: 64px;
    height: 64px;
  }

  .user-name {
    h6 {
      font-size: 15px;
      font-weight: 500;
      line-height: 19px;
      color: #101010;
    }
    p {
      font-size: 13px;
      color: #7e8392;
    }
  }
  button.edit-btn {
    opacity: 0;
  }
  > .section {
    padding: 0 10px;
  }
  .section:hover {
    button.edit-btn {
      opacity: 1;
    }
  }
  .assign-user {
    font-size: 13px;
    line-height: 20px;
    padding: 0 14px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .assign-name {
    display: flex;
    align-items: center;
    gap: 21px;
    align-items: center;
    label {
      font-size: 13px;
      color: #7e8392;
    }
  }
  .user-icons-button {
    display: flex;
    align-items: end;
    gap: 24px;
  }
  .user-icons-button button {
    padding: 0px;
  }
  .assign-name button {
    background: none;
    border: none;
  }

  .icon-details {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .assign-name p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7e8392;
  }

  .assign-name svg {
    width: 17px;
    height: 17px;
  }

  .icons-listing-name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #101010;
  }

  .icons-listing {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #7e8392;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .icons-listing h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #101010;
  }

  .svg-icon {
    // width:19px;
    // height:19px;
  }

  .list-button {
    background: none;
    border: none;
    padding-top: 3px;
  }

  .list-button svg {
    width: 17px;
    height: 17px;
  }

  .social-media-list {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .social-media-list {
    a {
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 5px;
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
`;

const DrawerWrapper = styled.div`
&& {
  .top-bar {
    height: 71px;
    max-height: 71px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #7E839233;
    // margin-bottom: 30px;
  }

  .slide-wrapper {
    overflow: auto;
    height: calc(100vh - 177px);
    padding: 16px 0;
  }

  .activity {
    font-size: 13px;
    font-weight: 400;
    color: #7E8392;
  }
}
`;

