import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useState } from "react";
import AppCustomTable from "../../AppComponents/AppCustomTable";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { SegmentActions } from "../../Store/Slices/Segment/Segment.actions";
import {
  getAllSegmentList,
  segmentDataCount,
} from "../../Store/Slices/Segment/Segment.selectors";
import { ReactComponent as SearchIcon } from "../../assets/images/Searchicon.svg";
import {
  AppPagination,
  StyledAppInput,
} from "../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import CreateSegment from "./CreateSegment";
import { SegmentWrap } from "./SegmentListStyles";
import DeleteLogo from "./Segment_Assets/Delete.svg";
import EditLogo from "./Segment_Assets/Edit.svg";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppDeleteItem from "../../AppComponents/AppDeleteItem";
import AppDrawer from "../../AppComponents/AppDrawer";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import {
  ProtectedAppButton,
  ProtectedAppUiElement,
} from "../../AppComponents/ProtectedComponents";
import norecord from "../../assets/images/segment.svg";
import { CreateSegmentWrapper } from "./CreateSegmentStyles";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";

type Person = {
  name: string;
  total_contacts: any;
  created_by: string;
  created_time: string;
  modified_by: string;
  modified_time: string;
  actions: any;
};

interface Props extends LoaderComponentProps {
  toggle: any;
  isShowing: any;
  onChangeCallbackFn?: (value: any) => void;
  type: any;
}

const SegmentListFn: React.FC<Props> = ({
  onChangeCallbackFn,
  isShowing,
  setLoading,
}) => {
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    query: "",
  });

  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [editData, setEditData] = useState<any>(null);
  const { showAlert } = useNotification();
  const navigate = useNavigate();

  const closeDrawer = () => {
    setIsDrawerOpened(false);
    setEditData(null);
  };

  const toggleDrawer = () => {
    setIsDrawerOpened(!isDrawerOpened);

    if (isDrawerOpened) {
      setEditData(null);
    }
  };

  const allSegmentList = useAppSelector(getAllSegmentList);

  const allSegmentPagination = useAppSelector(segmentDataCount);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!editData) {
      getSegmentTableData();
    }
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSegmentTableData = () => {
    let temp: any = {
      search: localValues?.query,
      limit: localValues.itemsPerPage.value,
      offset: localValues.currentPage,
    };
    setLoading(true);
    dispatch(SegmentActions.getAllSegmentListData(temp))
      .then((res) => { })

      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
        closeDrawer();
      });
  };

  const formateDate = (d: any) => {
    return moment(d).format("MM/DD/YYYY");
  };

  // Handle Pagination Functions
  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  };

  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };

  const handleDeleteBtn = (id: any) => {
    setDeleteData(null);
    dispatch(SegmentActions.deleteSegmentById(id))
      .then((response: any) => {
        setLocalValues({
          ...localValues,
          itemsPerPage: 10,
          currentPage: 0,
        });
        getSegmentTableData();
        showAlert("Segment is deleted", "success");
      })
      .catch((error) => { });
  };

  const GotoContactsPage = (data: any) => {
    const subUrl = `${data?.original?.id}`;
    navigate(`/contacts/segment/list/${subUrl}`);
  };

  const Columns: ColumnDef<Person>[] = [
    {
      header: "Segment Name",
      accessorFn: (row: any) => <p>{row?.name || "N/A"}</p>,
      id: "name",
      cell: (info: any) => (
        <span
          onClick={() => GotoContactsPage(info?.row)}
          style={{ cursor: "pointer" }}
        >
          {info.getValue()}{" "}
        </span>
      ),
      size: 300,
      minSize: 300,
    },
    // {
    //   header: () => <div style={{ textAlign: "center" }}>Total Contacts</div>,
    //   accessorFn: (row: any) => (
    //     <p style={{ textAlign: "center" }}>{row?.contact_count || 0}</p>
    //   ),
    //   id: "total_contacts",
    //   cell: (info: any) => info.getValue(),
    //   size: 240,
    //   minSize: 240,
    // },
    {
      header: "Created By",
      accessorFn: (row: any) => <p>{row?.created_by.first_name || "N/A"}</p>,
      id: "created_by.first_name",
      cell: (info: any) => info.getValue(),
      size: 240,
      minSize: 240,
    },
    {
      header: "Created On",
      accessorFn: (row: any) => row.created_time || "N/A",
      id: "created_time",
      cell: (info: any) => formateDate(info.getValue()),
      size: 250,
      minSize: 250,
    },
    {
      header: "Last Updated By",
      accessorFn: (row: any) => <p>{row.updated_by.first_name || "N/A"}</p>,
      id: "first_name",
      cell: (info: any) => info.getValue(),
      size: 250,
      minSize: 250,
    },
    {
      header: "Last Updated On",
      accessorFn: (row: any) => row.modified_time || "N/A",
      id: "modified_time",
      cell: (info: any) => formateDate(info.getValue()),
      size: 250,
      minSize: 250,
    },
    {
      header: () => <div style={{ textAlign: "center" }}>Actions</div>,
      accessorFn: (row: any) => row,
      id: "actions",
      cell: (info: any) => {
        return (
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <>
              {info?.row?.original?.can_update ? (
                <img
                  src={EditLogo}
                  alt="action"
                  onClick={() => handleEditIconFn(info.getValue())}
                  style={{ cursor: "pointer" }}
                />
              ) : null}
              {info?.row?.original?.can_delete ? (
                <img
                  src={DeleteLogo}
                  alt="action"
                  onClick={() => setDeleteData(info.getValue()?.id)}
                  style={{ cursor: "pointer" }}
                />
              ) : null}
            </>
          </span>
        );
      },
      size: 250,
      minSize: 250,
    },
  ];

  //Search Functionality
  const handleSelectSearch = (e?: any) => {
    setLocalValues({
      ...localValues,
      query: e.target.value,
      currentPage: 0,
    });
  };

  const handleEditIconFn = (row: any) => {
    // delete row.filter_query;
    setEditData(row);
    toggleDrawer();
  };

  return (
    <SegmentWrap>
      {/* TOPBAR */}
      <div className="topmenu">
        <div className="menu-left">Segments</div>
        <div className="menu-right">
          <div className="faq-search" style={{ marginRight: "1rem" }}>
            <StyledAppInput>
              <input
                type="text"
                onChange={(e: any) => handleSelectSearch(e)}
                name="query"
                id="query"
                placeholder="Search"
              />
              <SearchIcon />
            </StyledAppInput>
          </div>
          <div
            className="button"
            style={{
              backgroundColor: "#5B73E8",
              color: "white",
              borderRadius: "6px",
            }}
          >
            <ProtectedAppButton
              moduleId="segments_crud"
              specificPermissionKey="create"
              style={{ width: "148px", color: "white", borderRadius: "6px" }}
              onClick={() => {
                toggleDrawer();
              }}
            >
              <AppMaterialIcons iconName={"add"} />
              Create Segment
            </ProtectedAppButton>
            <AppDrawer
              open={isDrawerOpened}
              onClose={() => undefined}
              sx={{ "& .MuiPaper-root": { minWidth: "999px", width: "999px" } }}
            >
              <CreateSegmentWrapper>
                <Box className="form-heading">
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                      {editData?.id ? "Edit" : "Create"} Segment
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        toggleDrawer();
                      }}
                    >
                      <AppMaterialIcons iconName={"Close"} />
                    </IconButton>
                  </Box>
                </Box>

                <Divider />
                <CreateSegment
                  getTableData={getSegmentTableData}
                  editData={editData}
                />
              </CreateSegmentWrapper>
            </AppDrawer>
          </div>
        </div>
      </div>

      {/* SEGMENT_TABLE */}

      <div className="data-container">
        {allSegmentList && allSegmentList?.length > 0 ? (
          <AppCustomTable data={allSegmentList} dynamicsColumns={Columns} />
        ) : (
          <div style={{ width: "100%", height: "100vh" }}>
            <div className="norecord-data">
              <img src={norecord} alt="img" />
              <p></p>
            </div>
            <div className="add-list">
              <p>Target the right contacts by segmenting them first</p>

              <div
                className="button"
                style={{
                  backgroundColor: "#5B73E8",
                  color: "white",
                  borderRadius: "6px",
                }}
              >
                <ProtectedAppButton
                  moduleId="segments_crud"
                  specificPermissionKey="create"
                  style={{
                    width: "148px",
                    color: "white",
                    borderRadius: "6px",
                  }}
                  onClick={() => {
                    toggleDrawer();
                  }}
                >
                  <AppMaterialIcons iconName={"add"} />
                  Create Segment
                </ProtectedAppButton>
                <AppDrawer
                  open={isDrawerOpened}
                  onClose={() => undefined}
                  sx={{
                    "& .MuiPaper-root": { minWidth: "999px", width: "999px" },
                  }}
                >
                  <CreateSegmentWrapper>
                    <ProtectedAppUiElement
                      moduleId="segments_crud"
                      specificPermissionKey={editData?.id ? "update" : "create"}
                    >
                      <Box className="form-heading">
                        <Box>
                          <Typography variant="h6" sx={{ fontWeight: 700 }}>
                            {editData?.id ? "Edit" : "Create"} Segment
                          </Typography>
                        </Box>
                        <Box>
                          <IconButton
                            onClick={() => {
                              toggleDrawer();
                            }}
                          >
                            <AppMaterialIcons iconName={"Close"} />
                          </IconButton>
                        </Box>
                      </Box>
                    </ProtectedAppUiElement>

                    <Divider />
                    <CreateSegment
                      getTableData={getSegmentTableData}
                      editData={editData}
                    />
                  </CreateSegmentWrapper>
                </AppDrawer>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* pagination  */}
      {allSegmentList && allSegmentList?.length ? (
            <AppPaginationComponent
            totalCount={allSegmentPagination?.totalRecords}
            handlePageChange={handlePageChange}
            currentPage={localValues?.currentPage}
            totalPages={allSegmentPagination?.totalPages}
            rowsPerPage={localValues?.itemsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
      ) : null}

      <AppDeleteItem
        title="Delete Segment"
        subTitle="Are you sure, you want to delete this Segment ?"
        onDelete={() => handleDeleteBtn(deleteData)}
        setDeleteData={setDeleteData}
        deleteData={deleteData}
      />
    </SegmentWrap>
  );
};

export default withLoader(SegmentListFn);
