import { Box, Breadcrumbs, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormObserver,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import { LoaderComponentProps } from "../../../../AppComponents/Loader";
import { AppConfig } from "../../../../Config/app-config";
import { HocBackdropLoader } from "../../../../HOC/HocBackdropLoader";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { EmailActions } from "../../../../Store/Slices/email/email.actions";
import { emailDataStore, isEmailTemplateExist } from "../../../../Store/Slices/email/email.selectors";
import { EmailNetworkService } from "../../../../Store/Slices/email/EmailNetworkService";
import {
  EmailBroadcastScheduleStep2Schema,
  EmailBroadcastScheduleStep5Schema,
  EmailCreateBroadcast,
} from "../EmailValidationSchema";
import { StyledContentBox } from "../Template/Styles";
import { BROADCAST_EMAIL_FORM_DATA } from "../Utils/Constants";
import {
  convertDataToEmailBroadcastForm,
  convertDateTimeToUTC,
} from "../Utils/utils";
import RenderFormStep1 from "./components/RenderFormStep1";
import RenderFormStep2 from "./components/RenderFormStep2";
import RenderFormStep3 from "./components/RenderFormStep3";
import RenderFormStep4 from "./components/RenderFormStep4";
import RenderFormStep5 from "./components/RenderFormStep5";
import { StyledCreateBroadcastWrap } from "./Styles";

import { ReactComponent as Confirm } from "../../../../assets/images/confirm.svg";
import { ReactComponent as Recipients } from "../../../../assets/images/recipients.svg";
import { ReactComponent as Schedule } from "../../../../assets/images/schedule.svg";
import { ReactComponent as Setup } from "../../../../assets/images/setup.svg";
import { ReactComponent as Template } from "../../../../assets/images/template.svg";
import { marketingListActions } from "../../../../Store/Slices/MarketingList/marketingList.actions";
import { marketingListStore } from "../../../../Store/Slices/MarketingList/marketingList.selectors";

import {
  resetEmailData,
  testEmailTemplateData,
} from "../../../../Store/Slices/email/email.slice";
import { removeMarketingListData } from "../../../../Store/Slices/MarketingList/marketingList.slice";
import {
  BreadcrumbsItem,
  EmailMainBoxStepperWrapper,
  EmailMainBoxTopbar,
} from "./EmailCreateBroadcastStyle";
import { StyledCreditBox } from "../../WhatsApp/Templates/Styles";
import BroadcastCreditDetails from "../../Utils/BroadcastCreditDetails";
import { creditDetailsStore } from "../../../../Store/Slices/CreditDetails/CreditDetails.selectors";
import {
  ProtectedAppButton,
  ProtectedAppForm,
} from "../../../../AppComponents/ProtectedComponents";
import { findFormSpecificPermissionKeyUsingLocation } from "../../../../Utils/rolesUtils";
import styled from "styled-components";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { theme } from "../../../../Customization/Theme";
import RenderFormStepNew2 from "./components/RenderFormStepNew2";
// import { ReactComponent as ConfirmImage } from "../../../../assets/images/confirm.svg";
// import styled from "styled-components";

interface Props extends LoaderComponentProps { }
interface BreadCrumbItem {
  id: number;
  name: string;
  icon: JSX.Element;
}

const BroadcastCreate: React.FC<Props> = ({ setLoading }) => {
  const formikRef: any = useRef(null);
  const { postEmailBroadcastData, filteredTemplateByID } =
    useAppSelector(emailDataStore);
  const { broadcastByIdData }: any = useAppSelector(marketingListStore);
  const { broadcastCreditDetails }: any = useAppSelector(creditDetailsStore);
  const checkIfTemplateExist = useAppSelector(isEmailTemplateExist);
  const { isShowing, toggle } = useModal();

  const BreadCrumbsItems: BreadCrumbItem[] = [
    {
      id: 1,
      name: "Setup",
      icon: <Setup />,
    },
    {
      id: 2,
      name: "Template",
      icon: <Template />,
    },
    {
      id: 3,
      name: "Recipients",
      icon: <Recipients />,
    },
    {
      id: 4,
      name: "Review Cost & Confirm",
      icon: <Confirm />,
    },
    {
      id: 5,
      name: "Schedule",
      icon: <Schedule />,
    },
  ];

  const parameters = new URLSearchParams(window.location.search);

  const localStorageFormData: any = AppConfig.getUserData(
    BROADCAST_EMAIL_FORM_DATA
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { showAlert } = useNotification();
  const { step_id, broadcast_id }: any = useParams();

  const [formStep, setFormStep] = useState<number>(
    step_id && broadcast_id && !parameters?.get("view")
      ? Number(step_id)
      : step_id && filteredTemplateByID
        ? Number(step_id)
        : 1
  );

  const [formikProps, setFormikProps] = useState<any>(null);
  const [isEditingBroadcast, setIsEditingBroadcast] = useState<boolean>(
    step_id && broadcast_id && step_id === String(5) ? true : false
  );
  const [isView, setIsView] = useState<boolean>(
    step_id && broadcast_id && step_id === String(1) ? true : false
  );
  const { values, errors }: any = formikProps || {};
  // useEffect(() => {
  // }, [values]);
  const [broadcastNowPayload, setBroadcastNowPayload] = useState<any>(null);
  //*WILL BE USED TO SET THE FORM ACCESS TYPE
  const [formAccessType, setFormAccessType] = useState<
    "create" | "read" | "update" | "delete" | undefined
  >();
  //*getting the form access type using the location on first render
  useEffect(() => {
    const result = findFormSpecificPermissionKeyUsingLocation();
    if (result === "update" && parameters?.get("view")) {
      setFormAccessType("read");
    } else {
      setFormAccessType(result);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [userInput, setUserInput] = useState<any>(
    localStorageFormData
      ? {
        broadcast_name: localStorageFormData?.broadcast_name || "",
        broadcast_template_id:
          localStorageFormData?.broadcast_template_id || null,
        broadcast_action:
          localStorageFormData?.broadcast_action ||
          "broadcast_marketing_list",

        subject: localStorageFormData?.subject,
        from_name: localStorageFormData?.from_name || 0,
        from_email: localStorageFormData?.from_email || null,
        reply_to: localStorageFormData?.reply_to || "",
        footer: localStorageFormData?.footer || "",
        email_attachment: localStorageFormData?.email_attachment,
        when_to_send: localStorageFormData?.when_to_send || "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),
        marketing_list: localStorageFormData?.marketing_list || 0,
        filters: localStorageFormData?.filters || null,
        selected_rows: localStorageFormData?.selected_rows || [],
        search_term: localStorageFormData?.search_term || "",
        emailTemplate: localStorage.getItem("HTMLTemplate") || null,
        HtmlTemplate: localStorage.getItem("JSONTemplate") || null,
        id: localStorageFormData?.id || null,
        template_name: localStorageFormData?.template_name || null,
        template_type: localStorageFormData?.template_type || null,
        marketing_Name: "",
        list_name: localStorageFormData?.list_name || "",
      }
      : {
        broadcast_name: "",
        broadcast_template_id: 0,
        broadcast_action: "broadcast_marketing_list",

        subject: "",
        from_name: "",
        reply_to: "",
        footer: "",
        email_attachment: "",
        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),
        marketing_list: 0,
        filters: null,
        selected_rows: [],
        search_term: "",
        emailTemplate: "",
        HtmlTemplate: "",
        id: postEmailBroadcastData,
        template_name: null,
        template_type: null,
        marketing_Name: "",
        list_name: "",
      }
  );

  const payloadObject = (values: any) => {
    let payload: any = {
      broadcast_name: values?.broadcast_name,
      broadcast_template_id: values?.broadcast_template_id,
      broadcast_action: values?.broadcast_action?.value,
      status: "draft",
    };
    if (formStep === 5) {
      payload["broadcast_id"] = postEmailBroadcastData || values?.id;
      payload["scheduled_now"] =
        values?.when_to_send === "broadcast_now" ? true : false;
      payload["scheduled_date"] = null;
      if (values?.when_to_send === "schedule_broadcast") {
        payload["scheduled_date"] = convertDateTimeToUTC(
          values?.date,
          values?.time
        );
      }
      payload["updated_html"] = localStorage.getItem("HTMLTemplate");
    }
    return payload;
  };

  // const handleFormNavigation = (formIndex: number) => {
  //   let url = `/broadcast/email/create-broadcast/${formStep}`;
  //   navigate(url);
  // };
  // useEffect(() => {
  //   handleFormNavigation(formStep);
  // }, [formStep]);

  useEffect(() => {
    return () => {
      AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, null);
      localStorage.removeItem("HTMLTemplate");
      localStorage.removeItem("JSONTemplate");
      setUserInput({
        broadcast_name: "",
        broadcast_action: "broadcast_marketing_list",
        broadcast_template_id: 0,
        subject: "",
        from_name: "",
        reply_to: "",
        footer: "",
        email_attachment: "",
        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),
        marketing_list: 0,
        filters: null,
        selected_rows: [],
        search_term: "",
        emailTemplate: "",
        HtmlTemplate: "",
        id: "",
        template_type: null,
        template_name: null,
      });
      formikRef?.current?.handleReset();
    };
  }, []);

  //* HANDLE SUBMIT BTN API CALL
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    if (formStep === 1) {
      if (
        userInput?.id &&
        values?.broadcast_name === userInput?.broadcast_name &&
        values?.broadcast_template_id !== 0 &&
        values?.broadcast_template_id === userInput?.broadcast_template_id
      ) {
        AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, values);
        setFormStep(formStep + 1);
        SubmitProps.setSubmitting(false);
        return;
      } else {
        const payload = {
          broadcast_name: values?.broadcast_name.trim() || "",
          subject: values?.subject,
          from_name: values?.from_name || null,
          from_email: values?.from_email || null,
          reply_to: values?.reply_to || "",
          footer: values?.footer || "",
          email_attachment: values?.email_attachment?.FileName,
        };
        if (postEmailBroadcastData) {
          EmailNetworkService.updateEmailBroadcast(
            payload,
            postEmailBroadcastData
          )
            .then((res: any) => {
              AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, values);
              setFormStep(formStep + 1);
            })
            .catch((err: any) => {
              showAlert("Something went wrong", "error");
            })
            .finally(() => {
              SubmitProps.setSubmitting(false);
            });
        } else {
          dispatch(EmailActions.postEmailCreateBroadcast(payload))
            .then((res: any) => {
              if (res?.payload?.data?.error) {
                showAlert(
                  res?.payload.data?.error || "Something went wrong",
                  "error"
                );
              } else {
                values["id"] = res?.payload.data?.data?.id;
                AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, values);
                setFormStep(formStep + 1);
              }
            })
            .catch((err: any) => {
              showAlert("Something went wrong", "error");
            })
            .finally(() => {
              SubmitProps.setSubmitting(false);
            });
        }
        return;
      }
    }

    if (formStep === 2 || formStep === 4) {
      SubmitProps.setSubmitting(false);
      AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, values);
      setFormStep(formStep + 1);

      return;
    }
    if (formStep === 3) {
      SubmitProps.setSubmitting(false);
      AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, values);
      setFormStep(formStep + 1);
      return;
    }
    if (formStep === 5) {
      let payload = payloadObject(values);
      if (isEditingBroadcast) {
        payload.status = "scheduled";
        payload.mode = "update";
        if (payload?.scheduled_now) {
          setBroadcastNowPayload({ payload, id: broadcast_id });
          SubmitProps.setSubmitting(false);
          toggle();
          return;
        } else {
          setLoading(true);
          EmailNetworkService.updateEmailBroadcast(payload, broadcast_id)
            .then((res: any) => {
              if (res?.data?.error) {
                showAlert(res?.data.error, "error");
                return;
              }
              AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, null);
              setUserInput({
                broadcast_name: "",
                broadcast_action: "broadcast_marketing_list",
                broadcast_template_id: 0,
                subject: "",
                from_name: "",
                reply_to: "",
                footer: "",
                email_attachment: "",
                when_to_send: "broadcast_now",
                date: new Date(Date.now()),
                time: new Date(Date.now()),
                marketing_list: 0,
                filters: null,
                selected_rows: [],
                search_term: "",
                emailTemplate: "",
                HtmlTemplate: "",
                id: "",
              });
              formikRef?.current?.handleReset();
              showAlert("Broadcast created", "success");
              navigate("/broadcast/email/scheduled");
            })
            .catch((err: any) => {
              showAlert(
                err?.response?.data?.error || "Something went wrong",
                "error"
              );
            })
            .finally(() => {
              SubmitProps.setSubmitting(false);
              setLoading(false);
            });
        }
      } else {
        AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, values);
        if (payload?.scheduled_now) {
          setBroadcastNowPayload({ payload, id: postEmailBroadcastData });
          SubmitProps.setSubmitting(false);
          toggle();
          return;
        } else {
          setLoading(true);
          EmailNetworkService.updateEmailBroadcast(
            payload,
            postEmailBroadcastData
          ).then((res: any) => {
            AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, null);
            showAlert("Broadcast created", "success");
            setUserInput({
              broadcast_name: "",
              broadcast_action: "broadcast_marketing_list",
              broadcast_template_id: 0,
              subject: "",
              from_name: "",
              reply_to: "",
              footer: "",
              email_attachment: "",
              when_to_send: "broadcast_now",
              date: new Date(Date.now()),
              time: new Date(Date.now()),
              marketing_list: 0,
              filters: null,
              selected_rows: [],
              search_term: "",
              emailTemplate: "",
              HtmlTemplate: "",
              id: "",
            });
            formikRef?.current?.handleReset();
            dispatch(resetEmailData({}));
            setLoading(false);
            navigate("/broadcast/email/scheduled");

          }).catch((err: any) => {
            showAlert(
              err?.response?.data?.error || "Something went wrong",
              "error"
            );
            setLoading(false);
          }).finally(() => {
            SubmitProps.setSubmitting(false);
            setLoading(false);
          });
        }
      }
    }
  };

  //* only first time render
  useEffect(() => {
    if ((isEditingBroadcast || isView) && broadcast_id) {
      dispatch(
        marketingListActions.getScheduledBroadcastByID(broadcast_id)
      ).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if ((isEditingBroadcast || isView) && broadcastByIdData) {
      setLoading(true);
      let temp = convertDataToEmailBroadcastForm(broadcastByIdData);
      setUserInput(temp);
      setLoading(true);

      dispatch(
        EmailActions.getEmailTempListById(temp.broadcast_template_id)
      ).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }
  }, [broadcastByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleBackClick = (isReset?: boolean) => {
    if (formStep === 1) {
      AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, null);
      localStorage.removeItem("HTMLTemplate");
      localStorage.removeItem("JSONTemplate");
      dispatch(removeMarketingListData({}));
      setUserInput({
        broadcast_name: "",
        broadcast_action: "broadcast_marketing_list",
        broadcast_template_id: 0,
        subject: "",
        from_name: "",
        reply_to: "",
        footer: "",
        email_attachment: "",
        when_to_send: "broadcast_now",
        date: new Date(Date.now()),
        time: new Date(Date.now()),
        marketing_list: 0,
        filters: null,
        selected_rows: [],
        search_term: "",
        emailTemplate: "",
        HtmlTemplate: "",
        id: "",
        template_type: null,
        template_name: null,
      });
      formikRef?.current?.handleReset();
      dispatch(resetEmailData({}));
      if (!isReset) {
        let url = `/broadcast/email/history`;
        navigate(url);
      }
      return;
    }

    if (formStep === 2) {
      setFormStep(formStep - 1);
      return;
    }
    if (formStep === 3) {
      setFormStep(formStep - 1);
      return;
    }
    if (formStep === 4) {
      setFormStep(formStep - 1);
      return;
    }
    if (formStep === 5) {
      setFormStep(formStep - 1);
      return;
    }
  };
  const handleNavigateBack = () => {
    if (window.location.pathname === "/broadcast/email/history") {
      navigate("/broadcast/email/history");
    } else {
      navigate("/broadcast/email/scheduled");
    }
  };


  return (
    <StyledCreateBroadcastWrap step={formStep}>



      <div className="main" style={{ margin: 0 }}>
        <StyledContentBox style={{ margin: "0", height: "100%" }}>
          {/* FORM BOX */}

          <div
            className={`formBox ${formStep === 2 || formStep === 4 ? "w-100" : ""
              }`}
            style={{ padding: 0 }}
          >
            <ProtectedAppForm
              divStyle={{
                gap: 0
              }}
              initialValues={userInput}
              onSubmit={(values: any, submitProps: any) => {
                handleSubmitBtn(values, submitProps);
              }}
              innerRef={formikRef}
              initialTouched={userInput}
              moduleId="email_broadcast_crud"
              specificPermissionKey={formAccessType}
              validationSchema={
                (formStep === 1 && EmailCreateBroadcast) ||
                // (formStep === 2 && EmailTemplateList) ||
                (formStep === 3 && EmailBroadcastScheduleStep2Schema) ||
                (formStep === 5 && EmailBroadcastScheduleStep5Schema) ||
                null
              }
            >
              <EmailMainBoxTopbar>
                <div className="left">
                  <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    {!isEditingBroadcast && (
                      <IconButton onClick={() => handleBackClick()}>
                        <AppMaterialIcons
                          iconName={"ArrowBackIosRounded"}
                          style={{ fontSize: "0.8rem" }}
                        />
                      </IconButton>
                    )}
                    <p className="heading" style={{ padding: "8px 0", marginLeft: "4px" }}>
                      {isEditingBroadcast ? "Edit" : "Create"} Broadcast
                    </p>
                  </Box>
                </div>
                <Box className="right">
                  <Box>
                    {formStep !== 4 && formStep !== 2 && (
                      <div className="BtnsBox" style={{ margin: "0px", flexWrap: "nowrap" }}>
                        {formStep === 1 && !isView ? (
                          <AppButton
                            onClick={() =>
                              handleBackClick(formStep === 1 ? true : false)
                            }
                            variant="grey"
                            disabled={isEditingBroadcast || isView}
                          >
                            {formStep === 1 && !isView ? "Reset" : "Back"}
                          </AppButton>
                        ) : null}

                        {isView ? (
                          <AppButton
                            onClick={() => handleNavigateBack()}
                            variant="grey"
                          >
                            Back
                          </AppButton>
                        ) : null}

                        {formStep === 5 && isEditingBroadcast ? (
                          <AppButton
                            onClick={() => navigate("/broadcast/email/scheduled")}
                            variant="grey"
                          >
                            Back
                          </AppButton>
                        ) : null}
                        {formStep === 5 ? (
                          <>
                            {
                              !isEditingBroadcast ? <AppButton
                                onClick={() => handleBackClick()}
                                variant="grey"
                                disabled={isEditingBroadcast}
                              >
                                Back
                              </AppButton> : null
                            }

                            <AppSubmitButton
                              title={
                                isEditingBroadcast
                                  ? "Update"
                                  : values.when_to_send === "broadcast_now"
                                    ? "Broadcast Now"
                                    : "Schedule Broadcast"
                              }
                            />
                          </>
                        ) : isView ? null : (
                          <>
                            {
                              formStep === 3 ? (<AppButton
                                onClick={() => handleBackClick()}
                                variant="grey"
                                disabled={isEditingBroadcast}
                              >
                                Back
                              </AppButton>) : null
                            }

                            <AppSubmitButton style={{ width: "80px" }} title="continue" />
                          </>
                        )}
                      </div>
                    )}
                    <div className="test-tamplate__btn" style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}>
                      {formStep === 4 ? (
                        <>
                          {/* <AppButton
                            startIcon="ri-arrow-left-s-line"
                            className="back-btn"
                            onClick={() => handleBackClick()}
                            variant="outline"
                            disabled={isEditingBroadcast}
                          >
                            <AppMaterialIcons iconName="ChevronLeftOutlined" />
                            Back
                          </AppButton> */}
                          <AppButton
                            variant="outline"

                            // endIcon="ri-arrow-right-s-line"
                            onClick={() => dispatch(testEmailTemplateData(true))}
                            disabled={values?.template_name ? false : true}
                          >
                            Test Template
                            {/* <AppMaterialIcons iconName="ChevronRightOutlined" /> */}
                          </AppButton>
                          <AppSubmitButton style={{ width: "80px" }} title="continue" />
                        </>
                      ) : null}
                    </div>

                    <div className="BtnsBox" style={{ margin: "0px", flexWrap: "nowrap" }}>
                    </div>

                  </Box>
                </Box>

              </EmailMainBoxTopbar>

              {
                formStep === 2 && !checkIfTemplateExist ? null : (
                  <EmailMainBoxStepperWrapper>
                    <Breadcrumbs aria-label="breadcrumb" separator=">">
                      {BreadCrumbsItems?.map((item: BreadCrumbItem) => {
                        if (isView && item?.id === 1) {
                          return (
                            <BreadcrumbsItem iconColorCondition={item?.id === formStep} key={item?.id}>
                              <div className="icon">{item?.icon}</div>
                              <div className="text">
                                <p>{item?.name}</p>
                              </div>
                            </BreadcrumbsItem>
                          );
                        } else if (!isView) {
                          return (
                            <BreadcrumbsItem iconColorCondition={item?.id === formStep} key={item?.id}>
                              <div className="icon">{item?.icon}</div>
                              <div className="text">
                                <p>{item?.name}</p>
                              </div>
                            </BreadcrumbsItem>
                          );
                        }
                      })}
                      {/* {BreadCrumbsItems?.map((item) => {
                        return (
                          <BreadcrumbsItem iconColorCondition={item.id <= formStep}>
                            <div className="icon">{item?.icon}</div>
                            <div className="text">
                              <p>{item?.name}</p>
                            </div>
                          </BreadcrumbsItem>
                        );
                      })} */}
                    </Breadcrumbs>

                  </EmailMainBoxStepperWrapper>
                )
              }
              <Box className="content_wrapper" style={{ height: `${formStep === 2 && !checkIfTemplateExist ? "calc(100vh - 128px)" : "calc(100vh - 192px)"}`, overflow: "auto" }}>

                <Box className="main_content">
                  {formStep === 4 && broadcastCreditDetails != null ? (
                    <StyledCreditBox style={{ width: "50%" }}>
                      <h3 className="titleText">Review Cost</h3>
                      <BroadcastCreditDetails
                        creaditsData={broadcastCreditDetails}
                      ></BroadcastCreditDetails>
                      {/* {!broadcastCreditDetails.valid ?
              <div className="ButtonDiv">
              <AppButton
                onClick={() => navigate("/broadcast/whatsapp/scheduled")}
                startIcon = "ri-wallet-3-line"
                width = "fit-content"
                >
                Add credit balance
              </AppButton>
              </div>: null
              } */}
                    </StyledCreditBox>
                  ) : null}

                  {formStep === 1 && (
                    <RenderFormStep1
                      setFormStep={setFormStep}
                      formStep={formStep}
                      isView={isView}
                    />
                  )}
                  {formStep === 2 && <RenderFormStepNew2 setFormStep={setFormStep} formStep={formStep} />}
                  {formStep === 3 && (
                    <RenderFormStep3
                      setFormStep={setFormStep}
                      formStep={formStep}
                    />
                  )}
                  {formStep === 4 && !isEditingBroadcast && (
                    <RenderFormStep4
                      handleBackClick={handleBackClick}
                      isEditingBroadcast={isEditingBroadcast}
                      formStep={formStep}
                    />
                  )}
                  {formStep === 5 && (
                    <RenderFormStep5
                      setFormStep={setFormStep}
                      formStep={formStep}
                    />
                  )}

                  <AppFormObserver setFormikProps={setFormikProps} />
                </Box>
              </Box>
              <AppModel
                isShowing={isShowing}
                onClose={() => toggle()}
                divStyle={{
                  padding: 0,
                }}
                PaperProps={{
                  style: {
                    boxShadow: "none",
                    width: "450px",
                    height: "150px",
                  },
                }}
              >
                <ModalContainer>
                  <div className="header">
                    <h4>Broadcast now!</h4>
                    <div className="header-right">
                      <span onClick={() => toggle(false)}>
                        <AppMaterialIcons iconName="close" />
                      </span>
                    </div>
                  </div>
                  <div className="body">
                    <p>Are you sure, you want to broadcast this message now?</p>
                  </div>
                  <div className="footer" >
                    <AppButton
                      variant="grey"
                      onClick={() => {
                        toggle(false);
                      }}
                    >
                      Cancel
                    </AppButton>
                    <AppButton
                      variant="default"
                      onClick={() => {
                        EmailNetworkService.updateEmailBroadcast(broadcastNowPayload?.payload, broadcastNowPayload?.id)
                          .then((res: any) => {
                            showAlert("Broadcast created", "success");
                            setLoading(false)
                            navigate("/broadcast/email/history");
                          }).catch((err: any) => {
                            showAlert(
                              err?.response?.data?.error || "Something went wrong",
                              "error"
                            );
                          }).finally(() => {
                            setLoading(false);
                          });
                      }}
                    >
                      Broadcast Now
                    </AppButton>
                  </div>
                </ModalContainer>
              </AppModel>
            </ProtectedAppForm>
          </div>
          {/* <ConfirmationImage>
          <ConfirmImage />
      </ConfirmationImage> */}
          {/* PREVIEW WIDGET */}
        </StyledContentBox>
      </div>
    </StyledCreateBroadcastWrap>
  );
};

export default HocBackdropLoader(BroadcastCreate);

const ModalContainer = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0.875rem;
    height:40px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
      h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
  .body{
    p {
        padding: 0.875rem;
        color: ${theme.palette.default.darkGrey};
      }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
  }
`;