import styled from 'styled-components'
import { AppForm, AppFormFastField } from '../../../../../AppComponents/Forms'
import AppFormCheckboxField from '../../../../../AppComponents/Forms/AppFormCheckboxField'
import FieldLabel from '../../../Components/FieldLabel'

const NotificationsSetting = (): JSX.Element => {
    return (
        <Container>
            <AppForm initialValues={{}} validationSchema={undefined} onSubmit={() => console.log("Submited")}>
                <FieldLabel label='Send submission email notification to' />
                <AppFormFastField name="emails" />
                <FieldLabel label='Follow-up options' isTooltipAvaliable={true} tooltipText='Hello' />
                <AppFormCheckboxField name='follow' label={"Send submission email notifications to the contact's owner"} />
            </AppForm>
        </Container>
    )
}

const Container = styled.div`
&&{
    max-width: 600px;
}`

export default NotificationsSetting