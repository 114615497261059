import { Box, Grid } from "@mui/material";
import { useFormikContext, getIn } from "formik";
import React, { useState, useEffect } from "react";
import { createFilter } from "react-select";
import {
  AppFormMobileField,
  AppFormField,
  AppFormTextArea,
  AppFormRadioField,
} from "../../../../AppComponents/Forms";
import AppFormCheckboxField from "../../../../AppComponents/Forms/AppFormCheckboxField";
import AppFormDateAndTimePicker from "../../../../AppComponents/Forms/AppFormDateAndTimePicker";
import AppReactSelectField from "../../../../AppComponents/Forms/AppFormReactSelect";
import useDebounce from "../../../../Hooks/useDebounce";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppSelector, useAppDispatch } from "../../../../Store";
import { ContactsActions } from "../../../../Store/Slices/Contacts/contacts.action";
import { ContactsAddDropDown } from "../../../../Store/Slices/Contacts/contacts.selector";
import { Section } from "../AddContactStyles";
import styled from "styled-components";

const DynamicsInputFields: React.FC<any> = (props) => {
  const {
    lifeCycleStage,
    AddContactsFields,
    lifeCycleStatus,
    setLifeCycleStatus,
    ContactsLifeCycleFields,
    setLocalStore,
    localStore,
    setValid,
    valid,
    hideLabelSectionTitle,
    parentHeight,
  } = props;
  const { values, setFieldValue }: any = useFormikContext();
  const dropDownListData: any = useAppSelector(ContactsAddDropDown);
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const dependentFieldDataFromApi = [
    "multiselect",
    "dropdown",
    "autocomplete",
    "radio",
    "checkbox",
    "string",
  ];
  const [autoDemo, setAutoDemo] = useState(false); //remove after auto-complete type
  const [otherLostReason, setOtherLostReason] = useState(false);
  const [fieldData, setFieldData] = useState<any>({
    name: "",
    id: "",
    parent_id: "",
    is_dependant: "",
    state_region: "",
  });
  const [dependentField, setdependentField] = useState<any>(null);
  let Inputvalues: any = values;

  // auto complete handlechange
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 1000);
  const handleChange = (field: any, value: any) => {
    if (value?.length > 2) setSearchTerm({ Field: field, value: value });
  };
  useEffect(() => {
    if (searchTerm?.Field) {
      let field = searchTerm?.Field;
      if (field.is_dependant) {
        getObjectValue(field, searchTerm?.value);
      } else {
        setAutoDemo(true);
        setFieldData(searchTerm?.Field);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const getDropDown = (payload: any) => {
    dispatch(ContactsActions.getAddContactsDropDown(payload))
      .then((res: any) => {
        if (res?.payload?.data?.data?.options?.length === 1) {
          if (res?.payload?.data?.data?.type === "radio") {
            setFieldValue(
              res?.payload?.data?.data?.name,
              res?.payload?.data?.data?.options[0]?.id
            );
          }
          if (res?.payload?.data?.data?.type === "dropdown") {
            setFieldValue(
              res?.payload?.data?.data?.name,
              res?.payload?.data?.data?.options[0]
            );
          }
        }
        setAutoDemo(false);
      })
      .catch((error: any) => {
        showAlert("Field does not exist.", "warning");
      })
      .finally(() => { });
  };

  // get api call for drop-down list
  const getData = (type: string, parentId: string, selectedId: string) => {
    let prevent =
      (fieldData.type === "string" || fieldData.type === "autocomplete") &&
        (searchTerm?.value !== "" || selectedId !== "")
        ? true
        : false; // prevent api call  from onclick in dropdown for autocomplete
    let searchValue = searchTerm?.value || "";
    const payload = { parentId, selectedId, searchValue };
    getDropDown(payload);
  };

  // lifecycle stage
  useEffect(() => {
    if (
      (Object.keys(Inputvalues)?.length > 0 &&
        Inputvalues?.lifecycle_stage &&
        Inputvalues?.lifecycle_stage?.label) ||
      fieldData.name === "lifecycle_stage"
    ) {
      if (fieldData.name === "lifecycle_stage") {
        setFieldValue("lifecycle_stage_status", "");
        setFieldValue("lost_reason", "");
      }
      const getStatusDropDown = ContactsLifeCycleFields.find(
        (item: any) => item?.label === Inputvalues?.lifecycle_stage?.label
      );
      const statusDropDown =
        getStatusDropDown?.statuses?.map((el: any) => ({
          ...el,
          value: el.id,
        })) || [];
      setLifeCycleStatus(statusDropDown);
    }

    //for lost reason
    setValid((prev: any) => ({
      ...prev,
      lost_reason: Inputvalues?.lifecycle_stage_status?.type !== "closed_lost",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Inputvalues]);
  // store dipendent fild id to make enable **
  const getObjectValue = async (field: any, value: any) => {
    setdependentField(() => {
      const dependent = localStore.schemaName.find(
        (el: any) => el.dependant_field_id === field?.id
      );
      return dependent;
    });
    setFieldData(field);
    setAutoDemo(true);

    // // Skip further processing for specific fields
    // if (
    //   field?.name === "lifecycle_stage" ||
    //   field?.name === "lifecycle_stage_status"
    // ) {
    //   return;
    // }
    if (
      (typeof value === "string" && value.trim() !== "") ||
      Array.isArray(value) ||
      typeof value === "object" ||
      typeof value === "boolean"
    ) {
      const dependent = localStore.schemaName.find(
        (el: any) => el.dependant_field_id === field?.id
      );

      if (
        field &&
        dependent?.is_dependant &&
        !localStore.isEnabled?.includes(dependent?.id)
      ) {
        await setLocalStore((prevLocalStore: any) => ({
          ...prevLocalStore,
          isEnabled: [...prevLocalStore?.isEnabled, dependent?.id],
        }));
        dropDownEnalbe(field);
      }
    }
  };

  // calling api for dropdown **
  useEffect(() => {
    if (fieldData && fieldData?.name?.length && autoDemo) {
      setFieldValue(dependentField?.name, "");
      if (
        searchTerm?.Field?.name &&
        searchTerm?.value &&
        fieldData?.name === searchTerm?.Field?.name &&
        !dependentFieldDataFromApi?.includes(dependentField?.type)
      ) {
        // calling for auto complete********
        if (fieldData?.type === "autocomplete") {
          getCountyCityStateOption(fieldData?.id, searchTerm?.value);
        } else {
          getData(
            fieldData.name,
            fieldData?.id,
            Inputvalues[fieldData.name]?.id
          );
        }
      } else if (dependentFieldDataFromApi?.includes(dependentField?.type)) {
        if (!fieldData.is_dependant && dependentField?.is_dependant) {
          getData(
            fieldData?.name,
            fieldData?.id,
            Inputvalues[fieldData.name]?.id || ""
          );
        } else {
          if (fieldData?.is_dependant) {
            getData(
              fieldData?.name,
              fieldData?.id,
              Inputvalues[fieldData.name]?.id || ""
            );
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldData, debouncedSearchTerm, autoDemo]);

  // check enable and disable field***
  const dropDownEnalbe = (field: any) => {
    // lifecycle stages changes
    // if (
    //   field?.name === "lifecycle_stage" ||
    //   field?.name === "lifecycle_stage_status"
    // ) {
    //   return false;
    // }
    if (field?.is_dependant) {
      if (localStore?.isEnabled?.includes(field?.id)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  // validation enable disable for required field***
  const ValidationSchema = () => {
    // setValid({ lost_reason: valid?.lost_reason });
    if (
      fieldData &&
      dependentField?.name &&
      dropDownListData?.options?.length === 0
    ) {
      setValid((prev: any) => ({
        ...prev,
        [`${dependentField?.name}`]: !(dropDownListData?.options?.length === 0),
      }));
    }
  };
  // set dropDown data for dependent field and auto-complete in field *****
  useEffect(() => {
    ValidationSchema();
    if (fieldData && fieldData?.name && typeof dropDownListData !== "string") {
      if (dependentField && dependentField?.name) {
        if (dependentField?.type === "checkbox") {
          // setValus in check box
          return setFieldValue(dependentField?.name, dropDownListData);
        } else {
          return setLocalStore({
            ...localStore,
            [`${dependentField?.name}`]: dropDownListData,
          });
        }
      } else if (fieldData?.type === "autocomplete") {
        return setLocalStore({
          ...localStore,
          [`${fieldData?.name}`]: dropDownListData?.options,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownListData]);
  // for lifecycle statges
  const DropDownOptions = (name: string, options: any, field?: any) => {
    if (options?.length > 0) {
      if (field?.type === "multiselect" && values[name]?.length > 0) {
        var uniqueResultTwo = options.filter(function (obj: any) {
          return !values[name].some(function (obj2: any) {
            return obj.value == obj2.value;
          });
        });
        return uniqueResultTwo;
      }

      return options;
    }
    if (name === "lifecycle_stage") {
      return lifeCycleStage;
    } else if (name === "lifecycle_stage_status") {
      return lifeCycleStatus;
    } else if (localStore && localStore[name]) {
      if (typeof localStore[name] === "object" && !localStore[name].length) {
        if (
          Array.isArray(localStore[name]?.options) &&
          localStore[name]?.options?.length > 0
        ) {
          return localStore[name]?.options;
        }
      } else {
        return localStore[name];
      }
    }
  };

  // for country-code change
  const CountryCode = (field: string) => {
    if (field === "mobile_phone_number") {
      const forMobile = getIn(values, "mobile_phone_number");
      const countryCode = getIn(
        values,
        "mobileCountryFormatmobile_phone_number"
      )?.dialCode;
      if ((forMobile && forMobile?.length - countryCode?.length) > 0) {
        return getIn(values, "mobileCountryFormatmobile_phone_number")
          ?.dialCode;
      } else {
        return getIn(values, "country_region")?.value?.toLowerCase();
      }
    }
  };

  const getCountyCityStateOption = (parentId: any, value: any) => {
    if (searchTerm) {
      let searchValue = searchTerm?.value || "";
      // let fieldId = field?.id
      const payload = { parentId, searchValue };
      if (payload) {
        dispatch(ContactsActions.getAddContactsDropDown(payload))
          .then((res: any) => {
            setLocalStore({
              ...localStore,
              [`${res?.payload?.data?.data?.name}`]:
                res?.payload?.data?.data?.options,
            });
          })
          .catch((error: any) => {
            showAlert("Field does not exist.", "warning");
          })
          .finally(() => { });
      }
    }
  };

  useEffect(() => {
    if (values?.lost_reason?.label === "Other" || values?.lost_reason?.value === "Other") {
      setOtherLostReason(true);
    } else {
      setOtherLostReason(false);
      setFieldValue("other_lost_reason", "")
    }
  }, [values]);

  const Fildes = (key: any, field: any) => {
    return (
      <React.Fragment key={key}>
        {(field?.type === "dropdown" || field?.type === "multiselect") &&
          DropDownOptions(field?.name, field?.options)?.length !== 0 ? (
          !dropDownEnalbe(field) && (
            <Grid item xs={12} sm={12} md={12}>
              {!(field?.name === "lost_reason" && valid?.lost_reason) && (
                <AppReactSelectField
                  menuPosition="fixed"
                  filterOption={createFilter({ ignoreAccents: false })}
                  field={field}
                  label={field?.label}
                  options={
                    DropDownOptions(field?.name, field?.options, field) || []
                  }
                  valueKey={field?.value}
                  name={field?.name}
                  placeholder={`Select ${field?.label}`}
                  isMulti={field?.type === "multiselect" ? true : false}
                  isDisabled={dropDownEnalbe(field)}
                  requiredSign={field?.required}
                  onChangeCallbackFn={(value: any) => {
                    getObjectValue(field, value);
                  }}
                  tooltip={field?.tooltip}
                />
              )}
            </Grid>
          )
        ) : field?.type === "autocomplete" ? (
          !dropDownEnalbe(field) && (
            <Grid item xs={12} sm={12} md={12}>
              <AppReactSelectField
                menuPosition="fixed"
                filterOption={createFilter({ ignoreAccents: false })}
                field={field}
                label={field?.label}
                defaultInputValue={values?.[field?.name]?.label || ""}
                defaultValue={values?.[field?.name] || ""}
                options={
                  (localStore[field.name]?.length
                    ? localStore[field.name]
                    : localStore[field.name]?.options) || []
                }
                valueKey={field?.value}
                name={field?.name}
                placeholder={`Select ${field?.label}`}
                isDisabled={dropDownEnalbe(field)}
                isClearable={true}
                requiredSign={field?.required}
                onInputChange={(value: any) => {
                  handleChange(field, value);
                }}
                onChangeCallbackFn={(value: any) => {
                  setSearchTerm(null);
                  getObjectValue(field, value);
                }}
                tooltip={field?.tooltip}
                isLoading={
                  field?.id === fieldData?.id && !Inputvalues[field?.name]
                }
              />
            </Grid>
          )
        ) : field?.type === "phone" ? (
          <Grid item xs={12} sm={12} md={12}>
            <AppFormMobileField
              label={field?.label}
              name={field?.name}
              placeholder="+1919991234567"
              mobileCountryFormat={`mobileCountryFormat${field?.name}`}
              defaultCountry={CountryCode(field?.name)}
              requiredSign={field?.required || field?.original_name === "mobile_phone_number" ? true : false}
              tooltip={field?.tooltip}
              onChangeCallbackFn={(name: any, value: any) => {
                getObjectValue(field, value);
              }}
            />
          </Grid>
        ) : field?.type === "landline" ? (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ display: "flex", gap: "10px" }}
          >
            <Grid item xs={3} sm={3} md={3}>
              <AppFormField
                label={"LandLine"}
                name={`landlineExtension${field?.name}`}
                placeholder={"Code"}
                type="number"
                requiredSign={field?.required}
                tooltip={field?.tooltip}
                onChangeCallbackFn={(value: any) => {
                  getObjectValue(field, value);
                }}
              />
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <AppFormField
                divStyle={{ marginTop: "1.3rem" }}
                label={""}
                name={field?.name}
                placeholder={field?.label}
                type="number"
                requiredSign={field?.required}
                tooltip={field?.tooltip}
                onChangeCallbackFn={(value: any) => {
                  getObjectValue(field, value);
                }}
              />
            </Grid>
          </Grid>
        ) : field?.type === "checkbox" ? (
          <Grid item xs={12} sm={12} md={12}>
            <AppFormCheckboxField
              name={field?.name}
              label={field?.label}
              requiredSign={field?.required}
              tooltip={field?.tooltip}
              onChange={(value: any) => {
                getObjectValue(field, value?.traget?.checked);
              }}
            />
          </Grid>
        ) : field?.type === "textarea" ? (
          <Grid item xs={12} sm={12} md={12}>
            <AppFormTextArea
              divStyle={{ marginTop: "1.3rem" }}
              label={field?.label}
              name={field?.name}
              placeholder={field?.label}
              requiredSign={field?.required}
              tooltip={field?.tooltip}
            // onChangeCallbackFn={(value: any) => {
            //   getObjectValue(field, value);
            // }}
            />
          </Grid>
        ) : field?.type === "radio" &&
          DropDownOptions(field?.name, field?.options)?.length ? (
          <Grid item xs={12} sm={12} md={12}>
            <AppFormRadioField
              label={field?.label}
              name={field?.name}
              Options={DropDownOptions(field?.name, field?.options) || []}
              requiredSign={field?.required}
              sendOnlyValue={false}
              tooltip={field?.tooltip}
              onChangeCallbackFn={(value: any) => {
                getObjectValue(field, value);
              }}
            />
          </Grid>
        ) : field?.type === "datetime" ? (
          <Grid item xs={12} sm={12} md={12}>
            <AppFormDateAndTimePicker
              label={field?.label}
              name={field?.name}
              placeholder={field?.label}
              requiredSign={field?.required}
              tooltip={field?.tooltip}
            // onChangeCallbackFn={(value: any) => {
            //   getObjectValue(field, value);
            // }}
            />
          </Grid>
        ) : field?.type === "string" ||
          field?.type === "email" ||
          field?.type === "number" ? (
          !(
            field?.name === "other_lost_reason" &&
            valid?.lost_reason &&
            !otherLostReason
          ) && (
            <Grid item xs={12} sm={12} md={12}>
              <AppFormField
                label={field?.label}
                name={field?.name}
                placeholder={field?.label}
                requiredSign={field?.required || field?.type === "email" ? true : false}
                tooltip={field?.tooltip}
                type={field?.type === "number" ? "number" : "text"}
              />
            </Grid>
          )
        ) : otherLostReason && field?.type === "text" ? (
          <Grid item xs={12} sm={12} md={12}>
            <AppFormField
              label={field?.label}
              name={field?.name}
              placeholder={field?.label}
              requiredSign={field?.required}
              tooltip={field?.tooltip}
              type={field?.type === "number" ? "number" : "text"}
            />
          </Grid>
        ) : null}
      </React.Fragment>
    );
  };
  return (
    <Box>
      {AddContactsFields &&
        AddContactsFields?.map((section: any) => (
          <AddContactFieldWrapper key={`section-${section?.label}`}>
            {hideLabelSectionTitle ? null : (
              <Section style={{ marginBottom: "1rem", display: "block" }}>
                {section?.label}
              </Section>
            )}
            <Grid
              sx={{
                marginTop: "1rem",
                padding: "0",
                height: parentHeight || "auto",
                overflowY: "auto",
              }}
              container
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 12, sm: 12, md: 12 }}
            >
              {section?.fields.map((field: any) => {
                if (field?.type === "section") {
                  return (
                    <React.Fragment key={`nested-section-${field?.id}`}>
                      {hideLabelSectionTitle ? null : (
                        <Grid
                          sx={{ marginTop: "2rem", padding: "1rem" }}
                          container
                          spacing={{ xs: 2, md: 2 }}
                          columns={{ xs: 12, sm: 12, md: 12 }}
                        >
                          <Section>{field?.label}</Section>
                        </Grid>
                      )}
                      {field?.fields?.map((subField: any) =>
                        Fildes(subField?.id, subField)
                      )}
                    </React.Fragment>
                  );
                } else {
                  return Fildes(field?.id, field);
                }
              })}
            </Grid>
          </AddContactFieldWrapper>
        ))}
    </Box>
  );
};

export default DynamicsInputFields;

const AddContactFieldWrapper = styled.div`
  .MuiGrid-root.MuiGrid-grid-xs-12 {
    padding-top: 5px;
  }
  .MuiRadio-root {
    padding: 0 6px;
  }
`;
