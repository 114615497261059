import { Avatar, Checkbox, Divider, IconButton, debounce } from "@mui/material";
import Popover from "@mui/material/Popover";
import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import AppCustomTable from "../../AppComponents/AppCustomTable";
import AppDeleteItem from "../../AppComponents/AppDeleteItem";
import AppIcon from "../../AppComponents/AppIcon";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
} from "../../AppComponents/Forms";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";
import useDebounce from "../../Hooks/useDebounce";
import useModal from "../../Hooks/useModel/useModel";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { CreateMarketingListValidations } from "../../Models/ValidationSchema";
import { useAppDispatch, useAppSelector } from "../../Store";
import { MarketingListNetworkService } from "../../Store/Slices/MarketingList/MarketingListNetworkService";
import { marketingListActions } from "../../Store/Slices/MarketingList/marketingList.actions";
import { marketingListStore } from "../../Store/Slices/MarketingList/marketingList.selectors";
import { setMarketingListPageNumber } from "../../Store/Slices/MarketingList/marketingList.slice";
import { ReactComponent as SearchIcon } from "../../assets/images/Searchicon.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit.svg";
import {
  StyledAppInput
} from "../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import {
  AnsweredFaqList,
  AnsweredTable,
  ContactMarketingWrap,
} from "./ContactMarketingListStyle";
import ContactMarketingData from "./ContactMarketingNodata";
import CreateListModal from "./commonComponents/marketingListModals/CreateListModal";

interface Props extends LoaderComponentProps { }

// Define your Person type
type Person = {
  created_by: unknown;
  name: string;
  contact_count: any;
  created_time: string;
  modified_time: string;
  list_owner: string;
  id: string;
};

const ContactMarketingList: React.FC<Props> = ({ setLoading }) => {
  // hooks
  const { isShowing, toggle } = useModal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();

  // statet
  const [searchInput, setSearchInput] = useState<any>({
    marketing_list_search: "",
  });
  const [selectedRow, setSelectedRow] = useState([]);
  // const [dynamicsColumns, setDynamicsColumns] = useState<any>([]);
  const [deleteData, setDeleteData] = useState<any>(false);
  // select row
  const [selectAll, setSelectAll] = useState<boolean>(false);
  // source id for update modal
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<any>([]);
  // searchterm
  const debouncedSearchTerm = useDebounce<string>(searchInput, 800);

  
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    query: "",
    checkbox: [],
    checkboxAll: false,
  });


  // const
  const { allMarketingListData, allMarketingListDataCount, totalPages } =
    useAppSelector(marketingListStore);
  // modal toggle functions
  const handleCreateListClick = useCallback(() => {
    toggle();
  }, [toggle]);

  const handlClick = () => {
    // navigate(`/bot/${bot.bot_id}/contact-marketing-detail`);
  };

  const getMarketingTableData = () => {
    let temp = {
      search: localValues?.query,
      limit: localValues.itemsPerPage?.value,
      offset: localValues.currentPage,
    };
    setLoading(true);
    dispatch(marketingListActions.getAllMarketingListings(temp)).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (selectAll) {
      const allDocumentIds = allMarketingListData.map((item: any) => item.id);
      setSelectedDocumentIds(allDocumentIds);
    }
  }, [allMarketingListData]); // eslint-disable-line react-hooks/exhaustive-deps

  // all Select
  const handleCheckAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setSelectAll(event.target.checked);
    if (isChecked) {
      const allDocumentIds = allMarketingListData?.map((item: any) => item.id);
      setSelectedDocumentIds(allDocumentIds);
    } else {
      setSelectedDocumentIds([]);
    }
  };

  const handleSelectChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    documentId: any
  ) => {
    const isChecked = event.target.checked;
    let ids:any = []
    setSelectedDocumentIds((prevSelectedIds: any) => {
      let updatedSelectedIds = isChecked
        ? [...prevSelectedIds, documentId]
        : prevSelectedIds.filter((id: any) => id !== documentId);
      ids = updatedSelectedIds
      // Check if all individual checkboxes are selected
      const allDocumentsSelected =
        allMarketingListData?.every((item: any) =>
          updatedSelectedIds?.includes(item.documentId)
        ) && allMarketingListData.length > 0;

      // Update selectAll state based on the allDocumentsSelected flag
      setSelectAll(allDocumentsSelected);
     
      return updatedSelectedIds;
    });
    if(ids.length === allMarketingListData.length){
      setSelectAll(true);
    }else{
      setSelectAll(false);
    }
  };

  // avtar
  const AvtarName = (props: any) => {
    const { info, name } = props;
    return (
      <div
        className="avatar"
        style={{
          display: "flex",
          fontSize: "14px",
          gap: "1rem",
          alignItems: "center",
          fontWeight: "400",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(
            `/contacts/marketing/detail/${info?.cell?.row?.original?.id}`
          );
          dispatch(setMarketingListPageNumber(0));
        }}
      >
        <Avatar
          alt={name}
          sx={{ bgcolor: "#5B73E8" }}
          style={{ width: "25px", height: "25px", fontSize: "13px" }}
        >
          {name?.chatAt?.[0] || "G"}
        </Avatar>
        {name}
      </div>
    );
  };

  const formateDate = (d: any) => {
    return moment(d).format("MM/DD/YYYY HH:mm A");
  };

  // Table Header Columns
  const Columns: ColumnDef<Person>[] = [
    {
      id: "select",
      size: 50,
      maxSize: 50,
      accessorFn: (row) => row?.id,
      header: ({ table }) => (
        <Checkbox checked={selectAll} onChange={handleCheckAllChange} />
      ),
      cell: (info: any) => (
        <span>
          {info?.row?.original?.can_delete ? (
            <Checkbox
              checked={selectedDocumentIds.includes(info.getValue())}
              onChange={(event: any) =>
                handleSelectChanges(event, info.getValue())
              }
            />
          ) : null}
        </span>
      ),
    },
    {
      header: "List Name",
      accessorFn: (row) => row?.name,
      id: "name",
      cell: (info: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <AvtarName info={info} name={info.getValue()} />
            {info?.row?.original?.can_update ? (
              <EditPopOver
                rowId={info?.row?.original?.id}
                name={info.getValue()}
                callBack={getMarketingTableData}
              />
            ) : null}
          </div>
        );
      },
      size: 300,
      minSize: 300,
    },
    // {
    //   header: "Total Records",
    //   accessorFn: (row) => row?.contact_count?.total_contacts || 0,
    //   id: "total_contacts",
    //   cell: (info) => info.getValue(),
    //   size: 140,
    //   minSize: 140,
    // },
    // {
    //   header: "Unique Email ID",
    //   accessorFn: (row) => row?.contact_count?.unique_email_count || 0,
    //   id: "unique_email_count",
    //   cell: (info) => info.getValue(),
    //   size: 160,
    //   minSize: 160,
    // },
    // {
    //   header: "Unique Mobile no.",
    //   accessorFn: (row) => row?.contact_count?.unique_mobile_count || 0,
    //   id: "unique_mobile_count",
    //   cell: (info) => info.getValue(),
    //   size: 170,
    //   minSize: 170,
    // },
    {
      header: "Created On",
      accessorFn: (row) => row?.created_time || 0,
      id: "created_time",
      cell: (info) => formateDate(info.getValue()),
      size: 200,
      minSize: 200,
    },
    {
      header: "Updated on",
      accessorFn: (row) => row?.modified_time || 0,
      id: "modified_time",
      cell: (info) => formateDate(info.getValue()),
      size: 200,
      minSize: 200,
    },
    {
      header: "Created By",
      accessorFn: (row) => row?.created_by,
      id: "created_by",
      cell: (info) => info.getValue(),
      size: 150,
      minSize: 150,
    },
  ];

  const handleDeleteBtn = (item: any) => {
    let payLoad: any = {
      selectAll: selectAll,
      marketingListId_list: selectedDocumentIds,
    };
    dispatch(marketingListActions.deleteMarketingListById(payLoad))
      .then((res: any) => {
        if (res?.payload?.data.status === true) {
          setDeleteData(false);
          showAlert(res?.payload?.data?.msg, "success");
          setSelectedDocumentIds([]);
          setSearchInput({ marketing_list_search: "" });
          setLocalValues({
            itemsPerPage: { label: "50  per page", value: 50 },
            currentPage: 0,
            query: "",
            checkbox: [],
            checkboxAll: false,
          });
        } else {
          showAlert(
            res?.payload?.data.error || "Something went wrong",
            "error"
          );
        }
      })
      .catch((err: any) => {
        showAlert("Something went wrong", "error");
      });
  };

  // Handle Pagination Functions
  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  };
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };
  // render Effect on pagination
  useEffect(() => {
    getMarketingTableData();
  }, [localValues]);

  // Hadnling Search Functionality
  const handleSelectSearch = debounce((e?: any) => {
    setLocalValues({
      ...localValues,
      query: e.target.value,
      currentPage: 0,
    });
  }, 700);

  let subTitle = `"Are you sure, you want to delete ${localValues.checkboxAll ? "All record" : selectedDocumentIds.length
    } item ?"`;

  return (
    <ContactMarketingWrap>
      <div className="topmenu">
        <div
          className="menu-left"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2rem",
          }}
        >
          Marketing List
          {selectedDocumentIds.length > 0 ? (
            <ProtectedAppButton
              moduleId="remove_contacts_from_list"
              onClick={() => setDeleteData(true)}
              variant="danger-filled"
            >
              Delete{" "}
              <AppIcon
                title={"ri-delete-bin-6-line"}
                size="15px"
                color="white"
                hoverColor="#F34E4E"
              />
            </ProtectedAppButton>
          ) : null}
        </div>
        <div className="menu-right">
          <div className="faq-search" style={{ marginRight: "1rem" }}>
            <StyledAppInput>
              <input
                type="text"
                onChange={(e: any) => handleSelectSearch(e)}
                name="query"
                id="query"
                placeholder="Search By Name"
              />
              <SearchIcon />
            </StyledAppInput>
          </div>
          <div className="button">
            <ProtectedAppButton
              moduleId="marketing_list_crud"
              specificPermissionKey="create"
              style={{ width: "153px" }}
              onClick={() => handleCreateListClick()}
            >
              {/* <AppMaterialIcons iconName="add" /> */}
              Create New List
            </ProtectedAppButton>
          </div>
        </div>
      </div>
      <div className="data-container">
        {!allMarketingListData || allMarketingListData?.length === 0 ? (
          <ContactMarketingData
            toggleModalCreate={handleCreateListClick}
            handlClick={handlClick}
            isShowing={isShowing}
            setLocalValues={setLocalValues}
            localValues={localValues}
          />
        ) : (
          <React.Fragment>
            <AppCustomTable
              data={allMarketingListData}
              dynamicsColumns={Columns}
              // setSelectedRow={setSelectedRow}
              removeContactDetailsLink={true}
              setSelectedRow={setSelectedDocumentIds}
              selectedRow={selectedDocumentIds}
            />
            {isShowing && (
              <AnsweredTable>
                <AnsweredFaqList>
                  <CreateListModal
                    toggleModalCreate={handleCreateListClick}
                    handlClick={handlClick}
                    isShowing={isShowing}
                  />
                </AnsweredFaqList>
              </AnsweredTable>
            )}
          </React.Fragment>
        )}
      </div>
      {/* pagination  */}
      {allMarketingListData && allMarketingListData?.length ? (
        <AppPaginationComponent
          totalCount={allMarketingListDataCount}
          handlePageChange={handlePageChange}
          currentPage={localValues?.currentPage}
          totalPages={totalPages}
          rowsPerPage={localValues?.itemsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
      {/* delete modal */}
      <AppDeleteItem
        title="Delete Marketing list"
        subTitle={subTitle}
        onDelete={() => handleDeleteBtn(deleteData)}
        setDeleteData={setDeleteData}
        deleteData={deleteData}
      />
    </ContactMarketingWrap>
  );
};
export default withLoader(ContactMarketingList);

function EditPopOver(props: any) {
  // hooks
  const { showAlert } = useNotification();
  // props
  const { rowId, name, callBack } = props;
  // states
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [userInput, setUserInput] = useState<any>({
    name: name,
  });
  // contstant values
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // handle open
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // close func
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSubmitButton = (values: any, submitProps: any) => {
    MarketingListNetworkService.updateNewMarketingListData(values, rowId)
      .then((res: any) => {
        if (res?.data.status === true) {
          handleClose();
          showAlert(res.data.msg, "success");
          callBack();
        } else {
          showAlert(
            res?.payload?.data.error || "Something went wrong",
            "error"
          );
        }
      })
      .catch((err: any) => {
        showAlert(err?.response?.data.error || "Something went wrong", "error");
        submitProps.resetForm();
      })
      .finally(() => {
        submitProps.setSubmitting(false);
      });
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <EditIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            onSubmitButton(values, submitProps);
          }}
          validationSchema={CreateMarketingListValidations}
          divStyle={{ width: "100%" }}
        >
          <div className="detailSMStableAction">
            <AppFormField
              divStyle={{
                padding: "1rem",
              }}
              label="Rename list name"
              type="text"
              name={"name"}
              placeholder="Enter List Name"
              errorMessage=""
            />
            <Divider style={{ margin: "10px 0px" }} />
            <div
              className="right"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "7px",
                margin: "0.5rem",
              }}
            >
              <AppButton
                divStyle={{ minWidth: "120px" }}
                variant="outline"
                onClick={() => handleClose()}
                download
              >
                Cancel
              </AppButton>
              <AppSubmitButton title="save" />
            </div>
          </div>
        </AppForm>
      </Popover>
    </div>
  );
}
