import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Paper,
  Popover,
  Select,
  Typography,
  debounce,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { format } from "date-fns";
import { uniqueId } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppPagination } from "../../Components/BotBuilder/BotKnowledge/BotKnowledgeStyles";
import { TableTitleHeader } from "../../Components/Broadcast/WhatsApp/BroadcastHistory/HistoryStyles";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { DefinedCustomDates } from "../../Models/defaultSelectOptions";
import { useAppDispatch } from "../../Store";
import { marketingListActions } from "../../Store/Slices/MarketingList/marketingList.actions";
import TemplateAPIERROR from "../../assets/images/No_records_found.svg";
import AppButton from "../AppButton";
import { AppMaterialIcons } from "../AppMaterialIcons";
import { LoaderComponentProps, withLoader } from "../Loader";
import AppForm from "./AppForm";
import AppFormSearchField from "./AppFormSearchField";
import {
  ErrorComponent,
  MainContainer,
  NewNativeSelect,
  TemplateTable,
} from "./Styles";
import { ProtectedAppButton } from "../ProtectedComponents";
import AppPaginationComponent from "../Pagination/Pagination";
import RefreshIcon from '@material-ui/icons/Refresh';
import { broadcastActions } from "../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import NoRecordDefaultImage from "../../assets/images/noDatafound.svg";
import AppSkeletonTableLoader from "../../Components/UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import { theme } from "../../Customization/Theme";
import styled from "styled-components";
import NoRecordsFound from "../../Components/UiComponents/NoRecordsFound/NoRecordsFound";
import useModal from "../../Hooks/useModel/useModel";
import AppModel from "../../Hooks/useModel/AppModel";
// interface Props extends LoaderComponentProps {
//   type: any;
//   header: any;
//   keyList: any;
//   height: any;
//   customStyle?: any;
// }

interface Props {
  type: any;
  header: any;
  keyList: any;
  height: any;
  customStyle?: any;
  skeltonLoaderHeight?: any;
}

const AppMarketingHistory: React.FC<Props> = ({
  type,
  header,
  keyList,
  // setLoading,
  height,
  customStyle,
  skeltonLoaderHeight
}) => {
  const formikRef = useRef(null);
  const dispatch = useAppDispatch();
  const Navigate = useNavigate();
  const { showAlert } = useNotification();
  const [errorApi, setErrorApi] = useState(false);
  const [getBroadcastData, setBroadcastData] = useState(false);
  const [list, setList] = useState<any>([]);
  const [pagination, setPagination] = useState<any>();
  const [onSearch, setOnSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Modals
  const TemplateModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingTemplateModal: isShowing, toggleTemplateModal: toggle };
  };
  const { isShowingTemplateModal, toggleTemplateModal } = TemplateModal();
  const has_low_balance = localStorage.getItem("has_low_balance")
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    startDate: DefinedCustomDates?.last30Days?.startDate,
    endDate: DefinedCustomDates?.last30Days?.endDate,
    search: "",
    itemsPerPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    totalPages: 0,
  });
  const getBroadCastData = () => {
    setLoading(true);
    dispatch(
      marketingListActions.getAllBroadcastData({
        start: format(localValues.startDate, "yyyy-MM-dd"),
        limit: localValues.itemsPerPage?.value,
        offset: onSearch ? 0 : localValues.currentPage,
        end: format(localValues.endDate, "yyyy-MM-dd"),
        status: localValues?.status,
        search: localValues?.search,
        broadcast_type: type,
      })
    )
      .then((res: any) => {
        setList(res?.payload?.data?.results);
        setPagination(res?.payload?.data?.pagination);
        setOnSearch(false);
        setBroadcastData(false);
        setErrorApi(false);
        setLoading(false);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
        setOnSearch(false);
        setBroadcastData(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (getBroadcastData) {
      getBroadCastData();
      setBroadcastData(false);
    }
  }, [getBroadcastData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getBroadCastData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
    setBroadcastData(true);
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
    setBroadcastData(true);
  };
  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
    });
    setBroadcastData(true);
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
      currentPage: 0,
    });
    setBroadcastData(true);
    setOnSearch(true);
  }, 700);
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
    setBroadcastData(true);
  };
  const clearAllCallback = () => {
    setLocalValues({
      ...localValues,

      status: "",
      search: "",
    });
    setBroadcastData(true);
  };

  const handleButtonClick = (formikRef: any) => {
    formikRef.current.handleReset();
  };
  const handleRefresh = (id: any) => {
    handleClose()
    dispatch(broadcastActions.getBroadcastStatsData(id))
      .then((res: any) => {
        showAlert(
          "Stats refresh successfully",
          "success"
        )
        getBroadCastData();
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  // Action Click for table
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow({})
  };


  const renderSelectIcon = localValues.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  return (
    <MainContainer style={{ padding: "0px" }}>
      <TemplateTable>
        <TableTitleHeader>
          <div className="left">
            <div className="title">
              <p style={{ fontSize: "13px" }}>Filter By</p>
            </div>
            <div className="">
              <AppForm
                initialValues={{ search: "" }}
                onSubmit={(values: any, submitProps: any) => { }}
                innerRef={formikRef}
                divStyle={{ width: "100%" }}
              >
                <Grid
                  container
                  spacing={1}
                  display="flex"
                  sx={{ m: 0.2, alignItems: "center" }}
                >
                  <Grid
                    item
                    alignItems="center"
                    sx={{ paddingTop: "0 !important", }}
                  >
                    <NewNativeSelect>
                      <Select
                        defaultValue={undefined}
                        name="status"
                        value={localValues?.status}
                        onChange={(e) => handleSelectChange(e, "status")}
                        displayEmpty
                        className="new-select-main"
                        style={{
                          height: "28px"
                        }}
                        placeholder="Hello"
                      >
                        <MenuItem value="" disabled>
                          Select Status
                        </MenuItem>
                        <MenuItem value="processing">Processing</MenuItem>
                        <MenuItem value="running">Running</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                      </Select>
                      <div
                        className="clear-icon"
                        onClick={(e: any) => clearCallback(e, "status")}
                      >
                        {renderSelectIcon}
                      </div>
                    </NewNativeSelect>
                  </Grid>

                  <Grid
                    item
                    alignItems="center"
                    display="flex"
                    sx={{ padding: "1px !important" }}
                    gap="0.5rem"
                  >
                    <AppFormSearchField
                      name="search"
                      placeholder="Search"
                      defaultBorder={true}
                      divStyle={{
                        marginLeft: "10px",
                        minWidth: "220px",
                        marginBottom: "0",
                      }}
                      onChange={(e: any) => {
                        handleSelectSearch(e, "search");
                      }}
                      clearCallback={(e: any) => clearCallback(e, "search")}
                    />

                    {localValues.status && localValues.search && (
                      <AppButton
                        variant="outline"
                        onClick={() => {
                          clearAllCallback();
                          handleButtonClick(formikRef);
                        }}
                        sx={{ mb: "9px" }}
                      >
                        Reset
                      </AppButton>
                    )}
                  </Grid>
                </Grid>
              </AppForm>
            </div>
          </div>
        </TableTitleHeader>

        {
          loading ? (
            <AppSkeletonTableLoader
              numberOfCol={4}
              numberofRow={10}
              skeletonHeight={30}
              skeletonWidth={"100%"}
              skeletonHeaderHeight={40}
              skeletonHeaderWidth={"100%"}
              tableHeight={`calc(100vh - ${skeltonLoaderHeight}px)`}
            />
          ) : (
            <>
              <Paper sx={{
                padding: "0px 8px 6px 8px",
                overflowX: "auto", boxShadow: "none", borderRadius: 0,
                borderLeft: `1px solid ${theme.palette.default.border}`,
                borderBottom: `1px solid ${theme.palette.default.border}`,
                borderRight: `1px solid ${theme.palette.default.border}`,
              }}>
                <Box
                  display="flex"
                  sx={{
                    padding: 0,
                    height: list?.length
                      ? `calc(100vh - ${height}px)`
                      : `calc(100vh - ${+height - 31}px)`,
                  }}
                  flexDirection="column"
                  style={customStyle}
                  className={`table-wrapper ${errorApi || list?.length === 0 ? "errorWrapper" : ""
                    }`}
                >
                  {/* Table Heading */}
                  <Box
                    display="flex"
                    sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
                    className="tabel-heading"
                  >
                    {header.map((key: any, index: number) => {
                      return (
                        <Box
                          className={`table-cell ${key !== "Name" && key !== "Sent On" ? "align-center" : ""
                            }`}
                          minWidth={
                            index === 0 ? "200px" : index === 1 ? "240px" : "127px"
                          }
                          width={
                            index === 0
                              ? "16%"
                              : index === 1
                                ? "20%"
                                : `calc(100% / ${header.length - 2})`
                          }
                          px={0.5}
                          py={0.5}
                        >
                          <Typography className="table-head-text">{key}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                  {errorApi ||
                    (list?.length === 0 ? (
                      <>
                        <NoRecordsFound subText="Get started by creating a broadcast first.">
                          <ProtectedAppButton
                            moduleId={`${type}_broadcast_crud`}
                            specificPermissionKey="create"
                            variant="primarydark"
                            onClick={() =>
                              Navigate(`/broadcast/${type}/create-broadcast/1`)
                            }
                            isDisabled={has_low_balance === "true"}
                            title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
                          >
                            Create Broadcast
                            <AppMaterialIcons iconName="Add" />
                          </ProtectedAppButton>
                        </NoRecordsFound>
                      </>
                    ) : (
                      <Box className={`table-content`}>
                        {list?.map((currentItem: any, index: number) => {
                          const date = moment(currentItem?.[keyList?.[1]]).format(
                            " DD MMM , YYYY HH:mm A "
                          );
                          return (
                            <Box
                              display="flex"
                              className="table-data__row"
                              key={index}
                            >
                              <Box
                                minWidth="200px"
                                width="16%"
                                px={0.5}
                                py={0.5}
                                display="flex"
                                sx={{ alignItems: "center" }}
                                className="table-cell"
                              >
                                <Typography className="table-text">
                                  {currentItem?.status === "completed" ? (
                                    //DISABLE CLICK FOR WHATSFOR FORNOW DETAILS NOT AVAILABLE
                                    <Link
                                      to={
                                        type === "whatsapp"
                                          ? `/broadcast/whatsapp/broadcast-details/${currentItem?.id}`
                                          : `/broadcast/${type}/broadcast-details/${currentItem?.id}/overview`
                                      }
                                      className="text-overlay"
                                      title={currentItem?.[keyList?.[0]]}
                                      style={{
                                        textDecoration: "none",
                                        maxWidth: "160px",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {currentItem?.[keyList?.[0]]}
                                    </Link>
                                  ) : (
                                    currentItem?.[keyList?.[0]]
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                minWidth="240px"
                                width="20%"
                                px={0.5}
                                py={0.5}
                                display="flex"
                                sx={{ alignItems: "center" }}
                                className="table-cell"
                              >
                                <Typography
                                  title={date}
                                  sx={{
                                    textDecoration: "none",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }} className="table-text">{date}</Typography>
                              </Box>

                              <Box
                                minWidth="127px"
                                width={`calc(100% / ${header.length - 2})`}
                                px={0.5}
                                py={0.5}
                                display="flex"
                                sx={{ alignItems: "center", justifyContent: "center" }}
                                className=" table-cell"
                              >
                                <Typography sx={{
                                  textDecoration: "none",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  textAlign: "center",
                                }} title={currentItem?.[keyList?.[2]]}
                                  className="table-text ">
                                  {currentItem?.[keyList?.[2]]}
                                </Typography>
                              </Box>

                              <Box
                                minWidth="127px"
                                width={`calc(100% / ${header.length - 2})`}
                                px={0.5}
                                py={0.5}
                                display="flex"
                                sx={{ alignItems: "center", justifyContent: "center" }}
                                className="table-cell"

                              >
                                <StatusTypographyChip
                                  status={currentItem?.[keyList?.[3]]}
                                  title={currentItem?.[keyList?.[3]]}
                                  className="table-text text-capitalize completed">
                                  {currentItem?.[keyList?.[3]]}
                                </StatusTypographyChip>
                              </Box>

                              <Box
                                minWidth="127px"
                                width={`calc(100% / ${header.length - 2})`}
                                px={0.5}
                                py={0.5}
                                display="flex"
                                sx={{ alignItems: "center", justifyContent: "center" }}
                                className="table-cell"
                              >
                                <Typography
                                  sx={{
                                    textDecoration: "none",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                  }}
                                  title={currentItem?.[keyList?.[4]] || "0"}
                                  className="table-text"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {" "}
                                  {currentItem?.[keyList?.[4]] || "0"}
                                </Typography>
                              </Box>

                              <Box
                                minWidth="127px"
                                width={`calc(100% / ${header.length - 2})`}
                                px={0.5}
                                py={0.5}
                                display="flex"
                                sx={{ alignItems: "center", justifyContent: "center" }}
                                className="table-cell"
                              >
                                <Typography
                                  title={currentItem?.[keyList?.[5]] || "0"}
                                  sx={{
                                    textDecoration: "none",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                  }} className="table-text">
                                  {" "}
                                  {currentItem?.[keyList?.[5]] || "0"}
                                </Typography>
                              </Box>

                              <Box
                                minWidth="127px"
                                width={`calc(100% / ${header.length - 2})`}
                                px={0.5}
                                py={0.5}
                                display="flex"
                                sx={{ alignItems: "center", justifyContent: "center" }}
                                className="table-cell"
                              >
                                <Typography
                                  title={currentItem?.[keyList?.[6]] || "0"}
                                  sx={{
                                    textDecoration: "none",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                  }}
                                  className="table-text">
                                  {" "}
                                  {currentItem?.[keyList?.[6]] || "0"}
                                </Typography>
                              </Box>

                              {type === "whatsapp" || type === "email" ? (
                                <Box
                                  minWidth="127px"
                                  width={`calc(100% / ${header.length - 2})`}
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{ alignItems: "center", justifyContent: "center" }}
                                  className="table-cell"
                                >
                                  <Typography title={currentItem?.[keyList?.[7]] || "0"}
                                    sx={{
                                      textDecoration: "none",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      textAlign: "center",
                                    }} className="table-text r">
                                    {currentItem?.[keyList?.[7]] || "0"}
                                  </Typography>
                                </Box>
                              ) : null}
                              {type === "email" ? (
                                <Box
                                  minWidth="127px"
                                  width={`calc(100% / ${header.length - 2})`}
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{ alignItems: "center", justifyContent: "center" }}
                                  className="table-cell"
                                >
                                  <Typography title={currentItem?.[keyList?.[8]] || "0"}
                                    sx={{
                                      textDecoration: "none",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      textAlign: "center",
                                    }} className="table-text r">
                                    {currentItem?.[keyList?.[8]] || "0"}
                                  </Typography>
                                </Box>
                              ) : null}
                              {type === "email" ? (
                                <Box
                                  minWidth="127px"
                                  width={`calc(100% / ${header.length - 2})`}
                                  px={0.5}
                                  py={0.5}
                                  display="flex"
                                  sx={{ alignItems: "center" }}
                                  className="align-center table-cell"
                                >
                                  <Typography className="table-text r">
                                    {" "}
                                    {currentItem?.[keyList?.[9]] || "0"}
                                  </Typography>
                                </Box>
                              ) : null}
                              {/* <Box
                                minWidth="127px"
                                width={`calc(100% / ${header.length - 2})`}
                                px={0.5}
                                py={0.5}
                                display="flex"
                                sx={{ alignItems: "center" }}
                                className="align-center table-cell"
                              >
                                <Typography className="table-text r">
                                  <a onClick={() => handleRefresh(currentItem?.id)}> <RefreshIcon style={{
                                    width: "17px",
                                    margin: "-2px 3px",
                                    cursor: "pointer"
                                  }} />  </a>
                                </Typography>
                              </Box> */}
                              <Box
                                minWidth="127px"
                                width={`calc(100% / ${header.length - 2})`}
                                px={0.5}
                                py={0.5}
                                display="flex"
                                sx={{
                                  textAlign: "center",
                                  gap: "1rem",
                                  justifyContent: "center",
                                }}
                                className="table-cell"
                              >
                                <IconButton
                                  onClick={(e) => handleClick(e, currentItem)}
                                  aria-describedby={id}

                                >
                                  <AppMaterialIcons iconName="MoreVert" />
                                </IconButton>

                                <Popover
                                  id={id}
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                  sx={{
                                    width: "100%",
                                    "& .MuiPaper-root": {
                                      borderRadius: 1,
                                      boxShadow: "none !important",
                                      border: "1px solid #d2d2d2"
                                    },
                                  }}
                                >
                                  <Box
                                    sx={{ width: "100%", maxWidth: 160, minWidth: 160 }}
                                  >
                                    <nav aria-label="secondary mailbox folders">
                                      <List
                                        disablePadding
                                        sx={{
                                          color: `${theme.palette.default.darkGrey}`,
                                        }}
                                      >
                                        <ListItem disablePadding>
                                          <ListItemButton
                                            sx={{ p: "0 2" }}                                         >
                                            {/* <LinkIcon /> */}
                                            <Typography sx={{ ml: 1 }} variant="body2">

                                              {type === "email" ? (
                                                <Link
                                                  to={`/broadcast/${type}/broadcast-details/${selectedRow?.id}/overview`}
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                >
                                                  Overview
                                                  {/* <LeaderboardOutlinedIcon fontSize="small" /> */}
                                                </Link>
                                              ) : type === "sms" ? (
                                                <Link
                                                  to={`/broadcast/${type}/broadcast-details/${selectedRow?.id}/overview`}
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                >
                                                  Overview
                                                  {/* <LeaderboardOutlinedIcon fontSize="small" /> */}
                                                </Link>
                                              ) : (
                                                <Link
                                                  to={`/broadcast/${type}/broadcast-details/${selectedRow?.id}`}
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                >
                                                  Overview
                                                </Link>
                                              )}


                                            </Typography>
                                          </ListItemButton>
                                        </ListItem>
                                        <Divider />

                                        <ListItem
                                          disablePadding
                                          onClick={() => handleRefresh(selectedRow?.id)}
                                        >
                                          <ListItemButton>
                                            {/* <ReatainIcon /> */}
                                            <Typography sx={{ ml: 1, color: theme.palette.default.black }} variant="body2">
                                              Refresh
                                            </Typography>
                                          </ListItemButton>
                                        </ListItem>
                                        {
                                          type === "email" && <>
                                            <Divider />
                                            <ListItem disablePadding>
                                              <ListItemButton
                                                onClick={() => toggleTemplateModal()}
                                              >
                                                {/* <DeleteIcon /> */}
                                                <Typography sx={{ ml: 1, color: theme.palette.default.black }} variant="body2">
                                                  Template Preview
                                                </Typography>
                                              </ListItemButton>
                                            </ListItem>
                                          </>
                                        }

                                      </List>
                                    </nav>
                                  </Box>
                                </Popover>
                                {/* <IconButton>
                                  {type === "email" ? (
                                    <Link
                                      to={`/broadcast/${type}/broadcast-details/${currentItem?.id}/overview`}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      <LeaderboardOutlinedIcon fontSize="small" />
                                    </Link>
                                  ) : type === "sms" ? (
                                    <Link
                                      to={`/broadcast/${type}/broadcast-details/${currentItem?.id}/overview`}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      <LeaderboardOutlinedIcon fontSize="small" />
                                    </Link>
                                  ) : (
                                    // <Link
                                    //   to={`/`}
                                    //   style={{
                                    //     textDecoration: "none",
                                    //     color: "black",
                                    //   }}
                                    // >
                                    //   <LeaderboardOutlinedIcon fontSize="small" />
                                    // </Link>
                                    <Link
                                      to={`/broadcast/${type}/broadcast-details/${currentItem?.id}`}
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      <LeaderboardOutlinedIcon fontSize="small" />
                                    </Link>
                                  )}
                                </IconButton> */}
                              </Box>

                            </Box>
                          );
                        })}
                      </Box>
                    ))}
                </Box>
              </Paper>
              {list?.length ? (
                <AppPaginationComponent
                  totalCount={pagination?.totalRecords}
                  handlePageChange={handlePageChange}
                  currentPage={localValues?.currentPage}
                  totalPages={pagination?.totalPages}
                  rowsPerPage={localValues?.itemsPerPage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  title="Contacts"
                />
              ) : null}
            </>
          )
        }

        {/* Template Modal */}
        <AppModel
          isShowing={isShowingTemplateModal}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              boxShadow: "none",
              width: "700px",
            },
          }}
        >
          <TemplateDisplaywrap>
            <div className="crawl-popup">
              <div className="crawl-heading">
                <p>{selectedRow?.broadcast_name}</p>
              </div>
              <div className="close-icon">
                <IconButton
                  onClick={() => {
                    toggleTemplateModal();
                    handleClose()
                  }}
                >
                  <AppMaterialIcons iconName="Close" />
                  {/* <FailedIcon /> */}
                </IconButton>
              </div>
            </div>

            <div className="upload-file">
              <iframe
                title="HTML Preview"
                srcDoc={JSON.parse(selectedRow?.updated_html || `""`) || {}}
                className="iframe"
                style={{
                  width: "100%",
                  height: "calc(100vh - 281px)",
                  border: "1px solid #ccc",
                  // padding: "5px",
                  margin: "0 auto",
                  display: "block",
                }}
              />
            </div>
            <div className="popup-buttons">
              <AppButton
                onClick={() => {
                  toggleTemplateModal();
                  handleClose()
                }}
                variant="grey"
              >
                Cancel
              </AppButton>
            </div>
          </TemplateDisplaywrap>

        </AppModel>


      </TemplateTable>
    </MainContainer>
  );
};

// export default withLoader(AppMarketingHistory);
export default AppMarketingHistory;

const StatusTypographyChip = styled(Typography)`
&& {
  min-width: 100%;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  padding: 2px 8px;
  border-radius: 4px;
  
  ${(props: any) => {
    switch (props.status) {
      case 'completed':
        return `
            border: 1px solid ${theme.palette.default.successNew};
            background-color: ${theme.palette.default.successNewBackground};
            color: ${theme.palette.default.successNew};
          `;
      case 'running':
        return `
            border: 1px solid ${theme.palette.default.error};
            background-color: #FFEDED;
            color: ${theme.palette.default.error};
          `;
      case 'processing':
        return `
            border: 1px solid ${theme.palette.default.paleGold};
            background-color: ${theme.palette.default.EarlyDawn};
            color: #918548;
          `;
      default:
        return `
            border: none;
            background-color: none;
          `;
    }
  }}

}
`
// running processing

export const TemplateDisplaywrap = styled.div`
  .crawl-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.default.grey};
    align-items: center;
    padding: 22px 16px;

    p {
      color: #323232;
      font-weight: 400;
      font-style: inter;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .header {
    margin-bottom: 55px;
  }

  h3 {
    line-height: 30px;
    font-weight: 500;
    font-style: inter;
    font-size: 20px;
    color: #000000;
  }
  .paragraph {
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: #f5f7ff;
    border: 1px solid #bfcaff;
    padding: 10px;
    margin-top: 16px;
    p {
      font-family: inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .input-field {
    margin-top: 1rem;
  }
  .input-field label {
    font-style: inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  .popup-buttons {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    border-top: 1px solid ${theme.palette.default.grey};
  }

  .upload-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    .header {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }
  }

  .upload-file p {
    font-family: inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
`;