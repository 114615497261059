import { applicationFormData } from "./dashboard.selectors";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DashboardActions } from "./dashboard.actions";
import { DefinedCustomDates } from "../../../Models/defaultSelectOptions";
//import { DefinedCustomDates } from "../../../Models/defaultSelectOptions";

interface initialStateTypes {
  data: {
    dashboardUsers: any;
    returning_leads: any;
    duration: any;
    conversation_status: any;
    agent_status: any;
    user_growth: any;
    summary_msg_dash: any;
    bot_conversation: any;
    response_time: any;
    channel_overview: any;
    conversational_traffic: any;
    summary_intents: any;
    overall: any;
    bot: any;
    agent: any;
    agent_conversation_status: any;
    bot_conversation_status: any;
    AgentBotID: any;
    AgentChannelName: any;
    loading: boolean;
    DateRange: any;
    agentDateRange: any;
    botDateRange: any;
    applicationFormData: any;
    applicationFormDataError: boolean;
    contactVsApplicationData: any;
    applicationPaymentFIlter: any;
    applicationPaymentFIlterCity: any;
    applicationDateFilter: any;
    applicationSubmissionFilter: any;
    applicationAnalyticsFilter: any;
    applicationFilter: any;
    applicationFilterOfflineOnline: any;
    applicationData: any;
    tableData: any;
  };
  BotId: string;
  Bottype: any;
  error: boolean;
  loading: boolean;
  loadingArray: Array<any>;
  matomo_details: any;
  project_id: any;
}

const initialState: initialStateTypes = {
  data: {
    dashboardUsers: {},
    returning_leads: {},
    duration: {},
    conversation_status: {},
    agent_status: {},
    user_growth: {},
    summary_msg_dash: {},
    bot_conversation: {},
    response_time: {},
    channel_overview: {},
    conversational_traffic: {},
    summary_intents: {},
    overall: [],
    bot: [],
    agent: [],
    agent_conversation_status: {},
    bot_conversation_status: {},
    AgentBotID: {},
    AgentChannelName: {},
    loading: false,
    DateRange: {
      startDate: DefinedCustomDates?.last7Days?.startDate,
      endDate: DefinedCustomDates?.last7Days?.endDate,
    },
    agentDateRange: {
      startDate: DefinedCustomDates?.last7Days?.startDate,
      endDate: DefinedCustomDates?.last7Days?.endDate,
    },
    botDateRange: {
      startDate: DefinedCustomDates?.last7Days?.startDate,
      endDate: DefinedCustomDates?.last7Days?.endDate,
    },
    applicationFormData: {},
    applicationFormDataError: false,
    contactVsApplicationData: {},
    applicationPaymentFIlter: {},
    applicationPaymentFIlterCity: {},
    applicationDateFilter: {},
    applicationSubmissionFilter: {},
    applicationAnalyticsFilter: {},
    applicationFilter: {},
    applicationFilterOfflineOnline: {},
    applicationData: {},
    tableData: {},
  },
  BotId: "",
  Bottype: null,
  error: false,
  loading: false,
  loadingArray: [],
  matomo_details: null,
  project_id: null,
};

const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState,
  reducers: {
    Overall: (state: any, action: PayloadAction<number>) => {
      state.data.overall = action.payload;
      return state;
    },
    Agent: (state: any, action: PayloadAction<number>) => {
      state.data.agent = action.payload;
      return state;
    },
    Bot: (state: any, action: PayloadAction<number>) => {
      state.data.bot = action.payload;
      return state;
    },
    AgentBotID: (state: any, action: PayloadAction<any>) => {
      state.data.AgentBotID = action.payload;
      return state;
    },
    AgentChannelName: (state: any, action: PayloadAction<any>) => {
      state.data.AgentChannelName = action.payload;
      return state;
    },
    BotID: (state: any, action: PayloadAction<any>) => {
      state.BotId = action.payload;
      return state;
    },
    BotType: (state: any, action: PayloadAction<any>) => {
      state.Bottype = action.payload;
      return state;
    },
    LoadingStatus: (state: any, action: PayloadAction<any>) => {
      state.data.loading = action.payload;
      return state;
    },
    setDashboardDateRange(state, action) {
      state.data.DateRange = { ...action.payload };
    },
    setAgentDashboardDateRange(state, action) {
      state.data.agentDateRange = { ...action.payload };
    },
    setBotDashboardDateRange(state, action) {
      state.data.botDateRange = { ...action.payload };
    },
    removeDashboard(state: any, action: PayloadAction<any>) {
      if (action.payload !== "botType") {
        state.data = initialState.data;
        state.BotId = "";
      } else {
        state.BotId = "";
        state.Bottype = "";
      }

      return state;
    },
    removeLoadingFromArray(state: any, action: PayloadAction<any>) {
      state.loadingArray = state.loadingArray.filter(
        (ele: any) => ele !== action?.payload,
      );
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        DashboardActions.getEnagementandConversion.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "engagementRate"];
        },
      )
      .addCase(
        DashboardActions.getAquisitionByDevice.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "deviceData"];
        },
      )
      .addCase(DashboardActions.getDashboardUsers.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.loadingArray = [...state.loadingArray, "userDashboard"];
      })
      .addCase(
        DashboardActions.getDashboardUsers.fulfilled,
        (state, action) => {
          state.data.dashboardUsers = action.payload?.data?.dashboardUsers;
          state.loading = false;
          state.error = false;
        },
      )
      .addCase(DashboardActions.getDashboardUsers.rejected, (state, action) => {
        state.data.dashboardUsers = {};
      })
      .addCase(
        DashboardActions.getDashReturningLead.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "returningLeads"];
        },
      )
      .addCase(
        DashboardActions.getDashReturningLead.fulfilled,
        (state, action) => {
          state.data.returning_leads = action.payload?.data?.returning_leads;
          state.loading = false;
          state.error = false;
        },
      )
      .addCase(
        DashboardActions.getDashReturningLead.rejected,
        (state, action) => {
          state.data.returning_leads = {};
        },
      )
      .addCase(DashboardActions.getDashDuration.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.loadingArray = [...state.loadingArray, "duration"];
      })
      .addCase(DashboardActions.getDashDuration.fulfilled, (state, action) => {
        state.data.duration = action.payload?.data?.duration;
        state.loading = false;
        state.error = false;
      })
      .addCase(DashboardActions.getDashDuration.rejected, (state, action) => {
        state.data.duration = {};
      })
      .addCase(
        DashboardActions.getCoversationStatus.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "conversationStatus"];
        },
      )
      .addCase(
        DashboardActions.getCoversationStatus.fulfilled,
        (state, action) => {
          state.data.conversation_status =
            action.payload?.data?.conversation_status;
          state.loading = false;
          state.error = false;
        },
      )
      .addCase(
        DashboardActions.getCoversationStatus.rejected,
        (state, action) => {
          state.data.conversation_status = {};
        },
      )
      .addCase(DashboardActions.getAgentStatus.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.loadingArray = [...state.loadingArray, "agentConversation"];
      })
      .addCase(DashboardActions.getAgentStatus.fulfilled, (state, action) => {
        state.data.agent_status = action.payload?.data;
        state.loading = false;
        state.error = false;
      })
      .addCase(DashboardActions.getAgentStatus.rejected, (state, action) => {
        state.data.agent_status = {};
      })
      .addCase(
        DashboardActions.getUserGrowthChannelWise.pending,
        (state, action) => {
          state.loadingArray = [...state.loadingArray, "userGrowth"];
        },
      )
      .addCase(
        DashboardActions.getUserGrowthChannelWise.fulfilled,
        (state, action) => {
          state.data.user_growth = action.payload?.data?.user_growth;
          state.loading = false;
          state.error = false;
        },
      )
      .addCase(
        DashboardActions.getUserGrowthChannelWise.rejected,
        (state, action) => {
          state.data.user_growth = {};
        },
      )
      .addCase(DashboardActions.getSummaryMsg.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.loadingArray = [...state.loadingArray, "messageSummary"];
      })
      .addCase(DashboardActions.getSummaryMsg.fulfilled, (state, action) => {
        state.data.summary_msg_dash = action.payload?.data;
        state.loading = false;
        state.error = false;
      })
      .addCase(DashboardActions.getSummaryMsg.rejected, (state, action) => {
        state.data.summary_msg_dash = {};
      })
      // Summary Csat
      .addCase(
        DashboardActions.getCsatDetails.pending,
        (state, action: any) => {
          state.error = false;
          state.loadingArray = [
            ...state.loadingArray,
            action?.meta?.arg?.csatRef,
          ];
        },
      )
      .addCase(DashboardActions.getBotConversation.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.loadingArray = [...state.loadingArray, "botConversations"];
      })
      .addCase(
        DashboardActions.getBotConversation.fulfilled,
        (state, action) => {
          state.data.bot_conversation = action.payload?.data;
          state.loading = false;
          state.error = false;
        },
      )
      .addCase(
        DashboardActions.getBotConversation.rejected,
        (state, action) => {
          state.data.bot_conversation = {};
        },
      )
      .addCase(DashboardActions.getResponseTime.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.loadingArray = [...state.loadingArray, "responseTime"];
      })
      .addCase(DashboardActions.getResponseTime.fulfilled, (state, action) => {
        state.data.response_time = action.payload?.data;
        state.loading = false;
        state.error = false;
      })
      .addCase(DashboardActions.getResponseTime.rejected, (state, action) => {
        state.data.response_time = {};
      })

      .addCase(DashboardActions.getlabelsSummary.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.loadingArray = [...state.loadingArray, "conversationalLabels"];
      })
      .addCase(DashboardActions.getDownloadLabel.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.loadingArray = [...state.loadingArray, "conversationalLabels"];
      })
      .addCase(
        DashboardActions.getCoversationalTraffic.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "traffic"];
        },
      )
      .addCase(
        DashboardActions.getCoversationalTraffic.fulfilled,
        (state, action) => {
          state.data.conversational_traffic = action.payload?.data;
          state.loading = false;
          state.error = false;
        },
      )
      .addCase(
        DashboardActions.getCoversationalTraffic.rejected,
        (state, action) => {
          state.data.conversational_traffic = {};
        },
      )
      //   Get Conversation By Agent
      .addCase(
        DashboardActions.getConversationsByAgent.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "agentConversation"];
        },
      )
      //   Download Conversation By Agent
      .addCase(
        DashboardActions.getDownloadConversations.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "agentConversation"];
        },
      )
      //   Get Agent Response Time
      .addCase(
        DashboardActions.getAgentResponseTime.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "timeStats"];
        },
      )
      .addCase(
        DashboardActions.getAgentCoversationStatus.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "conversationStatus"];
        },
      )
      .addCase(
        DashboardActions.getAgentCoversationStatus.fulfilled,
        (state, action) => {
          state.data.agent_conversation_status =
            action.payload?.data?.conversation_status;
          state.loading = false;
          state.error = false;
        },
      )
      .addCase(
        DashboardActions.getAgentCoversationStatus.rejected,
        (state, action) => {
          state.data.bot_conversation_status = {};
        },
      )
      .addCase(
        DashboardActions.getBotCoversationStatus.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
        },
      )
      .addCase(
        DashboardActions.getBotCoversationStatus.fulfilled,
        (state, action) => {
          state.data.bot_conversation_status =
            action.payload?.data?.conversation_status;
          state.loading = false;
          state.error = false;
        },
      )
      .addCase(
        DashboardActions.getBotCoversationStatus.rejected,
        (state, action) => {
          state.data.bot_conversation_status = {};
        },
      )

      .addCase(
        DashboardActions.getAquisitionByOverview.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "channelOverview"];
        },
      )
      .addCase(
        DashboardActions.getAquisitionByOverview.fulfilled,
        (state, action) => {
          state.data.channel_overview = action.payload?.data;
          state.loading = false;
          state.error = false;
        },
      )
      .addCase(
        DashboardActions.getAquisitionByOverview.rejected,
        (state, action) => {
          state.data.channel_overview = {};
        },
      )
      .addCase(DashboardActions.getMatomoDetails.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.loadingArray = [...state.loadingArray, "matomoDetails"];
      })
      .addCase(DashboardActions.getMatomoDetails.fulfilled, (state, action) => {
        state.project_id = action.payload?.data?.[0]?.config_value.site_id;

        state.loading = false;
        state.error = false;
      })
      .addCase(DashboardActions.getMatomoDetails.rejected, (state, action) => {
        state.project_id = {};
      })
      .addCase(DashboardActions.getProjectVersion.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.loadingArray = [...state.loadingArray, "matomoDetails"];
      })
      .addCase(
        DashboardActions.getProjectVersion.fulfilled,
        (state, action) => {
          state.matomo_details = action.payload?.data;
          state.loading = false;
          state.error = false;
        },
      )
      .addCase(DashboardActions.getProjectVersion.rejected, (state, action) => {
        state.matomo_details = {};
      })

      // APplication Form Response
      .addCase(
        DashboardActions.getApplicationFormAnalytics.rejected,
        (state, action) => {
          state.data.applicationFormData = {};
        },
      )
      .addCase(
        DashboardActions.getApplicationFormAnalytics.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "applicationformdata"];
        },
      )
      .addCase(
        DashboardActions.getApplicationFormAnalytics.fulfilled,
        (state, action) => {
          state.data.applicationFormData = action?.payload?.data;
          state.loading = false;
          state.error = false;
        },
      )

      // APplication Vs Contact Response
      .addCase(
        DashboardActions.getContactVSApplicationAction.rejected,
        (state, action) => {
          state.data.contactVsApplicationData = {};
        },
      )
      .addCase(
        DashboardActions.getContactVSApplicationAction.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "applicationvscontact"];
        },
      )
      .addCase(
        DashboardActions.getContactVSApplicationAction.fulfilled,
        (state, action) => {
          state.data.contactVsApplicationData = action?.payload?.data;
          state.loading = false;
          state.error = false;
        },
      )
      // Payment Filter
      .addCase(
        DashboardActions.getApplicationPaymentFilterAction.rejected,
        (state, action) => {
          console.log(action.error.message);
          state.loading = false;
          state.error = true;
          state.data.applicationFormDataError = true;
          state.data.applicationPaymentFIlter = {};
        },
      )
      .addCase(
        DashboardActions.getApplicationPaymentFilterAction.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [
            ...state.loadingArray,
            "applicationPaymentFilter",
          ];
        },
      )
      .addCase(
        DashboardActions.getApplicationPaymentFilterAction.fulfilled,
        (state, action) => {
          state.data.applicationPaymentFIlter = Array.isArray(action?.payload?.data?.response) ? action?.payload?.data : [];
          state.loading = false;
          state.error = false;
        },
      )

      // Payment Filter City
      .addCase(
        DashboardActions.getApplicationPaymentFilterActionCity.rejected,
        (state, action) => {
          state.data.applicationPaymentFIlterCity = {};
        },
      )
      .addCase(
        DashboardActions.getApplicationPaymentFilterActionCity.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [
            ...state.loadingArray,
            "applicationPaymentFilterCity",
          ];
        },
      )
      .addCase(
        DashboardActions.getApplicationPaymentFilterActionCity.fulfilled,
        (state, action) => {
          state.data.applicationPaymentFIlterCity = Array.isArray(action?.payload?.data?.response)  ? action?.payload?.data : [];
          state.loading = false;
          state.error = false;
        },
      )

      // Date Filter
      .addCase(
        DashboardActions.getApplicationDateFilterAction.rejected,
        (state, action) => {
          state.data.applicationDateFilter = {};
        },
      )
      .addCase(
        DashboardActions.getApplicationDateFilterAction.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "applicationDateFilter"];
        },
      )
      .addCase(
        DashboardActions.getApplicationDateFilterAction.fulfilled,
        (state, action) => {
          state.data.applicationDateFilter = action?.payload?.data;
          state.loading = false;
          state.error = false;
        },
      )

      // Submission Filter
      .addCase(
        DashboardActions.getApplicationSubmissionFilterAction.rejected,
        (state, action) => {
          state.data.applicationSubmissionFilter = {};
        },
      )
      .addCase(
        DashboardActions.getApplicationSubmissionFilterAction.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [
            ...state.loadingArray,
            "applicationSubmissionFilter",
          ];
        },
      )
      .addCase(
        DashboardActions.getApplicationSubmissionFilterAction.fulfilled,
        (state, action) => {
          state.data.applicationSubmissionFilter = Array.isArray(action?.payload?.data?.response) ? action?.payload?.data : [];
          state.loading = false;
          state.error = false;
        },
      )
      // Analitics Filter
      .addCase(
        DashboardActions.getApplicationAnalyticsSummaryFilterAction.rejected,
        (state, action) => {
          state.data.applicationAnalyticsFilter = {};
        },
      )
      .addCase(
        DashboardActions.getApplicationAnalyticsSummaryFilterAction.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [
            ...state.loadingArray,
            "applicationAnalyticsFilter",
          ];
        },
      )
      .addCase(
        DashboardActions.getApplicationAnalyticsSummaryFilterAction.fulfilled,
        (state, action) => {
          state.data.applicationAnalyticsFilter = action?.payload?.data;
          state.loading = false;
          state.error = false;
        },
      )

      // Application Filter
      .addCase(
        DashboardActions.getApplicationFilterAction.rejected,
        (state, action) => {
          state.data.applicationFilter = {};
        },
      )
      .addCase(
        DashboardActions.getApplicationFilterAction.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "applicationFilter"];
        },
      )
      .addCase(
        DashboardActions.getApplicationFilterAction.fulfilled,
        (state, action) => {
          state.data.applicationFilter = action?.payload?.data;
          state.loading = false;
          state.error = false;
        },
      )

      // Application Filter Offline Online
      .addCase(
        DashboardActions.getApplicationFilterActionOfflineOnline.rejected,
        (state, action) => {
          state.data.applicationFilterOfflineOnline = {};
        },
      )
      .addCase(
        DashboardActions.getApplicationFilterActionOfflineOnline.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [
            ...state.loadingArray,
            "applicationFilterOfflineOnline",
          ];
        },
      )
      .addCase(
        DashboardActions.getApplicationFilterActionOfflineOnline.fulfilled,
        (state, action) => {
          state.data.applicationFilterOfflineOnline = action?.payload?.data;
          state.loading = false;
          state.error = false;
        },
      )

      // Application Data
      .addCase(
        DashboardActions.getApplicationDataAction.rejected,
        (state, action) => {
          state.data.applicationData = {};
        },
      )
      .addCase(
        DashboardActions.getApplicationDataAction.pending,
        (state, action) => {
          state.loading = true;
          state.error = false;
          state.loadingArray = [...state.loadingArray, "applicationData"];
        },
      )
      .addCase(
        DashboardActions.getApplicationDataAction.fulfilled,
        (state, action) => {
          state.data.applicationData = action?.payload?.data;
          state.loading = false;
          state.error = false;
        },
      );
  },
});
export const {
  Overall,
  Agent,
  Bot,
  AgentChannelName,
  AgentBotID,
  BotID,
  BotType,
  LoadingStatus,
  removeDashboard,
  setDashboardDateRange,
  removeLoadingFromArray,
  setAgentDashboardDateRange,
  setBotDashboardDateRange,
} = DashboardSlice.actions;

export default DashboardSlice.reducer;
