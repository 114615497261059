import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormDatePicker,
  AppFormField,
  AppFormTimePickerMui,
} from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";
import { AppConfig } from "../../../../Config/app-config";
import { MultipleFieldsRow } from "../../../../Customization/CommonElements";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { format } from "date-fns";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import AppRangeFutureDatePicker from "../../../../AppComponents/AppRangeFutureDatePicker";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { CoreService } from "../../../../Services/Apis/CoreApis";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { marketingListActions } from "../../../../Store/Slices/MarketingList/marketingList.actions";
import { SMSActions } from "../../../../Store/Slices/SMS/sms.actions";
import {
  DeleteError,
  DeleteSuccess,
} from "../../../../Store/Slices/SMS/sms.selectors";
import { resetError } from "../../../../Store/Slices/SMS/sms.slice";
import {
  BroadcastTopTitle,
  TableFilter,
  TableWrapper,
} from "../../../../TableStyle";
import TemplateAPIERROR from "../../../../assets/images/TemplateAPIERROR.png";
import Danger from "../../../../assets/images/danger-live.svg";
import { PageDiv } from "../../WhatsApp/ScheduledBroadcast/Styles";
import { DeleteCannedResponse } from "../../WhatsApp/Templates/TemplatesListing";
import { ErrorComponent } from "../../WhatsApp/Templates/TemplatesListingCSS";
import { BROADCAST_SMS_FORM_DATA } from "../../WhatsApp/Utils/Constants";
import UploadTemplate from "../BroadCastTemplate/UploadTemplate";
import { EditBroadcast, SchenduleBoradcastWrapper } from "./Styles";
import { ProtectedAppButton } from "../../../../AppComponents/ProtectedComponents";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import AppSkeletonTableLoader from "../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";

// interface Props extends LoaderComponentProps {}
interface Props { }

const SMSScheduledBroadcast: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { isShowing, toggle } = useModal();
  const [isShowingModal, toggleModal] = useState<boolean>(false);
  const deleteErrorMessage = useAppSelector(DeleteError);
  const deleteSuccessMessage = useAppSelector(DeleteSuccess);
  const queryModel: any = useModal();
  const has_low_balance = localStorage.getItem("has_low_balance")
  const dispatch = useAppDispatch();
  const [errorApi, setErrorApi] = useState(false);
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [totalcount, setTotolCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [status, setStatus] = useState<string>("scheduled");
  const [credits, setCredits] = useState<any>([]);
  const [selectedId, setSelectedId] = useState<string>("");

  const [localValues, setLocalValues] = useState<any>({
    search: "",
    status: "",
    category: "",
    startDate: null,
    endDate: null,
  });

  let { showAlert } = useNotification();

  //* Render only once*//
  useEffect(() => {
    AppConfig.setUserData(BROADCAST_SMS_FORM_DATA, null);
  }, []);
  useEffect(() => {
    if (deleteErrorMessage && deleteErrorMessage?.length > 0) {
      showAlert(deleteErrorMessage, "error");

      setTimeout(function () {
        dispatch(resetError({}));
      }, 5000);
    }
    if (deleteSuccessMessage && deleteSuccessMessage?.length > 0) {
      showAlert(deleteSuccessMessage, "success");

      setTimeout(function () {
        dispatch(resetError({}));
      }, 5000);
    }
  }, [deleteSuccessMessage, deleteErrorMessage]);
  const getPreviousData = () => {
    let start = null;
    let end = null;
    if (localValues.startDate && localValues.endDate) {
      start = format(localValues.startDate, "yyyy-MM-dd");
      end = format(localValues.endDate, "yyyy-MM-dd");
    }
    const payload: any = {
      scheduled_start: start,
      scheduled_end: end,
      limit: 20,
      offset: 0,
      status: status,
      search: localValues?.search,
      broadcast_type: "sms",
    };
    setLoading(true);
    dispatch(marketingListActions.getAllBroadcastData(payload))
      .then((res: any) => {
        setList(res?.payload?.data.results);
        setTotolCount(res?.payload?.data?.count);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPreviousData();
  }, [localValues, currentPage, itemsPerPage]);

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  };

  const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
    setLocalValues({
      ...localValues,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  };

  const editBroadcastTemplate = (id: any, view?: boolean) => {
    if (view) {
      return navigate(`/broadcast/sms/edit-broadcast/1/${id}?view=true`);
    }
    navigate(`/broadcast/sms/edit-broadcast/4/${id}`);
  };
  useEffect(() => {
    CoreService.getCredits().then((response) => {
      const reversedItems = response?.data;
      setCredits(reversedItems);
    });
  }, [localValues]);
  const handleClickDelete = (id: any) => {
    const payload: any = {
      id: id,
      type: "sms",
    };
    dispatch(SMSActions.deleteSmsBroadcastById(payload)).then((res: any) => {
      getPreviousData();
      toggleModal(false);
    });
  };

  // Headers For Table
  const header = ["Name", "Recipients", "Scheduled"];
  // Mapping For Table Column
  const keyToHeaderMap: any = {
    Name: "broadcast_name",
    Recipients: "recipients",
    Scheduled: "scheduled_date",
  };
  // Width For Table Column
  const colWidth: any = {
    Name: "340",
    Recipients: "280",
    Scheduled: "320",
  };
  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format(" DD MMM , YYYY hh:mm A ");
    // const date = moment(dateObj).format("DD MMM, YYYY HH:mm A");
    return date;
  };

  return (
    <SchenduleBoradcastWrapper>
      {/* Top BAr */}

      {/* ==== Header ==== */}

      <PageDiv>
        <BroadcastTopTitle style={{ padding: "16px 0px" }}>
          <Box className="topTitle-left">
            <Typography fontSize="16px" fontWeight="500" component="h6">
              Scheduled Broadcast
            </Typography>
          </Box>
          <Box className="topTitle-right">
            <ProtectedAppButton
              moduleId="sms_template"
              specificPermissionKey="create"
              variant="outline"
              onClick={() => queryModel.toggle()}
              style={{ minWidth: "170px" }}
            >
              Upload Template
            </ProtectedAppButton>
            <ProtectedAppButton
              moduleId="sms_broadcast_crud"
              specificPermissionKey="create"
              variant="primarydark"
              onClick={() => navigate("/broadcast/sms/create-broadcast/1")}
              isDisabled={has_low_balance === "true"}
              title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
            >
              Create Broadcast
              <AppMaterialIcons iconName="Add" />
            </ProtectedAppButton>
          </Box>
        </BroadcastTopTitle>

        {/* ==== Filter ==== */}

        <AppForm // requestSearch(values, submitProps);
          initialValues={{ search: "" }}
          onSubmit={(values: any, submitProps: any) => {}}
          // validationSchema={BotValidationSchema}
          divStyle={{ width: "100%" }}
        >
          <TableFilter style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <div className="filter-left">
              <Typography fontSize="13px" variant="h6">
                Sort By
              </Typography>
              <Box>
                <AppRangeFutureDatePicker
                  dateRange={{
                    startDate: localValues?.startDate,
                    endDate: localValues?.endDate,
                  }}
                  reset={true}
                  setDateRange={handleChangeDate}
                  dateFieldStyle={{
                    height: "28px",
                  }}
                />
              </Box>
              <Box>
                <AppFormSearchField
                  name="search"
                  placeholder="Search"
                  defaultBorder={true}
                  divStyle={{
                    marginBottom: "0",
                  }}
                  onChange={(e: any) => handleSelectChange(e, "search")}
                />
              </Box>
            </div>
            <div className="filter-right"></div>
          </TableFilter>
        </AppForm>

        {/* ==== Table ==== */}
                  {
          loading ? (<AppSkeletonTableLoader
            numberOfCol={4}
            numberofRow={10}
            skeletonHeight={30}
            skeletonWidth={"100%"}
            skeletonHeaderHeight={40}
            skeletonHeaderWidth={"100%"}
            tableHeight={`calc(100vh - 287px)`}
          />) : (
              <TableWrapper height="calc(100vh - 215px)">
                <div>
                  <Box className="table-header">
                    <Box className="table-row">
                      {header?.map((header: string, index: number) => {
                        return (
                          <Box
                            className={`col-head ${header
                              .toLowerCase()
                              .replaceAll(" ", "_")}`}
                            style={{
                              // maxWidth: colWidth[header] + "px",
                              minWidth: colWidth[header] + "px",
                            }}
                          >
                            <Typography sx={{ fontWeight: 500 }}>
                              {header}
                            </Typography>
                          </Box>
                        );
                      })}
                      <Box
                        className="col-head"
                        sx={{
                          maxWidth: "100px",
                          minWidth: "100px",
                          height: "2.969rem",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography></Typography>
                      </Box>
                    </Box>
                  </Box>

                  {
                    errorApi || list?.length === 0 ? (
                      <NoRecordsFound />
                    ) : (
                      <Box className="table-body">
                        {list?.map((row: any, rowIndex: any) => {
                          return (
                            <Box key={rowIndex} className="table-row">
                              {header?.map((header: any, ColumnIndex: any) => {
                                const isActionColumn = header === "Action";
                                return (
                                  <Box
                                    className={`col-body ${header
                                      .toLowerCase()
                                      .replaceAll(" ", "_")}`}
                                    title={
                                      header === "Scheduled"
                                        ? formatDateField(row[keyToHeaderMap[header]])
                                        : row[keyToHeaderMap[header]]
                                          ? row[keyToHeaderMap[header]]
                                          : "-"
                                    }
                                    style={{
                                      // maxWidth: isActionColumn
                                      //   ? "400px"
                                      //   : colWidth[header] + "px",
                                      minWidth: isActionColumn
                                        ? "400px"
                                        : colWidth[header] + "px",
                                    }}
                                  >
                                    <Typography className="table-text">
                                      {header === "Scheduled"
                                        ? formatDateField(row[keyToHeaderMap[header]])
                                        : row[keyToHeaderMap[header]]
                                          ? row[keyToHeaderMap[header]]
                                          : "-"}
                                    </Typography>
                                  </Box>
                                );
                              })}

                              <Box
                                display="flex"
                                sx={{
                                  maxWidth: "100px",
                                  minWidth: "100px",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                                className="col-body"
                              >
                                {row?.can_update && (
                                  <IconButton
                                    onClick={() => editBroadcastTemplate(row?.id)}
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                )}
                                {row?.can_delete && (
                                  <IconButton>
                                    <DeleteOutlineOutlinedIcon
                                      onClick={() => {
                                        setSelectedId(row?.id);
                                        toggleModal(true);
                                      }}
                                      fontSize="small"
                                    />
                                  </IconButton>
                                )}
                                <IconButton>
                                  <RemoveRedEyeOutlinedIcon
                                    onClick={() =>
                                      editBroadcastTemplate(row?.id, true)
                                    }
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    )
                  }



                </div>
              </TableWrapper>
          )
                  }
        
            

            <AppModel
              isShowing={isShowing}
              onClose={() => toggle()}
              divStyle={{
                padding: 0,
              }}
              PaperProps={{
                style: {
                  boxShadow: "none",
                  width: "600px",
                },
              }}
            >
              <EditBroadcast>
                <div className="hedaer">
                  <div className="title">
                    <h4>Edit Broadcast</h4>
                  </div>
                  <div className="close">
                    <AppMaterialIcons iconName={"Close"} />
                  </div>
                </div>
                <div className="body">
                  <div className="form-wrap">
                    <AppForm
                      initialValues={{}}
                      onSubmit={(values: any, submitProps: any) => {
                        // handleSubmitBtn(values, submitProps);
                      }}
                      initialTouched={{}}
                    >
                      <AppFormField
                        name="broadcast_name"
                        label="Broadcast Name"
                        placeholder="Broadcast name"
                      />
                      <MultipleFieldsRow>
                        <AppFormDatePicker name="date" />
                        <AppFormTimePickerMui name="time" />
                      </MultipleFieldsRow>
                    </AppForm>
                  </div>
                </div>
                <div className="footer">
                  <div className="save">
                    <AppButton>Save</AppButton>
                  </div>
                </div>
              </EditBroadcast>
            </AppModel>
          
      </PageDiv>
      <AppModel
        isShowing={queryModel.isShowing}
        onClose={() => queryModel.toggle()}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
      >
        <UploadTemplate close={() => queryModel.toggle()} />
      </AppModel>
      <AppModel
        isShowing={isShowingModal}
        onClose={toggleModal}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "500px",
          },
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="danger">
                <img src={Danger} alt="" />
              </div>
              <div className="text">
                <h4>Delete The SMS Broadcast</h4>
                <p>Are you sure, you want to delete?</p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => toggleModal(false)}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => toggleModal(false)}>
              Cancel
            </AppButton>
            <AppButton
              variant="danger-filled"
              onClick={() => handleClickDelete(selectedId)}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </AppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>
    </SchenduleBoradcastWrapper>
  );
};

// export default withLoader(SMSScheduledBroadcast);
export default SMSScheduledBroadcast;
