import http from "../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class WhatsappSettingsService {
    // post whatsapp config 
    static whatsappConfigData(data: any) {
        return http.post(
            `${consoleBaseUrl}${Endpoints.ADD_WHATSAPP_CONFIG_URL}`, data
        )
    }

    // get whatsapp config data list
    static getWhatsappConfigData() {
        return http.get(`${consoleBaseUrl}${Endpoints.WHATSAPP_CONFIG_LIST_URL}`);
    }
}

// Label EndpointValidate whatsapp Settings 
class Endpoints {
    static ADD_WHATSAPP_CONFIG_URL = "/workspace/whatsapp/auth/callback"
    static WHATSAPP_CONFIG_LIST_URL = "/workspace/whatsapp/config"
}