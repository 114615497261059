// vendors
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import cloneDeep from "lodash.clonedeep";

// components
import UIButton from "../../../Components/Button";
import Input from "../../../Components/Input";
import Icon from "../../../Components/Icon";

const LiveAgent = ({ onSaveClick, activeButtonData, onCancelClick }: any) => {
  const [value, setValue] = useState<string>("");

  const handleSaveClick = () => {
    let updatedAction = cloneDeep(activeButtonData);
    updatedAction.type = 'live_agent';
    updatedAction.value.text = value;
    onSaveClick && onSaveClick(updatedAction);
  };

  const handleCancelClick = () => {
    onCancelClick && onCancelClick();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (activeButtonData && activeButtonData.type == "live_agent") {
      setValue(activeButtonData.value.text);
    }
  }, [activeButtonData]);

  return (
    <>
      <Box sx={{ px: "16px" }}>
        <UIButton fullWidth color="success" variant="outlined">
          Live Agent
        </UIButton>
      </Box>
      <Box sx={{ p: "16px" }}>
        <Box>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#7E8392",
            }}
          >
            Button title
          </Typography>
          <Input
            placeholder="Title"
            onChange={handleChange}
            value={value}
            error={value === ""}
          />
        </Box>
      </Box>
      <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UIButton variant="outlined" onClick={handleCancelClick}>
            Cancel
          </UIButton>
          <UIButton
            color="primary"
            variant="contained"
            startIcon={<Icon icon="save" color="#fff" size={13} />}
            disabled={value === ""}
            onClick={handleSaveClick}
          >
            Save
          </UIButton>
        </Box>
      </Box>
    </>
  );
};
export default LiveAgent;
